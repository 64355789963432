import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FaqService, FaqQuestionAnswer } from '../faq.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

@Component({
  selector: 'app-faq-details',
  templateUrl: './faq-details.component.html',
  styleUrls: ['./faq-details.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate(300)]),
      transition(':leave', [animate(500)]),
    ]),
  ],
})
export class FaqDetailsComponent implements OnInit {
  lan: number;
  step = 0;
  moduleId: number;
  moduleNameEn: string;
  moduleNameHi: string;
  questions: FaqQuestionAnswer[] = [];
  sanitizedQuestions: any[] = []; // Array for sanitized questions with HTML line breaks
  showData: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private faqService: FaqService,
    private ngxLoader: NgxUiLoaderService,
    private translate: TranslateService,
    private translateHE: TranslateHEService,
    private sanitizer: DomSanitizer
  ) {
    // Initialize language based on session storage
    this.lan = sessionStorage.getItem("selectedLanguage") === 'hi' ? 1 : 0;

    // Subscribe to language change notifications
    this.translateHE.currentApprovalStageMessage.subscribe((msg: number) => {
      this.lan = msg;
      this.translate.use(this.lan === 1 ? 'hi' : 'en');
    });
  }

  ngOnInit(): void {
    // Get module details from query parameters
    this.route.queryParams.subscribe((params) => {
      this.moduleId = +params['moduleId'];
      this.moduleNameEn = params['moduleNameEn'];
      this.moduleNameHi = params['moduleNameHi'];
      this.loadQuestions(); // Load questions for the module
    });
  }

  // Load questions based on moduleId
  loadQuestions(): void {
    this.ngxLoader.start();
    this.faqService.getFaqQuestions(this.moduleId).subscribe(
      (response: any) => {
        if (response.responseData && response.responseData.length > 0) {
          this.questions = response.responseData;
          // Sanitize and format questions with HTML line breaks
          this.sanitizedQuestions = this.questions.map((question) => ({
            ...question,
            answerTextEn: this.sanitizeText(question.answerTextEn),
            answerTextHi: this.sanitizeText(question.answerTextHi),
          }));
          this.showData = true;
        } else {
          this.questions = [];
          this.showData = false;
        }
        this.ngxLoader.stop();
      },
      (error) => {
        console.error('Error fetching questions:', error);
        this.questions = [];
        this.showData = false;
        this.ngxLoader.stop();
      }
    );
  }

  // Replace \n with <br> tags and sanitize
  sanitizeText(text: string): any {
    const formattedText = text ? text.replace(/\\n/g, '<br>') : '';
    return this.sanitizer.bypassSecurityTrustHtml(formattedText);
  }  

  // Set the currently opened step in the accordion
  setStep(index: number): void {
    this.step = index;
  }
}
