<div class="fid-header">
    <div class="container">
        <div class="breadcrumb-content">
            <div class="row">
                <div class="col-lg-12">
                    <h3>{{'citizen-registration-search-doc-details.sign-up-as'|translate}}</h3>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container margin-b10">

    <lib-alertmessage></lib-alertmessage>
    <form (ngSubmit)="onSubmitForPersonalProfile($event, profilePersonalDetailHtml.form)"
        #profilePersonalDetailHtml="ngForm" novalidate autocomplete="off">

        <div class="card">

            <div class="card-body">
                <fieldset class="legend-section">
                    <legend class="sidebar__widget-title">

                        {{'citizen-profile-details.citizen-contact-details.heading'|translate}}
                    </legend>
                    <div class="row">

                        <div class="col-md-12" [ngClass]="{'order-2': lan === 1}">
                            <div class="form-group">
                                <label>{{'citizen-registration-details.personal-details.fullname' | translate}} <span
                                        style="color: red;">*</span></label>
                                <div class="input-group mb-3">
                                    <select
                                        [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && title.invalid }"
                                        required id="title" name="title" #title="ngModel"
                                        [(ngModel)]="userProfileModel.title" class="form-control "
                                        style="max-width: 60px;padding: 0;">
                                        <option [ngValue]="null" disabled>Title</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Miss">Miss</option>
                                    </select>
                                    <input [disabled]="firstNameDisable" (change)="send(firstNameEn, firstNameHi)"
                                        [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && firstNameEn.invalid }"
                                        required id="firstNameEn" name="firstNameEn" #firstNameEn="ngModel"
                                        [(ngModel)]="userProfileModel.firstNameEn" class="form-control "
                                        placeholder="First Name" maxlength="60" alpha [appCopyPastValidation]="true">
                                    <input [disabled]="middleNameDisable" id="middleNameEn" name="middleNameEn"
                                        #middleNameEn="ngModel" [(ngModel)]="userProfileModel.middleNameEn"
                                        class="form-control " placeholder="Middle Name (Optional)" maxlength="60" alpha
                                        [appCopyPastValidation]="true" (change)="send(middleNameEn, middleNameHi)">
                                    <input [disabled]="lastNameDisable" (change)="send(lastNameEn, lastNameHi)"
                                        [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && lastNameEn.invalid }"
                                        required id="lastNameEn" name="lastNameEn" #lastNameEn="ngModel"
                                        [(ngModel)]="userProfileModel.lastNameEn" class="form-control "
                                        placeholder="Last Name" maxlength="60" alpha [appCopyPastValidation]="true">
                                    <div *ngIf="profilePersonalDetailHtml.submitted && (title.invalid || firstNameEn.invalid || lastNameEn.invalid)"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="lan==0 &&(title.errors?.required || firstNameEn.errors?.required || lastNameEn.errors?.required)">
                                            This Field is Mandatory</div>
                                        <div
                                            *ngIf="lan==1 &&(title.errors?.required || firstNameEn.errors?.required || lastNameEn.errors?.required)">
                                            {{'required' | translate}}</div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="col-lg-12" [ngClass]="{'order-1': lan === 1}">
                            <div class="form-group">
                                <label>{{'citizen-registration-details.personal-details.fullnamehi' | translate}} <span
                                        style="color: red;">*</span></label>
                                <div class="input-group mb-3">
                                    <select
                                        [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && titlehi.invalid }"
                                        required id="titlehi" name="titlehi" #titlehi="ngModel"
                                        [(ngModel)]="userProfileModel.title" class="form-control "
                                        style="max-width: 60px;padding: 0;">
                                        <option [ngValue]="null" disabled>शीर्षक</option>
                                        <option value="Mr.">श्री</option>
                                        <option value="Mrs.">श्रीमती</option>
                                        <option value="Miss">कुमारी</option>
                                    </select>
                                    <input pattern="[\u0900-\u097F. : -]+"
                                        [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && firstNameHi.invalid }"
                                        (change)="sendHi(firstNameHi,firstNameEn)" required id="firstNameHi"
                                        name="firstNameHi" #firstNameHi="ngModel"
                                        [(ngModel)]="userProfileModel.firstNameHi" class="form-control "
                                        placeholder="पहला नाम" maxlength="60">
                                    <input pattern="[\u0900-\u097F. : -]+" id="middleNameHi" name="middleNameHi"
                                        #middleNameHi="ngModel" [(ngModel)]="userProfileModel.middleNameHi"
                                        (change)="sendHi(middleNameHi,middleNameEn)" class="form-control "
                                        placeholder="मध्य नाम (वैकल्पिक)" maxlength="60">
                                    <input pattern="[\u0900-\u097F. : -]+"
                                        [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && lastNameHi.invalid }"
                                        required id="lastNameHi" name="lastNameHi" #lastNameHi="ngModel"
                                        [(ngModel)]="userProfileModel.lastNameHi"
                                        (change)="sendHi(lastNameHi,lastNameEn)" class="form-control "
                                        placeholder="उपनाम" maxlength="60">
                                    <div *ngIf="profilePersonalDetailHtml.submitted && (firstNameHi.invalid || lastNameHi.invalid)"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="lan==0 &&(firstNameHi.errors?.required || lastNameHi.errors?.required)">
                                            This Field is Mandatory</div>
                                        <div
                                            *ngIf="lan==1 &&(firstNameHi.errors?.required || lastNameHi.errors?.required)">
                                            {{'required' | translate}}</div>
                                    </div>
                                    <div *ngIf="(firstNameHi.touched && firstNameHi.invalid) || (middleNameHi.touched && middleNameHi.invalid) || (lastNameHi.touched && lastNameHi.invalid)"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="firstNameHi.errors?.pattern || middleNameHi.errors?.pattern || lastNameHi.errors?.pattern">
                                            हिंदी में टाइप करें</div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="col-md-6" [ngClass]="{'order-3': lan === 1}">
                            <label
                                class="d-block">{{'citizen-profile-details.citizen-contact-details.mobile-number'|translate}}
                                <span style="color: red;">*</span></label>

                            <div class="row">
                                <div class="col-md-9 col-sm-8 col-8">
                                    <div class="form-group">
                                        <div>
                                            <input type="text" class="form-control " name="contactNo"
                                                #contactNo="ngModel" [(ngModel)]="userProfileModel.contactNo"
                                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-mobile-number'|translate}}"
                                                [OnlyNumber]="true" maxlength="10" minlength="10" pattern="^[6-9]\d{9}$"
                                                required (contextmenu)="false;" [appCopyPastValidation]="true"
                                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && contactNo.invalid }">
                                            <div *ngIf="profilePersonalDetailHtml.submitted && contactNo.invalid"
                                                class="invalid-feedback">
                                                <div *ngIf="lan==0 &&(contactNo.errors?.required)">This Field is
                                                    Mandatory
                                                </div>
                                                <div *ngIf="lan==1 &&(contactNo.errors?.required)">{{'required' |
                                                    translate}}</div>
                                            </div>
                                            <div *ngIf="contactNo.touched && contactNo.invalid"
                                                class="invalid-feedback">
                                                <div *ngIf="contactNo.errors?.pattern">
                                                    {{'citizen-profile-details.login-detail-profile.mobile-validate' |
                                                    translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-4 col-4">
                                    <a mat-raised-button *ngIf="counter == 0 && !otpTouched && otpBtnVisibility"
                                        mat-button class="btn btn-primary  btn-sm" (click)="setOtpClicked()"
                                        (keyup.enter)="setOtpClicked()">{{'get-otp'
                                        |
                                        translate}}</a>

                                    <a mat-raised-button *ngIf="counter == 0 && !otpTouched && !otpBtnVisibility"
                                        mat-button class="btn btn-success btn-sm"><i style="font-size: 25px;"
                                            class="fa fa-check-circle-o" aria-hidden="true"></i></a>

                                    <a *ngIf="counter == 0 && otpTouched" mat-button class="btn btn-sm btn-info btn-sm"
                                        (click)="setOtpClicked()" (keyup.enter)="setOtpClicked()">{{'resend-otp'
                                        | translate}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" [ngClass]="{'order-3': lan === 1}">
                            <div class="row" *ngIf="getOtpClicked">
                                <label
                                    class="w-100 d-flex align-items-center bg-light justify-content-between mr-3"><span
                                        class="float-left">{{'enter-otp' | translate}}</span>

                                    <span  *ngIf="counter != 0">
                                        <span style="font-weight: 400; font-size: 13px;">
                                            {{'regenerate_otp' | translate}} 
                                        </span>  &nbsp;
                                        {{counter |
                                        formatTime}}</span>
                                         <p style="font-weight: 400; font-size: 13px;">( {{'otpValid'| translate}} )</p>

                                </label>
                                <div class="col-md-7 pl-0 col-7">
                                    <div class="form-group">
                                        <input class="form-control input_user" name="otp" [(ngModel)]="otp"
                                            minlength="6" maxlength="6" required [OnlyNumber]="true">
                                    </div>
                                </div>
                                <div class="col-md-5 col-5">
                                    <div class="d-flex">
                                        <button type="button" mat-raised-button class="btn btn-primary mr-1"
                                            (click)="verifyOtp()">{{'submit' |
                                            translate}}</button>

                                        <button mat-raised-button *ngIf="counter == 0" class="btn btn-secondary"
                                            (click)="resetForm()">{{'reset' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" [ngClass]="{'order-4': lan === 1}">
                            <label>{{'citizen-profile-details.citizen-contact-details.e-mail'|translate}} </label> <!--  <span
                                    style="color: red;">*</span> -->
                            <div class="row">
                                <div class="col-md-9 col-sm-8 col-8">
                                    <div class="form-group">

                                        <input type="text" class="form-control" name="email" #email="ngModel"
                                            [(ngModel)]="userProfileModel.email"
                                            placeholder="{{'citizen-profile-details.citizen-contact-details.enter-e-mail'|translate}} "
                                            maxlength="60" required (contextmenu)="false;"
                                            [appCopyPastValidation]="true"
                                            [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && email.invalid }"
                                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                            (change)="getEmailAvailability(email.value)">
                                        <!--  (blur)="onChangeEmail()" -->
                                        <!-- (change)="getEmailAvailability(email.value)"> -->
                                        <div *ngIf="profilePersonalDetailHtml.submitted && email.invalid"
                                            class="invalid-feedback">
                                            <div *ngIf="lan==0 &&(email.errors?.required)">This Field is Mandatory</div>
                                            <div *ngIf="lan==1 &&(email.errors?.required)">{{'required' | translate}}
                                            </div>
                                        </div>
                                        <div *ngIf="email.touched && email.invalid" class="invalid-feedback-1">
                                            <div *ngIf="email.errors?.pattern">
                                                {{'citizen-profile-details.citizen-contact-details.mail-validate' |
                                                translate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-3 col-sm-4 col-4">
                                    <a mat-raised-button
                                        *ngIf="counterForEmail == 0 && !otpTouchedForEmail && otpBtnVisibilityForEmail"
                                        mat-button class="btn btn-primary  btn-sm"
                                        (click)="setOtpClickedForEmail()">{{'get-otp' |
                                        translate}}</a>

                                    <a mat-raised-button
                                        *ngIf="counterForEmail == 0 && !otpTouchedForEmail && !otpBtnVisibilityForEmail"
                                        mat-button class="btn btn-success btn-sm"><i style="font-size: 25px;"
                                            class="fa fa-check-circle-o" aria-hidden="true"></i></a>

                                    <a *ngIf="counterForEmail == 0 && otpTouchedForEmail" mat-button
                                        class="btn btn-sm btn-info" (click)="setOtpClickedForEmail()">{{'resend-otp'
                                        | translate}}</a>
                                </div> -->
                            </div>

                        </div>
                        <div class="col-md-6" [ngClass]="{'order-4': lan === 1}">
                            <div *ngIf="getOtpClickedForEmail">

                                <div class="row">
                                    <label
                                        class="w-100 d-flex align-items-center bg-light justify-content-between mr-3"><span
                                            class="float-left">{{'enter-otp' | translate}}</span>

                                        <span  *ngIf="counterForEmail != 0">
                                            <span style="font-weight: 400; font-size: 13px;">{{'regenerate_otp' | translate}}</span> &nbsp;
                                            {{counterForEmail |
                                            formatTime}}</span>
                                             <p style="font-weight: 400; font-size: 13px;">( {{'otpValid'| translate}} )</p>

                                    </label>
                                    <div class="col-md-7 pl-0 col-7">
                                        <div class="form-group">
                                            <input class="form-control input_user" name="otpForEmail"
                                                [(ngModel)]="otpForEmail" minlength="6" maxlength="6" required
                                                [OnlyNumber]="true">
                                        </div>
                                    </div>
                                    <div class="col-md-5  col-5">
                                        <button type="button" mat-raised-button class=" btn btn-primary mr-1"
                                            (click)="verifyOtpForEmail()">{{'submit' |
                                            translate}}</button>

                                        <button mat-raised-button *ngIf="counterForEmail == 0"
                                            class=" btn btn-secondary" (click)="resetFormForEmail()">{{'reset' |
                                            translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="legend-section">
                    <legend class="sidebar__widget-title">
                        {{'citizen-registration-details.address-detail'|translate}}
                    </legend>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{'citizen-profile-details.citizen-contact-details.state'|translate}} <span
                                        style="color: red;">*</span></label>
                                <ng-select
                                    [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && stateIds.invalid }"
                                    name="stateIds" #stateIds="ngModel" required [(ngModel)]="stateId"
                                    (change)="setState()">
                                    <ng-option value=""
                                        selected>{{'citizen-registration-details.address-details.select-state'|translate}}</ng-option>
                                    <ng-option *ngFor="let ds of states"
                                        [value]="ds.id">{{lan==1?ds.demographyNameHi:ds.demographyNameEn}}</ng-option>
                                </ng-select>
                                <div *ngIf="profilePersonalDetailHtml.submitted && stateIds.invalid"
                                    class="invalid-feedback">
                                    <div *ngIf="lan==0 &&(stateIds.errors?.required)">This Field is Mandatory</div>
                                    <div *ngIf="lan==1 &&(stateIds.errors?.required)">{{'required' | translate}}</div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{'citizen-profile-details.citizen-contact-details.district'|translate}} <span
                                        style="color: red;">*</span></label>
                                <ng-select
                                    [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && districtIds.invalid }"
                                    name="districtIds" #districtIds="ngModel" required [(ngModel)]="districtId"
                                    (change)="setDistrict()"> <!--  (change)="checkForSameAddress()" -->
                                    <ng-option value=""
                                        selected>{{'citizen-registration-details.address-details.select-district'|translate}}</ng-option>
                                    <ng-option *ngFor="let ds of districts"
                                        [value]="ds.id">{{lan==1?ds.demographyNameHi:ds.demographyNameEn}}</ng-option>
                                </ng-select>
                                <div *ngIf="profilePersonalDetailHtml.submitted && districtIds.invalid"
                                    class="invalid-feedback">
                                    <div *ngIf="lan==0 &&(districtIds.errors?.required)">This Field is Mandatory</div>
                                    <div *ngIf="lan==1 &&(districtIds.errors?.required)">{{'required' | translate}}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{'citizen-profile-details.citizen-contact-details.address-line-1'|translate}}
                                    <span style="color: red;">*</span></label>
                                <input [disabled]="addressLine1Disable"
                                    [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && addressLine1.invalid }"
                                    name="addressLine1" #addressLine1="ngModel" required
                                    [(ngModel)]="userProfileModel.addressLine1" class="form-control "
                                    placeholder="{{'citizen-profile-details.citizen-contact-details.enter-address-line-1'|translate}}"
                                    maxlength="120" appAlphaNumericSpace [appCopyPastValidation]="true">
                                <!--  (change)="checkForSameAddress()" -->
                                <div *ngIf="profilePersonalDetailHtml.submitted && addressLine1.invalid"
                                    class="invalid-feedback">
                                    <div *ngIf="lan==0 &&(addressLine1.errors?.required)">This Field is Mandatory</div>
                                    <div *ngIf="lan==1 &&(addressLine1.errors?.required)">{{'required' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">

                            <div class="form-group">
                                <label>{{'citizen-profile-details.citizen-contact-details.address-line-2'|translate}}</label>
                                <input [disabled]="addressLine2Disable" name="addressLine2" #addressLine2="ngModel"
                                    [(ngModel)]="userProfileModel.addressLine2" class="form-control "
                                    placeholder="{{'citizen-profile-details.citizen-contact-details.enter-address-line-2'|translate}}"
                                    maxlength="120" appAlphaNumericSpace [appCopyPastValidation]="true">
                                <!--  (change)="checkForSameAddress()" -->
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{'citizen-profile-details.citizen-contact-details.city'|translate}} <span
                                        style="color: red;">*</span></label>
                                <input [disabled]="cityDisable"
                                    [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && city.invalid }"
                                    name="city" #city="ngModel" required [(ngModel)]="userProfileModel.city"
                                    class="form-control"
                                    placeholder="{{'citizen-profile-details.citizen-contact-details.enter-city'|translate}}"
                                    maxlength="60" alpha [appCopyPastValidation]="true">
                                <!--  (change)="checkForSameAddress()" -->
                                <div *ngIf="profilePersonalDetailHtml.submitted && city.invalid"
                                    class="invalid-feedback">
                                    <div *ngIf="lan==0 &&(city.errors?.required)">This Field is Mandatory</div>
                                    <div *ngIf="lan==1 &&(city.errors?.required)">{{'required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{'citizen-profile-details.citizen-contact-details.postal-code'|translate}} <span
                                        style="color: red;">*</span></label>
                                <input [disabled]="postalCodeDisable"
                                    [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && postalCode.invalid }"
                                    name="postalCode" #postalCode="ngModel" required
                                    [(ngModel)]="userProfileModel.postalCode" class="form-control"
                                    placeholder="{{'citizen-profile-details.citizen-contact-details.enter-postal-code'|translate}}"
                                    minlength="6" maxlength="6" [OnlyNumber]="true" pattern="^\d{6}$">
                                <!--  (change)="checkForSameAddress()" -->
                                <div *ngIf="profilePersonalDetailHtml.submitted && postalCode.invalid"
                                    class="invalid-feedback">
                                    <div *ngIf="lan==0 &&(postalCode.errors?.required)">This Field is Mandatory</div>
                                    <div *ngIf="lan==1 &&(postalCode.errors?.required)">{{'required' | translate}}</div>
                                </div>
                                <div *ngIf="postalCode.touched && postalCode.invalid" class="invalid-feedback">
                                    <div *ngIf="postalCode.errors?.pattern">
                                        {{'citizen-registration-details.address-details.postal-code-validate' |
                                        translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>{{'enter-captcha' | translate}} <span style="color: red;">*</span></label>
                            <div class="row">


                                <div class="col-md-5 col-6">
                                    <div class="input-group mb-2 ">
                                        <input type="text" name="captchaStr" [(ngModel)]="captchaStr" required
                                            minlength="4" maxlength="4" class="form-control input_user p-3"
                                            placeholder="{{'enter-captcha' | translate}}">
                                    </div>
                                </div>
                                <div class="col-md-auto  text-center col-2">
                                    <img src="assets/images/refresh_image.png" height="26px" class="mt-2"
                                        (click)="getStartupCaptcha()" />
                                </div>
                                <div class="col-md-auto col-4">
                                    <div class="input-group mb-2" style="align-content: center;">
                                        <img src="{{captchaImageBase64Img}}" height="50" />
                                        <!-- <button (click)="getStartupCaptcha()">REFRESH CAPTCHA</button> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </fieldset>

                <!-- !verifyMobileNo || !verifyEmail -->
                <button [disabled]="!verifyMobileNo" class="mt-2  ml-2 btn btn-primary float-right" mat-raised-button type="submit" id="submit"
                    name="submit" value="submit">{{'submit_details' | translate}}</button>
            </div>

        </div>

    </form>

</div>