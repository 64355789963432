<div class="position-relative">
    <div class="fid-header"></div>
    <div class="breadcrumb-content">
        <div class="container">
            <div class="d-flex justify-content-between">
                <h2>
                    {{'detailsPages.docserCerti' | translate}}
                </h2>

                <div>
                    <button mat-button="mat-button" class="btn btn-info mr-1" id="sticky_Div"
                        (click)="navigatesearchVerifyCertifiedEregistryNo()">
                        {{'homepage.verCerCoDocu' | translate}}
                    </button>
                    <button mat-button="mat-button" class="btn btn-primary" id="sticky_Div" (click)="navigateSearch()">

                        {{'detailsPages.processFordocserCerti' | translate}}
                    </button>

                </div>
            </div>
        </div>
    </div>

    <div class="container mb-3">
        <mat-card class="example-card">
            <mat-card-title></mat-card-title>
            <mat-card-content class="mat-card-content">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <mat-accordion class="example-headers-align">
                            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            What is Document Search?
                                        </div>
                                        <div *ngIf="lan == 1">
                                            दस्तावेज़ खोज क्या है?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            With the Document Search/Download Certified Module in SAMPADA 2.0, the
                                            registered Users can search and download the Certified copy of the
                                            registered documents.
                                        </p>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            SAMPADA 2.0 में दस्तावेज़ खोज/डाउनलोड प्रमाणित मॉड्यूल से पंजीकृत उपयोगकर्ता
                                            पंजीकृत दस्तावेज़ों की प्रमाणित प्रति खोज और डाउनलोड कर सकते हैं।
                                        </p>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            What is Certified Copy and for what purpose required?
                                        </div>
                                        <div *ngIf="lan == 1">
                                            प्रमाणित प्रतिलिपि क्या है और किस उद्देश्य के लिए आवश्यक है?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            The end goal of using search facility is to improve the usability of the
                                            application, so that all the registered stakeholders can easily search for
                                            desired document and if required, they can download the certified copy of
                                            the same by paying the required fees. The powerful searching facility allows
                                            users to get their information right away, saving time and boosting their
                                            productivity.
                                        </p>


                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            खोज सुविधा का उपयोग करने का लक्ष्य एप्लिकेशन की उपयोगिता में सुधार करना है,
                                            ताकि सभी पंजीकृत हितधारक आसानी से वांछित दस्तावेज़ की खोज कर सकें और यदि
                                            आवश्यक हो, तो आवश्यक शुल्क का भुगतान करके उसकी प्रमाणित प्रति डाउनलोड कर
                                            सकें। शक्तिशाली खोज सुविधा उपयोगकर्ताओं को तुरंत अपनी जानकारी प्राप्त करने
                                            की अनुमति देती है, जिससे समय की बचत होती है और उनकी उत्पादकता बढ़ती है
                                        </p>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            Charges for Document Search & Certified Copy.
                                        </div>
                                        <div *ngIf="lan == 1">
                                            दस्तावेज़ खोज और प्रमाणित प्रतिलिपि के लिए शुल्क
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            If user wants to search the document registered before 1-07-2015, user needs
                                            to fill 50 Rs. fees for per financial year record using eWallet or online
                                        </p>
                                        <p>
                                            If user wants to search the document registered after 30-06-2015, user needs
                                            to fill 350 Rs. fees for per certified copy using eWallet or online
                                        </p>
                                        <p>Fees are configurable at admin level.</p>

                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            यदि उपयोगकर्ता 1-07-2015 से पहले पंजीकृत दस्तावेज़ को खोजना चाहता है, तो
                                            उपयोगकर्ता को 50 रुपये भरने होंगे। ई-वॉलेट या ऑनलाइन का उपयोग करके प्रति
                                            वित्तीय वर्ष रिकॉर्ड के लिए शुल्क

                                        </p>
                                        <p>
                                            यदि उपयोगकर्ता 30-06-2015 के बाद पंजीकृत दस्तावेज़ को खोजना चाहता है, तो
                                            उपयोगकर्ता को 350 रुपये भरने होंगे। ईवॉलेट या ऑनलाइन का उपयोग करके प्रति
                                            प्रमाणित प्रति के लिए शुल्क

                                        </p>
                                        <p>
                                            शुल्क, व्यवस्थापक स्तर पर कॉन्फ़िगर करने योग्य हैं।
                                        </p>

                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            Who can search Document & download Certified Copy.
                                        </div>
                                        <div *ngIf="lan == 1">
                                            कौन दस्तावेज़ खोज सकता है और प्रमाणित प्रति डाउनलोड कर सकता है।
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            Registered Users like Citizen Users, Department Users, Service Providers,
                                            and also Unregistered Users can search Document & download Certified Copy.
                                        </p>

                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            पंजीकृत उपयोगकर्ता जैसे नागरिक उपयोगकर्ता, विभाग उपयोगकर्ता, सेवा प्रदाता,
                                            और अपंजीकृत उपयोगकर्ता भी दस्तावेज़ खोज सकते हैं और प्रमाणित प्रति डाउनलोड
                                            कर सकते हैं।
                                        </p>

                                    </div>
                                </div>
                            </mat-expansion-panel>


                            <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            Proceed for Document Search & Certified Copy.
                                        </div>
                                        <div *ngIf="lan == 1">
                                            दस्तावेज़ खोज और प्रमाणित प्रतिलिपि के लिए आगे बढ़ें
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            If the users want to download the certified copy of the searched Registered
                                            document, they must pay the prescribed amount for each copy and submit the
                                            request. After the SR digital sign is applied to the registered document,
                                            user shall be able to download it. The downloaded document will be available
                                            for the user for one year. User can also view the downloaded documents
                                            history.
                                        </p>

                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            यदि उपयोगकर्ता खोजे गए पंजीकृत दस्तावेज़ की प्रमाणित प्रति डाउनलोड करना
                                            चाहते हैं, तो उन्हें प्रत्येक प्रति के लिए निर्धारित राशि का भुगतान करना
                                            होगा और अनुरोध सबमिट करना होगा। पंजीकृत दस्तावेज़ पर उप पंजीयक के डिजिटल
                                            साइन
                                            लागू होने के बाद, उपयोगकर्ता इसे डाउनलोड करने में सक्षम होगा। डाउनलोड किया
                                            गया दस्तावेज़ उपयोगकर्ता के लिए एक वर्ष तक उपलब्ध रहेगा। उपयोगकर्ता डाउनलोड
                                            किए गए दस्तावेज़ों का इतिहास भी देख सकता है।
                                        </p>

                                    </div>
                                </div>
                            </mat-expansion-panel>

                        </mat-accordion>
                    </div>
                    <!-- <div class="col-xl-6 col-lg-6 col-sm-12">
                        <div class="process_img">
                            <img src="assets/images/SPlicenseprocess.jpg" style="width: 100%"/>
                        </div>
                    </div> -->
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>