import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgxXmlToJsonService } from 'ngx-xml-to-json';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { CitizenAuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/citizen-authentication.service';
import { EkycService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/ekyc.service';
import { PanService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/pan.service';
import { AadharEkycOTPAuthRequest, AadharEkycOTPAuthResponse, AadharEkycOTPGenResponse } from 'projects/common-lib/src/lib/models/aadharEkyc.model/AadharEkyc.model';
import { IgrsEregPartyDetailsBean } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { PanKyc } from 'projects/common-lib/src/lib/models/panKyc.model/pankyc.model';
import { Subscription, timer } from 'rxjs';
import { delay } from 'rxjs/operators';

export class EstampDialogData {
  byEstamp: boolean = false
  ekycStatus: string
  aadharNumberMasked: string
  userID: any
  estampPartyModel:any
}

@Component({
  selector: 'app-kyc-details',
  templateUrl: './kyc-details.component.html',
  styleUrls: ['./kyc-details.component.scss']
})
export class KycDetailsComponent implements OnInit {

  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  radio: number = 11;

  uid: any;
  vid: any;

  countDown: Subscription;
  counter = 0; // 10 min 10*60
  tick = 1000; // 1 sec

  panKyc: PanKyc;
  fullName: string;
  @Input() mobileNo: any;
  showPanSubmit: boolean = false;
  disabledPanFullName: boolean = false;
  enableVerifyButton: boolean = false;
  rdFM220U: any;
  rdFM220UStatus: any;
  morpho1300e3: any;
  iritechData: any;
  iritechStatus: any;
  mantrafms100: any;
  mantramfs110: any;
  mantramfs110Status: any;
  MantraIrisMIS100v2: any;
  MantraIrisMIS100v2Status: any;
  aadharEkycOTPGenResponse: AadharEkycOTPGenResponse;
  aadharEkycOTPAuthRequest: AadharEkycOTPAuthRequest = new AadharEkycOTPAuthRequest();
  aadharEkycOTPAuthResponse: AadharEkycOTPAuthResponse;

  consentcheck1: any = null;
  consentcheck2: any = null;

  otpGenerated: boolean = false;
  otpaadhar: any;


  divhidekyc = true;
  divhideotherkyc = false;
  scanBtn: boolean = false;


  pannumber: any;
  panDob: any;
  panFullName:any;
  byEstamp: boolean = false;
  authType: string = 'EKYC';

  @Output() verifyAdharOtpOut = new EventEmitter<AadharEkycOTPAuthResponse>();
  @Output() verifyPanOut = new EventEmitter<any>();

  @Input() kycSource: string;
  @Input() kycObject: any;
  @Input() citizenId: any;
  @Input() departmentId: any;
  @Input() aadharMasked: any;
  @Input() citizenType: any;
  @Input() userType: any;
  @Input() isProtestReq: any;

  passhide = true;
  show = false;

  constructor(private ekycService: EkycService, private panService: PanService, private translateService: TranslateService,
    private ngxService: NgxUiLoaderService, private ngxXmlToJsonService: NgxXmlToJsonService, @Inject(MAT_DIALOG_DATA) public data: EstampDialogData,
    private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public eRegPartyModel: IgrsEregPartyDetailsBean, private _AESEncryptDecryptService: AESEncryptDecryptService, private citizenAuthenticationService: CitizenAuthenticationService,) {
    this.byEstamp = data.byEstamp
    this.citizenId = data.userID
    this.departmentId = data.userID

    if (data.aadharNumberMasked?.length == 12)
      this.uid = data.aadharNumberMasked
    else if (data.aadharNumberMasked?.length == 16)
      this.vid = data.aadharNumberMasked
  }

  ngOnInit(): void {
    //history.pushState(null, '');
    //console.log("kycSource::"+this.kycSource)
   // console.log("kyc req data ::"+JSON.stringify(this.data))
    if(this.data?.estampPartyModel != null && this.data?.estampPartyModel!= undefined){
      this.pannumber = this.data?.estampPartyModel?.panNumber
        this.fullName = this.data?.estampPartyModel?.partyName
        this.panFullName = this.data?.estampPartyModel?.partyName
        this.panDob = this.data?.estampPartyModel?.dob
    }
    if (this.kycObject != undefined && this.kycObject != null) {
      if (this.kycSource == 'Aadhaar') {
        this.divhidekyc = true;
        this.divhideotherkyc = false;

        if (this.kycObject.residentDetails != null) {
          if (this.kycObject.residentDetails.aadhaarId != null && this.kycObject.residentDetails.aadhaarId.length == 12) {
            this.uid = this.kycObject.residentDetails.aadhaarId
          } else if (this.kycObject.residentDetails.aadhaarId != null && this.kycObject.residentDetails.aadhaarId.length == 16) {
            this.vid = this.kycObject.residentDetails.aadhaarId
          } else {
            this.uid = "";
            this.vid = "";
          }
        }
      } if (this.kycSource == 'Pan') {
        
      } else {
        this.divhidekyc = false;
        this.divhideotherkyc = true;
      }
    }

    if (this.userType != undefined && this.userType != null && this.userType == 'citizen') {
      if (this.aadharMasked != undefined && this.citizenType != undefined && this.citizenType == 1)
        this.uid = this.aadharMasked
    } else {
      if (this.aadharMasked != undefined)
        this.uid = this.aadharMasked
    }
  }
  closeKycModel() {
    this.dialogRef.close();
  }

  visibleDiv(id: any) {

    //
    if (id == 'otherkyc') {
      this.divhidekyc = false;
      this.divhideotherkyc = true;
    }
    if (id == 'kyc') {
      this.divhidekyc = true;
      this.divhideotherkyc = false;
    }
  }

  populateAuthRequest() {
    this.aadharEkycOTPAuthRequest.txn = this.aadharEkycOTPGenResponse.txn;
    this.aadharEkycOTPAuthRequest.aadhaarNumber = this.uid;
    this.aadharEkycOTPAuthRequest.vid = this.vid;
  }


  onCheckboxChange(event: any) {
    if (event.target.checked) {
      this.consentcheck1 = true;
      this.consentcheck2 = true;
    } else {
      this.consentcheck1 = false;
      this.consentcheck2 = false;
    }
  }

  getAdharOtp() {
    let ud: string = this.uid;
    let vd: string = this.vid;
    if ((this.uid != null && ud.length == 12) || (this.vid != null && vd.length == 16)) {
      if ((this.uid != null && ud.length == 12) && (ud.startsWith('1') || ud.startsWith('0'))) {
        this.alertmessage.errorAlertMessage('kyc-details.check-aadhar-vid');
        return;
      }
      if ((this.consentcheck1 != null && this.consentcheck1 == true) && (this.consentcheck2 != null && this.consentcheck2 == true)) {
        let reqNo = ud != null && ud != "" ? ud : vd;
        this.ngxService.start();
        //let encReqNo = this._AESEncryptDecryptService.encryptInAngular(reqNo);
        let userID = this.citizenId != null && this.citizenId != "" ? this.citizenId : this.departmentId;
        let userTYPE = this.citizenId != null && this.citizenId != "" ? 'citizen' : 'department';
        let param = { uid: this._AESEncryptDecryptService.encryptInAngular(reqNo), userId: userID, userType: userTYPE };
        this.ekycService.generateAadharOtp(param).subscribe(res => {
          this.ngxService.stop();
          this.otpaadhar = '';
          //
          if (res && res.responseStatus == 'true') {
            this.alertmessage.successAlertMessage(res);
            this.otpGenerated = true;
            this.aadharEkycOTPGenResponse = res.responseData;
            //populate further auth request
            this.populateAuthRequest();
            this.counter = 60;
            // this.countDown = timer(0, this.tick).subscribe(() => {
            //   if (this.counter > 0) {
            //     --this.counter;
            //   }
            // });
            this.citizenAuthenticationService.timer(this.counter).subscribe({
              next: (display) => {
                const words = display.split(':');
                this.counter = Number(words[1]);
              },
              complete: () => {
                console.log('Timer completed')
                this.counter = 0;
              },
            });
          } else {
            this.ngxService.stop();
            this.alertmessage.errorAlertMessage(res);
            this.otpGenerated = false;
            this.aadharEkycOTPGenResponse = res.responseData;
          }
        });
      } else {
        this.alertmessage.errorAlertMessage('kyc-details.check-consent');
      }
    } else {
      this.alertmessage.errorAlertMessage('kyc-details.check-aadhar-vid');
    }

  }

  verifyAdharOtp() {
    if (this.otpaadhar == null || this.otpaadhar == undefined) {
      this.alertmessage.errorAlertMessage('enter-otp');
    } else {
      this.aadharEkycOTPAuthRequest.otp = this.otpaadhar;

      if (this.citizenId != undefined) {
        //console.log("KYC_DETAILS____citizenId", this.citizenId);
        this.aadharEkycOTPAuthRequest.citizenId = this.citizenId;
      } else {
        //console.log("KYC_DETAILS____departmentId", this.departmentId);
        this.aadharEkycOTPAuthRequest.departmentId = this.departmentId;
      }

      this.ngxService.start();
      this.ekycService.verifyAadharOtp(this.aadharEkycOTPAuthRequest).subscribe(res => {
        this.ngxService.stop();
        //
        if (res && res.responseStatus == 'true') {
          this.alertmessage.successAlertMessage(res);
          this.aadharEkycOTPAuthResponse = res.responseData;
          setTimeout(() => { this.verifyAdharOtpOut.emit(this.aadharEkycOTPAuthResponse); }, 1000);

          if ((this.eRegPartyModel.partyTypeNameEn == undefined || this.eRegPartyModel.partyTypeNameEn == null) && (this.eRegPartyModel.poaType == undefined || this.eRegPartyModel.poaType == null || this.eRegPartyModel.partyBean.poaType == undefined || this.eRegPartyModel.partyBean.poaType == null || this.eRegPartyModel.transactingThroughPOA == false) && this.isProtestReq==undefined && !this.eRegPartyModel?.isAadhaarKycPerformedByEregCompl) { //&& (this.citizenId == undefined)
            this.dialogRef.close({ data: this.aadharEkycOTPAuthResponse }); //This line resposible for throw resonse for EAuth in Admission Esign Page (Reg Completion)
          }
        } else {
          this.alertmessage.errorAlertMessage(res);
          this.aadharEkycOTPAuthResponse = res.responseData;
          setTimeout(() => { this.verifyAdharOtpOut.emit(this.aadharEkycOTPAuthResponse); }, 2000);
        }
      });
      // this.otpVerifiedObj = {'id':1,'status':'success','firstName':'kumar'};

    }

  }

  skipPan() {
    this.aadharEkycOTPAuthResponse = new AadharEkycOTPAuthResponse;
    let res = { 'skip': 1 }
    this.verifyPanOut.emit(res);
  }

  setAdharKycMethod(type: any) {
    if (type == 'otp') {
      this.otpGenerated = false;
    } else if (type == 'biot') {
      this.otpGenerated = false;
    } else if (type == 'bioi') {
      this.otpGenerated = false;
    } else {
      this.otpGenerated = false;
    }
  }

  deviceCheck(val: any) {
    //
    if (this.consentcheck1 != null && this.consentcheck2 != null) {
      if (val == 'mantra-fms100') {
        console.log("mantra-fms100");

        this.ngxService.start();
        this.ekycService.checkMantraRdService().subscribe(res => {
          this.ngxService.stop();
          const options = { // set up the default options
            // textKey: 'text', // tag name for text nodes
            //attrKey: 'RDService', // tag for attr groups
            // cdataKey: 'cdata', // tag for cdata nodes (ignored if mergeCDATA is true)
          };
          let parsejson = this.ngxXmlToJsonService.xmlToJson(res, options);
          this.mantrafms100 = parsejson;
          console.log(parsejson?.RDService?.attr?.status);
          //

          if (parsejson?.RDService?.attr?.status === "READY") {
            this.scanBtn = true;
            // this.alertmessage.successAlertMessage("device found");

          } else {
            this.scanBtn = false;
            this.ngxService.stop();
            this.alertmessage.errorAlertMessage('kyc-details.no-mantra-device-found');
            //  alert("No device found");
          }
          //
        });
      } else if (val == 'rd-fm220u') {
        this.ngxService.start();

        let devicePort: string[] = ['11100/', '11101/', '11102/', '11103/', '11104/', '11105/', '11106/', '11107/', '11108/', '11109/', '11200/'];
        devicePort.forEach(element => {
          this.ekycService.checkFM2202RdServiceInfo(element).subscribe(res => {
            this.ngxService.stop();
            console.log("rd-fm220u");
            //console.log(res);
            const options = { // set up the default options
              // textKey: 'text', // tag name for text nodes
              //attrKey: 'RDService', // tag for attr groups
              // cdataKey: 'cdata', // tag for cdata nodes (ignored if mergeCDATA is true)
            };
            let parsejson = this.ngxXmlToJsonService.xmlToJson(res, options);
            this.rdFM220U = parsejson;
            console.log(parsejson?.RDService?.attr?.status);

            if (parsejson?.RDService?.attr?.status === 'READY') {
              this.scanBtn = true;
              this.rdFM220UStatus = parsejson?.RDService?.attr?.status;
              return;
              // this.alertmessage.successAlertMessage("device found");
            } else {
              if (this.rdFM220UStatus != "READY") {
                this.scanBtn = false;
                this.ngxService.stop();
                this.alertmessage.errorAlertMessage('kyc-details.no-fm220u-device-found');
                //alert("No device found");
              }
            }
          });
        });
      }
      else if (val == 'mantra-mfs110') {
        console.log("mantra-mfs110");

        this.ngxService.start();
        let devicePort: string[] = ['11100/', '11101/', '11102/', '11103/', '11104/', '11105/', '11106/', '11107/', '11108/', '11109/', '11200/'];
        //let devicePort: string[] = ['11103/'];

        devicePort.forEach(element => {
          //let element = devicePort[i];
          this.ekycService.checkMFS110RdService(element).subscribe(res => {
            this.ngxService.stop();
            const options = { // set up the default options
              // textKey: 'text', // tag name for text nodes
              //attrKey: 'RDService', // tag for attr groups
              // cdataKey: 'cdata', // tag for cdata nodes (ignored if mergeCDATA is true)
            };
            let parsejson = this.ngxXmlToJsonService.xmlToJson(res, options);
            this.mantramfs110 = parsejson;
            console.log(parsejson?.RDService?.attr?.status);
            //alert("No device found"+parsejson);
            //

            if (parsejson?.RDService?.attr?.status === "READY") {
              this.scanBtn = true;
              this.mantramfs110Status = parsejson?.RDService?.attr?.status;
              return;
              // this.alertmessage.successAlertMessage("device found");

            } else {
              if (this.mantramfs110Status != "READY") {
                this.scanBtn = false;
                this.ngxService.stop();
                this.alertmessage.errorAlertMessage('kyc-details.no-mfs110-device-found');
                //  alert("No device found");
              }
            }
            //
          });
        });
      } else if (val == 'iritech') {
        this.ngxService.start();
        let devicePort: string[] = ['11100/', '11101/', '11102/', '11103/', '11104/', '11105/', '11106/', '11107/', '11108/', '11109/', '11200/'];
        devicePort.forEach(element => {
          this.ekycService.checkRdServiceInfo(element).subscribe(res => {
            this.ngxService.stop();
            console.log("iritech");
            //console.log(res);
            const options = { // set up the default options
              // textKey: 'text', // tag name for text nodes
              // attrKey: 'RDService', // tag for attr groups
              // cdataKey: 'cdata', // tag for cdata nodes (ignored if mergeCDATA is true)
            };
            let parsejson = this.ngxXmlToJsonService.xmlToJson(res, options);
            this.iritechData = parsejson;
            if (parsejson?.RDService?.attr?.status === "READY") {
              this.scanBtn = true;
              this.iritechStatus = parsejson?.RDService?.attr?.status;
              // this.alertmessage.successAlertMessage("device found");
            } else {
              if (this.iritechStatus != "READY") {
                this.scanBtn = false;
                this.ngxService.stop();
                this.alertmessage.errorAlertMessage('kyc-details.no-iritech-device-found');
                //alert("No device found");
              }
            }
          });
        });
      } else if (val == 'mantraIrisMIS100v2') {
        this.ngxService.start();
        let devicePort: string[] = ['11100/', '11101/', '11102/', '11103/', '11104/', '11105/', '11106/', '11107/', '11108/', '11109/', '11200/'];

        devicePort.forEach(element => {
          this.ekycService.checkRdServiceIrisMIS100v2Info(element).subscribe(res => {
            this.ngxService.stop();
            console.log("mantraIrisMIS100v2");
            //console.log(res);
            const options = { // set up the default options
              // textKey: 'text', // tag name for text nodes
              // attrKey: 'RDService', // tag for attr groups
              // cdataKey: 'cdata', // tag for cdata nodes (ignored if mergeCDATA is true)
            };
            let parsejson = this.ngxXmlToJsonService.xmlToJson(res, options);
            this.MantraIrisMIS100v2 = parsejson;
            if (parsejson?.RDService?.attr?.status === "READY") {
              this.scanBtn = true;
              this.MantraIrisMIS100v2Status = parsejson?.RDService?.attr?.status;
              // this.alertmessage.successAlertMessage("device found");
            } else {
              if (this.MantraIrisMIS100v2Status != "READY") {
                this.scanBtn = false;
                this.ngxService.stop();
                this.alertmessage.errorAlertMessage('kyc-details.no-mantraIrisMIS100v2-device-found');
                //alert("No device found");
              }
            }
          });
        });
      }
      else if (val == 'morpho-1300e3') {
        console.log("morpho-1300e3");
        this.ngxService.start();
        this.ekycService.checkMorphoRdServiceInfo().subscribe(res => {
          this.ngxService.stop();
          console.log("morpho-1300e3");
          //console.log(res);
          const options = { // set up the default options
            // textKey: 'text', // tag name for text nodes
            // attrKey: 'RDService', // tag for attr groups
            // cdataKey: 'cdata', // tag for cdata nodes (ignored if mergeCDATA is true)
          };
          let parsejson = this.ngxXmlToJsonService.xmlToJson(res, options);
          this.morpho1300e3 = parsejson;
          if (parsejson?.RDService?.attr?.status === "READY") {
            this.scanBtn = true;
            // this.alertmessage.successAlertMessage("device found");
          } else {
            this.scanBtn = false;
            this.ngxService.stop();
            this.alertmessage.errorAlertMessage('kyc-details.no-morpho-device-found');
            alert("No device found");
          }
        });
      }
      else {
        this.scanBtn = false;
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('kyc-details.no-biometri-device-found');
        alert("No device found");
      }
    } else {
      this.scanBtn = false;
      this.ngxService.stop();
      this.alertmessage.errorAlertMessage('kyc-details.check-consent');

    }
  }

  verifyBio(captureJsondata: any) {
    this.aadharEkycOTPAuthRequest.aadhaarNumber = this.uid;
    //this.aadharEkycOTPAuthRequest.aadhaarNumber = '01112043ERK/YoR8lnbzlrskFaVXYaZmM0Obu2JfrhzvUmL6k4KYtDppCT19fJzG++5G+hVX';
    this.aadharEkycOTPAuthRequest.vid = this.vid;
    this.aadharEkycOTPAuthRequest.pidData = captureJsondata;
    if (this.citizenId != undefined) {
      //console.log("KYC_DETAILS____citizenId", this.citizenId);
      this.aadharEkycOTPAuthRequest.citizenId = this.citizenId;
    } else {
      //console.log("KYC_DETAILS____departmentId", this.departmentId);
      this.aadharEkycOTPAuthRequest.departmentId = this.departmentId;
    }
    this.ngxService.start();
    this.ekycService.verifyAadharBio(this.aadharEkycOTPAuthRequest).subscribe(res => {
      this.ngxService.stop();
      //
      if (res && res.responseStatus == 'true') {
        this.alertmessage.successAlertMessage(res);
        this.aadharEkycOTPAuthResponse = res.responseData;
        //console.log("aadharEkycOTPAuthResponse", this.aadharEkycOTPAuthResponse);
        //console.log("aadharEkycOTPAuthResponse_res", res);
        setTimeout(() => { this.verifyAdharOtpOut.emit(this.aadharEkycOTPAuthResponse); }, 1000);

        if ((this.eRegPartyModel.partyTypeNameEn == undefined || this.eRegPartyModel.partyTypeNameEn == null) && (this.eRegPartyModel.poaType == undefined || this.eRegPartyModel.poaType == null || this.eRegPartyModel.partyBean.poaType == undefined || this.eRegPartyModel.partyBean.poaType == null || this.eRegPartyModel.transactingThroughPOA == false) && this.isProtestReq==undefined) { //&& (this.citizenId == undefined)
          this.dialogRef.close({ data: this.aadharEkycOTPAuthResponse });
        }

      } else {
        this.alertmessage.errorAlertMessage(res);
        this.aadharEkycOTPAuthResponse = res.responseData;
        setTimeout(() => { this.verifyAdharOtpOut.emit(this.aadharEkycOTPAuthResponse); }, 2000);
      }
    });
  }


  verifyIricBio(captureJsondata: any) {
    this.aadharEkycOTPAuthRequest.aadhaarNumber = this.uid;
    this.aadharEkycOTPAuthRequest.vid = this.vid;
    this.aadharEkycOTPAuthRequest.pidData = captureJsondata;
    //console.log("verifyIricBio_aadharEkycOTPAuthRequest_pidData", captureJsondata);
    if (this.citizenId != undefined) {
      //console.log("KYC_DETAILS____citizenId", this.citizenId);
      this.aadharEkycOTPAuthRequest.citizenId = this.citizenId;
    } else {
      //console.log("KYC_DETAILS____departmentId", this.departmentId);
      this.aadharEkycOTPAuthRequest.departmentId = this.departmentId;
    }
    this.ngxService.start();
    this.ekycService.verifyAadharIrisBio(this.aadharEkycOTPAuthRequest).subscribe(res => {
      this.ngxService.stop();
      //
      if (res && res.responseStatus == 'true') {
        this.alertmessage.successAlertMessage(res);
        this.aadharEkycOTPAuthResponse = res.responseData;
        setTimeout(() => { this.verifyAdharOtpOut.emit(this.aadharEkycOTPAuthResponse); }, 1000);

        if ((this.eRegPartyModel.partyTypeNameEn == undefined || this.eRegPartyModel.partyTypeNameEn == null) && (this.eRegPartyModel.poaType == undefined || this.eRegPartyModel.poaType == null || this.eRegPartyModel.partyBean.poaType == undefined || this.eRegPartyModel.partyBean.poaType == null || this.eRegPartyModel.transactingThroughPOA == false) && this.isProtestReq==undefined) { //&& (this.citizenId == undefined)
          this.dialogRef.close({ data: this.aadharEkycOTPAuthResponse });
        }

      } else {
        this.alertmessage.errorAlertMessage(res);
        this.aadharEkycOTPAuthResponse = res.responseData;
        setTimeout(() => { this.verifyAdharOtpOut.emit(this.aadharEkycOTPAuthResponse); }, 2000);
      }
    });
  }
  verifyMantraIrisMIS100v2Bio(captureJsondata: any) {
    this.aadharEkycOTPAuthRequest.aadhaarNumber = this.uid;
    this.aadharEkycOTPAuthRequest.vid = this.vid;
    this.aadharEkycOTPAuthRequest.pidData = captureJsondata;
    if (this.citizenId != undefined) {
      //console.log("KYC_DETAILS____citizenId", this.citizenId);
      this.aadharEkycOTPAuthRequest.citizenId = this.citizenId;
    } else {
      //console.log("KYC_DETAILS____departmentId", this.departmentId);
      this.aadharEkycOTPAuthRequest.departmentId = this.departmentId;
    }
    //console.log("verifyIricBio_aadharEkycOTPAuthRequest_pidData", captureJsondata);
    this.ngxService.start();
    this.ekycService.verifyAadharIrisMantraMIS100v2Bio(this.aadharEkycOTPAuthRequest).subscribe(res => {
      this.ngxService.stop();
      //
      if (res && res.responseStatus == 'true') {
        this.alertmessage.successAlertMessage(res);
        this.aadharEkycOTPAuthResponse = res.responseData;
        setTimeout(() => { this.verifyAdharOtpOut.emit(this.aadharEkycOTPAuthResponse); }, 1000);

        if ((this.eRegPartyModel.partyTypeNameEn == undefined || this.eRegPartyModel.partyTypeNameEn == null) && (this.eRegPartyModel.poaType == undefined || this.eRegPartyModel.poaType == null || this.eRegPartyModel.partyBean.poaType == undefined || this.eRegPartyModel.partyBean.poaType == null || this.eRegPartyModel.transactingThroughPOA == false) && this.isProtestReq==undefined) { //&& (this.citizenId == undefined)
          this.dialogRef.close({ data: this.aadharEkycOTPAuthResponse });
        }

      } else {
        this.alertmessage.errorAlertMessage(res);
        this.aadharEkycOTPAuthResponse = res.responseData;
        setTimeout(() => { this.verifyAdharOtpOut.emit(this.aadharEkycOTPAuthResponse); }, 2000);
      }
    });
  }

  scanDevice() {
    let ud: string = this.uid;
    let vd: string = this.vid;
    if ((this.uid != null && ud.length == 12) || (this.vid != null && vd.length == 16)) {
      if (this.consentcheck1 != null && this.consentcheck2 != null) {
        if (this.mantrafms100?.RDService?.attr?.status === "READY") {
          this.ngxService.start();
          this.ekycService.checkMantraFMS100RdServiceCaptureFP().subscribe(data => {
            let captureXMLdata = data;
            //
            this.ngxService.stop();
            let captureJsondata = this.ngxXmlToJsonService.xmlToJson(data, "");
            console.log("scanDevice");
            console.log("mantra-fms100");
            //console.log(captureJsondata);

            if (captureJsondata?.PidData?.Resp.attr.errCode == "0") {
              // this.alertmessage.successAlertMessage(this.translateService.instant('successfully-captured'));
              this.verifyBio(captureXMLdata);
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "700" || captureJsondata?.PidData?.Resp.attr.errCode == "1140") {
              this.alertmessage.errorAlertMessage("capture-timeout");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "710") {
              this.alertmessage.errorAlertMessage("being-used-byanother-application");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "720") {
              this.alertmessage.errorAlertMessage("device-not-ready");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "730") {
              this.alertmessage.errorAlertMessage("capture-failed");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "740") {
              this.alertmessage.errorAlertMessage("device-needs-to-be-re-initialized");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "780") {
              this.alertmessage.errorAlertMessage("the-app-is-out-of-date");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "790") {
              this.alertmessage.errorAlertMessage("the-handset-failed");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "800") {
              this.alertmessage.errorAlertMessage("root-checking-failed");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "810") {
              this.alertmessage.errorAlertMessage("rdService-is-checking");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1307") {
              this.alertmessage.errorAlertMessage("no-device-connected");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1142") {
              this.alertmessage.errorAlertMessage("unknown-sensor");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1135") {
              this.alertmessage.errorAlertMessage("invalid-template");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1132") {
              this.alertmessage.errorAlertMessage("invalid-key");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1329") {
              this.alertmessage.errorAlertMessage("data-sign-failed");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-6") {
              this.alertmessage.errorAlertMessage("used-byanother-app");
            }
            else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1509") {
              this.alertmessage.errorAlertMessage("device-not-connect");
            }
            else {
              this.alertmessage.errorAlertMessage("Error Code: " + captureJsondata?.PidData?.Resp.attr.errCode + " Msg.:" + captureJsondata?.PidData?.Resp.attr.errInfo);
            }
          });
        }
        //else if (this.mantramfs110?.RDService?.attr?.status === "READY") {
        else if (this.mantramfs110Status === "READY") {

          this.ngxService.start();
          let devicePort: string[] = ['11100/', '11101/', '11102/', '11103/', '11104/', '11105/', '11106/', '11107/', '11108/', '11109/', '11200/'];
          //let devicePort: string[] = ['11103/'];
          for (let i = 0; i < devicePort.length; i++) {
            let element = devicePort[i];

            this.ekycService.checkMFS110RdServiceCaptureFP(element, this.authType).pipe(delay(5000)).subscribe(data => {
              let captureXMLdata = data;
              //
              this.ngxService.stop();
              if (data != "8") {
                let captureJsondata = this.ngxXmlToJsonService.xmlToJson(data, "");
                console.log("scanDevice");
                console.log("mantra-mfs110s");
                console.log("mantra-mfs110s_errorcode=", captureJsondata?.PidData?.Resp.attr.errCode);
                //console.log(captureJsondata);

                if (captureJsondata?.PidData?.Resp.attr.errCode == "0") {
                  // this.alertmessage.successAlertMessage(this.translateService.instant('successfully-captured'));
                  this.verifyBio(captureXMLdata);
                  return;
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "700" || captureJsondata?.PidData?.Resp.attr.errCode == "1140") {
                  this.alertmessage.errorAlertMessage("capture-timeout");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "710") {
                  this.alertmessage.errorAlertMessage("being-used-byanother-application");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "720") {
                  //this.alertmessage.errorAlertMessage("device-not-ready");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "730") {
                  this.alertmessage.errorAlertMessage("capture-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "740") {
                  this.alertmessage.errorAlertMessage("device-needs-to-be-re-initialized");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "780") {
                  this.alertmessage.errorAlertMessage("the-app-is-out-of-date");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "790") {
                  this.alertmessage.errorAlertMessage("the-handset-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "800") {
                  this.alertmessage.errorAlertMessage("root-checking-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "810") {
                  this.alertmessage.errorAlertMessage("rdService-is-checking");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1307") {
                  this.alertmessage.errorAlertMessage("no-device-connected");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1142") {
                  this.alertmessage.errorAlertMessage("unknown-sensor");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1135") {
                  this.alertmessage.errorAlertMessage("invalid-template");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1132") {
                  this.alertmessage.errorAlertMessage("invalid-key");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1329") {
                  this.alertmessage.errorAlertMessage("data-sign-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-6") {
                  this.alertmessage.errorAlertMessage("used-byanother-app");
                }
                else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1509") {
                  this.alertmessage.errorAlertMessage("device-not-connect");
                }
                else {
                  //this.alertmessage.errorAlertMessage("Error Code: " + captureJsondata?.PidData?.Resp.attr.errCode + " Msg.:" + captureJsondata?.PidData?.Resp.attr.errInfo);
                }
                return;
              } else {
                //this.alertmessage.errorAlertMessage("tech_error_msg");
              }
            });
          }
        }
        //else if (this.rdFM220U?.RDService?.attr?.status === "READY") {
        else if (this.rdFM220UStatus === "READY") {
          this.ngxService.start();
          let devicePort: string[] = ['11100/', '11101/', '11102/', '11103/', '11104/', '11105/', '11106/']; //, '11200/'
          for (let i = 0; i < devicePort.length; i++) {
            let element = devicePort[i];
            this.ekycService.checkRdServiceFM220UCaptureFP(element, this.authType).subscribe(data => {
              let captureXMLdata = data;
              //
              this.ngxService.stop();
              if (data != "8") {

                let captureJsondata = this.ngxXmlToJsonService.xmlToJson(data, "");
                console.log("scanDevice");
                console.log("RdServiceFM220U");
                //console.log(captureJsondata);

                if (captureJsondata?.PidData?.Resp.attr.errCode == "0") {
                  // this.alertmessage.successAlertMessage(this.translateService.instant('successfully-captured'));
                  this.verifyBio(captureXMLdata);
                  return;
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "700" || captureJsondata?.PidData?.Resp.attr.errCode == "1140") {
                  this.alertmessage.errorAlertMessage("capture-timeout");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "710") {
                  //this.alertmessage.errorAlertMessage("being-used-byanother-application");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "720") {
                  //this.alertmessage.errorAlertMessage("device-not-ready");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "730") {
                  this.alertmessage.errorAlertMessage("capture-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "740") {
                  this.alertmessage.errorAlertMessage("device-needs-to-be-re-initialized");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "780") {
                  this.alertmessage.errorAlertMessage("the-app-is-out-of-date");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "790") {
                  this.alertmessage.errorAlertMessage("the-handset-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "800") {
                  this.alertmessage.errorAlertMessage("root-checking-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "810") {
                  this.alertmessage.errorAlertMessage("rdService-is-checking");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1307") {
                  this.alertmessage.errorAlertMessage("no-device-connected");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1142") {
                  this.alertmessage.errorAlertMessage("unknown-sensor");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1135") {
                  this.alertmessage.errorAlertMessage("invalid-template");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1132") {
                  this.alertmessage.errorAlertMessage("invalid-key");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1329") {
                  this.alertmessage.errorAlertMessage("data-sign-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-6") {
                  this.alertmessage.errorAlertMessage("used-byanother-app");
                }
                else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1509") {
                  //this.alertmessage.errorAlertMessage("device-not-connect");
                }
                else {
                  //this.alertmessage.errorAlertMessage("Error Code: " + captureJsondata?.PidData?.Resp.attr.errCode + " Msg.:" + captureJsondata?.PidData?.Resp.attr.errInfo);
                }
                return;
              } else {
                //this.alertmessage.errorAlertMessage("tech_error_msg");
              }
            });
          }
        } else if (this.iritechStatus === "READY") {
          this.ngxService.start();
          let devicePort: string[] = ['11100/', '11101/', '11102/', '11103/', '11104/', '11105/', '11106/', '11107/', '11108/', '11109/', '11200/'];
          for (let i = 0; i < devicePort.length; i++) {
            let element = devicePort[i];
            this.ekycService.checkRdServiceIritechDataCaptureFP(element).subscribe(data => {
              let captureXMLdata = data;
              //
              this.ngxService.stop();
              if (data != "8") {
                let captureJsondata = this.ngxXmlToJsonService.xmlToJson(data, "");
                console.log("scanDevice");
                console.log("RdServiceIritech");
                //console.log(captureJsondata);

                if (captureJsondata?.PidData?.Resp.attr.errCode == "0") {
                  // this.alertmessage.successAlertMessage(this.translateService.instant('successfully-captured'));
                  this.verifyIricBio(captureXMLdata);
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "700" || captureJsondata?.PidData?.Resp.attr.errCode == "1140") {
                  this.alertmessage.errorAlertMessage("capture-timeout");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "710") {
                  this.alertmessage.errorAlertMessage("being-used-byanother-application");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "720") {
                  //this.alertmessage.errorAlertMessage("device-not-ready");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "730") {
                  this.alertmessage.errorAlertMessage("capture-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "740") {
                  this.alertmessage.errorAlertMessage("device-needs-to-be-re-initialized");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "780") {
                  this.alertmessage.errorAlertMessage("the-app-is-out-of-date");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "790") {
                  this.alertmessage.errorAlertMessage("the-handset-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "800") {
                  this.alertmessage.errorAlertMessage("root-checking-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "810") {
                  this.alertmessage.errorAlertMessage("rdService-is-checking");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1307") {
                  this.alertmessage.errorAlertMessage("no-device-connected");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1142") {
                  this.alertmessage.errorAlertMessage("unknown-sensor");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1135") {
                  this.alertmessage.errorAlertMessage("invalid-template");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1132") {
                  this.alertmessage.errorAlertMessage("invalid-key");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1329") {
                  this.alertmessage.errorAlertMessage("data-sign-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-6") {
                  this.alertmessage.errorAlertMessage("used-byanother-app");
                }
                else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1509") {
                  this.alertmessage.errorAlertMessage("device-not-connect");
                }
                else {
                  //this.alertmessage.errorAlertMessage("Error Code: " + captureJsondata?.PidData?.Resp.attr.errCode + " Msg.:" + captureJsondata?.PidData?.Resp.attr.errInfo);
                }
                return;
              } else {
                //this.alertmessage.errorAlertMessage("tech_error_msg");
              }
            });
          }
        }
        else if (this.MantraIrisMIS100v2Status === "READY") {
          this.ngxService.start();
          let devicePort: string[] = ['11100/', '11101/', '11102/', '11103/', '11104/', '11105/', '11106/', '11107/', '11108/', '11109/', '11200/'];

          devicePort.forEach(element => {
            //for (let i = 0; i < devicePort.length; i++) {
            //let element = devicePort[i];
            this.ekycService.checkRdServiceMantraIrisMIS100v2DataCaptureFP(element).subscribe(data => {
              let captureXMLdata = data;
              //
              this.ngxService.stop();
              if (data != "8") {
                let captureJsondata = this.ngxXmlToJsonService.xmlToJson(data, "");
                console.log("scanDevice");
                console.log("RdServiceMantraIrisMIS100v2");
                //console.log(captureJsondata);

                if (captureJsondata?.PidData?.Resp.attr.errCode == "0") {
                  // this.alertmessage.successAlertMessage(this.translateService.instant('successfully-captured'));
                  this.verifyMantraIrisMIS100v2Bio(captureXMLdata);
                  return;
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "700" || captureJsondata?.PidData?.Resp.attr.errCode == "1140") {
                  this.alertmessage.errorAlertMessage("capture-timeout");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "710") {
                  this.alertmessage.errorAlertMessage("being-used-byanother-application");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "720") {
                  //this.alertmessage.errorAlertMessage("device-not-ready");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "730") {
                  this.alertmessage.errorAlertMessage("capture-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "740") {
                  this.alertmessage.errorAlertMessage("device-needs-to-be-re-initialized");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "780") {
                  this.alertmessage.errorAlertMessage("the-app-is-out-of-date");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "790") {
                  this.alertmessage.errorAlertMessage("the-handset-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "800") {
                  this.alertmessage.errorAlertMessage("root-checking-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "810") {
                  this.alertmessage.errorAlertMessage("rdService-is-checking");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1307") {
                  this.alertmessage.errorAlertMessage("no-device-connected");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1142") {
                  this.alertmessage.errorAlertMessage("unknown-sensor");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1135") {
                  this.alertmessage.errorAlertMessage("invalid-template");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1132") {
                  this.alertmessage.errorAlertMessage("invalid-key");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1329") {
                  this.alertmessage.errorAlertMessage("data-sign-failed");
                } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-6") {
                  this.alertmessage.errorAlertMessage("used-byanother-app");
                }
                else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1509") {
                  this.alertmessage.errorAlertMessage("device-not-connect");
                }
                else {
                  //this.alertmessage.errorAlertMessage("Error Code: " + captureJsondata?.PidData?.Resp.attr.errCode + " Msg.:" + captureJsondata?.PidData?.Resp.attr.errInfo);
                }
                return;
              } else {
                //this.alertmessage.errorAlertMessage("tech_error_msg");
              }
            });
          });
        }
        else if (this.morpho1300e3?.RDService?.attr?.status === "READY") {

          this.ngxService.start();
          this.ekycService.checkMorphoRdServiceCaptureFP().subscribe(data => {
            let captureXMLdata = data;
            //
            this.ngxService.stop();
            let captureJsondata = this.ngxXmlToJsonService.xmlToJson(data, "");
            //
            console.log("MorphoRdServiceCaptureFP");

            if (captureJsondata?.PidData?.Resp.attr.errCode == "0") {
              this.alertmessage.successAlertMessage(this.translateService.instant('successfully-captured'));
              this.verifyBio(captureXMLdata);
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "700" || captureJsondata?.PidData?.Resp.attr.errCode == "1140") {
              this.alertmessage.errorAlertMessage("capture-timeout");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "710") {
              this.alertmessage.errorAlertMessage("being-used-byanother-application");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "720") {
              this.alertmessage.errorAlertMessage("device-not-ready");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "730") {
              this.alertmessage.errorAlertMessage("capture-failed");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "740") {
              this.alertmessage.errorAlertMessage("device-needs-to-be-re-initialized");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "780") {
              this.alertmessage.errorAlertMessage("the-app-is-out-of-date");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "790") {
              this.alertmessage.errorAlertMessage("the-handset-failed");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "800") {
              this.alertmessage.errorAlertMessage("root-checking-failed");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "810") {
              this.alertmessage.errorAlertMessage("rdService-is-checking");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1307") {
              this.alertmessage.errorAlertMessage("no-device-connected");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1142") {
              this.alertmessage.errorAlertMessage("unknown-sensor");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1135") {
              this.alertmessage.errorAlertMessage("invalid-template");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1132") {
              this.alertmessage.errorAlertMessage("invalid-key");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1329") {
              this.alertmessage.errorAlertMessage("data-sign-failed");
            } else if (captureJsondata?.PidData?.Resp.attr.errCode == "-6") {
              this.alertmessage.errorAlertMessage("used-byanother-app");
            }
            else if (captureJsondata?.PidData?.Resp.attr.errCode == "-1509") {
              this.alertmessage.errorAlertMessage("device-not-connect");
            }
            else {
              this.alertmessage.errorAlertMessage("Error Code: " + captureJsondata?.PidData?.Resp.attr.errCode + " Msg.:" + captureJsondata?.PidData?.Resp.attr.errInfo);
            }



          }
          );
        }
      } else {
        this.alertmessage.errorAlertMessage('kyc-details.check-consent');
        this.ngxService.stop();
      }
    } else {
      this.alertmessage.errorAlertMessage('kyc-details.check-aadhar-vid');
      this.ngxService.stop();
    }
  }



  validatePanData() {
    if (this.pannumber != null && this.pannumber != undefined && this.pannumber.length > 3) {
      this.ngxService.start();
      let encPan = this._AESEncryptDecryptService.encryptInAngular(this.pannumber);
      // this.panService.checkPanNumber(encPan)
      // checkProteanPanNumber(encPan,this.panDob)
      this.panService.checkPanNumber(encPan).subscribe(data => {
        this.ngxService.stop();
        if (data.responseStatus == 'true') {
          this.panKyc = data.responseData;
          if (this.panKyc.status == 1 && this.panKyc.detailStatus == 'E') {
            this.fullName = this.panKyc.firstName + " " + (this.panKyc.middleName != "" ? this.panKyc.middleName + " " : "") + this.panKyc.lastName;
            if (this.panKyc.nameonCard == undefined || this.panKyc.nameonCard == "") {
              this.panKyc.nameonCard = this.panKyc.firstName + " " + (this.panKyc.middleName != "" ? this.panKyc.middleName + " " : "") + this.panKyc.lastName;
            }
            //this.fullName = this.panKyc.title + " " + this.panKyc.firstName + " " + this.panKyc.middleName + " " + this.panKyc.lastName;
            this.showPanSubmit = true;
            this.disabledPanFullName = true;

          } else {
            //alert("PAN NOT EXIST ::" + this.panKyc.detailStatusDesc);
            this.fullName = undefined;
            this.pannumber = undefined
            this.showPanSubmit = false;
            this.disabledPanFullName = false;
            this.alertmessage.errorAlertMessage({ responseMessage: this.panKyc.detailStatusDesc, responseMessageEn: this.panKyc.detailStatusDesc, responseMessageHi: this.panKyc.detailStatusDesc });
          }
        } else {
          this.fullName = undefined;
          this.pannumber = undefined
          this.alertmessage.errorAlertMessage(data);
        }
        // setTimeout(() => { this.verifyPanOut.emit(this.panKyc); }, 1000);
      });
      this.ngxService.stop();
    } else {
      this.ngxService.stop();
      this.alertmessage.errorAlertMessage("pan-required");
    }
  }

  validateProteanPanData() {
    if (this.pannumber != null && this.pannumber != undefined && this.pannumber.length > 3) {
      this.ngxService.start();
    //  let encPan = this._AESEncryptDecryptService.encryptInAngular(this.pannumber);
      // this.panService.checkPanNumber(encPan)
      // checkProteanPanNumber(encPan,this.panDob)
      let panDob = new DatePipe('en-US').transform(this.panDob, 'dd/MM/yyyy')
      //console.log("pan::"+this.pannumber +" panDob::"+panDob+" panFullName::"+this.panFullName);

      let encVal = this._AESEncryptDecryptService.encrypt("pannumber=" + this.pannumber + "~panDob=" + panDob+"~panFullName="+this.panFullName);

      this.panService.checkProteanPanNumber(encVal).subscribe(data => {
        this.ngxService.stop();
        if (data.responseStatus == 'true') {
          this.panKyc = data.responseData;
          if (this.panKyc.status == 1 && this.panKyc.detailStatus == 'E') {
            // this.fullName = this.panKyc.firstName + " " + (this.panKyc.middleName != "" ? this.panKyc.middleName + " " : "") + this.panKyc.lastName;
            // if (this.panKyc.nameonCard == undefined || this.panKyc.nameonCard == "") {
            //   this.panKyc.nameonCard = this.panKyc.firstName + " " + (this.panKyc.middleName != "" ? this.panKyc.middleName + " " : "") + this.panKyc.lastName;
            // }
            //this.fullName = this.panKyc.title + " " + this.panKyc.firstName + " " + this.panKyc.middleName + " " + this.panKyc.lastName;
            if(this.panKyc.dobStatus != 'Y'){
             // this.panDob = "";
              this.alertmessage.errorAlertMessage({ responseMessage: this.panKyc.dobStatusDesc, responseMessageEn: this.panKyc.dobStatusDesc, responseMessageHi: this.panKyc.dobStatusDesc });
            }
            else if(this.panKyc.fullNameStatus != 'Y'){
             // this.panFullName = "";
              this.alertmessage.errorAlertMessage({ responseMessage: this.panKyc.nameonCard, responseMessageEn: this.panKyc.nameonCard, responseMessageHi: this.panKyc.nameonCard });
            }else{
              this.fullName = this.panKyc.nameonCard;
              this.showPanSubmit = true;
              this.disabledPanFullName = true;

             // this.panKyc.firstName = this.panKyc.nameonCard;
              this.panKyc.dobStatusDesc = this.panDob;
              this.alertmessage.successAlertMessage({ responseMessage: 'Your PAN verification is successful', responseMessageEn: 'Your PAN verification is successful', responseMessageHi: 'आपका पैन सत्यापन सफलतापूर्वक हो गया है' });
            }

          } else {
            //alert("PAN NOT EXIST ::" + this.panKyc.detailStatusDesc);
           // this.fullName = undefined;
           // this.pannumber = undefined
            this.showPanSubmit = false;
            this.disabledPanFullName = false;
            this.alertmessage.errorAlertMessage({ responseMessage: this.panKyc.detailStatusDesc, responseMessageEn: this.panKyc.detailStatusDesc, responseMessageHi: this.panKyc.detailStatusDesc });
          }
        } else {
         // this.fullName = undefined;
         // this.pannumber = undefined
          this.alertmessage.errorAlertMessage(data);
        }
        // setTimeout(() => { this.verifyPanOut.emit(this.panKyc); }, 1000);
      });
      this.ngxService.stop();
    } else {
      this.ngxService.stop();
      this.alertmessage.errorAlertMessage("pan-required");
    }
  }

  onSubmitPanDetails() {
    this.showPanSubmit = false;
    if (this.panKyc.status == 1) {

      setTimeout(() => { this.verifyPanOut.emit(this.panKyc); }, 1000);

      if ((this.eRegPartyModel.partyTypeNameEn == undefined || this.eRegPartyModel.partyTypeNameEn == null) && (this.eRegPartyModel.poaType == undefined || this.eRegPartyModel.poaType == null || this.eRegPartyModel.partyBean.poaType == undefined || this.eRegPartyModel.partyBean.poaType == null || this.eRegPartyModel.transactingThroughPOA == false)) { //&& (this.citizenId == undefined)
        this.dialogRef.close({ data: this.panKyc });
      }

    } else {
      this.alertmessage.errorAlertMessage('kyc-details.check-aadhar-vid');
      setTimeout(() => { this.verifyPanOut.emit(this.panKyc); }, 1000);
    }
  }

  onResetDetails() {
    this.showPanSubmit = false;
    if (this.panKyc.status == 1) {
      this.panKyc = null;
      this.fullName = "";
      this.pannumber = "";
      this.panDob = "";
      this.panFullName = "";
      this.disabledPanFullName = false;


      //setTimeout(() => { this.panKyc=null; }, 1000);
      //this.dialogRef.close({data:this.panKyc});

    } else {
      this.alertmessage.errorAlertMessage('kyc-details.check-aadhar-vid');
    }
  }

  changeFocusFromPan() {
    this.enableVerifyButton = true;
    // if(this.panKyc.status == 1){
    //   this.panKyc=null;
    //   this.fullName="";
    //   this.pannumber="";
    //   this.disabledPanFullName = false;


    // }else{
    //   this.alertmessage.errorAlertMessage('kyc-details.check-aadhar-vid');
    // }
  }
  verifyIricMantraMIS100v2Bio(captureJsondata: any) {
    this.aadharEkycOTPAuthRequest.aadhaarNumber = this.uid;
    this.aadharEkycOTPAuthRequest.vid = this.vid;
    this.aadharEkycOTPAuthRequest.pidData = captureJsondata;
    //console.log("verifyIricBio_aadharEkycOTPAuthRequest_pidData", captureJsondata);
    this.ngxService.start();
    this.ekycService.verifyAadharIrisMantraMIS100v2Bio(this.aadharEkycOTPAuthRequest).subscribe(res => {
      this.ngxService.stop();
      //
      if (res && res.responseStatus == 'true') {
        this.alertmessage.successAlertMessage(res);
        this.aadharEkycOTPAuthResponse = res.responseData;
        setTimeout(() => { this.verifyAdharOtpOut.emit(this.aadharEkycOTPAuthResponse); }, 1000);

      } else {
        this.alertmessage.errorAlertMessage(res);
        this.aadharEkycOTPAuthResponse = res.responseData;
        setTimeout(() => { this.verifyAdharOtpOut.emit(this.aadharEkycOTPAuthResponse); }, 2000);
      }
    });
  }
}
