
<div class="fid-header"></div>
<div class="breadcrumb-content">
    <div class="container">
    <div class="row">
        <div class="col-lg-12">
            <h2>{{'ticketView.TicketView' | translate}}</h2>
        </div>
    </div>
</div>
</div>
<div class="container">
<fieldset class="legend-section">
    <lib-alertmessage #alertMessage></lib-alertmessage>
        <form novalidate #formRef="ngForm">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class=" form-group">
                        <label>{{'ticketView.TicketNumber' | translate}}</label>
                        <h3 class="viewmodetext form-control disabled">{{ticketModelElement.ticketNumber}}</h3>
                    </div>
                </div>
                <div class="col-lg-8 col-sm-8">
                    <div class=" form-group">
                        <label>{{'ticketView.Subject' | translate}}</label>
                        <h3 class="viewmodetext form-control disabled">{{ticketModelElement.subject}}</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class=" form-group">
                        <label>{{'ticketView.Priority' | translate}}</label>
                        <h3 class="viewmodetext form-control disabled">{{ticketModelElement.priority_name}}</h3>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-sm-6">
                    <div class=" form-group">
                        <label>Category</label>
                        <h3 class="viewmodetext">{{ticketModelElement.category_name}}</h3>
                    </div>
                </div> -->
                <div class="col-lg-4 col-sm-6">
                    <div class=" form-group">
                        <label>{{'ticketView.CreatedDateTime' | translate}}</label>
                        <h3 class="viewmodetext form-control disabled">{{ticketModelElement.created_date | date: 'dd/MM/yyyy hh:mm a'}}</h3>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class=" form-group">
                        <label>{{'ticketView.AssignTo' | translate}}</label>
                        <!-- <h3 class="viewmodetext form-control disabled">{{ticketModelElement.assignee_name}}</h3> -->
                        <h3 class="viewmodetext form-control disabled">{{getMaskedString(ticketModelElement.assignee_name)}} (Support Team Member)</h3>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class=" form-group">
                        <label>{{'ticketView.DueDate' | translate}}</label>
                        <h3 class="viewmodetext form-control disabled">{{ticketModelElement.dueDate | date: 'dd/MM/yyyy'}}</h3>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class=" form-group">
                        <label>{{'ticketView.TicketStatus' | translate}}</label>
                        <h3 class="viewmodetext form-control disabled">{{ticketModelElement.ticketstatus}}</h3>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6" *ngIf="ticketModelElement.updated_date != null">
                    <div class=" form-group">
                        <label>{{'ticketView.LastModifiedOn' | translate}}</label>
                        <h3 class="viewmodetext form-control disabled">{{ticketModelElement.updated_date | date: 'dd/MM/yyyy hh:mm a'}}</h3>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6" *ngIf="ticketModelElement.updated_date != null">
                    <div class=" form-group">
                        <label>{{'ticketView.LastModifiedBy' | translate}}</label>
                        <h3 class="viewmodetext form-control disabled">{{getMaskedString(ticketModelElement.assignee_name)}} (Support Team Member)</h3>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-sm-6">
                    <div class=" form-group">
                        <label>Admin Name</label>
                        <h3 class="viewmodetext">{{ticketModelElement.admin_name}}</h3>
                    </div>
                </div> -->
                <!-- <div class="col-lg-4 col-sm-6" *ngIf="ticketModelElement.remark != ''">
                    <div class=" form-group">
                        <label>{{'ticketView.Remark' | translate}}</label>
                        <h3 class="viewmodetext">{{ticketModelElement.remark}}</h3>
                    </div>
                </div> -->
                <div class="col-lg-4 col-sm-6" *ngIf="ticketModelElement.fileUrl != null">
                    <div class=" form-group">
                        <label>{{'ticketView.Download' | translate}}</label>
                        <h3 class="viewmodetext"><a target="_blank" href="{{ticketModelElement.fileUrl}}">Click Here</a></h3>
                    </div>
                </div>


                <div class="col-lg-12 col-sm-12">
                    <div class=" form-group">
                        <label>{{'ticketView.Description' | translate}}</label>
                        <textarea autosize class="form-control" disabled>{{ticketModelElement.description}}</textarea>

                    </div>
                </div>

                <div class="col-lg-12 col-sm-12" *ngIf="ticketModelElement.remarkList.length > 0">
                    <div class=" form-group">
                        <label>{{'ticketView.Remark' | translate}}</label>
                        <div *ngFor="let remarkObj of ticketModelElement.remarkList">
                            <div class="col-lg-4 col-sm-6" *ngIf="(remarkObj.attachment_file != null && remarkObj.attachment_file != '')">
                                <div class=" form-group">
                                    <label>{{'ticketView.Download' | translate}}</label>
                                    <h3 class="viewmodetext"><a target="_blank" href="{{remarkObj.attachment_file}}">Click Here</a></h3>
                                </div>
                            </div>

                            <!-- <div class="d-flex align-items-baseline mb-3 flex-mobile-wrap" *ngIf="remarkObj.remark_external != null">
                                <label class="mr-4 text-nowrap">{{remarkObj.created_date | date: 'dd/MM/yyyy hh:mm a'}}</label>
                               
                                    <div class="form-control disabled" [innerHTML]="remarkObj.remark_external" aria-disabled="true" disabled></div>
                               
                            </div> -->
                            <div class="mb-3" *ngIf="(remarkObj.remark_external != null && remarkObj.remark_external != '') && remarkObj.create_user_type == '1'">
                               
                                <div class="row">
                               <div class="col-lg-3 col-sm-4">
                                    <label class="text-nowrap w-100">{{remarkObj.created_date | date: 'dd/MM/yyyy hh:mm a'}}</label>
                                     <span style="font-size: 14px;">({{getMaskedString(remarkObj.assignee_name)}} (Support Team Member))</span>

                                </div>
                                <div class="col-lg-9 col-sm-8">
                                     <textarea autosize class="form-control" disabled>{{remarkObj.remark_external}}</textarea>
                                </div>
                               </div>
                                <!--  <div>
                                <label class="mr-4 text-nowrap">{{remarkObj.created_date | date: 'dd/MM/yyyy hh:mm a'}}</label>
                                 <h3 class="mr-4 text-nowrap">({{getMaskedString(remarkObj.assignee_name)}} (Support Team Member))</h3>
                               
                                </div>
                                 <div class="form-control disabled" [innerHTML]="remarkObj.remark_external" aria-disabled="true" disabled></div>
                                -->
                            </div>
                            <div class="mb-3" *ngIf="(remarkObj.remark != null && remarkObj.remark != '') && remarkObj.create_user_type == '2'">
                                 <div class="row">
                               <div class="col-lg-3 col-sm-4">
                                    <label class="text-nowrap w-100">{{remarkObj.created_date | date: 'dd/MM/yyyy hh:mm a'}}</label>
                                     <span style="font-size: 14px;">(User)</span>

                                </div>
                                <div class="col-lg-9 col-sm-8">
                                     <textarea autosize class="form-control" disabled>{{remarkObj.remark}}</textarea>
                                </div>
                               </div>
                                 
                                <!-- <div>
                                <label class="mr-4 text-nowrap">{{remarkObj.created_date | date: 'dd/MM/yyyy hh:mm a'}}</label>
                                <h3 class="mr-4 text-nowrap">(User)</h3>
                                   </div>
                                   <div class="form-control disabled" [innerHTML]="remarkObj.remark" aria-disabled="true" disabled></div>
                                 -->
                            </div>
                        </div>
                    </div>
                </div>

                </div>
        </form>

        <div class="modal-body declaration-info">
            <form novalidate #catDialogformRef="ngForm" (ngSubmit)="onSubmitClarificationRevert()">
                <fieldset class="legend-section" *ngIf="ticketModelElement.status != '1'">
                    <legend class="sidebar__widget-title" *ngIf="ticketModelElement.status == '7'">
                        Clarification Revert To User
                    </legend>
                    <legend class="sidebar__widget-title" *ngIf="ticketModelElement.status != '1'">
                        Remark
                    </legend>
                        <div class="col-lg-12">
                            <div class=" form-group">
                                <!-- <label>{{'legacyDocumentRequest.AreYouSureYouWantto' | translate}} </label> -->
                                <textarea type="text" maxlength="50" class="form-control" id="remark" name="remark" [(ngModel)]="ticketModelElement.remark"  ngModel="" #remarkRef="ngModel" required
                                  placeholder="{{'qms.remark-placeholder' | translate}}"  [class.ctrl-error]="remarkRef?.errors?.required"></textarea>
                                  <div *ngIf="remarkRef?.errors?.required" class="error-message">
                                    {{'ticket-title.Remarkisrequired' | translate}}.
                                  </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class=" form-group">
                                <label>{{'ticket-title.UploadFile' | translate}} </label>
                                <input type="file" class="form-control p-0" name="file_id" [(ngModel)]="ticketModelElement.fileData" ngModel="" #uploadFileRef="ngModel" (change)="onFileSelected($event)"/>
                                <div *ngIf="!uploadfileErrorMsg" class="upload-invalid-feedback">
                                    <div>{{'ticket-file-type-error' | translate}}</div>
                                </div>
                            </div>
                        </div>
                </fieldset>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary" [disabled]="catDialogformRef.invalid" *ngIf="ticketModelElement.status != '1'"> {{'ticket-title.Submit' | translate}} </button>
                    <a mat-raised-button color="default" class="btn btn-secondary" routerLink="{{backUrl}}">{{'ticketView.back' | translate}}
                    </a>
                </div>
            </form>
        </div>

    </fieldset>
</div>
<ngx-ui-loader></ngx-ui-loader>