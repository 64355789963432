<div class="auth-main"> 
    <div class="container">
  
    <div class="row justify-content-center">
        <div class="col-lg-8 align-self-center">
            <div class="row d-flex justify-content-center h-50" >
                <div class="col-lg-7 user_card">
                    <!-- <div class="d-flex justify-content-center">
                        <div class="login_logo_container"> <img src="./assets/images/logo-1.png" class="login_logo" alt="Logo"> </div>
                    </div>  -->
                    <div class="d-flex ">
                        <h2 class="login-title text-primary d-block ">{{'forgot-password' | translate}}</h2>
                    </div>
                    <lib-alertmessage></lib-alertmessage>

                    <div class="row">
                        <div class="col-lg-12">
                            <div class=" " *ngIf="usernameOption">
                        
                                <form name="form" (ngSubmit)="usernameFormHtml.form.valid && onSubmitUserNameForOtp()" #usernameFormHtml="ngForm" novalidate> 
                                    <div class="input-group mb-3">
                                        <div class="input-group-append"> <span class="input-group-text bg-primary"><i class="fa fa-user"></i></span> </div>
                                        <input type="text" name="userName1" #userName1="ngModel" (contextmenu)="false;" required appAlphaNumericDotUndsDashA [appCopyPastValidation]="true" maxlength="60"
                                        [(ngModel)]="userName" class="form-control input_user" [ngClass]="{ 'is-invalid': usernameFormHtml.submitted && userName1.invalid }"
                                        placeholder="{{'username-email' | translate}}" id="username" autocomplete="off">
                                        <div *ngIf="usernameFormHtml.submitted && userName1.invalid" class="invalid-feedback">
                                            <div *ngIf="userName1.errors?.required">{{'required' | translate}}</div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center mt-3 login_container">
                                        <button mat-raised-button type="submit" class="btn btn-primary"><span class="click-icon"></span> <span class="pl-2">{{'get-otp' | translate}}</span></button>
                                        </div>
                                </form> 
                            </div>
                            <div class=" " *ngIf="otpOption">
                                
                                <form name="form" (ngSubmit)="otpPwdFormHtml.form.valid && onOtpForPassword()" #otpPwdFormHtml="ngForm" novalidate> 
                                    <div class="input-group mb-3">
                                        <div class="input-group-append"> <span class="input-group-text bg-primary"><i class="fa fa-user"></i></span> </div>
                                        <input type="text" name="disable-userName" [(ngModel)]="userName" (contextmenu)="false;" [appCopyPastValidation]="true" class="form-control input_user" disabled>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-append"> <span class="input-group-text bg-primary"><i class="fa fa-key"></i></span> </div>
                                        <input type="text" name="otp2" #otp2="ngModel" (contextmenu)="false;" required [appCopyPastValidation]="true"  minlength="6" maxlength="6"
                                        [(ngModel)]="otp" class="form-control input_user" [ngClass]="{ 'is-invalid': otpPwdFormHtml.submitted && otp2.invalid }"
                                        placeholder="{{'otp' | translate}}" id="otp">
                                        <div *ngIf="otpPwdFormHtml.submitted && otp2.invalid" class="invalid-feedback">
                                            <div *ngIf="otp2.errors?.required">{{'required' | translate}}</div>
                                        </div>
                                        <div class="d-block w-100 text-right mt-1">
                                            <a class="forgetpasswrd">
                                                <span  *ngIf="counter != 0" style="font-weight: 400; font-size: 13px;">
                                                    {{'regenerate_otp' | translate}} 
                                                </span> 
                                                <span *ngIf="counter != 0">{{counter | formatTime}}</span> 
                                                <span *ngIf="counter == 0" (click)="onSubmitUserNameForOtp();">{{'resend-otp' | translate}}</span></a>
                                                 <p style="font-weight: 400; font-size: 13px;">( {{'otpValid'| translate}} )</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="input-group mb-2" style="align-content: center;">
                                                <img src="{{captchaImageBase64Img}}" style="padding-right: 2%;"/><img src="assets/images/refresh_image.png" (click)="getStartupCaptcha()" height="26px" />
                                                <!-- <button (click)="getStartupCaptcha()">REFRESH CAPTCHA</button> -->
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="input-group mb-2">
                                                <input type="text" name="captchaStr1" #captchaStr1="ngModel" (contextmenu)="false;" required [appCopyPastValidation]="true"
                                                minlength="4" maxlength="4"
                                                [(ngModel)]="captchaStr" class="form-control input_user" [ngClass]="{ 'is-invalid': otpPwdFormHtml.submitted && captchaStr1.invalid }"
                                                placeholder="{{'enter-captcha' | translate}}" id="captchaStr">
                                                <div *ngIf="otpPwdFormHtml.submitted && captchaStr1.invalid" class="invalid-feedback">
                                                    <div *ngIf="captchaStr1.errors?.required">{{'required' | translate}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div>
                                        <button type="button" class="btn btn-link" style="float:right;"><span class="click-icon"></span> <span class="pl-2">Resend</span></button>
                                    </div> -->
                                    <div class="d-flex justify-content-center mt-3 login_container">
                                        <button mat-raised-button type="submit" class="btn btn-primary"><span class="click-icon"></span> <span class="pl-2">{{'verify-otp' | translate}}</span></button>
                                    </div>
                                </form> 
                            </div> 
                            <div class=" " *ngIf="passwordOption">
                                
                                <form name="form" (ngSubmit)="passwordPwdFormHtml.form.valid && onPasswordForSubmit()" #passwordPwdFormHtml="ngForm" novalidate> 
                                    <div class="input-group mb-3">
                                        <label class="w-100">{{'username-email' | translate}} <span style="color: red;">*</span></label>
                                        <div class="input-group-append"> <span class="input-group-text bg-primary"><i class="fa fa-user"></i></span> </div>
                                        <input type="text" name="uName" ngModel [(ngModel)]="userName" (contextmenu)="false;" [appCopyPastValidation]="true" class="form-control input_user" disabled>
                                    </div>
                                    <div class="input-group mb-3"> 
                                        <label class="w-100">{{'password' | translate}} <span style="color: red;">*</span></label>
                                        <div class="input-group-append"> <span class="input-group-text bg-primary"><i class="fa fa-key"></i></span> </div>
                                        <input [type]="passhide ? 'password' : 'text'" name="password1" #password1="ngModel" (contextmenu)="false;" required [appCopyPastValidation]="true"
                                        [(ngModel)]="password" class="form-control input_user"  minlength="6" pattern="^(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$&])).{6,14}$"
                                        placeholder="{{'password' | translate}}" id="password" [ngClass]="{ 'is-invalid': passwordPwdFormHtml.submitted && password1.invalid }">
                                        <div *ngIf="passwordPwdFormHtml.submitted && password1.invalid" class="invalid-feedback">
                                            <div *ngIf="password1.errors?.required">{{'required' | translate}}</div>
                                        </div>
                                        <div class="input-group-prepend showPassword"> <button type="button" class=" "  (click)="passhide = !passhide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passhide"><mat-icon>{{passhide ? 'visibility_off' : 'visibility'}}</mat-icon></button> </div>
                                        
                                    </div>
                                    <div *ngIf="password1.touched && password1.invalid">
                                        <div *ngIf="password1.errors?.pattern && lan==0" class="invalid-feedback mb-2"  >
                                            Password Must Have<br />
                                            1. Minimum 6 and Maximum 14 characters <br />
                                            2. at least one small letter <br />
                                            3. at least one number <br />
                                            4. at least one special character<br />
                                            5. at least one capital letter<br />
                                        </div>
                                        <div *ngIf="password1.errors?.pattern && lan==1" class="invalid-feedback mb-2">
                                            पासवर्ड होना चाहिए<br />
                                            1. न्यूनतम 8 और अधिकतम 14 वर्ण <br />
                                            2. कम से कम एक छोटा अक्षर <br />
                                            3. कम से कम एक नंबर <br />
                                            4. कम से कम एक विशेष वर्ण<br />
                                            5. कम से कम एक कैपिटल अक्षर<br />
                                        </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <label class="w-100">{{'conf-password' | translate}} <span style="color: red;">*</span></label>
                                        <div class="input-group-append"> <span class="input-group-text bg-primary"><i class="fa fa-key"></i></span> </div>
                                        <input type="password" name="confpassword1" #confpassword1="ngModel" compare-password="password1" minlength="6" (contextmenu)="false;" required [appCopyPastValidation]="true"
                                        [(ngModel)]="confPassword" class="form-control input_user"
                                        placeholder="{{'conf-password' | translate}}" [ngClass]="{ 'is-invalid': passwordPwdFormHtml.submitted && confpassword1.invalid }">
                                        <div *ngIf="passwordPwdFormHtml.submitted && confpassword1.invalid" class="invalid-feedback">
                                            <div *ngIf="confpassword1.errors?.required">{{'required' | translate}}</div>
                                        </div>
                                        <div class="invalid-feedback" *ngIf="confpassword1.dirty && ((password1.errors && password1.errors?.compare) || (confpassword1.errors && confpassword1.errors?.compare)) && !confpassword1.errors?.required">
                                            {{'notmatch-password' | translate}}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center mt-3 login_container">
                                        <button mat-raised-button type="submit" class="btn btn btn-primary"><span class="click-icon"></span> <span class="pl-2">{{'submit' | translate}}</span></button>
                                        </div>
                                </form> 
                            </div>
                            <div class="mt-4">
                                <div class="d-flex justify-content-center links"><a [routerLink]="isUri=='department' ? '/alogin' : '/clogin'">{{'switch-login' | translate}}</a> </div>
                            </div>
                    
                            <!-- <div class="mt-4">
                                <div class="d-flex justify-content-center links"><a class="backtohome" routerLink="/"><i class="fa fa-home"></i> </a> </div>
                                
                            </div> -->
                        </div>
                        
                    </div>
            
                </div>
                <div class="col-md-5 bg-light">
                    <div class="d-flex h-100 align-items-center twxt-center">
                        <img src="assets/images/forgot-password.png" class="w-100" alt="Forget Password">
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    </div>
    </div>
     
    <ngx-ui-loader></ngx-ui-loader>