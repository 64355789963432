import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { FaqService, FaqModule } from './faq.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {
  lan: number;
  currentModules: FaqModule[] = [];
  breadcrumb: FaqModule[] = [];
  private routerSubscription: Subscription;

  constructor(
    private faqService: FaqService,
    private ngxLoader: NgxUiLoaderService,
    private translate: TranslateService,
    private router: Router,
    private location: Location,
    private translateService: TranslateHEService
  ) {
    this.lan = sessionStorage.getItem("selectedLanguage") === 'hi' ? 1 : 0;
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg;
      this.translate.use(msg === 1 ? 'hi' : 'en');
    });
  }

  ngOnInit(): void {
    this.loadRootModules();

    // Listen to router events to detect browser back/forward navigation
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && this.breadcrumb.length > 0) {
        // Go back one level in the breadcrumb hierarchy
        const lastModule = this.breadcrumb.pop();
        if (lastModule && lastModule.parentId) {
          this.loadSubModules(lastModule.parentId);
        } else {
          this.loadRootModules();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  loadRootModules(): void {
    this.ngxLoader.start();
    this.faqService.getRootModules().subscribe(
      (response: any) => {
        this.currentModules = response.responseData;
        this.breadcrumb = [];
        this.ngxLoader.stop();
      },
      (error) => {
        console.error('Error fetching root FAQ modules:', error);
        this.ngxLoader.stop();
      }
    );
  }

  onModuleClick(module: FaqModule): void {
    if (!module || module.moduleId === undefined) {
      console.error('Invalid module data:', module);
      return;
    }
  
    this.ngxLoader.start();
    this.faqService.getSubModules(module.moduleId).subscribe(
      (response: any) => {
        if (response.responseData && response.responseData.length > 0) {
          // Module has submodules; show submodules instead
          this.breadcrumb.push(module);
          this.currentModules = response.responseData;
        } else {
          // No submodules, navigate to FaqDetailsComponent
          this.router.navigate(['/faqDetails'], {
            queryParams: {
              moduleId: module.moduleId,
              moduleNameEn: module.moduleNameEn,
              moduleNameHi: module.moduleNameHi
            }
          });
        }
        this.ngxLoader.stop();
      },
      (error) => {
        console.error('Error fetching submodules:', error);
        this.ngxLoader.stop();
      }
    );
  }
  
  onBreadcrumbClick(module: FaqModule): void {
    const index = this.breadcrumb.indexOf(module);
    if (index !== -1) {
      this.breadcrumb = this.breadcrumb.slice(0, index + 1);

      if (module.parentId) {
        this.loadSubModules(module.parentId);
      } else {
        this.loadRootModules();
      }
    }
  }

  loadSubModules(parentId: number): void {
    this.ngxLoader.start();
    this.faqService.getSubModules(parentId).subscribe(
      (response: any) => {
        this.currentModules = response.responseData;
        this.ngxLoader.stop();
      },
      (error) => {
        console.error('Error fetching submodules:', error);
        this.ngxLoader.stop();
      }
    );
  }

  onBack(): void {
    if (this.breadcrumb.length > 0) {
      const lastModule = this.breadcrumb.pop();
      if (lastModule && lastModule.parentId) {
        this.loadSubModules(lastModule.parentId);
      } else {
        this.loadRootModules();
      }
    } else {
      this.router.navigate(['/faqModules']);
    }
  }
}
