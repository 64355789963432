<lib-alertmessage></lib-alertmessage>
<div class="row justify-content-center">
    <div class="col-lg-11 duty-card">
        <h3 class="Search-title">
            {{ 'duty-calculation.commonly_used_document' | translate }}
        </h3>
        <div class="row">
            <div class="col-md-12">
                <div class="new_menu">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                            id="navbarNav2" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation" aria-controls="navbarNav">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNav" aria-labelledby="navbarNav2">
                            <ul class="navbar-nav w-100">
                                <li class="nav-item" (click)="close_Nav()">
                                    <a class="nav-link" [ngClass]="{'active': sales}"
                                        (click)="changeDutyCalculate('sales');">
                                        <!-- <i class="fa fa-calculator" aria-hidden="true"></i> -->
                                        <mat-icon
                                            class="material-icons-outlined text-primary">real_estate_agent</mat-icon>
                                        <span class="spantext">{{'duty-calculation.sale_purchase' | translate
                                            }}</span></a>
                                </li>
                                <li class="nav-item" (click)="close_Nav()">
                                    <a class="nav-link" [ngClass]="{'active': mortgage}"
                                        (click)="changeDutyCalculate('mortgage');">
                                        <mat-icon class="material-icons-outlined text-primary">apartment</mat-icon>
                                        <span class="spantext">{{'duty-calculation.mortgage' | translate }}</span></a>
                                </li>
                                <li class="nav-item" (click)="close_Nav()">
                                    <a class="nav-link" [ngClass]="{'active': willdeed}"
                                        (click)="changeDutyCalculate('willdeed');">
                                        <mat-icon class="material-icons-outlined text-primary">task</mat-icon>
                                        <span class="spantext">{{'duty-calculation.will_Deed' | translate }}</span></a>
                                </li>
                                <li class="nav-item" (click)="close_Nav()">
                                    <a class="nav-link" [ngClass]="{'active': giftdeed}"
                                        (click)="changeDutyCalculate('giftdeed');">
                                        <mat-icon class="material-icons-outlined text-primary">redeem</mat-icon>
                                        <span class="spantext">{{'duty-calculation.gift_deed' | translate }}</span></a>
                                </li>
                                <li class="nav-item" (click)="close_Nav()">
                                    <a class="nav-link" [ngClass]="{'active': leasedeed}"
                                        (click)="changeDutyCalculate('leasedeed');">
                                        <mat-icon class="material-icons-outlined text-primary">home_work</mat-icon>
                                        <span class="spantext">{{'duty-calculation.lease_deed' | translate }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div class="or-divider">
            <span class="span1">
                <span class="text-primary">{{'duty-calculation.or' | translate }}</span>
                ( {{ 'duty-calculation.other_document' | translate }} )</span>
        </div>

        <!-- <div class="arrow-box" *ngIf="arrow_box">
            <mat-icon (click)="arrowFunction()">keyboard_arrow_down</mat-icon>
        </div> -->
        <!-- <div *ngIf="search_div"> -->

        <form #dutySearchForm="ngForm"
            (ngSubmit)="dutySearchForm.form.valid && onSubmitDeedDetails(dutySearchForm,true)">

            <div class="row justify-content-center">
                <div class="col-xl-4 col-sm-4">

                    <!-- <label class="required">{{ 'duty-calculation.Select-deed-Catagory' |
                                    translate }}</label> -->
                    <ng-select appendTo="body" placeholder="{{ 'duty-calculation.Select-deed-Catagory' | translate }}"
                        #DeedCatNgSelect [(ngModel)]="deedCatSelectedId" name="DeedCatNgSelect" [clearable]="true"
                        required (ngModelChange)="onDeedCategorySelected()"
                        [ngbTooltip]="deedCatSelected.deedCategoryNameEn!==undefined?lan==0?deedCatSelected.deedCategoryNameEn:deedCatSelected.deedCategoryNameHi:''"
                        [disabled]="!fromEregistryModuleImpoundORCOS && igrsEregTxnDetlsBean?.statusBean?.id >= 45">
                        <ng-option [value]="deedCatItem.id"
                            *ngFor="let deedCatItem of deedCategoryBeanList | orderBy: 'deedCategoryNameEn'">
                            <div
                                [title]="deedCatItem.deedCategoryNameEn!==undefined? lan==0?deedCatItem.deedCategoryNameEn:deedCatItem.deedCategoryNameHi:''">
                                {{lan==0?deedCatItem.deedCategoryNameEn:deedCatItem.deedCategoryNameHi}}
                            </div>
                        </ng-option>
                    </ng-select>
                    <div class="requiredColor" *ngIf="dutySearchForm.submitted && !deedCatSelectedId"> {{
                        'duty-calculation.deed-Catagory-Required' | translate }}</div>

                </div>
                <div class="col-xl-4 col-sm-4">
                    <!-- <label class=" required">{{ 'duty-calculation.Select-Deed-Type' | translate
                                }}</label> -->
                    <ng-select appendTo="body" #DeedTypeNgSelect name="DeedTypeNgSelect"
                        [(ngModel)]="deedTypeSelectedId"
                        placeholder="{{ 'duty-calculation.Select-Deed-Type' | translate }}" required [clearable]="true"
                        [ngbTooltip]="deedTypeSelected.deedTypeNameEn!==undefined? lan==0?deedTypeSelected.deedTypeNameEn:deedTypeSelected.deedTypeNameHi:''"
                        (ngModelChange)="onDeedTypeSelected()"
                        [disabled]="!fromEregistryModuleImpoundORCOS && igrsEregTxnDetlsBean?.statusBean?.id >= 45">
                        <ng-option [value]="deedTypeItem.id"
                            *ngFor="let deedTypeItem of deedTypeCategoryBeanList | orderBy: 'deedTypeNameEn'">
                            <div
                                [title]="deedTypeItem.deedTypeNameEn!==undefined? lan==0?deedTypeItem.deedTypeNameEn:deedTypeItem.deedTypeNameHi:''">
                                {{lan==0?deedTypeItem.deedTypeNameEn:deedTypeItem.deedTypeNameHi}}</div>
                        </ng-option>
                    </ng-select>
                    <div class=" requiredColor" *ngIf="dutySearchForm.submitted && !deedTypeSelectedId"> {{
                        'duty-calculation.deed-Type-Required' | translate }}</div>
                </div>
                <div class="col-xl-4 col-sm-4">
                    <!-- <label class=" required">{{ 'duty-calculation.Select-Instrument' | translate
                                }}</label> -->
                    <ng-select appendTo="body" #DeedInstNgSelect name="DeedInstNgSelect"
                        placeholder="{{ 'duty-calculation.Select-Instrument' | translate }}" required [clearable]="true"
                        [ngbTooltip]="deedInstrumentSelected!==undefined? lan==0?deedInstrumentSelected?.instrumentNameEn:deedInstrumentSelected?.instrumentNameHi:''"
                        [(ngModel)]="deedInstrumentSelectedId" (ngModelChange)="onDeedInstrumentSelected(true)"
                        [disabled]="!fromEregistryModuleImpoundORCOS && igrsEregTxnDetlsBean?.statusBean?.id >= 45">
                        <ng-option [value]="instItem.id"
                            *ngFor="let instItem of deedInstrumentBeanList | orderBy: 'instrumentNameEn'">
                            <div
                                [title]="instItem?.instrumentNameEn!==undefined? lan==0?instItem?.instrumentNameEn:instItem?.instrumentNameHi:''">
                                {{lan==0?instItem.instrumentNameEn:instItem.instrumentNameHi}}</div>
                        </ng-option>
                    </ng-select>
                    <div class="requiredColor" *ngIf="dutySearchForm.submitted && !deedInstrumentSelectedId">
                        {{'duty-calculation.deed-Inst-Required' | translate }}</div>
                </div>

            </div>
            <div *ngIf="!hideSearchFileds" class="or-divider"><span class="span1"><span
                        class="text-primary">{{'duty-calculation.or' | translate }}</span> ( {{
                    'duty-calculation.Search_Instrument' | translate }} )</span></div>


            <div *ngIf="!hideSearchFileds" class="row form-inline mb-3">
                <div class="col-md-12">

                    <div class=" m-0 justify-content-center">
                        <ng-select appendTo="body" class="searchInst" name="searchInst" [multiple]="false"
                            [clearable]="true"
                            [ngbTooltip]="searchTextInst?.instrumentNameEn==null?'': lan==0?searchTextInst?.instrumentNameEn:searchTextInst?.instrumentNameHi"
                            placeholder="{{ 'duty-calculation.Search_Instrument' | translate }}" #searchInst
                            [(ngModel)]="searchTextInstId" minlength="3" [closeOnSelect]="true"
                            (keyup)="onFilterKeyChange(searchInst)" [maxSelectedItems]="1"
                            (ngModelChange)="onSearchInstrumentSelected()"
                            [disabled]="!fromEregistryModuleImpoundORCOS && igrsEregTxnDetlsBean?.statusBean?.id >= 45">

                            <ng-option *ngFor="let inst of serachInstList" [value]="inst?.id">
                                <div
                                    *ngIf="serachInstList!=null && serachInstList!=undefined && serachInstList?.length>0">
                                    <div
                                        [title]="inst?.instrumentNameEn==null?'': lan==0?inst?.instrumentNameEn:inst?.instrumentNameHi">
                                        <i class="fa fa-search"></i>
                                        {{lan==0?inst.instrumentNameEn:inst.instrumentNameHi}}
                                    </div>
                                </div>
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>


            <!--------transactiononbehalf----------->


            <div class=" select-box row align-items-center mt-4" *ngIf="fromEregistryModule">
                <label class="col-md-4">
                    {{'e-stamp.transactiononbehalf' | translate}}
                    <span class="text-danger">*</span></label>
                    <div class="mb-1 col-md-8">
                    <ng-select type="text"  [(ngModel)]="igrsEregTxnDetlsBean.userId" name="userId" #userIdSelected="ngModel" required appendTo="body" class="form-control p-0" 
                    [disabled]="!fromEregistryModuleImpoundORCOS && igrsEregTxnDetlsBean?.statusBean?.id >= 45" [clearable]="true"   placeholder="{{'Search-Document-Certified.select' | translate}} ">
                       <ng-option [value]="userProfileModel.id"> {{lan==0?"Individual":"स्वयं"}}</ng-option>
                       <ng-option [value]="item.citizenId"
                          *ngFor="let item of representativeList | orderBy:'firstNameEn'">
                          {{item.username}} ({{item.registrationNo}})
                          <!-- {{lan==0?item.firstNameEn:item.firstNameHi}} -->
                       </ng-option>
                    </ng-select>
                    <div *ngIf="dutySearchForm.submitted && !igrsEregTxnDetlsBean.userId" class=" requiredColor">
                        {{(lan==0)?"transaction on behalf required":"की ओर से संव्यवहार आवश्यक है"}}
                    </div>
                    </div>
            </div>
            <!--------transactiononbehalf----------->






            <!--------Mining Lease----------->
            <div class="miningLease mt-4 mb-2"
                *ngIf="(deedInstrumentSelectedId == 81 || deedInstrumentSelectedId == 83 || deedInstrumentSelectedId == 84) && fromEregistryModule">
                <div class="form-check form-check-inline mr-5 flex-wrap">
                    <label class="form-check-label" for="mineralRadio1">
                        <input class="form-check-input" type="radio" name="major" id="mineralRadio1" [value]=1
                            (click)="openMineralDialog($event,1)" [(ngModel)]="mineralTypeId">
                        {{'duty-calculation.majo-mineral' | translate }}

                    </label>

                </div>
                <div class="form-check form-check-inline flex-wrap">
                    <label class="form-check-label" for="mineralRadio2">
                        <input class="form-check-input" type="radio" name="major" id="mineralRadio1" [value]=2
                            (click)="openMineralDialog($event,2)" [(ngModel)]="mineralTypeId">

                        {{'duty-calculation.mino-mineral' | translate }}
                    </label>

                </div>
            </div>
            <!-------Mining Lease------------>

            <!--------===Amendemet / Correction==EregistryModule----------->
            <div class="miningLease mt-4 mb-2"
                *ngIf="(deedInstrumentSelectedId == 22 || deedInstrumentSelectedId == 37) && fromEregistryModule">

                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    [expanded]="isExpanded">
                    <mat-expansion-panel-header>
                        <mat-panel-title *ngIf="(deedInstrumentSelectedId == 22)">

                            {{'duty-calculation.detailOfAmendemetCorrection' | translate }}
                        </mat-panel-title>
                        <mat-panel-title *ngIf="(deedInstrumentSelectedId == 37)">

                            {{'duty-calculation.detailOfCancellation' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                     <div class="note_point">
                        <p class="m-0 d-flex alert alert-info" style="line-height: 1.6;">
                            <b class="d-flex" style="margin-right: 5px;">
                             {{'legacyDocumentRequest.note' | translate}} :
                            </b>
                            <span *ngIf="lan==0"> 1. If your Document is registered either in SAMPADA 1 (ex. MPXXXXXXXXXAXXXXX48) or SAMPADA 2.0 (ex. MPXXIGRXXXXXXXXAXXXXXXX56) then please select Yes Option. <br> 2. Please Note in case of SAMPADA 2.0 Document, Endorsement Seal (Mortgage/DeMortgage/Amendment/Cancellation) will be affixed only if you select Yes. </span>
                            <span *ngIf="lan==1"> 1. यदि आपका दस्तावेज़ SAMPADA 1 (उदा. MPXXXXXXXXAXXXXX48) या SAMPADA 2.0 (उदा. MPXXIGRXXXXXXXXAXXXXXXX56) में पंजीकृत है तो कृपया हाँ विकल्प चुनें। <br> 2. कृपया ध्यान दें कि संपदा 2.0 दस्तावेज़ के मामले में, पृष्ठांकन मुहर (बंधक/डीमोर्टगेज/संशोधन/रद्दीकरण) तभी लगाई जाएगी जब आप हाँ का चयन करेंगे। </span>
                        </p>


                     <ng-template #noInfo let-modal>
                
                        <!-- <button type="button" class="colsebtn h4" (click)="modal.dismiss('Cross click')" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button> -->
                        <div class="modal-body declaration-info">
                             <fieldset class="legend-section">
                            <legend class="sidebar__widget-title">
                                   {{'legacyDocumentRequest.note' | translate}}
                                </legend>
                        <p class="m-0 d-flex alert alert-info">
                            
                            <span *ngIf="lan==0"> 1. If your Document is registered either in SAMPADA 1 (ex. MPXXXXXXXXXAXXXXX48) or SAMPADA 2.0 (ex. MPXXIGRXXXXXXXXAXXXXXXX56) then please select Yes Option. <br> 2. Please Note in case of SAMPADA 2.0 Document, Endorsement Seal (Mortgage/DeMortgage/Amendment/Cancellation) will be affixed only if you select Yes. </span>
                            <span *ngIf="lan==1"> 1. यदि आपका दस्तावेज़ SAMPADA 1 (उदा. MPXXXXXXXXAXXXXX48) या SAMPADA 2.0 (उदा. MPXXIGRXXXXXXXXAXXXXXXX56) में पंजीकृत है तो कृपया हाँ विकल्प चुनें। <br> 2. कृपया ध्यान दें कि संपदा 2.0 दस्तावेज़ के मामले में, पृष्ठांकन मुहर (बंधक/डीमोर्टगेज/संशोधन/रद्दीकरण) तभी लगाई जाएगी जब आप हाँ का चयन करेंगे। </span>
                        </p>
                                </fieldset>
                                   <div class="text-center">
                            <button class="btn btn-primary" (click)="modal.dismiss('Cross click')">
                        {{'property-identificationValuation.oK' | translate}}
                    </button>
                        </div>
                        </div>
                        
                </ng-template>







                        </div>

                    <fieldset class="legend-section mt-2 mb-2   align-items-center">
                        <div class="row align-items-center mt-2">
                            <div class="col-md-6">
                                <div class="row">
                                    <label
                                        class="col-md-auto m-0 mr-4 mt-1">{{"proIdentification-Mortgage.sampadaregiDocNo"
                                        | translate}}</label>
                                    <div class="col-md-auto m-0"
                                        [disabled]="igrsEregTxnDetlsBean?.statusBean?.id >= 45">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                name="radioOptionCheckSampadaId" id="radioOptionCheckSampadaId_YES"
                                                value="YES" (click)="checkSampadaIdStatus('YES')"
                                                [disabled]="igrsEregTxnDetlsBean?.statusBean?.id >= 45"
                                                [(ngModel)]="checkSampadaId">
                                            <label class="form-check-label" for="inlineRadio1">
                                                {{'property-valuation.Yes' | translate}}
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"
                                                name="radioOptionCheckSampadaId" id="radioOptionCheckSampadaId_NO"
                                                value="NO" (click)="checkSampadaIdStatus('NO', noInfo)"
                                                [(ngModel)]="checkSampadaId">
                                            <label class="form-check-label" for="inlineRadio2">
                                                {{'property-valuation.No' | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6" *ngIf="(checkSampadaId==='YES')">
                                <div class="propery-search p-5">
                                    <div class="d-flex">
                                        <input type="text" class="form-control noColorplaceholder" appAlphaNumericSpace
                                            [(ngModel)]="sampadaPropertyId" maxlength="100" name="sampadaPropertyId"
                                            placeholder="{{'proIdentification-Mortgage.EnterSampadaregiDocNo' | translate}}"
                                            [disabled]="igrsEregTxnDetlsBean?.statusBean?.id >= 45" />
                                        <button class="btn btn-primary btn-sm Search_pro ml-1" type="button"
                                            placement="left" container="body"
                                            [disabled]="igrsEregTxnDetlsBean?.statusBean?.id >= 45"
                                            ngbTooltip="{{'proIdentification-Mortgage.rsearch' | translate}}"
                                            (click)="searchPropertyByPropertyId()">
                                            <mat-icon>search</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </fieldset>
                    <!--this condition is skip bcz hole legecy data is not available *ngIf="!(checkSampadaId==='YES')" -->
                    <fieldset class="legend-section" *ngIf="false">
                        <div class="row  d-flex align-items-center">
                            <div class="col-md-auto col-sm-auto">
                                <label>
                                    {{'duty-calculation.manualRegDocNo' | translate }}
                                </label>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="propery-search p-5">
                                    <div class="d-flex">
                                        <input type="text" class="form-control" appAlphaNumericSpace maxlength="100"
                                            [(ngModel)]="manualSearchPropertyId" name="manualSearchPropertyId"
                                            placeholder="{{'duty-calculation.enterManualRegDocNo' | translate }}" />
                                        <button class="btn btn-primary btn-sm Search_pro ml-1" type="button"
                                            placement="left" container="body"
                                            ngbTooltip="{{'proIdentification-Mortgage.rsearch' | translate}}"
                                            (click)="manualSearchProperty()">
                                            <mat-icon>search</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <app-parent-instrument-search *ngIf="!(checkSampadaId==='YES')" category={{parentCategory}}
                        deedType={{parentDeedType}} instrument={{parentInstrument}}
                        [igrsEregTxnDetlsBean]="igrsEregTxnDetlsBean"
                        (parentSelectedInstrumentObj)="getParentSelectedInstrumentObj($event)">




                    </app-parent-instrument-search>







                    <div class="row mt-2 mb-3"
                        *ngIf="igrsEregTxnDetlsBean?.cmsRegSearchDetails?.deedCategoryNameEn != null && igrsEregTxnDetlsBean?.cmsRegSearchDetails?.deedCategoryNameEn != undefined">
                        <div class="col-md-12">
                            <div class="table-responsive shadow-none mt-2">
                                <table class="table table-bordered mb-0">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col" style="width:20%;">
                                                {{ "duty-calculation.Deed-Catagory" | translate }}
                                            </th>
                                            <th scope="col" style="width: 20%;">
                                                {{ "duty-calculation.Deed-Type" | translate }}
                                            </th>
                                            <th scope="col" style="width: 20%;">{{ "duty-calculation.Instrument" |
                                                translate }}</th>
                                            <th scope="col">{{ "proposalSection.dateOfExec" |
                                                translate }}</th>
                                            <th scope="col">{{
                                                "citizen-profile-details.citizen-juristic-service-details.registration-date"
                                                | translate }}</th>
                                            <th scope="col">{{
                                                "Search-Document-Certified.search_reg_no" | translate }}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{lan==0?igrsEregTxnDetlsBean?.cmsRegSearchDetails?.deedCategoryNameEn:igrsEregTxnDetlsBean?.cmsRegSearchDetails?.deedCategoryNameHi}}
                                            </td>
                                            <td>{{lan==0?igrsEregTxnDetlsBean?.cmsRegSearchDetails?.deedTypeNameEn:igrsEregTxnDetlsBean?.cmsRegSearchDetails?.deedTypeNameHi}}
                                            </td>
                                            <td>{{lan==0?igrsEregTxnDetlsBean?.cmsRegSearchDetails?.instrumentNameEn:igrsEregTxnDetlsBean?.cmsRegSearchDetails?.instrumentNameHi}}
                                            </td>

                                            <td
                                                *ngIf="(checkSampadaId==='YES' || igrsEregTxnDetlsBean?.statusBean?.id >= 45)">
                                                {{igrsEregTxnDetlsBean?.cmsRegSearchDetails?.registryInitiationDate |
                                                date:'dd/MM/YYYY, h:mm:ss a'}}

                                            </td>
                                            <td
                                                *ngIf="(checkSampadaId==='YES' || igrsEregTxnDetlsBean?.statusBean?.id >= 45)">
                                                {{igrsEregTxnDetlsBean?.cmsRegSearchDetails?.documentRegistrationDate |
                                                date:'dd/MM/YYYY, h:mm:ss a'}}
                                            </td>

                                            <td
                                                *ngIf="!(checkSampadaId==='YES' || igrsEregTxnDetlsBean?.statusBean?.id >= 45)">
                                                <!-- {{igrsEregTxnDetlsBean?.cmsRegSearchDetails?.registryInitiationDate | date:'dd/MM/YYYY, h:mm:ss a'}}   -->
                                                <!-- <input type="date" name="registryInitiationDate" [max]="toDate"  [(ngModel)]="registryInitiationDate" (change)="setDates('iniDate')" class="form-control"> -->
                                                <div class="example-full-width">
                                                    <!-- <mat-label>Choose a date</mat-label> -->
                                                    <input matInput [matDatepicker]="regInipicker" [max]="toDate"
                                                        name="registryInitiationDate"
                                                        (dateChange)="setDates('iniDate',$event)" readonly
                                                        [(ngModel)]="registryInitiationDate"
                                                        class="ng-trim-ignore date-form-control"
                                                        placeholder="Date Of Execution">
                                                    <mat-datepicker-toggle matIconSuffix
                                                        [for]="regInipicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #regInipicker></mat-datepicker>
                                                </div>
                                            </td>
                                            <td
                                                *ngIf="!(checkSampadaId==='YES' || igrsEregTxnDetlsBean?.statusBean?.id >= 45)">
                                                <!-- {{igrsEregTxnDetlsBean?.cmsRegSearchDetails?.documentRegistrationDate | date:'dd/MM/YYYY, h:mm:ss a'}}   -->
                                                <!-- <input type="date" name="documentRegistrationDate" [max]="toDate"  [(ngModel)]="documentRegistrationDate" (change)="setDates('regDate')" class="form-control"> -->
                                                <div class="example-full-width">
                                                    <!-- <mat-label>Choose a date</mat-label> -->
                                                    <input #input matInput [matDatepicker]="regComppicker"
                                                        [max]="toDate" name="documentRegistrationDate"
                                                        (dateChange)="setDates('regDate',$event)" readonly
                                                        [(ngModel)]="date" class="ng-trim-ignore date-form-control"
                                                        placeholder="Date of Registration">
                                                    <mat-datepicker-toggle matIconSuffix
                                                        [for]="regComppicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #regComppicker></mat-datepicker>
                                                </div>
                                            </td>




                                            <td>{{igrsEregTxnDetlsBean?.parentRegNo}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <fieldset class="legend-section mt-2" *ngIf="propertyIdentificationBySampadaId.length>0">
                        <legend class="sidebar__widget-title">
                            {{"proIdentification-Mortgage.lstPro" | translate}}
                        </legend>
                        <div class="table-responsive shadow-none">
                            <table class="table table-bordered">
                                <thead class="thead-dark">
                                    <tr>
                                        <th>#</th>
                                        <!-- <th style="width:8%" *ngIf="igrsEregTxnDetlsBean.deedInstrumentSelected.deedTypeId.id!=16">
                                  {{"proIdentification-Mortgage.transProp" | translate}}
                                </th> -->
                                        <th style="width:15%">
                                            {{'eRegistry.upload-Link.propertyType' | translate}}
                                        </th>
                                        <th>
                                            {{'ereg-property-details.SAMPADAPropertyID' | translate}}
                                        </th>
                                        <th style="width:20%">
                                            {{'ereg-property-details.landParcelIDPre' | translate}}
                                        </th>
                                        <th>
                                            {{'ereg-property-details.khasraplotHouse' | translate}}
                                        </th>

                                        <th>
                                            {{"proIdentification-Mortgage.addreOfProp" | translate}}
                                        </th>
                                        <th class="text-center">
                                            {{'ereg-property-details.totalArea'| translate}}
                                        </th>
                                        <th class="text-center">
                                            {{'ereg-property-details.action'| translate}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let propIdentiObj of propertyIdentificationBySampadaId;let i = index">
                                        <tr>
                                            <td>{{i+1}}</td>
                                            <!-- <td *ngIf="igrsEregTxnDetlsBean.deedInstrumentSelected.deedTypeId.id!=16">
                                    <ng-container *ngIf="propIdentiObj.alreadyAdded;else notAdded">
                                      {{'proIdentification.AlreadyAdded' | translate}}
                                    </ng-container>
                                    <ng-template #notAdded>
                                      <input class="form-check-input" type="checkbox" id="{{propIdentiObj.propertyId}}"
                                        name="{{propIdentiObj.propertyId}}" value="{{propIdentiObj.propertyId}}"
                                        (click)="onPropertyCheckBoxChange(propIdentiObj.id,propIdentiObj.landParcelId,propIdentiObj.propertyTypeId, $event)">
                                    </ng-template>
                                  </td> -->
                                            <td>{{propIdentiObj.propertyType}} {{((propIdentiObj.unit==='SQMT' ||
                                                propIdentiObj.unit==='SQFT') &&
                                                propIdentiObj.propertyType=='AGRICULTURAL LAND') ? (lan==0 ? '(PLOT)' :
                                                '(भूखंड)') : ''}}</td>
                                            <td>{{propIdentiObj.propertyId}}</td>
                                            <td>{{propIdentiObj.landParcelId}}</td>
                                            <td>{{propIdentiObj.khasraplotNo}}</td>
                                            <td>{{propIdentiObj.addressOfProperty}}</td>
                                            <td>{{propIdentiObj.transactingArea}} {{getUnit(propIdentiObj.unit)}}</td>
                                            <!-- *ngIf="(sampadaPropertyId.length==18 || sampadaPropertyId.length==19)" -->
                                            <td *ngIf="(sampadaPropertyId.length >=18 && sampadaPropertyId.length < 24)">
                                                <ng-container *ngIf="!propIdentiObj.demography; else dDone">
                                                    <a class="btn btn-primary"
                                                        (click)="addDemography(propIdentiObj,igrsEregTxnDetlsBean,i)">Identify
                                                        Guideline Location </a>
                                                </ng-container>
                                                <ng-template #dDone>
                                                    Guideline Added
                                                </ng-template>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-right">
                            <button class="btn btn-primary" type="button" (click)="getSelectedPropertyDetails()">
                                {{"proIdentification-Mortgage.addPropMortgage" | translate}}
                            </button>
                        </div>
                    </fieldset>


                </mat-expansion-panel>



            </div>

            <!--------==Amendemet / Correction=EregistryModule=----------->









            <div *ngIf="!fromEregistryModule" class="text-right mt-2 mb-3">
                <div class=" ">
                    <button type="button" mat-flat-button class="btn btn-secondary mr-1" (click)="resetFields()">{{
                        'duty-calculation.Reset' | translate }}</button>
                    <!--     <button (click)="onSubmitDeedDetailsOld()" class="btn btn-primary mr-3" type="button" id="button-addon2">{{ 'duty-calculation.Submit' | translate }}</button>-->
                    <button *ngIf="showSubmitBttn" mat-flat-button class="btn btn-primary " type="submit"
                        id="button-addon2">{{
                        'duty-calculation.Submit' | translate }}</button>

                </div>
            </div>
        </form>
    </div>

    <ngx-ui-loader [loaderId]="'instrument-search'"></ngx-ui-loader>
</div>