import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  private apiUrl!: string;

  constructor(private http: HttpClient, private appSettingsService: AppSettingService) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.apiUrl = settings.igrs_services_search_url + 'common/docsearch/faqModules';
    });
  }

  // Fetch root-level modules
  getRootModules(): Observable<FaqModule[]> {
    return this.http.get<FaqModule[]>(`${this.apiUrl}/root`).pipe(
      catchError(this.handleError)
    );
  }

  // Fetch submodules for a specific parent module
  getSubModules(parentId: number): Observable<FaqModule[]> {
    return this.http.get<FaqModule[]>(`${this.apiUrl}/${parentId}/submodules`).pipe(
      catchError(this.handleError)
    );
  }

  // Fetch questions and answers for a specific module
  getFaqQuestions(moduleId: number): Observable<FaqQuestionAnswer[]> {
    return this.http.get<FaqQuestionAnswer[]>(`${this.apiUrl}/${moduleId}/questions`).pipe(
      catchError(this.handleError)
    );
  }

  // Error handling method
  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `A client-side error occurred: ${error.error.message}`;
    } else {
      errorMessage = `A backend error occurred: ${error.status}, ${error.message}`;
    }
    return throwError(errorMessage);
  }
}

// Interfaces for data types
export interface FaqModule {
  moduleId: number;
  parentId: number;
  moduleNameEn: string;
  moduleNameHi: string;
  iconName: string;
  moduleLevel: number;
}

export interface FaqQuestionAnswer {
  qaId: number;
  questionTextEn: string;
  questionTextHi: string;
  answerTextEn: string;
  answerTextHi: string;
}
