import { IgrsSubClausePropertyMappingBean } from "./IgrsSubClausePropertyMappingBean"
import { MstDemographyBean } from "./MstDemographyBean"
import { MstPropertyTypeBean } from "./MstPropertyTypeBean"
import { PropertyOwner } from "./PropertyOwner"
import { UOMEnum } from "./UOMEnum"
import { IgrsEregMunicipalTaxesBean, IgrsEregLoanDetlsBean, IgrsEregPropChargeCasesBean, IgrsEregIvrsDetlsBean, IgrsEregPropAdditionalDetlsBean, EregPropertyLinkModel, IgrsEregPropImgDisplayBean, IgrsEstampPartyPropMappingBean, IgrsEregPartyDetailsBean } from "./e_registry/e-registry.model"

export class MPenagarpalikaResponsModel {

	id: number
	gisSearchTxt: string
	igrsUqId: number
	//unit: string
	unit: UOMEnum
	propertyTypeId: number
	totalArea: number
	applicationNo: number
	propertyId: string
	propertyNo: any
	latitude: number
	longitude: number
	name: string
	aadharCard: string
	CornerPlot: string
	subClause: IgrsSubClausePropertyMappingBean[] = new Array();
	demography: MstDemographyBean = new MstDemographyBean();
	demographyArray: MstDemographyBean[] = new Array()
	propertyType: string
	propertyOwner: PropertyOwner[] = []
	areaType: string
	GandiBasti: string
	OpenTerrace: string
	constrnTime: string
	ConstructionYear: string
	Ews: string
	constructionType: string
	constructionDate: string
	DirectionNorth: string
	DirectionEast: string
	DirectionWest: string
	DirectionSouth: string
	Buildingtype: string
	buildingSubType:string
	constructionYear: string
	buildingtype: string
	directionSouth: string
	foundation: string
	directionWest: string
	ews: string
	directionEast: string
	cornerPlot: any
	directionNorth: any
	otherDetails?: string;
	partialTransactArea: number
    address:string
	igrsEregMunicipalTaxesBean: IgrsEregMunicipalTaxesBean;
	igrsEregLoanDetlsBean: IgrsEregLoanDetlsBean;
	igrsEregChargeAndCasesBean: IgrsEregPropChargeCasesBean;
	igrsEregIvrsDetlsBean: IgrsEregIvrsDetlsBean;
	igrsEregPropAdditionalDetlsBean = new IgrsEregPropAdditionalDetlsBean();
	igrsEregPropLinking: EregPropertyLinkModel[];
	igrsEregPropImgDisplayBean = new IgrsEregPropImgDisplayBean();

	partyBeans: IgrsEregPartyDetailsBean[] = [];
	partyBuyerBeans: IgrsEregPartyDetailsBean[] = [];
	partySellerBeans: IgrsEregPartyDetailsBean[] = [];
	partyConsenterBeans: IgrsEregPartyDetailsBean[] = [];
	houseFlatNo:string
	partyBean: IgrsEregPartyDetailsBean = new IgrsEregPartyDetailsBean();
	expanded: boolean;
	makerRemarks: string;
	checkerRemarks: string;
	parentId: number;
	addPartyWithoutCustodianData: boolean = false;
	mauunalProcess:boolean=false
	isPnrd:boolean=false
	isZero:boolean=false
	districtId?:number
	isPartyForEdit:boolean
	isResidensial:boolean
	isCommercial:boolean
	isHealth:boolean
	isEduArea:boolean
	isOthers:boolean
	isIndustrial:boolean
	
}
