import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ChartType, ChartOptions } from 'chart.js';
// import { Console } from 'console';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts'
import { NgxPrintElementService } from 'ngx-print-element';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { DeedInstrumentBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-instrument-bean';
import { DutyCalculationRequest } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-calculation-request';
import { DutyCalculationResponse } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-calculation-response';
import { DutyConstants } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-constants';
import { DutyExemptionMapping } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-exemption-mapping';
import { DutyInputFields } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-input-fields';
import { DutyInputOtherFieldListDto } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-input-other-field-list-dto';
import { DutyOtherFlagEnum } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-other-flag-enum';
import { DutyOtherInputFields } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-other-input-fields';
import { DutyPropInputFields } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-prop-input-fields';
import { DutyPropInputFieldsDto } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-prop-input-fields-dto';
import { IgrsConsenterDetlsDto } from 'projects/common-lib/src/lib/models/dutyCalculation.model/igrs-consenter-detls-dto';
import { IgrsConsenterMap } from 'projects/common-lib/src/lib/models/dutyCalculation.model/igrs-consenter-map';
import { InstMappingBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/inst-mapping-bean';
import { MineralMstBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/mineral-mst-bean';
import { CalculateGuidelineValueComponent } from '../calculate-guideline-value/calculate-guideline-value.component';
import { CalculateOtherComponent } from '../calculate-other/calculate-other.component';
import { ConsenterDetailsComponent } from '../consenter-details/consenter-details.component';
import { DutyCalculationComponent } from '../duty-calculation.component';
import { CommunicationServiceService } from '../duty-service/communication-service.service';
import { DutyService } from '../duty-service/duty.service';
import { MineralSelectionComponent } from '../mineral-selection/mineral-selection.component';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { first, take } from 'rxjs/operators';
import { InstrumentSearchComponent } from '../instrument-search/instrument-search.component';
import { UtilityService } from 'projects/common-lib/src/lib/service/UtilityService';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);

export class SumOfMarketValParty{
    partyName:string
    marketValue:number
    valId:number
}

export class PartitionDeed{
  propValAttribute:any;
  partyList:Party[] = new Array();
}


export class Party {
  constructor(){
    this.name ='';
   // this.shareArea;
    this.valId;
    this.isPartySaved;
    this.partyShare = new PartyShare();

  }
  name: string;
  //shareArea:number;
  partyShare:PartyShare;
  valId: number;
  isPartySaved:boolean;
  sumOfMarketValue:number;
}

export class PartyShare{
  shareAreaSold:number;
}

export interface fieldMap {
  //id: string,
 // value: string
  [indexer: string] : string
}
@Component({
  selector: 'app-calculate-duty',
  templateUrl: './calculate-duty.component.html',
  styleUrls: ['./calculate-duty.component.scss']
})
export class CalculateDutyComponent implements OnInit,OnChanges {
  @ViewChild("dutyform" ,{static:false}) dutyform: NgForm;
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  @Input() instMappingBeanList: InstMappingBean[] = new Array();
  @Input() instrumentBean: DeedInstrumentBean;
  @Input() instrumentBeanDuplicate: DeedInstrumentBean;
  @Input() displayPropDiv:boolean;
  @Output() dutyCalculationResponseBeanEvent = new EventEmitter<DutyCalculationResponse>();
  @ViewChild(CalculateGuidelineValueComponent,{ static: false }) calculateGuidelineValueComponent: CalculateGuidelineValueComponent;

  dutyCalculationRequest: DutyCalculationRequest =  new DutyCalculationRequest();
  dutyCalculationResponseBean: DutyCalculationResponse = new DutyCalculationResponse();
  instExempMappingList: DutyExemptionMapping[]= new Array();
  selectedExemptions: DutyExemptionMapping[] = new Array();
  dutyInputFields: DutyInputFields = new DutyInputFields();
  dutyPropInputFields:DutyPropInputFields= new DutyPropInputFields();
  dutyPropInputFieldsArray: DutyPropInputFields[] = new Array();
  dutyOtherInputFields: DutyOtherInputFields[] = new Array();
  dutyOtherInputFields1: DutyOtherInputFields[] = new Array();
  dutyOtherInputFields2: DutyOtherInputFields[] = new Array();
  dutyOtherInputFields4: DutyOtherInputFields[] = new Array();

  dutyOtherInputFieldsAll: DutyOtherInputFields[] = new Array();
  allMineralList: MineralMstBean[] = new Array();
  filterMineralList: MineralMstBean[] = new Array();
  selectedMineralList: MineralMstBean[] = new Array();
  selectedConsenterList:  Array<any> = [];
  filterInstMappingList: InstMappingBean[] = new Array();
  propDivflagList:InstMappingBean[] = new Array();
  isConditionalQues: boolean=false;
  showExemptions: boolean = false;
  availExemption: boolean = false;
  radioExemption: boolean = false;
  propValSelect: boolean = false;
  propValFlow: boolean = true;
  exemptionsNotAvailable: boolean = false;
  pMappingSel: number;
  pMappingCheckSel: number;
  radioValSel:string;
  inputFields: any = new Array();
  propValArray: Array<any> = [];
  propValArrayPartitionDeed: Array<any> = [];
  partyListToCopy:Party[] = new Array();
  partyInput:Party
  propValListFTP:DutyPropInputFields[]= new Array();
  propValListSTP:DutyPropInputFields[]= new Array();
  propValFieldArray:DutyPropInputFieldsDto[]= new Array();
  DutyPropInputFieldsDto: DutyPropInputFieldsDto = new DutyPropInputFieldsDto();
  propValArrayFTP: Array<any> = [];
  propValArraySTP: Array<any> = [];
  fieldArray: Array<any> = [];
  dutyInputOtherFieldListDto:DutyInputOtherFieldListDto = new DutyInputOtherFieldListDto();
  dutyInputOtherFieldListArray : DutyInputOtherFieldListDto[]= new Array();
  propValAttribute: any = {};
  propValAttributeColEn: any = [];
  propValAttributeColHi: any = [];
  propMarketValue: number;
  propValId: number;
  //mstDemographyBean:any;
  propValIdValid: boolean = false;
  popUpValueSel: string;
  otherCalFlag: string='';
   propDivTitle: string='';
   isDropdownSelect:boolean=false;
   pMappingDropBean:InstMappingBean;
   fieldNameEn: any;
   pMappingDropSel: number;
   propSeq=1;
   //errMsgEn:string;
   //errMsgHi:string;
   //errMsg: any = {};
   cancellationFlag: boolean=false;
   ischeckMoveble: boolean = false;
   displayCalDutyFieldsFlag:boolean = false;
  movPropValfieldArray: Array<any> = [];
  movPropValAttribute: any = {};
  mvFileToUpload: File | null = null;

  propertySel:number;
  selectedPropertyList:Array<any>;
  consenterDetailMap = new Map<number, IgrsConsenterDetlsDto[]>();
  addMorePropEnable:boolean=true;
  //consenterDetailMap : IgrsConsenterDetlsDto[] = new Array();
  // inputFields :fieldMap = {};
   //inputFields = new Map<string, string>();
//Property Valuation Property
self = false;
other = false;

totalConsiderationAmount:number=0;
totalConsiderationAmountProp:number=0;

submitDisable:boolean=false;
removeUploadMovable:boolean =false;
isAgreementPartitionParty:boolean =true;




checkBtnMoveble(divid: string){

  if (divid === "self_tab") {
    this.ischeckMoveble = true;
    this.self = true;
    this.other = false;
  }
  else if (divid === "other_tab") {
    this.ischeckMoveble = false;
    this.self = false;
    this.other = true;
  }
}

consenterChange(event:any ,propertySel: any){
  if (event.target.value == 'true') {
        this.propValAttribute.propConstSelect = true;
       this.openDialogConsenter(event, propertySel);
    }else{
          this.propValAttribute.propConstSelect = false;
    }
}

consenterChangeFromList(event:any ,prop: any,index:number){
  let propertySel = prop.valTxnId;
  if (event.target.value == 'true') {
    //this.propValAttribute.propConstSelect = true;
    this.propValArray[index].propConstSelect = true;
          this.openDialogConsenter(event, propertySel);
    }else{
         // this.propValAttribute.propConstSelect = false;
          this.propValArray[index].propConstSelect = false;
    }
}

openDialogConsenter(event:any ,propertySel: any) {
  this.propertySel= propertySel;
  console.log("openDialogConsenter: propertySel - ",this.propertySel);
  if(this.consenterDetailMap.size>0){
    this.selectedConsenterList =this.consenterDetailMap.get(this.propertySel);
  }else{
    this.selectedConsenterList =[];
  }

  this.selectedPropertyList = this.propValAttribute.propertyIds; //[1,2,3];
  let obj = { selctedhasraList: this.selectedPropertyList, propertySel: this.propertySel,selectedConsenterList: this.selectedConsenterList};
  this.dialogDuty.open(ConsenterDetailsComponent, {
    data: obj,
    maxWidth: '50vw',
    width: '100%',
  });
}


  lan: number=1;
  constructor( public dialogRef: MatDialogRef<CalculateGuidelineValueComponent>,public router:Router, public dialogDuty: MatDialog,private dcService: DutyService,private serv: CommunicationServiceService,private ngxService: NgxUiLoaderService,
    private translateService: TranslateHEService, private translate: TranslateService,public print: NgxPrintElementService) {
  
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })
       monkeyPatchChartJsTooltip();
      monkeyPatchChartJsLegend();
      console.log("constructor -- CalculateDutyComponent");
      this.setPropDisplayFlag();
      this.radioExemption = false;
  }
  ngOnChanges(changes: SimpleChanges): void {
   //console.log("change p",changes.instrumentBean.previousValue)
   //console.log("change c ",changes.instrumentBean.currentValue)
   console.log("change p",changes.instMappingBeanList.previousValue)
   console.log("change c ",changes.instMappingBeanList.currentValue)
   console.log("change : CalculateDutyComponent");
   this.setPropDisplayFlag();
   this.otherCalFlag='';
   this.propDivTitle = '';
     this.resetDutyFields();
    // this.dutyform.resetForm();
     this.getExemptionMappingByInstId(this.instrumentBean.id);

     let minCheck =  this.instMappingBeanList.map(item => item).filter((item) => item.isPopup);
      console.log("minCheck-",minCheck);
      if(minCheck!=null && minCheck.length>0){
        this.getAllMineralList();
      }
  }
  public get DutyOtherFlagEnum(): typeof DutyOtherFlagEnum {
    return DutyOtherFlagEnum;
  }
  ngOnInit(): void {
    console.log("ngOnInit -- CalculateDutyComponent");
    this.radioExemption = false;
    if(this.instrumentBean!==undefined && this.instrumentBean!=null){
      //if(this.instMappingBeanList!=null && this.instMappingBeanList.length>0){
      //this.instrumentId = this.instMappingBeanList[0].instrumentId.id;
     // this.setPropDisplayFlag();
     console.log("Duty this.instrumentBean:-",this.instrumentBean);
      this.getExemptionMappingByInstId(this.instrumentBean.id);
      let minCheck =  this.instMappingBeanList.map(item => item).filter((item) => item.isPopup);
      console.log("minCheck-",minCheck);
      if(minCheck!=null && minCheck.length>0){
          this.getAllMineralList();
      }

      if(this.instrumentBean.deedTypeId.id==64){
          //let party =new Party();
          
        //this.partyList.push(party);
       // console.log("ngOnInit -- this.partyList",this.partyList);
      }
    }
  }

  ngAfterViewInit(){
    console.log("ngAfterViewInit : CalculateDutyComponent");
    this.serv.receivedFilter.subscribe(() => {
      console.log("subscribe : CalculateDutyComponent");
    this.setPropDisplayFlag();
    this.otherCalFlag='';
    this.propDivTitle = '';
      this.resetDutyFields();
      //this.dutyform.resetForm();
      //this.getExemptionMappingByInstId(this.instrumentBean.id);
      console.log("Duty ngAfterViewInit this.instrumentBean:-",this.instrumentBean);
      this.getAllMineralList();
    });



    this.serv.propertyValuationResponseBean.subscribe(res=>{
      console.log("CalculateDutyComponent-ngAfterViewInit-subscribe-calculatedValue:",res.calculatedValue);
      //this.propMarketValue =  res.calculatedValue;
      this.propValAttributeColEn=[];
      this.propValAttributeColHi=[];
      this.propValAttribute.fieldId = [];
      this.propValAttribute.fieldvalue = [];
      this.instMappingBeanList.forEach(item=>{
        if(item.propValField){

          if(this.inputFields[item.id]!=undefined && this.inputFields[item.id]!= null && (this.inputFields[item.id] != '') ){
            this.propValAttributeColEn.push(item.fieldNameEn);
            this.propValAttributeColHi.push(item.fieldNameHi);
            this.propValAttribute.fieldId.push(item.id);
            this.propValAttribute.fieldvalue.push(this.inputFields[item.id]);
          }
          //this.inputFields[item.id] = '';
        }
      });
      this.propValAttribute.sno = this.propValArray.length+1;
      this.propValAttribute.valTxnId =  res.valTxnId;
      this.propValAttribute.propertyIds =  res.propertyId; //[1,2,3]
      this.propValAttribute.propertyType =  res.propertyType;
      this.propValAttribute.propMarketValue =  res.calculatedValue;
      this.propValAttribute.builderMarketValue =  res.builderMarketValue;
      this.propValAttribute.totalArea =res.totalArea;
      this.propValAttribute.transactingArea = res.transactArea==null?res.totalArea:res.transactArea;
      this.propValAttribute.unit = res.unit
      this.propValAttribute.mstDemographyBean = res.mstDemographyBean;
      //this.addMoreProperty();
      console.log("propValAttribute after valuation: ",this.propValAttribute);

          if(this.instrumentBean.deedTypeId.id==64){

            let partitionDeed:  PartitionDeed= new PartitionDeed(); 
            partitionDeed.propValAttribute = Object.create(this.propValAttribute);

            if(this.propValArrayPartitionDeed?.length>0){
              let partitionDeedTemp =  this.propValArrayPartitionDeed?.[0];
                  let partyListExist =  partitionDeedTemp.partyList;
                  this.partyListToCopy = JSON.parse(JSON.stringify(partyListExist)) ;

                  this.partyListToCopy.forEach(e=>{
                    e.isPartySaved=false;
                  e.partyShare.shareAreaSold= null;
                  } );

                  partitionDeed.partyList = JSON.parse(JSON.stringify(this.partyListToCopy));
            }else{
                    let party1 = new Party();
                    let party2 = new Party();

                    party1.name = "Party 1";                  
                    party2.name = "Party 2";
                    partitionDeed.partyList.push(party1);
                    partitionDeed.partyList.push(party2);
            }
            //partitionDeed.partyList =new Array();
              this.propValArrayPartitionDeed.push(partitionDeed); //this.propValAttribute
          }
      this.displayPropDiv=false;
      this.addMorePropEnable=false;
      //console.log(this.dialogRef.close)//.closeMe();
      this.dialogRef.close();
      //this.dialogRef.afterClosed().pipe(take(1)).subscribe(result=>{
      //console.log(result)
      //});
     //this.dialogDuty.closeAll();
    });

    this.serv.selectedMineralListEmit.subscribe( list=> {
      console.log("CalculateDutyComponent-ngAfterViewInit-subscribe-selectMineralList- :",list);
      this.selectedMineralList  = list;
      this.inputFields[this.pMappingSel] = this.radioValSel;
     });

     
    this.serv.pvPopUpClose.subscribe( isPopUpClose=> {
      console.log("CalculateDutyComponent-ngAfterViewInit-subscribe-pvPopUpClose- :",isPopUpClose);
      console.log("isPopUpClose:",isPopUpClose);
      this.propValSelect= true;
      //this.propValFlow = false;
      this.propMarketValue = null;
      this.propValId=null;
     });


     this.serv.selectedConsenterListEmit.subscribe( list=> {
      console.log("CalculateDutyComponent-ngAfterViewInit-subscribe-selectedConsenterListEmit- :",list);
      this.selectedConsenterList  = list;
      console.log("selectedConsenterListEmit: propertySel - ",this.propertySel);

      this.consenterDetailMap.set(this.propertySel, this.selectedConsenterList);

       //[...new Map(this.selectedConsenterList.map((item: IgrsConsenterDetlsDto) => [this.propertySel, item])).values()];
     });


     this.serv.dutyOtherInputFieldsEmit.subscribe( list=> {
      console.log("CalculateDutyComponent-ngAfterViewInit-subscribe-dutyOtherInputFieldsEmit- :",list);
      this.dutyOtherInputFields = list;
      let selFieldId:number  = this.dutyOtherInputFields[0].parentId;
      let selPopUpId:number  = this.dutyOtherInputFields[0].popUpId;

      console.log("selFieldId:",selFieldId);
      console.log("selPopUpId:",selPopUpId);
      if(selPopUpId==DutyConstants.popup_premium){
        console.log("this.dutyOtherInputFields[0].calculatedPremium:",this.dutyOtherInputFields[0].calculatedPremium);
        this.inputFields[selFieldId] = this.dutyOtherInputFields[0].calculatedPremium;
        this.dutyOtherInputFields1 = this.dutyOtherInputFields;
      }else if(selPopUpId==DutyConstants.popup_royalty){
        this.inputFields[selFieldId] = this.dutyOtherInputFields[0].calculatedRoyalty;
        this.dutyOtherInputFields2 = this.dutyOtherInputFields;
      }else if(selPopUpId==DutyConstants.popup_rent){
        this.inputFields[selFieldId] = this.dutyOtherInputFields[0].calculatedRent;
        this.dutyOtherInputFields4 = this.dutyOtherInputFields;
      }
      });
  }

  setPropDisplayFlag(){
    this.propDivTitle = '';
    this.otherCalFlag='';
    //let flag:InstMappingBean[] = this.instMappingBeanList.map(item => item).filter((item) => { if(item.otherCalFlag!=null){item.otherCalFlag.toUpperCase() === 'FTP' || item.otherCalFlag.toUpperCase() === 'STP' }});
    this.propDivflagList= this.instMappingBeanList.map(item => item).filter((item) => (item.otherCalFlag === DutyOtherFlagEnum.FTP || item.otherCalFlag === DutyOtherFlagEnum.STP) );
    console.log("setPropDisplayFlag-othercalflag:",this.propDivflagList);
    if(this.propDivflagList!=undefined && this.propDivflagList.length>0){
      if(this.propDivflagList[0].otherCalFlag!=''){
       // if(flag!=''){
        this.displayPropDiv=false;
      }
    }else{
      this.displayPropDiv=true;
    }
    console.log("setPropDisplayFlag-displayPropDiv:",this.displayPropDiv);
  }

  getAllMineralList(){
    this.dcService.getAllMinerals().subscribe(dcdata => {
      console.log("getAllMineralList -subscribe..... ");
      this.allMineralList = dcdata.responseData;
      if(this.allMineralList === undefined){
               console.log(" getAllMineralList  undefined");
      }else{
            console.log("getAllMineralList -: ",this.allMineralList);
      }
    });
  }


  addMovPropValue() {
        if(this.movPropValAttribute == null || this.movPropValAttribute == undefined){
         let errMsg: any = {};
          errMsg.responseMessage = DutyConstants.mvPropErr1_En;
          errMsg.responseMessageEn= DutyConstants.mvPropErr1_En;
          errMsg.responseMessageHi= DutyConstants.mvPropErr1_Hi;
          this.alertmessage.errorAlertMessage(errMsg);
           return;
        }else if(this.movPropValAttribute.movPropDetls==null){
          let errMsg: any = {};
          errMsg.responseMessage = DutyConstants.mvPropErr2_En;
          errMsg.responseMessageEn= DutyConstants.mvPropErr2_En;
          errMsg.responseMessageHi= DutyConstants.mvPropErr2_Hi;
          this.alertmessage.errorAlertMessage(errMsg);
          return;
        }else if(this.movPropValAttribute.movPropConsdAmt==null){
          let errMsg: any = {};
          errMsg.responseMessage = DutyConstants.mvPropErr3_En;
          errMsg.responseMessageEn= DutyConstants.mvPropErr3_En;
          errMsg.responseMessageHi= DutyConstants.mvPropErr3_Hi;
          this.alertmessage.errorAlertMessage(errMsg);
          return;
         }
       // else if(this.movPropValAttribute.fileUpload==null){
        //   let errMsg: any = {};
        //   errMsg.responseMessage = DutyConstants.mvPropErr4_En;
        //   errMsg.responseMessageEn= DutyConstants.mvPropErr4_En;
        //   errMsg.responseMessageHi= DutyConstants.mvPropErr4_Hi;
        //   this.alertmessage.errorAlertMessage(errMsg);
        // }
        else{
              this.movPropValfieldArray.push(this.movPropValAttribute)
              this.movPropValAttribute = {};
        }
    }

  deleteMovPropValue(index: any) {
    this.movPropValfieldArray.splice(index, 1);
  }

  handleFileInput(event:any) {

    this.mvFileToUpload = event.target.files[0];

    if (this.mvFileToUpload.type == 'image/jpeg' || this.mvFileToUpload.type == 'application/pdf') {
        this.uploadFileToActivity();
    } else {
              alert("file type should be image of pdf")
              this.mvFileToUpload = null;
            return;
    }
  }




  uploadFileToActivity() {
  this.dcService.postMovPropFile(this.mvFileToUpload).subscribe(dcdata => {
    if (dcdata.responseStatus == "true") {
      console.log("File uploaded successfully.......");
      this.movPropValAttribute.fileUpload=true;
    }

    }, error => {
      this.movPropValAttribute.fileUpload=false;
      console.log(error);
    });
  }

  userTypeCheckBoxEvent(event: any,mapping: InstMappingBean) {
    console.log("userTypeCheckBoxEvent- ",event);
    let checkBoxVal = mapping.fieldNameEn;
    let  mappingId = mapping.id;
    console.log(checkBoxVal);
    console.log(mappingId);
    console.log(event.target.checked);
    this.pMappingCheckSel = mappingId;

    let pMapping  = mapping.parentMappingList;
    if(!event.target.checked){
      this.pMappingCheckSel = null;
      pMapping.forEach( p=> {
        console.log("id mapped:-", p.id);
        this.inputFields[p.id] = '';});
    }
  }

   
  userTypeEvent(event: any,pMapping:any) {
    
    console.log("userTypeEvent- ",event);
    this.instMappingBeanList.filter(f=>f.parentMappingList.filter(pf=>{
      if(pf.fieldNameEn===this.fieldNameEn){
        pMapping=pf;
      }
    }))
    this.isConditionalQues = !this.isConditionalQues;
    let radioVal = pMapping.fieldNameEn;
    let  pMappingId = pMapping.id;
    if(this.pMappingDropBean!=undefined){
      if(this.pMappingDropBean.parentId!=undefined){
        this.inputFields[this.pMappingDropBean.parentId] = this.pMappingDropBean?.fieldNameEn;
        this.dutyInputFields= new DutyInputFields();

        //this.dutyform.controls[this.pMappingDropBean.parentId].(this.pMappingDropBean?.fieldNameEn);
      }
    }

    console.log(radioVal);
    console.log(pMappingId);
    console.log(event.target);
    this.pMappingSel = pMappingId;
    this.radioValSel = radioVal.toUpperCase();
    if ( radioVal.toUpperCase() === DutyConstants.major_min ) {
      //console.log("radio val selected-",radioVal.toUpperCase());
      this.popUpValueSel = radioVal.toUpperCase();
      this.filterMineralList =  this.allMineralList.map(item => item).filter((item) => item.mineralType.toUpperCase() === DutyConstants.major_min);
      //console.log("filterMineralList selected-",this.filterMineralList);
      this.showPopUp(pMapping.isPopup,pMapping);
    }
    else if ( radioVal.toUpperCase() === DutyConstants.minor_min ) {
      //console.log("radio val selected-",radioVal.toUpperCase());
      this.popUpValueSel = radioVal.toUpperCase();
      //console.log("popUpValueSel- ",this.popUpValueSel);
      //console.log("allMineralList-", this.allMineralList);
      this.filterMineralList =  this.allMineralList.map(item => item).filter((item) => item.mineralType.toUpperCase() === DutyConstants.minor_min);
      //console.log("filterMineralList selected-",this.filterMineralList);
      this.showPopUp(pMapping.isPopup,pMapping);
    }
  }

  onPropValSelect(event: any){
    let valid:boolean = true;
    this.instMappingBeanList.forEach(item=>{
      if(item.propValField ){
        if(item.propValField && (item.parentId==undefined ||item.parentId==null) && item.fieldType!='CHECKBOX' ){
          //this.propValAttribute.fieldvalue = this.inputFields[item.id];
          console.log("item value:",this.inputFields[item.id]);
          console.log("item id:",item.id," ::item value:",this.inputFields[item.id]);
          console.log("parent item:",item.parentId," :: parent item value:",this.inputFields[item.parentId]);
          if(this.inputFields[item.id] == null ||this.inputFields[item.id] == undefined || (this.inputFields[item.id] == '' && item.fieldType=='TEXT')){
            valid=false;
            let errMsg: any = {};
            errMsg.responseMessage = DutyConstants.property_requiredField_En;
            errMsg.responseMessageEn= DutyConstants.property_requiredField_En;
            errMsg.responseMessageHi= DutyConstants.property_requiredField_Hi;
            this.alertmessage.errorAlertMessage(errMsg);
            this.propValAttribute.propValSelect=null;
            this.propValSelect= null;
            //this.propValFlow = false;
            this.propMarketValue = null;
           // return ;
          }
        }else if(item.propValField && item.parentId!=null && item.otherCalFlag==null){
          console.log("item id:",item.id," ::item value:",this.inputFields[item.id]);
          console.log("parent item:",item.parentId," :: parent item value:",this.inputFields[item.parentId]);
          if(this.inputFields[item.parentId]!=undefined && this.inputFields[item.parentId]!= null && (this.inputFields[item.parentId] != '')  ){
           
            if(this.inputFields[item.id] == undefined || this.inputFields[item.id] == null || (this.inputFields[item.id] == '')){

              valid=false;
              let errMsg: any = {};
              errMsg.responseMessage = DutyConstants.property_requiredField_En;
              errMsg.responseMessageEn= DutyConstants.property_requiredField_En;
              errMsg.responseMessageHi= DutyConstants.property_requiredField_Hi;
              this.alertmessage.errorAlertMessage(errMsg);
              
              this.propValAttribute.propValSelect=null;
            this.propValSelect= null;
            //this.propValFlow = false;
            this.propMarketValue = null;
            }
          }
        }
      }
    });

    if(valid){
                        if (event.target.value == 'true') {
                        console.log("onPropValSelect true- ",this.propValSelect);
                        this.propValSelect= true;
                          this.propValFlow = false;
                          this.propMarketValue = null;
                        }else{
                              console.log("onPropValSelect-false ",this.propValSelect);
                              this.propValSelect= false;
                              this.propValFlow = true;
                              this.openDialog('3000ms', '1500ms');
                        }
    }
  }

  checkFtpStpValIdExist(valId:any):boolean{
    let valIdExist = null;
    valIdExist =  this.propValListFTP?.find(f=>f.propValId!=null && Number(f.propValId) == Number(valId));
    valIdExist =  this.propValListFTP?.find(f=>f.propValId!=null && Number(f.propValId) == Number(valId));
    if(valIdExist!=null){
          return true;
    }else{
      return false;
    }
  }
  addMoreProperty() {
    console.log("addMoreProperty-",this.propValAttribute.propMarketValue) ;
    if(this.propValAttribute.propMarketValue!='' && this.propValAttribute.propMarketValue>0){
      if(this.otherCalFlag===DutyOtherFlagEnum.FTP){
        if(!this.checkFtpStpValIdExist(this.propValAttribute.valTxnId)){
        this.propValArrayFTP.push(this.propValAttribute.propMarketValue);
        this.dutyPropInputFields= new DutyPropInputFields();

        this.dutyPropInputFields.propValId = this.propValAttribute.valTxnId
        this.dutyPropInputFields.propertyType = this.propValAttribute.propertyType;
        this.dutyPropInputFields.propId = this.propValAttribute.valTxnId // change it to PROP ID once prop id generted
        this.dutyPropInputFields.marketValue = this.propValAttribute.propMarketValue;
        this.dutyPropInputFields.mstDemographyBean = this.propValAttribute.mstDemographyBean;
        this.dutyPropInputFields.totalArea = this.propValAttribute.totalArea;
        this.dutyPropInputFields.transactingArea = this.propValAttribute.transactArea;
        this.dutyPropInputFields.unit =this.propValAttribute.unit 
        this.dutyPropInputFields.flag = DutyOtherFlagEnum.FTP;
        this.propValListFTP.push(this.dutyPropInputFields);
        }else{
                    let errMsg: any = {};
                    errMsg.responseMessage = DutyConstants.propValId_exist_En;
                    errMsg.responseMessageEn= DutyConstants.propValId_exist_En;
                    errMsg.responseMessageHi= DutyConstants.propValId_exist_Hi;
                    this.alertmessage.errorAlertMessage(errMsg);
                    return ;
        }
       // this.addMorePropEnable=true;
        console.log("addMoreProperty-",this.propValArrayFTP)
      }else if(this.otherCalFlag===DutyOtherFlagEnum.STP){
        if(!this.checkFtpStpValIdExist(this.propValAttribute.valTxnId)){
        this.propValArraySTP.push(this.propValAttribute.propMarketValue);
        this.dutyPropInputFields= new DutyPropInputFields();
        this.dutyPropInputFields.propValId = this.propValAttribute.valTxnId
        this.dutyPropInputFields.propertyType =this.propValAttribute.propertyType
        this.dutyPropInputFields.propId = this.propValAttribute.valTxnId // change it to PROP ID once prop id generted
        this.dutyPropInputFields.marketValue = this.propValAttribute.propMarketValue;
        this.dutyPropInputFields.mstDemographyBean = this.propValAttribute.mstDemographyBean;
        this.dutyPropInputFields.totalArea = this.propValAttribute.totalArea;
        this.dutyPropInputFields.transactingArea = this.propValAttribute.transactArea;
        this.dutyPropInputFields.unit =this.propValAttribute.unit
        this.dutyPropInputFields.flag = DutyOtherFlagEnum.STP;
        this.propValListSTP.push(this.dutyPropInputFields);
        }else{
                    let errMsg: any = {};
                    errMsg.responseMessage = DutyConstants.propValId_exist_En;
                    errMsg.responseMessageEn= DutyConstants.propValId_exist_En;
                    errMsg.responseMessageHi= DutyConstants.propValId_exist_Hi;
                    this.alertmessage.errorAlertMessage(errMsg);
                    return ;
        }
       // this.addMorePropEnable=true;
        console.log("addMoreProperty-",this.propValArraySTP)
      }else{
        this.addMorePropEnable=true;
        this.displayPropDiv=true;
        this.propValArray.push(this.propValAttribute);

        if(null!=this.propValArrayPartitionDeed && this.propValArrayPartitionDeed.length>0){        
         let propExist = this.propValArrayPartitionDeed.find(f=> f.propValAttribute.valTxnId==this.propValAttribute.valTxnId);
          if(!propExist){
            let partitionDeed:  PartitionDeed = new PartitionDeed();
            partitionDeed.propValAttribute = Object.create(this.propValAttribute);
            //partitionDeed.partyList =new Array();
             this.propValArrayPartitionDeed.push(partitionDeed);
            
            //this.propValArrayPartitionDeed.push(this.propValAttribute);
          }
        }
        


        this.instMappingBeanList.forEach(item=>{

          if(item.propValField || item?.instrumentId?.id==105 || item?.instrumentId?.id==106 || item?.instrumentId?.id==107|| item?.instrumentId?.id==109){
            console.log("item.id:-",item.id);
            console.log(" this.inputFields[item.id]:-", this.inputFields[item.id]);
           // this.propValFieldArray.push(item.id,this.inputFields[item.id]);
           this.dutyPropInputFields= new DutyPropInputFields();
            this.dutyPropInputFields.id = item.id;
            this.dutyPropInputFields.propValId = this.propValAttribute.valTxnId
            this.dutyPropInputFields.propertyType = this.propValAttribute.propertyType
            this.dutyPropInputFields.propId = this.propValAttribute.valTxnId // change it to PROP ID once prop id generted
            this.dutyPropInputFields.seqNo = this.propSeq;
            this.dutyPropInputFields.value = this.inputFields[item.id];
            this.dutyPropInputFields.marketValue = this.propValAttribute.propMarketValue;
            this.dutyPropInputFields.builderMarketValue = this.propValAttribute.builderMarketValue;
            this.dutyPropInputFields.mstDemographyBean = this.propValAttribute.mstDemographyBean;
            this.dutyPropInputFields.totalArea = this.propValAttribute.totalArea;
            this.dutyPropInputFields.transactingArea = this.propValAttribute.transactArea;
            this.dutyPropInputFields.unit =this.propValAttribute.unit

            this.instMappingBeanList.map(item => item).filter((item) =>{ if(item.fieldNameEn.includes('Consideration Amount') && item.id==this.dutyPropInputFields.id){
              let consAmt:number = Number(this.dutyPropInputFields.value)
              this.totalConsiderationAmountProp = this.totalConsiderationAmountProp+consAmt;
            }
            // else{
            //         this.dutyPropInputFields= new DutyPropInputFields();
            //         this.dutyPropInputFields.id = item.id;
            //         this.dutyPropInputFields.propValId = this.propValAttribute.valTxnId
            //         this.dutyPropInputFields.propertyType = this.propValAttribute.propertyType
            //         this.dutyPropInputFields.marketValue = this.propValAttribute.propMarketValue;
            // }
          }
          
          );


            this.dutyPropInputFieldsArray.push(this.dutyPropInputFields);

            if(item?.instrumentId?.id!=105 && item?.instrumentId?.id!=106 && item?.instrumentId?.id!=107 && item?.instrumentId?.id!=109){
              this.inputFields[item.id] = '';
            }
            
            console.log("item.id:-",item.id);
            console.log(" this.inputFields[item.id]:-", this.inputFields[item.id]);
            console.log("this.dutyPropInputFields.id:-",this.dutyPropInputFields.id);
            console.log("this.dutyPropInputFields.value:-", this.dutyPropInputFields.value);

          }
        });

                    
        if(this.instMappingBeanList==null || this.instMappingBeanList==undefined || this.instMappingBeanList?.length==0 ||  this.instrumentBean.id==8){
          this.dutyPropInputFields= new DutyPropInputFields();
          this.dutyPropInputFields.propValId = this.propValAttribute.valTxnId
          this.dutyPropInputFields.propertyType = this.propValAttribute.propertyType
          this.dutyPropInputFields.marketValue = this.propValAttribute.propMarketValue;
          this.dutyPropInputFields.mstDemographyBean = this.propValAttribute.mstDemographyBean;
          this.dutyPropInputFields.builderMarketValue = this.propValAttribute.builderMarketValue;
          this.dutyPropInputFieldsArray.push(this.dutyPropInputFields);
        }
 
        this.DutyPropInputFieldsDto.dutyPropInputFieldsArray =  this.dutyPropInputFieldsArray;
        this.propValFieldArray.push(this.DutyPropInputFieldsDto);
        this.dutyPropInputFieldsArray  = new Array();
        this.DutyPropInputFieldsDto= new DutyPropInputFieldsDto();

        console.log("addMoreProperty propValArray-",this.propValArray)
        console.log("addMoreProperty propValFieldArray-",this.propValFieldArray)

        this.dutyOtherInputFieldsAll = [...this.dutyOtherInputFields1,...this.dutyOtherInputFields2,...this.dutyOtherInputFields4];
        this.dutyInputOtherFieldListDto.dutyInputOtherFieldList  = this.dutyOtherInputFieldsAll;
        this.dutyInputOtherFieldListDto.propId =  this.propSeq;
        this.dutyInputOtherFieldListArray.push(this.dutyInputOtherFieldListDto);
        this.dutyOtherInputFields1=new Array();
        this.dutyOtherInputFields2=new Array();
        this.dutyOtherInputFields4=new Array();
        this.dutyOtherInputFieldsAll =new Array();
        this.dutyInputOtherFieldListDto = new DutyInputOtherFieldListDto();
        this.propSeq++;
      }
    }

    //this.propValArray.push(this.propValAttribute.propMarketValue);
    this.propValAttribute = {};
    this.otherCalFlag=='';

  }

  deleteProperty(index:any, flag:string) {
    console.log("deleteProperty-index:",index);
    this.displayPropDiv=true;
    this.addMorePropEnable=true;
    //this.propValArray.splice(index, 1);
    if(flag===DutyOtherFlagEnum.FTP){
      this.propValArrayFTP.splice(index, 1);
     this.propValListFTP.splice(index, 1);
    }else if(flag===DutyOtherFlagEnum.STP){
      this.propValArraySTP.splice(index, 1);
      this.propValListSTP.splice(index, 1);
    }else{
      this.propValArray.splice(index, 1);
      this.propValArrayPartitionDeed.splice(index, 1);
      this.propValFieldArray.splice(index, 1);
      if(this.dutyInputOtherFieldListArray!=undefined){
        this.dutyInputOtherFieldListArray.splice(index, 1);
      }
      this.propSeq--;
    }

  }

  deleteLastOrOnlyProperty(){
    this.propValAttribute = {};
    this.otherCalFlag=='';
    this.displayPropDiv=true;
    this.addMorePropEnable=true;
  }
  addProperty(bean :InstMappingBean){
    console.log("addProperty-bean:",bean)
    this.otherCalFlag = bean.otherCalFlag;
    console.log("addProperty-otherCalFlag:",this.otherCalFlag)

    this.displayPropDiv=true;
    this.addMorePropEnable=true;
    this.propDivTitle = this.lan==0?bean.mappingDescEn:bean.mappingDescHi;
  }

  addMoreField(field :InstMappingBean){
    console.log("addField-",field)
    console.log("addField value-",this.inputFields[field.id])
    if(this.inputFields[field.id]!='' && this.inputFields[field.id]>0){
      this.fieldArray.push(this.inputFields[field.id]);
    }
     this.inputFields[field.id]='';
     console.log("addField: fieldArray-",this.fieldArray)
  }

  deleteField(index:number){
    console.log("deleteField index-",index)
    this.fieldArray.splice(index, 1);

  }

  onDropdownSelected(event?: any){
    console.log("onDropdownSelected");
    
    this.pMappingDropSel = this.pMappingDropBean.id;
    console.log("onDropdownSelected:pMappingDropSel",this.pMappingDropSel);
    console.log("onDropdownSelected:parentid",this.pMappingDropBean.parentId);
    this.inputFields[this.pMappingDropBean.parentId] = this.pMappingDropBean.fieldNameEn;
    this.isDropdownSelect=true;
  }

  calculateDuty(dutyform: NgForm){
    console.log("-------------- calculateDuty ------------");
    console.log("-------------- calculateDuty ------------",this.dutyform);
    console.log("this.inputFields:-",this.inputFields);
    console.log("this.selectedExemptions:-",this.selectedExemptions);
    if(this.validateFields()){
      console.log(" After validate Fields");
      this.submitDisable = true;
          this.dutyCalculationResponseBean = new DutyCalculationResponse();
          this.dutyCalculationRequest.moduleId = 4; //chatbot master
          this.dutyCalculationRequest.dutyInputFieldList = new Array();
          this.dutyCalculationRequest.instrumentId = this.instrumentBean.id;
          this.dutyCalculationRequest.instrumentBean = this.instrumentBean;
          this.dutyCalculationRequest.isDutyMovableProp = this.ischeckMoveble;
          if(this.consenterDetailMap.size>0){
            this.dutyCalculationRequest.isConsenterAdded = true;
           // this.dutyCalculationRequest.consenterDetailMap =  this.consenterDetailMap;
           this.dutyCalculationRequest.consenterDetailMap= new Array();
           [...this.consenterDetailMap].map(( [key, value]) =>
            { let igrsConsenterMap:IgrsConsenterMap= new IgrsConsenterMap;
                igrsConsenterMap.propertyValId = key;
                igrsConsenterMap.igrsConsenterDetlsDto = value;
                this.dutyCalculationRequest.consenterDetailMap.push(igrsConsenterMap);
            });

            console.log( "consenterDetailMap - ",this.dutyCalculationRequest.consenterDetailMap);
          }


          this.dutyCalculationRequest.cancellationFlag = this.instrumentBean.instCancellationFlag;
          this.dutyCalculationRequest.isAddPropApplicable = this.instrumentBean.addPropertyApplicable==null?false:this.instrumentBean.addPropertyApplicable;
          this.dutyCalculationRequest.isExemptionSelected = this.availExemption;
          this.dutyCalculationRequest.selectedExemptionList = this.selectedExemptions;
          this.dutyCalculationRequest.isPropFlow =  this.instrumentBean.propValReqFlag;
          this.dutyCalculationRequest.propValId = this.propValAttribute.valTxnId;//replace this with valuation ID once complted
          this.dutyCalculationRequest.propertyType = this.propValAttribute.propertyType;
          this.dutyCalculationRequest.propMarketValue = this.propValAttribute.propMarketValue;
          this.dutyCalculationRequest.builderMarketValue = this.propValAttribute.builderMarketValue;
          this.dutyCalculationRequest.mstDemographyBean = this.propValAttribute.mstDemographyBean;

          //if(this.propValArray!=undefined && this.propValArray.length > 0){
            if(this.propValAttribute.propMarketValue!=undefined &&  this.propValAttribute.propMarketValue>0){
                this.propValArray.push(this.propValAttribute);
            }
          //}
          //this.propValArray.push(this.propValAttribute);
          console.log("propValArray- ", this.propValArray);
          this.dutyCalculationRequest.propValFieldArray = this.propValFieldArray;
          this.dutyCalculationRequest.movPropValfieldArray = this.movPropValfieldArray;
          if(this.propValArray!=undefined && this.propValArray.length > 0){
            this.dutyCalculationRequest.marketValueList = new Array();
              this.propValArray.forEach(p=> {
                                              this.dutyCalculationRequest.marketValueList.push( p.propMarketValue)});
          }
         // this.dutyCalculationRequest.marketValueList = this.propValArray;
          this.dutyCalculationRequest.marketValueListFTP = this.propValArrayFTP;
          this.dutyCalculationRequest.marketValueListSTP = this.propValArraySTP;
          this.dutyCalculationRequest.propValListFTPSTP =  [...this.propValListFTP,...this.propValListSTP]; 
          this.dutyCalculationRequest.fieldArray = this.fieldArray;
          this.propValAttribute={};
          this.dutyCalculationRequest.dutyInputOtherFieldList = [...this.dutyOtherInputFields1,...this.dutyOtherInputFields2,...this.dutyOtherInputFields4];
          this.dutyInputOtherFieldListDto.dutyInputOtherFieldList = this.dutyCalculationRequest.dutyInputOtherFieldList;
          this.dutyInputOtherFieldListArray.push(this.dutyInputOtherFieldListDto);
          this.dutyCalculationRequest.dutyInputOtherFieldListReqArray = this.dutyInputOtherFieldListArray;

          Object.keys(this.dutyform.controls).forEach((key) => {
      
            this.dutyInputFields= new DutyInputFields();
            let index=0;
            console.log(this.dutyform.controls)
            console.log("key:",key),console.log("value:",this.dutyform.controls[key].value)
            //this.dutyCalculationRequest.fieldValuesReqMap.set(Number(key), this.inputFields[key]);
            //this.dutyCalculationRequest.dutyInputFieldList[Number(index)]
            const char = 'F_';
            if(key.substring(0, 2) === char){
              this.dutyInputFields.id = Number(key.substring(2, key.length));
              this.dutyInputFields.value = this.dutyform.controls[key].value;
              this.dutyCalculationRequest.dutyInputFieldList.push(this.dutyInputFields);


              this.instMappingBeanList.map(item => item).filter((item) =>{ if(item.fieldNameEn.includes('Consideration Amount') && item.id==this.dutyInputFields.id){
                console.log(" consideration amount of ID:-", this.dutyInputFields.id);
                let consAmt:number = Number(this.dutyInputFields.value)
               this.totalConsiderationAmount = consAmt;
              }});
              
              index++;
            }
          });

          this.totalConsiderationAmount = this.totalConsiderationAmount +this.totalConsiderationAmountProp;
          console.log(" total consideration amount :-", this.totalConsiderationAmount);
          this.dutyCalculationRequest.totalConsiderationAmt = this.totalConsiderationAmount;


             //for partition deed calculation logic
      if(this.instrumentBean.deedTypeId.id==64){
        let partyListSumOfMarketValue:SumOfMarketValParty[] =  new Array();

        this.propValArrayPartitionDeed.forEach(element => {
          let transactArea = element.propValAttribute.transactingArea;
          let marketValue = element.propValAttribute.propMarketValue;
         // let sumOfMarketVal :number=0;
         let valId =    element.propValAttribute.valTxnId;
          element.partyList.forEach((party:Party)=>{
            
             let partyName =    party.name;
             party.sumOfMarketValue = marketValue*(party.partyShare.shareAreaSold/transactArea);

             let sumOfMarketValPartyObj = new SumOfMarketValParty();
             sumOfMarketValPartyObj.marketValue =  party.sumOfMarketValue
             sumOfMarketValPartyObj.partyName = partyName
             sumOfMarketValPartyObj.valId =valId
             let partyExist  = partyListSumOfMarketValue?.find(f=> f.partyName!=null && partyName==f.partyName);
             if(partyExist==null || partyExist==undefined){
              partyListSumOfMarketValue.push(sumOfMarketValPartyObj);
             }else{
              partyListSumOfMarketValue?.find(f=> {if(f.partyName!=null && partyName==f.partyName){
                if(party.sumOfMarketValue!=null){
                  f.marketValue = Number(f.marketValue +   party.sumOfMarketValue)  ;
                }              
              }});
             }
         
          });
        });

        
          console.log('partyListSumOfMarketValue:-',partyListSumOfMarketValue);

          const partyListGrpByName = groupBy(partyListSumOfMarketValue, i => i.partyName);

          console.log('results:-',partyListGrpByName);

          let max:number=0;
          let sumOfMarketValAllParties:number=0;
          let dutyRegFeeGuildelineVal:number=0;

          partyListSumOfMarketValue?.forEach(f => {
        console.log("f.sumOfMarketVal:-",f.marketValue);
            if(f.marketValue!=null && f.marketValue!=undefined){
              sumOfMarketValAllParties = Number(sumOfMarketValAllParties) + Number(f.marketValue);
            }
            
            if(f.marketValue>max){
              max=f.marketValue;
            }
        
        });

        console.log("sumOfMarketValAllParties:-",sumOfMarketValAllParties);
        console.log("max:-",max);
      dutyRegFeeGuildelineVal = sumOfMarketValAllParties - max;

      console.log("dutyRegFeeGuildelineVal:-",dutyRegFeeGuildelineVal);
      this.dutyCalculationRequest.dutyRegFeeGuildelineVal =  dutyRegFeeGuildelineVal;
      }

          // Object.keys(this.inputFields).forEach((key) => {
          //   this.dutyInputFields= new DutyInputFields();
          //   let index=0;
          //   console.log("key:",key),console.log("value:",this.inputFields[key])
          //   //this.dutyCalculationRequest.fieldValuesReqMap.set(Number(key), this.inputFields[key]);
          //   //this.dutyCalculationRequest.dutyInputFieldList[Number(index)]
          //   this.dutyInputFields.id = Number(key);
          //   this.dutyInputFields.value = this.inputFields[key];
          //   this.dutyCalculationRequest.dutyInputFieldList.push(this.dutyInputFields);
          //   index++;
          // });
        //var result = new Map(this.inputFields.map(i => [i.key, i.val]));
        //console.log(this.dutyCalculationRequest.fieldValuesReqMap);

        this.ngxService.start();
          this.dcService.calculateDuty(this.dutyCalculationRequest).subscribe(dcdata => {
            this.ngxService.stop();
            console.log("calculateDuty -subscribe..... ");
            console.log(dcdata.responseData);
          //  this.instMappingBeanList = dcdata.responseData;
          // if(this.instMappingBeanList === undefined){
            //         console.log(" instMappingBeanList  undefined");
          // }else{
            //      console.log(this.instMappingBeanList);
            //}
            if (dcdata.responseStatus == "true") {
              this.dutyCalculationResponseBean = dcdata.responseData;
              this.dutyCalculationResponseBeanEvent.emit(this.dutyCalculationResponseBean);
              this.displayCalDutyFieldsFlag = true;
              this.displayPieChart();

            // this.openDialog(this.dutyCalculationResponseBean);
            // this.allFormReset();
            } else {
              console.log( dcdata)
              this.alertmessage.errorAlertMessage(dcdata);
            }
          });
    }
    //dutyform.resetForm();
  }

  getExemptionMappingByInstId(instrumentId:number){
    console.log("---- getExemptionMappingByInstId ---------");
    console.log(instrumentId);
    this.instExempMappingList = null
    this.dcService.getExemptionMappingByInstId(instrumentId).subscribe(dcdata => {
      console.log("getExemptionMappingByInstId -subscribe..... ");

      if(dcdata.responseStatus === "true"){
        this.instExempMappingList = dcdata.responseData;

      }else{
        this.instExempMappingList=null
        console.log("instExempMappingList: ",this.instExempMappingList);
      }
    });

  }

  onAvailExemption(event: any){
    if (event.target.value == 'true') {
      this.showExemptions = true;
        if(this.instExempMappingList.length==0){
          this.exemptionsNotAvailable = true;
          this.availExemption = true;
        }
    }else{
          this.availExemption = false;
          this.showExemptions = false;
          this.selectedExemptions = new Array();
    }
  }

  addExemptionEvent(event: any, slrow: any) {
    if (event.target.checked == true) {
      this.selectedExemptions.push(slrow);
    } else {
      this.selectedExemptions.forEach((value, index) => {
        if (value.id == slrow.id) {
          this.selectedExemptions.splice(index, 1);
        } else {
          this.selectedExemptions.push(slrow);
        }
      });
    }
  }

  showPopUp(popUpValue: any, field: any){
    console.log("showPopUp field id: ",field.id);
    console.log(" showPopUp popUpValue: ",popUpValue);
    if(popUpValue==DutyConstants.popup_premium || popUpValue==DutyConstants.popup_royalty || popUpValue==DutyConstants.popup_rent){
       this.filterInstMappingList = this.instMappingBeanList.map(item => item).filter((item) => (item.id==field.id && item.isPopup == popUpValue));

       console.log("showPopUp -filterInstMappingList:- ", this.filterInstMappingList);

       this.openCalOtherDialog('3000ms', '1500ms');

    }
    else if(popUpValue==3 ){
            this.openMineralDialog('3000ms', '1500ms');
    }
  }

  openDutyCalDialog() {

        console.log("openDutyCalDialog");
        console.log("openDutyCalDialog:instCancellationFlag-",this.instrumentBean.instCancellationFlag);
        if(!this.cancellationFlag ){
          this.cancellationFlag=true;
          let obj = { cancellationFlag: this.cancellationFlag, cancellationInstBean:this.instrumentBean };
       // this.instrumentBeanDuplicate = new DeedInstrumentBean();
     let dialogRef=  this.dialogDuty.open(DutyCalculationComponent,{ data: obj,
          maxWidth: '90vw',
          maxHeight: '90vh',
          height: '100%',
          width: '100%',

        });
        dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe((result=>{
          console.log('openDutyCalDialog was closed',result)
     }))
        this.router.navigateByUrl('../duty-calculation', { skipLocationChange: true }).then(() => {
          this.router.navigate(['../duty-calculation']);
        });
       }
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
    let builderShare=null;
    if(this.inputFields[6]!=undefined && this.inputFields[6]!=null){
      if('Yes'.toLowerCase() ===this.inputFields[6].toLowerCase()){
        builderShare = this.inputFields[9];
        console.log("openDialog:openDialog-",builderShare);
      }
    }

    let obj = { builderShare: builderShare };
    this.dialogRef=this.dialogDuty.open(CalculateGuidelineValueComponent, {data: obj,   maxWidth: '80vw',
    maxHeight: '80vh',
    height: '100%',
    width: '100%',
    panelClass: 'full-screen-modal'});
  }

  openMineralDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
    let obj = { filterMineralList: this.filterMineralList, popUpValueSel: this.popUpValueSel};
    this.dialogDuty.open(MineralSelectionComponent,  {
      data: obj,
    });
  }
  openCalOtherDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
    let obj = { filterInstMappingList: this.filterInstMappingList};
    this.dialogDuty.open(CalculateOtherComponent, { data: obj, maxWidth:'50vw', width:'50vw'});
  }

  validateFields():boolean{
    console.log("validateFields method");

    let isRadioFields:boolean=false;
    let isAnyRadioHasValue:boolean=false;

    if(this.instMappingBeanList!==null && this.instMappingBeanList!==undefined){
      this.instMappingBeanList.forEach(e=>{
               if( e.fieldType!=null &&  e.fieldType.toUpperCase()==='LABEL' && e.status) {
                      e.parentMappingList.forEach(p=>{
                        if( p.fieldType!=null &&  p.fieldType.toUpperCase()==='RADIO' && p.status) {
                              isRadioFields=true;
                                if(this.inputFields[p.parentId]!=null){
                                  isAnyRadioHasValue =  true;
                                }
                        }
                      });
               }
      });
    }


    if(isRadioFields){
      if(!isAnyRadioHasValue){
        return false;
      }
    }


    if(this.propValAttribute.propMarketValue==null && this.instrumentBean.propValReqFlag && this.instrumentBean.id!=68
       && this.otherCalFlag!=DutyOtherFlagEnum.FTP && this.otherCalFlag!=DutyOtherFlagEnum.STP){
      let errMsg: any = {};
      if(this.propValSelect==true &&   this.propValFlow==false){
        errMsg.responseMessage = DutyConstants.propValId_required_En;
        errMsg.responseMessageEn= DutyConstants.propValId_required_En;
        errMsg.responseMessageHi= DutyConstants.propValId_required_Hi;
      }else{
            errMsg.responseMessage = DutyConstants.property_required_En;
            errMsg.responseMessageEn= DutyConstants.property_required_En;
            errMsg.responseMessageHi= DutyConstants.property_required_Hi;
          }
  
      //this.alertmessage.errorAlertMessage(errMsg);
      return false;
    }else if(this.instrumentBean.propValReqFlag && this.propValAttribute.propMarketValue!=undefined &&  this.propValAttribute.propMarketValue>0 && (this.instrumentBean.id!=68 && this.instrumentBean.id!=105 && this.instrumentBean.id!=106 && this.instrumentBean.id!=107 && this.instrumentBean.id!=108 && this.instrumentBean.id!=109)){
      if(this.propValAttribute.propConstSelect){
        console.log("this.propValAttribute.propConstSelect:",this.propValAttribute.propConstSelect);
        let propCount1  = this.propValAttribute.propertyIds.length;
        console.log("propCount : ", propCount1);
        let valTxnId = this.propValAttribute.valTxnId;
        let propCount2;
        this.consenterDetailMap.forEach( function(value,key){
          console.log(`Map key is:${key} and value is:${value}`);
          if(valTxnId == key){
            console.log("value object size : ", value.length);
            propCount2 = value.length;
           // value.filter(f=> console.log("consenter selected property-",f.propertyId));
            //value.forEach(f=> console.log("consenter selected property-",f.propertyId));
          }
      });
         if(propCount1!=propCount2){
          let errMsg: any = {};
          errMsg.responseMessage = DutyConstants.constDetlsErr5_En;
          errMsg.responseMessageEn= DutyConstants.constDetlsErr5_En;
          errMsg.responseMessageHi= DutyConstants.constDetlsErr5_Hi;
          this.alertmessage.errorAlertMessage(errMsg);
           return false;
         }
      } if(this.propValArray.length>0){
        let returFlag = true;
        this.propValArray.forEach(c=>{
          if(c.propConstSelect){
            console.log("propConstSelect:",c.propConstSelect);
            let propCount1 = c.propertyIds.length;
            let valTxnId = c.valTxnId;
            console.log("From Array-propCount : ", propCount1);
            let propCount2;
            this.consenterDetailMap.forEach( function(value,key){
              console.log(`From Array - Map key is:${key} and value is:${value}`);
              if(valTxnId == key){
                console.log("value object size : ", value.length);
                propCount2 = value.length;
                // value.filter(f=> console.log("consenter selected property-",f.propertyId));
                 value.forEach(f=> console.log("consenter selected property-",f.propertyId));
              }
          });

          if(propCount1!=propCount2){
            returFlag = false;
            let errMsg: any = {};
            errMsg.responseMessage = DutyConstants.constDetlsErr5_En;
            errMsg.responseMessageEn= DutyConstants.constDetlsErr5_En;
            errMsg.responseMessageHi= DutyConstants.constDetlsErr5_Hi;
            this.alertmessage.errorAlertMessage(errMsg);
             return ;
           }
          }
        });

          return returFlag;
      }
    }

    if(this.propValListFTP.length==0 && this.propValListSTP.length==0 && this.instrumentBean.id==68){
      let errMsg: any = {};
      errMsg.responseMessage = DutyConstants.property_req_BOTH_En;
      errMsg.responseMessageEn= DutyConstants.property_req_BOTH_En;
      errMsg.responseMessageHi= DutyConstants.property_req_BOTH_Hi;
      this.alertmessage.errorAlertMessage(errMsg);
       return false;
    }
   else if(this.propValListFTP.length==0 && this.instrumentBean.id==68){
      let errMsg: any = {};
      errMsg.responseMessage = DutyConstants.property_req_FTP_En;
      errMsg.responseMessageEn= DutyConstants.property_req_FTP_En;
      errMsg.responseMessageHi= DutyConstants.property_req_FTP_Hi;
      this.alertmessage.errorAlertMessage(errMsg);
       return false;
    }else if(this.propValListSTP.length==0 && this.instrumentBean.id==68 ){
      let errMsg: any = {};
      errMsg.responseMessage = DutyConstants.property_req_STP_En;
      errMsg.responseMessageEn= DutyConstants.property_req_STP_En;
      errMsg.responseMessageHi= DutyConstants.property_req_STP_En;
      this.alertmessage.errorAlertMessage(errMsg);
       return false;
    }

    if(this.instrumentBean.id==8 && (this.inputFields[6]==null || this.inputFields[6]==undefined)){
      return false;
    }
    else if(this.instrumentBean.id==8 && this.inputFields[6]?.toUpperCase()==='YES'){
        if(this.inputFields[9]+this.inputFields[10]>100){
         // this.errMsgEn=DutyConstants.instID_8_En;
         // this.errMsgHi=DutyConstants.instID_8_Hi;
          let errMsg: any = {};
          errMsg.responseMessage = DutyConstants.instID_8_En;
          errMsg.responseMessageEn= DutyConstants.instID_8_En;
          errMsg.responseMessageHi= DutyConstants.instID_8_Hi;
          this.alertmessage.errorAlertMessage(errMsg);
           return false;
        }
    }
    else if(this.instrumentBean.id==105 ){
      console.log("this.propValArray.length:-",this?.propValArray?.length);
      if(this.inputFields[355]!=(this.propValArray.length+1)){
       // this.errMsgEn=DutyConstants.instID_9_En;
       // this.errMsgHi=DutyConstants.instID_9_Hi;
        let errMsg: any = {};
        errMsg.responseMessage = DutyConstants.instID_9_En;
        errMsg.responseMessageEn= DutyConstants.instID_9_En;
        errMsg.responseMessageHi= DutyConstants.instID_9_Hi;
        //this.alertmessage.errorAlertMessage(errMsg);
         //return false;
      }

     console.log('validationForPartitionDeed:-',this.validationForPartitionDeed());
     
      return this.returnWithErrorMsgValidationPartitionDeed();

    //  if(!this.validationForPartitionDeed()){
    //       let errMsg: any = {};
    //       errMsg.responseMessage = DutyConstants.partitonDeedAreaNotEqual_En;
    //       errMsg.responseMessageEn= DutyConstants.partitonDeedAreaNotEqual_En;
    //       errMsg.responseMessageHi= DutyConstants.partitonDeedAreaNotEqual_Hi;
    //       this.alertmessage.errorAlertMessage(errMsg);
    //       return false;
    //  }
    //  if(!this.validationForPartitionDeedSaveParty()){
    //   let errMsg: any = {};
    //   errMsg.responseMessage = DutyConstants.partitonDeedPartyNotSaved_En;
    //   errMsg.responseMessageEn= DutyConstants.partitonDeedPartyNotSaved_En;
    //   errMsg.responseMessageHi= DutyConstants.partitonDeedPartyNotSaved_Hi;
    //   this.alertmessage.errorAlertMessage(errMsg);
    //   return false;
    //  }
    }
     else  if(this.instrumentBean.id==106){
        if(this.inputFields[366]!=(this.propValArray.length+1)){
          let errMsg: any = {};
          errMsg.responseMessage = DutyConstants.instID_9_En;
          errMsg.responseMessageEn= DutyConstants.instID_9_En;
          errMsg.responseMessageHi= DutyConstants.instID_9_Hi;
          //this.alertmessage.errorAlertMessage(errMsg);
           //return false;
        }
        if(!this.validationForPartitionDeed()){
          let errMsg: any = {};
          errMsg.responseMessage = DutyConstants.partitonDeedAreaNotEqual_En;
          errMsg.responseMessageEn= DutyConstants.partitonDeedAreaNotEqual_En;
          errMsg.responseMessageHi= DutyConstants.partitonDeedAreaNotEqual_Hi;
          this.alertmessage.errorAlertMessage(errMsg);
          return false;
        }
      }
      else  if(this.instrumentBean.id==107){
        console.log("this.propValArray.length:-",this?.propValArray?.length);
        if(Number(this.inputFields[377])!=Number(this.propValArray.length+1)){
          let errMsg: any = {};
          errMsg.responseMessage = DutyConstants.instID_9_En;
          errMsg.responseMessageEn= DutyConstants.instID_9_En;
          errMsg.responseMessageHi= DutyConstants.instID_9_Hi;
          //this.alertmessage.errorAlertMessage(errMsg);
           //return false;
        }
        if(!this.validationForPartitionDeed()){
          let errMsg: any = {};
          errMsg.responseMessage = DutyConstants.partitonDeedAreaNotEqual_En;
          errMsg.responseMessageEn= DutyConstants.partitonDeedAreaNotEqual_En;
          errMsg.responseMessageHi= DutyConstants.partitonDeedAreaNotEqual_Hi;
          this.alertmessage.errorAlertMessage(errMsg);
          return false;
          }
      }
      else if(this.instrumentBean?.id==108 && this.fieldArray?.length<=1){
        let errMsg: any = {};
        errMsg.responseMessage = DutyConstants.instID_108_En;
        errMsg.responseMessageEn= DutyConstants.instID_108_En;
        errMsg.responseMessageHi= DutyConstants.instID_108_Hi;
        this.alertmessage.errorAlertMessage(errMsg);
        return false;
      }
      else  if(this.instrumentBean.id==109){
        console.log("this.propValArray.length:-",this?.propValArray?.length);
        if(Number(this.inputFields[391])!=Number(this.propValArray.length+1)){
          let errMsg: any = {};
          errMsg.responseMessage = DutyConstants.instID_9_En;
          errMsg.responseMessageEn= DutyConstants.instID_9_En;
          errMsg.responseMessageHi= DutyConstants.instID_9_Hi;
          //this.alertmessage.errorAlertMessage(errMsg);
          // return false;
        }
        if(!this.validationForPartitionDeed()){
          let errMsg: any = {};
          errMsg.responseMessage = DutyConstants.partitonDeedAreaNotEqual_En;
          errMsg.responseMessageEn= DutyConstants.partitonDeedAreaNotEqual_En;
          errMsg.responseMessageHi= DutyConstants.partitonDeedAreaNotEqual_Hi;
          this.alertmessage.errorAlertMessage(errMsg);
          return false;
        }
      }

      return true;
  }

  returnWithErrorMsgValidationPartitionDeed():boolean{
    let returVal:boolean=true;
    if(!this.validationForPartitionDeed()){
      let errMsg: any = {};
      errMsg.responseMessage = DutyConstants.partitonDeedAreaNotEqual_En;
      errMsg.responseMessageEn= DutyConstants.partitonDeedAreaNotEqual_En;
      errMsg.responseMessageHi= DutyConstants.partitonDeedAreaNotEqual_Hi;
      this.alertmessage.errorAlertMessage(errMsg);
      returVal= false;
    }
      if(!this.validationForPartitionDeedSaveParty()){
        let errMsg: any = {};
        errMsg.responseMessage = DutyConstants.partitonDeedPartyNotSaved_En;
        errMsg.responseMessageEn= DutyConstants.partitonDeedPartyNotSaved_En;
        errMsg.responseMessageHi= DutyConstants.partitonDeedPartyNotSaved_Hi;
        this.alertmessage.errorAlertMessage(errMsg);
        returVal= false;
      }

      return returVal;
  }


  validationForPartitionDeed():boolean{
      
      let returnVal:boolean=true;

    this.propValArrayPartitionDeed?.forEach(e=>{
      let totalPartyAreaSold:number=0;
      e.partyList.forEach((party:Party)=>{
        if(party.partyShare.shareAreaSold==null || party.partyShare.shareAreaSold==undefined){
          party.partyShare.shareAreaSold=0;
        }

        console.log('totalPartyAreaSold in loop:-',totalPartyAreaSold);
        console.log('party.partyShare.shareAreaSold in loop:-',party.partyShare.shareAreaSold);

        console.log('totalPartyAreaSold in loop NUMBER :-',Number(totalPartyAreaSold));
        console.log('party.partyShare.shareAreaSold in loop NUMBER:-',Number(party.partyShare.shareAreaSold));
          totalPartyAreaSold = Number(totalPartyAreaSold + party.partyShare.shareAreaSold);
      });

      console.log('totalPartyAreaSold:-',totalPartyAreaSold);
      console.log('totalPartyAreaSold NUMBER:-',Number(totalPartyAreaSold));
      console.log('e.propValAttribute.transactingArea:-',e.propValAttribute.transactingArea);
      if(Number(totalPartyAreaSold)!=Number(e.propValAttribute.transactingArea) ){
            returnVal = false;
            return;
      }else{
              //returnVal = true;
      }
    });

    return returnVal;

  }

  validationForPartitionDeedSaveParty():boolean{
      
    let returnVal:boolean=true;

  this.propValArrayPartitionDeed?.forEach(e=>{
    let totalPartyAreaSold:number=0;
    e.partyList.forEach((party:Party)=>{
      if(!party.isPartySaved){
         returnVal=false;
         return;
      }
    });
  });

  return returnVal;

}

  resetDutyForm(dutyForm: NgForm){
    dutyForm.resetForm();
  }

  resetDutyFields(){
    this.submitDisable = false;
    this.dutyCalculationRequest =  new DutyCalculationRequest();
    // this.dutyCalculationRequest.dutyInputFieldList= new Array();
    // this.dutyCalculationRequest.selectedExemptionList  = new Array();
    // this.dutyCalculationRequest.dutyInputOtherFieldList = new Array();
    // this.dutyCalculationRequest.propValFieldArray = new Array();
    // this.dutyCalculationRequest.dutyInputOtherFieldListReqArray = new Array();

    this.dutyCalculationResponseBean = new DutyCalculationResponse();
    //this.instExempMappingList= new Array();
    //this.exemList.checked
    this.selectedExemptions = new Array();
    this.isConditionalQues=false;
    this.showExemptions = false;
    this.availExemption = false;
    this.propValSelect = null;
    this.propValFlow = true;
    this.pMappingSel=null;
    this.pMappingCheckSel=null;
    this.inputFields= new Array();
    this.otherCalFlag='';
    this.propValArray = [];
    this.propValArrayPartitionDeed = [];
    this.propValArrayFTP = [];
    this.propValArraySTP = [];
    this.propValListFTP=[];
    this.propValListSTP=[];
    this.fieldArray = [];
    this.displayCalDutyFieldsFlag = false;
    this.propValFieldArray= new Array();
    this.movPropValfieldArray = [];
    this.movPropValAttribute = {};
    this.mvFileToUpload = null;
    this.ischeckMoveble = false;
    this.DutyPropInputFieldsDto= new DutyPropInputFieldsDto();
    this.dutyInputOtherFieldListArray = new Array();
    this.propertySel=null;
    this.selectedPropertyList=[];
    this.setPropDisplayFlag();
    this.propValAttribute={};
    this.addMorePropEnable=true;
    this.consenterDetailMap = new Map<number, IgrsConsenterDetlsDto[]>();
    this.dutyCalculationResponseBeanEvent.emit(this.dutyCalculationResponseBean);
    if(this.dutyform!==undefined){
      this.dutyform.resetForm();
    }

  }


  task: Task = {
    name: 'Stamp Duty Exemption',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Option 1', completed: false, color: 'primary'},
      {name: 'Option 2', completed: false, color: 'primary'},
      {name: 'Option 3', completed: false, color: 'primary'},
    ],
  };
  task1: Task = {
    name: 'Registration Fee Exemption.',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Option 1', completed: false, color: 'primary'},
      {name: 'Option 2', completed: false, color: 'primary'},
      {name: 'Option 3', completed: false, color: 'primary'},
    ],
  };
  panelOpenState = false;
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
    },
  };
  public pieChartOptions1: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
    },
  };

  public pieChartData: SingleDataSet =[1,1];
  public pieChartLabels: Label[] = [['Total','Payable ', 'Stamp Duty'], ['Total', 'Payable ', 'Registration Fees']];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins:any = [];

  public pieChartLabels1: Label[] = [['Govt', 'Stamp Duty'], ['Municipal', 'Stamp Duty '], ['Janpad', 'Stamp Duty '], ['Upkar', 'Stamp Duty ']];
  public pieChartData1: SingleDataSet=[1,1,1,1] ;
  public pieChartType1: ChartType = 'pie';
  public pieChartLegend1 = true;
  public pieChartPlugins1:any = [];

  displayPieChart(){

     this.pieChartData =[Number(this.dutyCalculationResponseBean.totalPayableStampDuty), Number(this.dutyCalculationResponseBean.totalPayableRegFee)];
      this.pieChartData1 = [this.dutyCalculationResponseBean.calculatedPSD , this.dutyCalculationResponseBean.calculatedMunicipalDuty, this.dutyCalculationResponseBean.calculatedJanpadDuty, this.dutyCalculationResponseBean.calculatedUpkarDuty];
  }

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
  }


  valuationIdExist():boolean{
    let returnVal : boolean=true;
    this.propValFieldArray.forEach(e=>{
      e.dutyPropInputFieldsArray.forEach(p=>{

        if(p.propValId==this.propValId){
          let errMsg: any = {};
          errMsg.responseMessage = DutyConstants.propValId_exist_En;
          errMsg.responseMessageEn= DutyConstants.propValId_exist_En;
          errMsg.responseMessageHi= DutyConstants.propValId_exist_En;
          this.alertmessage.errorAlertMessage(errMsg);
          returnVal = false;
          return;
        }
      });
    });

    return returnVal;
  }

  validateId(){

    if(this.valuationIdExist()){
      console.log("validateId: propValId:- ",this.propValId);
      if(this.propValId==undefined ||this.propValId==null){
        let errMsg: any = {};
        errMsg.responseMessage = DutyConstants.propValId_required_En;
        errMsg.responseMessageEn= DutyConstants.propValId_required_En;
        errMsg.responseMessageHi= DutyConstants.propValId_required_Hi;
        this.alertmessage.errorAlertMessage(errMsg);
       // return false;
      }else{

        this.ngxService.start();
 
        this.dcService.validatePropertyValId(this.propValId).subscribe(dcdata => {
          this.ngxService.stop();
          console.log("validatePropertyValId -subscribe..... ");
          console.log(dcdata.responseData);

          if (dcdata.responseStatus == "true") {

            let builderShare=null;
            if(this.inputFields[6]!=undefined && this.inputFields[6]!=null){
              if('Yes'.toLowerCase() ===this.inputFields[6].toLowerCase()){
                builderShare = this.inputFields[9];
                console.log("openDialog:openDialog-",builderShare);
              }
            }

            this.propValIdValid = true;
            this.propValFlow = true;
            this.propValId = null;
            console.log("CalculateDutyComponent-validatePropertyValId-subscribe-:",dcdata.responseData);
            this.propValAttributeColEn=[];
            this.propValAttributeColHi=[];
            this.propValAttribute.fieldId = [];
            this.propValAttribute.fieldvalue = [];
            this.instMappingBeanList.forEach(item=>{
              if(item.propValField){

                if(this.inputFields[item.id]!=undefined && this.inputFields[item.id]!= null && (this.inputFields[item.id] != '') ){
                  this.propValAttributeColEn.push(item.fieldNameEn);
                  this.propValAttributeColHi.push(item.fieldNameHi);
                  this.propValAttribute.fieldId.push(item.id);
                  this.propValAttribute.fieldvalue.push(this.inputFields[item.id]);
                }
                //this.inputFields[item.id] = '';
              }
            });
            this.propValAttribute.sno = this.propValArray.length+1;
            this.propValAttribute.valTxnId =  dcdata.responseData.valTxnId;
            this.propValAttribute.propertyIds = dcdata.responseData.propertyIds;// [1,2,3]
            this.propValAttribute.propertyType =  dcdata.responseData.mstPropertyTypeEntity;
            this.propValAttribute.propMarketValue =  dcdata.responseData.marketValue;
            this.propValAttribute.totalArea = dcdata.responseData.totalArea;
            let resJson =  JSON.parse(dcdata.responseData.propResponseJson);
            //console.log("resJson:-",resJson);
            this.propValAttribute.transactingArea = resJson.transactArea==null?dcdata.responseData.totalArea:resJson.transactArea;
            this.propValAttribute.unit = resJson.unit
            //this.propValAttribute.builderMarketValue =  dcdata.responseData.builderMV; //do not remove commented code in any review
            this.propValAttribute.builderMarketValue =  dcdata.responseData.marketValue*builderShare/100;
            this.propValAttribute.mstDemographyBean = dcdata.responseData.mstDemographyBean;
            //this.addMoreProperty();
            console.log("propValAttribute after valuation: ",this.propValAttribute);
            this.addMorePropEnable=false;
            this.displayPropDiv=false;

              if(this.instrumentBean.deedTypeId.id==64){

                let partitionDeed:  PartitionDeed= new PartitionDeed(); 
                partitionDeed.propValAttribute = Object.create(this.propValAttribute);

                if(this.propValArrayPartitionDeed?.length>0){
                  let partitionDeedTemp =  this.propValArrayPartitionDeed?.[0];
                      let partyListExist =  partitionDeedTemp.partyList;
                      this.partyListToCopy = JSON.parse(JSON.stringify(partyListExist)) ;

                      this.partyListToCopy.forEach(e=>{
                        e.isPartySaved=false;
                      e.partyShare.shareAreaSold= null;
                      } );

                      partitionDeed.partyList = JSON.parse(JSON.stringify(this.partyListToCopy));
                }else{
                        let party1 = new Party();
                        let party2 = new Party();

                        party1.name = "Party 1";                  
                        party2.name = "Party 2";
                        partitionDeed.partyList.push(party1);
                        partitionDeed.partyList.push(party2);
                }
                //partitionDeed.partyList =new Array();
                  this.propValArrayPartitionDeed.push(partitionDeed); //this.propValAttribute
              }

          } else {
                    this.propValIdValid = false;
                    this.propValId = null;
                    console.log( dcdata)
                    this.alertmessage.errorAlertMessage(dcdata);
          }
        });

      }
    }

      
  }


  ngOnDestroy(){
    console.log("ngOnDestroy : CalculateDutyComponent");

  }

//print page

onBtnPrintClick() {
  // let printData = document.getElementById('print-card').innerHTML;
  // document.body.appendChild(printData);

  // var myWindow = window.open("", "_blank");
  // myWindow.document.write(printData)
  // myWindow.print();

  let printContents, popupWin;
  printContents = document.getElementById('printID').innerHTML;
  popupWin = window.open('', 'top=0,left=0,height=400,width=auto');

  popupWin.document.open();

  popupWin.document.write(`
  <html>
  <head>
      <title>Registration and Stamp, Commercial Tax Department, Government of Madhya Pradesh</title>
      <style>
          <style type = "text/css" >
          @media screen {
            table{
              width:100%;
            }

}
 @media print {
  table{
    width:100%;
  }
}
 @media screen, print {
  table{
    width:100%;
  }
}

      </style>
  </head>
  <body onload="window.print();window.close()">

      ${printContents}

  </body>
</html>`
  );
  popupWin.document.close();

}

savePartyObj(index:number,partyObj:Party,prop:PartitionDeed,partionIndex:number){
  console.log("------savePartyObj--------------",partyObj);
  console.log("------savePartyObj partyList--------------",prop.partyList); //this.partyList
  partyObj.isPartySaved= true;
  //prop.partyList.push(new Party());
  //this.partyList.push(new Party());
  if(partyObj.partyShare.shareAreaSold==null || partyObj.partyShare.shareAreaSold==undefined){
    let errMsg: any = {};
    errMsg.responseMessage = DutyConstants.partitonDeedAreaNUll_En;
    errMsg.responseMessageEn= DutyConstants.partitonDeedAreaNUll_En;
    errMsg.responseMessageHi= DutyConstants.partitonDeedAreaNUll_Hi;
    this.alertmessage.errorAlertMessage(errMsg);
    partyObj.isPartySaved= false;
    //return false;
  }
}

editPartyObj(index:number,partyObj:Party,prop:PartitionDeed,partionIndex:number){
  console.log("------editPartyObj--------------",partyObj);
  console.log("------editPartyObj partyList--------------",prop.partyList);//this.partyList
   partyObj.isPartySaved= false;
  //this.partyList.push(new Party());
}

addMoreParty(){
  console.log("------addMoreParty--------------");
  //this.partyList.push(new Party());

  let length =   this.propValArrayPartitionDeed[0].partyList.length;
  
  this.propValArrayPartitionDeed?.forEach((e,index)=>{
    let party =new Party();
    party.name = "Party "+(length+1);
    e.partyList.push(party);
  });
}

deletePartyObj(index:number,partyObj:Party,prop:PartitionDeed,partionIndex:number){
  console.log("------deletePartyObj index--------------",index);
 // this.partyList.splice(index, 1);
    
 //prop.partyList.splice(index, 1);

    this.propValArrayPartitionDeed.forEach(e=>{
      e.partyList.splice(index, 1);
    });
}

}



//MovableProperty


//PropertyValuation
