import { Component, ElementRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'projects/common-lib/src/lib/helper-lib/services/common.service';
import { Demography } from 'projects/common-lib/src/lib/models/addOffice.model/addOffice.model';

import { Serviceprovider } from 'projects/common-lib/src/lib/models/serviceprovider/serviceprovider.model';
import { OfficeList } from 'projects/common-lib/src/lib/models/api.masterdata.model/office-list';

import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { CitizenAuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/citizen-authentication.service';
import { Captcha, Citizen } from 'projects/common-lib/src/lib/models';
import { Subscription, forkJoin, timer } from 'rxjs';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { TranslateService } from '@ngx-translate/core';

import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { CsvDownloadService } from '../../service/CsvDownloadService';
import { GuidelineService } from '../../service/GuidelineService';
import { CitizenService } from '../../service/citizen.service';
import { AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting/app.setting.service';
import { AppSetting } from 'projects/common-lib/src/lib/helper-lib/app.setting/app.setting';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PrintMap } from 'projects/common-lib/src/lib/service/printMap.service';
import { QueryLayerServices } from 'projects/common-lib/src/lib/service/queryLayer.services';
import { PropertyValuationService } from '../property-valuation/property-valuation.service';
import * as turf from '@turf/turf';
import { MstDemographyBean } from 'projects/common-lib/src/lib/models/MstDemographyBean';

//for grouping
export class PeriodicElement {
  id: number;
  position: number;
  wardColonyEn: string;
  wardColonyHi: string;
  plotresi: string;
  plotcomm:string;
  plotindus:string;
  buildrcc:string;
  buildrbc:string;
  buildtinshade:string;
  buildkabelu:string;
  buildshop:string;
  buildoffice:string;
  buildgodown:string;
  buildmultiresi:string;
  buildmulticomm:string;
  agriirrg:string;
  agriunirrg:string;
  landuptoresi:string;
  landuptocomm:string;
  // glDraftstatusEn:string;
  // glDraftstatusHi:string;
  // status:string;
  // glDraftstatusid:number;
  // draftStatusDetailsList  : DraftStatusDetails[]=new Array();
}
export interface Group {
  zoneEn: string;
  zoneHi: string;
  isGroupBy: boolean;
}
let ELEMENT_DATA: (Group | PeriodicElement)[] = [];

@Component({
  selector: 'app-gl-draft-suggestion',
  templateUrl: './gl-draft-suggestion.component.html',
  styleUrls: ['./gl-draft-suggestion.component.scss'],
  animations: [
    trigger('sidebarPannelInOut', [
      state(
        'in',
        style({
          width: '18%',
          display:'block'
        })
      ),
      state(
        'out',
        style({
          display:'none',
          width: '0%',
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
    trigger('mapbodyInOut', [
      state(
        'in',
        style({
          width: '100%',
          margin: '5px 5px 5px 5px'
        })
      ),
      state(
        'out',
        style({
          width: '82%',
          margin: '5px 5px 5px 2.5px'
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ])
  ]
})

export class GlDraftSuggestionComponent implements OnInit {

  @Input() isIndian: any;
  @ViewChild('DraftSuggestion') form: any;
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  @ViewChild('pdfDivId') pdfDivId: ElementRef;
  //for pagination
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  citizen: Citizen = new Citizen();

  ItemsArray: any[] = [];

  selectAreaTypeErrormsg: boolean = false;
  districtList:Demography[]=new Array();
  lan:number=1;
  districtId : any;
  isDisplayShow = true;
  isTableDisplayShow:boolean=false;
  gLDraftDetails$:Subscription;
  serviceprovider : Serviceprovider = new Serviceprovider();
  customOfficeList: OfficeList[] = new Array();
  PostSuggestion:boolean=false;
  pdfDownload:boolean=false;
  //save Suggestion Form
  saveSuggestionForm:FormGroup=new FormGroup({});
  submitted = false;
  showSaveButton=false;
  saveGLSuggestion$: Subscription;
  financialyearList: any[]=new Array();
  fileErrorMsg1:boolean=true;
  docType:any;
  docPath:any;
 // finYear:any;
  selectedDistrict:any;
  selectedOffice:any;
  guildeLineObj: any;
  selectedMstDemographyBean: any;
  currentDemography:any;
  //for otp
  captchaHandler?: Captcha = {};
  captchaImageBase64Img?: string = "";
  captchaId: any;
  captchaStr: any;
  countDown: Subscription;
  counter = 0; // 10 min 10*60
  tick = 1000; // 1 seccounter = 0; // 10 min 10*60
  otpTouched: boolean = false;
  getOtpClicked: boolean = false;
  otp: any;
  mobileNo: any;
  step0: boolean = true;
  step11: boolean = true;
  step12: boolean = false;
  step13: boolean = false;
  step1clear:boolean = false;
  step2clear:boolean = false;
  step21clear:boolean = false;
  verifiedOTP:boolean=false;
  otpVerification:boolean=false;

  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  searchFilter = "";

  pageSizeOptions: number[] = [5, 10, 15, 20, 25, 50, 100];
  publishDistrictList:any[]=[];
  districtNameHi :string[]=['नागरिकों के लिए प्रारूप गाईडलाइन खुला है '];
  districtNameEn :string[]=['Draft Guidelines For Citizen Open For '];
  isMarquee:boolean =false;
  isShow = true;
  hiddenvalue: string = "hidden";
  isChecked = true;
  isLoading = false;
  advnc_Status: any = "";

  usedBy:any="csv";

  demographyidchild: any = "";

  direction: string = "";

  //for param
  search:any;
  filterbystatus:any;
  active: string = "";
  financialYear: any;
  statusid: any="0";
  officeId:any="";
  pageno:any="";
  iDisplayLength:any="";
  showMap:boolean=false;
  @Output() map:any;
  selectGuideLineLayer:any;
  sidebarPannel:string="out";
  mapbody:string="in";
  selectedTabIndex:number=0;

  selection = new SelectionModel<any>(true, []);
  selectedValue: any;

  dataSource = new MatTableDataSource<any>();
  private settings!: AppSetting;
  districtIdforColonySearch:number=null;
  displayedColumns: string[] = ['position',
    'wardColony',
    'plotresi',
    'plotcomm',
    'plotindus',
    'buildrcc',
    'buildrbc',
    'buildtinshade',
    'buildkabelu',
    'buildshop',
    'buildoffice',
    'buildgodown',
    'buildmultiresi',
    'buildmulticomm',
    'agriirrg',
    'agriunirrg',
    'landuptoresi',
    'landuptocomm'
  ];

  //for grouping
  groupByColumns: string[] = ['zone'];
  showhierarchy:boolean=true;
  startSearch:boolean=false;
  public selectedIndex: number = 0;
  selectedHirarchy:string="0";
  showSearches:boolean=false;
  isSearching:boolean=true;
  isSearcedColony:boolean=false;
  searchedColony:Array<any>=[]
  @Output() mpBoundaryGeom:any;
  @ViewChild('colonySearchInput') searchInput: HTMLInputElement | any;
  currentShareUrl:string="";
  qaddress:string="";
  constructor(private ngxService: NgxUiLoaderService,
              private common:CommonService,
              private userService: CitizenService ,
              private glService: GuidelineService,
              config: NgbModalConfig,
              private modalService: NgbModal,
              private citizenAuthService: CitizenAuthenticationService,
              private translateService: TranslateHEService,
              private translate: TranslateService,
              private csvdownload: CsvDownloadService,
              private formBuilder: FormBuilder,
              private router: Router,
              private elementRef: ElementRef,
              private _AESEncryptDecryptService: AESEncryptDecryptService,
              private appSettingsService: AppSettingService,
              private pvService: PropertyValuationService,
              private printMap: PrintMap,
              private queryLayerServices:QueryLayerServices,
              private route: ActivatedRoute
    ){
     
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })

      this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
        this.settings = settings;
      });
    }
  ngOnInit(): void {
    this.serviceprovider.officeId=null;
    this.serviceprovider.districtId=null;
    this.serviceprovider.education='';
    this.serviceprovider.isPassed='';
    this.getDistricts();
    this.getFinancialYear();
    this.getAllDraftCitizenGL()
    this.saveSuggestionForm = this.formBuilder.group({
      demographyId:[''],
      officeId:[''],
      financialYr:[''],
      //title: ['',Validators.required],
      firstName: ['',Validators.required],
      middleName:[''],
      lastName: ['',Validators.required],
      email: ['',Validators.required],
      contactNo: ['',Validators.required],
      address: ['',Validators.required],
      suggestion: ['',Validators.required],
      docPath:[''],
      content:[''],
      docType:[''],

    });
    this.getStartupCaptcha();
    // Access query parameters using snapshot
    const stateParam = this.route.snapshot.queryParamMap.get('address');
    if(stateParam && stateParam != ""){
      this.qaddress = stateParam;
      this.selectedIndex =1;
      this.showMap=true;
    }
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
   }
  getDistricts(){
    this.common.getDistricts().subscribe(res=>{
        if(res.responseStatus){
          this.districtList = res.responseData;
          console.log("district list:",this.districtList )
        }else{

        }
    });

  }

  onTabChanged(event:any){
    //this.closeSidebar();
    this.selectedIndex =0;
    if(this.publishDistrictList.length>0 && event.index == 2){
      this.showMap=true;
    }else if(this.publishDistrictList.length == 0 && event.index == 1){
      this.showMap=true;
    }else{
      this.showMap=false;
    }
  }

  receiveMap(mapEvent:any){
    //console.log(mapEvent);
    this.ngxService?.start();
    this.pvService.getstateGeom().subscribe((res:any)=>{
      this.mpBoundaryGeom = res.features[0].geometry;
      setTimeout(()=>{
        this.startSearch = true;
      },500)
      this.ngxService?.stop();
    },(error:any)=>{
      this.ngxService?.stop();
    });
    this.map=mapEvent;
    this.addMapOnPrint();
    this.map.on('click', (evnt:any)=>{
      var pt = turf.point([evnt.latlng.lng, evnt.latlng.lat]);
      if(!turf.booleanPointInPolygon(pt, this.mpBoundaryGeom)){
        this.alertmessage.errorAlertMessage({responseMessage:'You have Click or Search Outsite of Mp State Boundary',responseMessageEn:'You Had Click or Search Outsite of Mp State Boundary',responseMessageHi:'आपने एमपी राज्य की सीमा के बाहर क्लिक या सर्च किया है '})
        return;
      }
      if(this.currentDemography != null){
       //this.resetHirarchy();
      }
      this.clearAll();
      this.getGlBylatLng(evnt);
    });
  }

  resetHirarchy(){
    this.showhierarchy = false;
    setTimeout(()=>this.showhierarchy=true,500);
  }

  addMapOnPrint(){
    let financialYearObj = this.financialyearList?.filter(year=> (year.fyType=="CURRENT"));
    this.printMap.addMap(this.map, financialYearObj[0]);
  }

  getCurrentFinancialYear(){
    return this.financialyearList.filter(year=> (year.fyType=="NEXT"))[0];
  }

  getDemography(demography:any){
    this.clearAll();
    this.getdemographyGeom(demography);
  }

  getAllDraftCitizenGL(){

    this.common.getAllDraftCitizenGL().subscribe(res=>{
        if(res.responseStatus){
          if(null!=res.responseData){
            this.publishDistrictList = res.responseData;
            this.selectedIndex =1;
            res.responseData.forEach((fyObject:any)=>{
              console.log(fyObject.demographyNameHi);
              this.districtNameEn.push(" "+fyObject.demographyNameEn)
              this.districtNameHi.push(" "+fyObject.demographyNameHi)
             });
          }
         this.isMarquee=true;
        }
    });


  }
  onChangeDistrict(event:any){
    this.PostSuggestion=false;
    this.serviceprovider.officeId=null;
    this.customOfficeList = [];
    //console.log(event.target.value);
    if (event.target.value > 0) {
      this.selectedDistrict=event.target.options[event.target.options.selectedIndex].text;
      this.ngxService.start();
      this.userService.get_offices_map(event.target.value).subscribe((officedata) => {
          this.customOfficeList = officedata.responseData;
          //console.log(this.customOfficeList);
          this.ngxService.stop();
      });
    }
  }
  onChangeOffice(event:any){
    this.PostSuggestion=false;
    if(event.target.value>0){
      this.selectedOffice=event.target.options[event.target.options.selectedIndex].text;
    }
  }

  getdemographyForSearch(event:any){
    if(event?.demographyTypeId == 2){

    }else if(event?.demographyTypeId == 3){

    }
  }
  //for get list of draft gl
  loadDataGLDraftSearchList(districtIds:any,districtOfficeId:any):any {
    this.officeId=districtOfficeId;
    this.searchFilter=districtOfficeId;
    this.districtId=districtIds;
   //for param
    this.search = "";
    this.filterbystatus="";
    this.active="";
    //this.financialYear="";
    this.statusid="7";
    this.officeId=districtOfficeId;
    this.pageno=this.currentPage,
    this.iDisplayLength=this.pageSize,
    this.usedBy="view",

    this.ngxService.start();
    this.isDisplayShow=false;
    //call service for get list of gl draft suggestion
    this.gLDraftDetails$=this.common.get_GLDraftDetailsByOfficeId(this.search,this.filterbystatus,
      this.active,this.financialYear,this.statusid,this.officeId,this.pageno,this.iDisplayLength,this.usedBy).subscribe(gLListData => {
      this.ngxService.stop();
      console.log(this.pageno);
      console.log(this.iDisplayLength);
      console.log(gLListData);
      this.PostSuggestion=false;
      if (gLListData) {
        ELEMENT_DATA=[];
        if(gLListData.listRecords!=null && gLListData.listRecords.length>0){
          let nameEn='',nameHi='',ttlnm="";
          let flg =gLListData.listRecords.length;
          for (let i = 0; i < flg; i++) {
            nameEn='';nameHi='';
            // console.log('-------------flg--------------- ',flg);
            // console.log('-------------i--------------- ',i);
            // flg=0;
            // console.log('---------------------------- ',gLListData.listRecords[i].totalDemghEn);
            if(i==0){
              ELEMENT_DATA.push({
                zoneEn: gLListData.listRecords[i]?.totalDemghEn,
                zoneHi: gLListData.listRecords[i]?.totalDemghHi,
                isGroupBy: true
              });
              // ttlnm=gLListData.listRecords[i]?.totalDemghEn;
              // flg++;
            }
            else if(i>0){
              // console.log((i-1),'-------------i--------------- ',gLListData.listRecords[i-1]?.totalDemghEn);
              // console.log(i,'-------------i--------------- ',gLListData.listRecords[i]?.totalDemghEn);
              // console.log('-------------i---------2222------ ',gLListData.listRecords[i-1]?.totalDemghEn!==gLListData.listRecords[i]?.totalDemghEn);
              if(gLListData.listRecords[i-1]?.totalDemghEn!==gLListData.listRecords[i]?.totalDemghEn){
                // flg++;
                // console.log('-------------i---------111111------ ',gLListData.listRecords[i-1]?.totalDemghEn!==gLListData.listRecords[i]?.totalDemghEn);
                ELEMENT_DATA.push({
                  zoneEn: gLListData.listRecords[i]?.totalDemghEn,
                  zoneHi: gLListData.listRecords[i]?.totalDemghHi,
                  isGroupBy: true
                });
              }

            }
            // console.log('----------------222------------ ',ELEMENT_DATA);
            if (gLListData.listRecords[i]?.ward_colony?.demographyNameEn!== undefined){
              nameEn=gLListData.listRecords[i]?.ward_colony?.demographyNameEn;
              nameHi=gLListData.listRecords[i]?.ward_colony?.demographyNameHi;
            }
            else if (gLListData.listRecords[i]?.village_coliny?.demographyNameEn!== undefined){
              nameEn=gLListData.listRecords[i]?.village_coliny?.demographyNameEn;
              nameHi=gLListData.listRecords[i]?.village_coliny?.demographyNameHi;
            }
            // console.log('----nameEn------ ',gLListData.listRecords[i]?.rnum);
            // console.log('----nameEn------ ',nameEn);
            // console.log('----nameHi------ ',nameHi);

            ELEMENT_DATA.push({
                "id":gLListData.listRecords[i]?.id,
                'position': gLListData.listRecords[i]?.rnum,
                'wardColonyEn': nameEn,
                'wardColonyHi': nameHi,
                'plotresi': gLListData.listRecords[i]?.plotresi,
                'plotcomm': gLListData.listRecords[i]?.plotcomm,
                'plotindus': gLListData.listRecords[i]?.plotindus,
                'buildrcc': gLListData.listRecords[i]?.buildrcc,
                'buildrbc': gLListData.listRecords[i]?.buildrbc,
                'buildtinshade': gLListData.listRecords[i]?.buildtinshade,
                'buildkabelu': gLListData.listRecords[i]?.buildkabelu,
                'buildshop': gLListData.listRecords[i]?.buildshop,
                'buildoffice': gLListData.listRecords[i]?.buildoffice,
                'buildgodown': gLListData.listRecords[i]?.buildgodown,
                'buildmultiresi': gLListData.listRecords[i]?.buildmultiresi,
                'buildmulticomm': gLListData.listRecords[i]?.buildmulticomm,
                'agriirrg': gLListData.listRecords[i]?.agriirrg,
                'agriunirrg': gLListData.listRecords[i]?.agriunirrg,
                'landuptoresi': gLListData.listRecords[i]?.landuptoresi,
                'landuptocomm': gLListData.listRecords[i]?.landuptocomm
                // 'glDraftstatusEn': gLListData.listRecords[i]?.draftStatusDetails?.nameEn,
                // 'glDraftstatusHi': gLListData.listRecords[i]?.draftStatusDetails?.nameHi,
                // 'glDraftstatusid':gLListData.listRecords[i]?.draftStatusDetails?.id,
                // 'status': gLListData.listRecords[i]?.status,
                // 'draftStatusDetailsList':gLListData.listRecords[i]?.draftStatusDetailsList
            });
            // console.log('----------------111------------ ',ELEMENT_DATA);
          }
        }
        this.dataSource.data =ELEMENT_DATA;
        //console.log(gLListData.responseStatus);
        //this.dataSource = new MatTableDataSource<any>(gLListData.listRecords);
        //this.dataSource = gLListData.listRecords;
        //  console.log(this.dataSource);
        //  console.log(this.dataSource.data.length);
           if(this.dataSource.data.length>0){
            this.PostSuggestion=true;
            this.isTableDisplayShow=true;
            setTimeout(() => {
            });
           }
           else{
            this.PostSuggestion=false;
            this.isTableDisplayShow=false;
           // this.alertmessage.errorAlertMessage(gLListData);
            this.alertmessage.errorAlertMessage({responseMessage:'The selected District Guideline is not available for citizens suggestions.',responseMessageEn:'The selected District Guideline is not available for citizens suggestions.',responseMessageHi:'चुने गए जिले  की गाइलाइन नागरिकों के सुझावों के लिए उपलब्ध नहीं है।'});
           }
           setTimeout(() => {
            this.paginator.length = gLListData.totalRecords;
            this.paginator.pageIndex = this.currentPage;
        });

      } else {
        // this.dataSource = new Array();
        this.dataSource = new MatTableDataSource<any>([]);
        this.isDisplayShow=false;
        this.PostSuggestion=false;
        this.isTableDisplayShow=false;
      }

      this.ngxService.stop();

    });
    this.ngxService.stop();
  }
  isGroup(index:any, item:any): boolean{
    return item.isGroupBy;
  }
  announceSortChangeGL(sortdqta: any) {
    //
     this.active = sortdqta.active;
     this.direction = sortdqta.direction;
     this.loadDataGLDraftSearchList(this.districtId,this.officeId);
   }
   dwnGLDraftDetails$:Subscription;
   downloadGLDraftDetails(fileType:any) {
    //for param
    this.search = "";
    this.filterbystatus="";
    this.active="";
    this.financialYear="";
    this.statusid="7";
    this.pageno=this.currentPage,
    this.iDisplayLength=this.pageSize,
    this.usedBy="csv",
    this.ngxService.start();
    this.dwnGLDraftDetails$=this.common.get_GLDraftDetailsByOfficeId(this.search,this.filterbystatus,
      this.active,this.financialYear,this.statusid,this.officeId,this.pageno,this.iDisplayLength,this.usedBy).subscribe(data => {

       if (data) {

         let date = new Date().toISOString().slice(0, 10);  let filename = "Draft Guideline List " + date;
         let columnname_key=[
          'Zone',
          'District',
          'Localbody_Or_Tehsil',
          'Ward_Or_Village_Patwari_Halka',
          'Ward_Colony_Or_Village_Colony',
          'Plot_Residential_Rate',
          'Plot_Commercial_Rate',
          'Plot_Industrial_Rate',
          'Building_Residential_RCC_Rate',
          'Building_Residential_RBC_Rate',
          'Building_Residential_Tin_Shade_Rate',
          'Building_Residential_Kabelu_Rate',
          'Building_Commercial_Shop_Rate',
          'Building_Commercial_Office_Rate',
          'Building_Commercial_Godown_Rate',
          'Building_Multi_Residential_Rate',
          'Building_Multi_Commercial_Rate',
          'Agriculture_Land_Irrigated_Rate',
          'Agriculture_Land_Un_irrigated_Rate',
          'Agriculture_Plot_Sub_Clause_wise_Residential_Rate',
          'Agriculture_Plot_Sub_Clause_wise_Commercial_Rate'
        ];
         let columnname=[
          this.translate.instant("guideline.zone"),
          this.translate.instant("guideline.district"),
          this.translate.instant("guideline.localbody_tehsil"),
          this.translate.instant("guideline.ward/village"),
          this.translate.instant("guideline.ward/village-colony"),
          this.translate.instant("guideline.resi"),
          this.translate.instant("guideline.comm"),
          this.translate.instant("guideline.ind"),
          this.translate.instant("guideline.rcc"),
          this.translate.instant("guideline.rbc"),
          this.translate.instant("guideline.tin_shade"),
          this.translate.instant("guideline.kaccha_abelu"),
          this.translate.instant("guideline.Shop"),
          this.translate.instant("guideline.office"),
          this.translate.instant("guideline.godown"),
          this.translate.instant("guideline.resi"),
          this.translate.instant("guideline.comm"),
          this.translate.instant("guideline.irgd"),
          this.translate.instant("guideline.unirgd"),
          this.translate.instant("guideline.sub-clause-resi"),
          this.translate.instant("guideline.sub-clause-comm")
        ];
         let userdatalist: GLDataList[] = new Array();
        // let glDraftPDFList : GLDraftPDFList[] = new Array();

         if(data.listRecords!=null && data.listRecords.length>0){
         for (let i = 0; i <= data.listRecords.length; i++) {
           let userlobj: GLDataList = new GLDataList();
         //  let glDraftobj: GLDraftPDFList = new GLDraftPDFList();
         const  demographyNameEn =  data.listRecords[i]?.localbody?.demographyNameEn?.replace('test','replacement');
         const  demographyNameHi =  data.listRecords[i]?.localbody?.demographyNameHi?.replace('test','replacement');
         if (demographyNameEn!== undefined || demographyNameHi!== undefined)  {
            userlobj.Localbody_Or_Tehsil = (this.lan == 0) ? demographyNameEn.replaceAll(',',';').replaceAll(/(\n)/g," ") : demographyNameHi.replaceAll(',',';').replaceAll(/(\n)/g," ");
           // glDraftobj.localbodyOrTehsil = (this.lan == 0) ? demographyNameEn.replaceAll(',',';').replaceAll(/(\n)/g," ") : demographyNameHi.replaceAll(',',';').replaceAll(/(\n)/g," ");
            if (data.listRecords[i]?.ward?.demographyNameEn!== undefined) {
               userlobj.Ward_Or_Village_Patwari_Halka = (this.lan == 0) ? data.listRecords[i]?.ward?.demographyNameEn.replaceAll(',',';').replaceAll('\n'," ") : data.listRecords[i]?.ward?.demographyNameHi.replaceAll(',',';').replaceAll('\n'," ");
               //glDraftobj.wardOrVillagePatwariHalka = (this.lan == 0) ? data.listRecords[i]?.ward?.demographyNameEn.replaceAll(',',';').replaceAll('\n'," ") : data.listRecords[i]?.ward?.demographyNameHi.replaceAll(',',';').replaceAll('\n'," ");
              }
             else {
               userlobj.Ward_Or_Village_Patwari_Halka = "";
               //glDraftobj.wardOrVillagePatwariHalka = "";
             }
             if (data.listRecords[i]?.ward_colony?.demographyNameEn !== undefined) {
               userlobj.Ward_Colony_Or_Village_Colony = (this.lan == 0) ? data.listRecords[i]?.ward_colony?.demographyNameEn.replaceAll(',',';').replace(/[\r\n]+/g," ") : data.listRecords[i]?.ward_colony?.demographyNameHi.replaceAll(',',';').replace(/[\r\n]+/g," ");
              // glDraftobj.wardColonyOrVillageColony = (this.lan == 0) ? data.listRecords[i]?.ward_colony?.demographyNameEn.replaceAll(',',';').replace(/[\r\n]+/g," ") : data.listRecords[i]?.ward_colony?.demographyNameHi.replaceAll(',',';').replace(/[\r\n]+/g," ");
              }
             else {
               userlobj.Ward_Colony_Or_Village_Colony = "";
               //glDraftobj.wardColonyOrVillageColony = "";
             }
          } else if(data.listRecords[i]?.tehsil?.demographyNameEn !== undefined) {
            userlobj.Localbody_Or_Tehsil = (this.lan == 0) ? data.listRecords[i]?.tehsil?.demographyNameEn.replaceAll(',',';').replaceAll(/(\n)/g," ") : data.listRecords[i]?.tehsil?.demographyNameHi.replaceAll(',',';').replaceAll(/(\n)/g," ");
           // glDraftobj.localbodyOrTehsil = (this.lan == 0) ? data.listRecords[i]?.tehsil?.demographyNameEn.replaceAll(',',';').replaceAll(/(\n)/g," ") : data.listRecords[i]?.tehsil?.demographyNameHi.replaceAll(',',';').replaceAll(/(\n)/g," ");
            if (data.listRecords[i]?.village?.demographyNameEn!== undefined){
               userlobj.Ward_Or_Village_Patwari_Halka = (this.lan == 0) ? data.listRecords[i]?.village?.demographyNameEn.replaceAll(',',';').replaceAll(/(\n)/g," ") : data.listRecords[i]?.village?.demographyNameHi.replaceAll(',',';').replaceAll(/(\n)/g," ");
              // glDraftobj.wardOrVillagePatwariHalka = (this.lan == 0) ? data.listRecords[i]?.village?.demographyNameEn.replaceAll(',',';').replaceAll(/(\n)/g," ") : data.listRecords[i]?.village?.demographyNameHi.replaceAll(',',';').replaceAll(/(\n)/g," ");
              }else {
               userlobj.Ward_Or_Village_Patwari_Halka = "";
              // glDraftobj.wardOrVillagePatwariHalka = "";
              }
             if (data.listRecords[i]?.village_coliny?.demographyNameEn !== undefined){
               userlobj.Ward_Colony_Or_Village_Colony = (this.lan == 0) ? data.listRecords[i]?.village_coliny?.demographyNameEn.replaceAll(',',';').replaceAll(/(\n)/g," ") : data.listRecords[i]?.village_coliny?.demographyNameHi.replaceAll(',',';').replaceAll(/(\n)/g," ");
              // glDraftobj.wardColonyOrVillageColony = (this.lan == 0) ? data.listRecords[i]?.village_coliny?.demographyNameEn.replaceAll(',',';').replaceAll(/(\n)/g," ") : data.listRecords[i]?.village_coliny?.demographyNameHi.replaceAll(',',';').replaceAll(/(\n)/g," ");
              }else {
               userlobj.Ward_Colony_Or_Village_Colony = "";
               //glDraftobj.wardColonyOrVillageColony = "";
             }
          }else{
           userlobj.Localbody_Or_Tehsil = "";
          // glDraftobj.localbodyOrTehsil= "";
          }

           if (data.listRecords[i]?.plotresi !== undefined) {
             userlobj.Plot_Residential_Rate = (this.lan == 0) ? data.listRecords[i]?.plotresi : data.listRecords[i]?.plotresi;
             //glDraftobj.plotResidentialRate = (this.lan == 0) ? data.listRecords[i]?.plotresi : data.listRecords[i]?.plotresi;
            } else {
             userlobj.Plot_Residential_Rate = "";
             //glDraftobj.plotResidentialRate = "";
           }

           if (data.listRecords[i]?.plotcomm !== undefined) {
             userlobj.Plot_Commercial_Rate = (this.lan == 0) ? data.listRecords[i]?.plotcomm : data.listRecords[i]?.plotcomm;
             //glDraftobj.plotCommercialRate = (this.lan == 0) ? data.listRecords[i]?.plotcomm : data.listRecords[i]?.plotcomm;
            } else {
             userlobj.Plot_Commercial_Rate = "";
             //glDraftobj.plotCommercialRate = ""
           }

           if (data.listRecords[i]?.plotindus !== undefined) {
             userlobj.Plot_Industrial_Rate = (this.lan == 0) ? data.listRecords[i]?.plotindus : data.listRecords[i]?.plotindus;
             //glDraftobj.plotIndustrialRate = (this.lan == 0) ? data.listRecords[i]?.plotindus : data.listRecords[i]?.plotindus;


            } else {
             userlobj.Plot_Industrial_Rate = "";
             //glDraftobj.plotIndustrialRate = "";
           }


           if (data.listRecords[i]?.buildrcc !== undefined) {
             userlobj.Building_Residential_RCC_Rate = (this.lan == 0) ? data.listRecords[i]?.buildrcc : data.listRecords[i]?.buildrcc;
            // glDraftobj.buildingResidentialRCCRate = (this.lan == 0) ? data.listRecords[i]?.buildrcc : data.listRecords[i]?.buildrcc;
            } else {
             userlobj.Building_Residential_RCC_Rate = "";
            // glDraftobj.buildingResidentialRCCRate  = "";
           }

           if (data.listRecords[i]?.buildrbc !== undefined) {
            userlobj.Building_Residential_RBC_Rate = (this.lan == 0) ? data.listRecords[i]?.buildrbc : data.listRecords[i]?.buildrbc;
           // glDraftobj.buildingResidentialRBCRate = (this.lan == 0) ? data.listRecords[i]?.buildrbc : data.listRecords[i]?.buildrbc;

          } else {
            userlobj.Building_Residential_RBC_Rate = "";
           // glDraftobj.buildingResidentialRBCRate = "";
          }

          if (data.listRecords[i]?.buildtinshade !== undefined) {
            userlobj.Building_Residential_Tin_Shade_Rate = (this.lan == 0) ? data.listRecords[i]?.buildtinshade : data.listRecords[i]?.buildtinshade;
           // glDraftobj.buildingResidentialTinShadeRate = (this.lan == 0) ? data.listRecords[i]?.buildtinshade : data.listRecords[i]?.buildtinshade;

          } else {
            userlobj.Building_Residential_Tin_Shade_Rate = "";
           // glDraftobj.buildingResidentialTinShadeRate =  "";
          }


           if (data.listRecords[i]?.buildkabelu !== undefined) {
            userlobj.Building_Residential_Kabelu_Rate = (this.lan == 0) ? data.listRecords[i]?.buildkabelu : data.listRecords[i]?.buildkabelu;
           // glDraftobj.buildingResidentialKabeluRate = (this.lan == 0) ? data.listRecords[i]?.buildkabelu : data.listRecords[i]?.buildkabelu;

          } else {
            userlobj.Building_Residential_Kabelu_Rate = "";
           // glDraftobj.buildingResidentialKabeluRate = "";
          }

          if (data.listRecords[i]?.buildshop !== undefined) {
            userlobj.Building_Commercial_Shop_Rate = (this.lan == 0) ? data.listRecords[i]?.buildshop : data.listRecords[i]?.buildshop;
           // glDraftobj.buildingCommercialShopRate = (this.lan == 0) ? data.listRecords[i]?.buildshop : data.listRecords[i]?.buildshop;

          } else {
            userlobj.Building_Commercial_Shop_Rate = "";
           // glDraftobj.buildingCommercialShopRate = "";
          }

          if (data.listRecords[i]?.buildoffice !== undefined) {
            userlobj.Building_Commercial_Office_Rate = (this.lan == 0) ? data.listRecords[i]?.buildoffice : data.listRecords[i]?.buildoffice;
           // glDraftobj.buildingCommercialOfficeRate = (this.lan == 0) ? data.listRecords[i]?.buildoffice : data.listRecords[i]?.buildoffice;

          } else {
            userlobj.Building_Commercial_Office_Rate = "";
           // glDraftobj.buildingCommercialOfficeRate = "";
          }

          if (data.listRecords[i]?.buildgodown !== undefined) {
            userlobj.Building_Commercial_Godown_Rate = (this.lan == 0) ? data.listRecords[i]?.buildgodown : data.listRecords[i]?.buildgodown;
           // glDraftobj.buildingCommercialGodownRate = (this.lan == 0) ? data.listRecords[i]?.buildgodown : data.listRecords[i]?.buildgodown;

          } else {
            userlobj.Building_Commercial_Godown_Rate = "";
           // glDraftobj.buildingCommercialGodownRate = "";
          }

          if (data.listRecords[i]?.buildmultiresi !== undefined) {
            userlobj.Building_Multi_Residential_Rate = (this.lan == 0) ? data.listRecords[i]?.buildmultiresi : data.listRecords[i]?.buildmultiresi;
           // glDraftobj.buildingMultiResidentialRate = (this.lan == 0) ? data.listRecords[i]?.buildmultiresi : data.listRecords[i]?.buildmultiresi;

          } else {
            userlobj.Building_Multi_Residential_Rate = "";
           // glDraftobj.buildingMultiResidentialRate = "";
          }

          if (data.listRecords[i]?.buildmulticomm !== undefined) {
            userlobj.Building_Multi_Commercial_Rate = (this.lan == 0) ? data.listRecords[i]?.buildmulticomm : data.listRecords[i]?.buildmulticomm;
           // glDraftobj.buildingMultiCommercialRate = (this.lan == 0) ? data.listRecords[i]?.buildmulticomm : data.listRecords[i]?.buildmulticomm;

          } else {
            userlobj.Building_Multi_Commercial_Rate = "";
           // glDraftobj.buildingMultiCommercialRate = "";
          }

          if (data.listRecords[i]?.agriirrg !== undefined) {
            userlobj.Agriculture_Land_Irrigated_Rate = (this.lan == 0) ? data.listRecords[i]?.agriirrg : data.listRecords[i]?.agriirrg;
          //  glDraftobj.agricultureLandIrrigatedRate = (this.lan == 0) ? data.listRecords[i]?.agriirrg : data.listRecords[i]?.agriirrg;

          } else {
            userlobj.Agriculture_Land_Irrigated_Rate = "";
           // glDraftobj.agricultureLandIrrigatedRate = "";
          }

          if (data.listRecords[i]?.agriunirrg !== undefined) {
            userlobj.Agriculture_Land_Un_irrigated_Rate = (this.lan == 0) ? data.listRecords[i]?.agriunirrg : data.listRecords[i]?.agriunirrg;
           // glDraftobj.agricultureLandUnirrigatedRate = (this.lan == 0) ? data.listRecords[i]?.agriunirrg : data.listRecords[i]?.agriunirrg;

          } else {
            userlobj.Agriculture_Land_Un_irrigated_Rate = "";
           // glDraftobj.agricultureLandUnirrigatedRate = "";
          }

          if (data.listRecords[i]?.landuptoresi !== undefined) {
            userlobj.Agriculture_Plot_Sub_Clause_wise_Residential_Rate = (this.lan == 0) ? data.listRecords[i]?.landuptoresi : data.listRecords[i]?.landuptoresi;
           // glDraftobj.agriculturePlotSubClauseWiseResidentialRate = (this.lan == 0) ? data.listRecords[i]?.landuptoresi : data.listRecords[i]?.landuptoresi;

          } else {
            userlobj.Agriculture_Plot_Sub_Clause_wise_Residential_Rate = "";
          //  glDraftobj.agriculturePlotSubClauseWiseResidentialRate = "";
          }

          if (data.listRecords[i]?.landuptocomm !== undefined) {
            userlobj.Agriculture_Plot_Sub_Clause_wise_Commercial_Rate = (this.lan == 0) ? data.listRecords[i]?.landuptocomm : data.listRecords[i]?.landuptocomm;
           // glDraftobj.agriculturePlotSubClausewiseCommercialRate = (this.lan == 0) ? data.listRecords[i]?.landuptocomm : data.listRecords[i]?.landuptocomm;

          } else {
            userlobj.Agriculture_Plot_Sub_Clause_wise_Commercial_Rate = "";
           // glDraftobj.agriculturePlotSubClausewiseCommercialRate = "";
          }

          if (data.listRecords[i]?.zone?.demographyNameEn !== undefined) {
            userlobj.Zone = (this.lan == 0) ? data.listRecords[i]?.zone?.demographyNameEn : data.listRecords[i]?.zone?.demographyNameHi;
            //glDraftobj.zone = (this.lan == 0) ? data.listRecords[i]?.zone?.demographyNameEn : data.listRecords[i]?.zone?.demographyNameHi;

          } else {
            userlobj.Zone = "";
            //glDraftobj.zone = "";
          }

         if (data.listRecords[i]?.district?.demographyNameEn !== undefined) {
           userlobj.District = (this.lan == 0) ? data.listRecords[i]?.district?.demographyNameEn : data.listRecords[i]?.district?.demographyNameHi;
           //glDraftobj.district = (this.lan == 0) ? data.listRecords[i]?.district?.demographyNameEn : data.listRecords[i]?.district?.demographyNameHi;
          } else {
           userlobj.District = "";
          // glDraftobj.district = "";
         }

           userdatalist.push(userlobj);
         //  glDraftPDFList.push(glDraftobj);
          // console.log(glDraftPDFList);
           //this.ItemsArray=glDraftPDFList;

         }
        }else{

        }

        if(fileType==true){
          console.log(userdatalist);
          this.csvdownload.downloadFile(userdatalist, filename, columnname,columnname_key);
        }else if(fileType==false){
          //this.printViewGl(glDraftPDFList)
        }

       } else {

       }

     this.ngxService.stop();
   });
   }
   printViewData$: Subscription;

  //printViewGl(data:any){
  //   const pdfTable = this.pdfTable.nativeElement;
  //  console.log(pdfTable);
  //  var html = $(pdfTable).html();
  //  var wi = window.open();
  //  $(wi.document.body).html(html);

  //}
  advanceFilterViewGl() {
    this.toggleStatusViewGl();
    this.loadDataGLDraftSearchList(this.districtId,this.officeId);
  }
  toggleStatusViewGl() {
    this.isShow = !this.isShow;
    if (this.isShow) {
    this.hiddenvalue = "hidden";
  } else {
    this.hiddenvalue = "";
   }
  }
  // Method for Page Change (Pagignation)
  pageChangedGL(event: PageEvent) {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
      this.loadDataGLDraftSearchList(this.districtId,this.officeId);
    }
  financialyear$:Subscription;
  getFinancialYear(){
   /*this.financialyear$=this.glService.get_financial_year().subscribe((resp:any)=>{
   this.financialyearList=resp.responseData;
   let fnyr=this.financialyearList[0];
   this.finYear=fnyr.finYear;
   console.log(this.finYear);
    },
    (err:any) => {
      console.log(err);
    }
    );*/



    let param={}
    this.glService.fetchFinancialYear().subscribe((resp:any)=>{
   //console.log("show service",resp);
   resp.responseData.forEach((fyObject:any)=>{
    if(fyObject.fyType==='NEXT'){
      this.financialyearList.push(fyObject);
    }
    if(fyObject.fyType==='CURRENT'){
      this.financialyearList.push(fyObject);
    }
   });
    this.financialYear= Math.max(... this.financialyearList.map(o => o.id));
    //this.loadCitizenSuggestion();
  },
  (err:any) => {
       // console.log(err);
      }
  );
  }

  //for otp
  onSubmitDraftSuggestion() {
    console.log("---------------------------------- ",this.saveSuggestionForm);
    this.submitted = true;
    if(this.saveSuggestionForm.invalid){
      this.otpVerification=false;
      return;
    }
    

    this.otpVerification=true;
    this.saveSuggestionForm.controls['demographyId'].setValue(this.districtId);
    this.saveSuggestionForm.controls['officeId'].setValue(this.officeId);
  }
  setOTPClicked(ContactNo:any):any {
    if(this.saveSuggestionForm.invalid){
      return;
    }
    // var regexp = new RegExp('^[6-9]\d{9}$');
    let mb: string = ContactNo;
    this.mobileNo=ContactNo;

    if(this.mobileNo == null || this.mobileNo == undefined || this.mobileNo == ""){
      this.getOtpClicked = false;
      return;
    } else if (mb.length != 10) {
      this.getOtpClicked = false;
      //this.alertmessage.errorAlertMessage('mobile-error');
      return;
    }

    let mb1:string = mb.substring(0,1);
    if(mb1 != '9' && mb1 != '8' && mb1 != '7' && mb1 != '6') {
      this.getOtpClicked = false;
      this.alertmessage.errorAlertMessage('mobile-error');
      return;
    }
    if (this.captchaStr == null || this.captchaStr == undefined || this.captchaStr == "") {
      this.getOtpClicked = false;
      this.alertmessage.errorAlertMessage('mobile-captcha-error');
      return;
    } else {
      this.citizen.mobileNo = this.mobileNo;
      this.citizen.captchaId = this.captchaId;
      this.citizen.captchaStr = this.captchaStr;
      this.citizenAuthService.getOtpGLpublic(this.citizen).subscribe(res => {
        //
        if (res && res.responseStatus == 'true') {
          this.getOtpClicked = true;
          this.otpTouched = true;
          this.counter = 60;
          
          // this.countDown = timer(0, this.tick).subscribe(() => {
          //   if (this.counter > 0) {
          //     --this.counter;
          //   }
          // });
          this.citizenAuthService.timer(this.counter).subscribe({
            next: (display) => {
              const words = display.split(':');
              this.counter = Number(words[1]);
            },
            complete: () => {
              console.log('Timer completed')
              this.counter = 0;
            },
          });
          this.alertmessage.successAlertMessage(res);
        } else {
          this.getOtpClicked = false;
          this.otpTouched = false;
          this.alertmessage.errorAlertMessage(res);
          this.gotostep11();
        }
      });
    }
  }
  verifyOtp(){
    console.log("docPath",this.docPath)
    if (this.otp == null || this.otp == undefined || this.otp == "") {
      this.alertmessage.errorAlertMessage('otp-error');
    } else {
      this.citizenAuthService.verifyOtp(this.mobileNo, this.otp).subscribe(res => {
        //
        if (res && res.responseStatus == 'true') {
          this.alertmessage.successAlertMessage(res);
          this.step1clear=true;
          this.gotostep12();
          this.getOtpClicked=false;
          this.verifiedOTP=true;
          this.otpVerification=false;
          
        } else {
          this.step1clear=false;
          this.alertmessage.errorAlertMessage(res);
          this.otp = null;
          this.getOtpClicked=true;
          this.verifiedOTP=false;
          this.otpVerification=true;
          

        }
      });
    }
  }

  doc1(fileInput: any): any {
   console.log("doc1 Called")
    if (fileInput.target.files && fileInput.target.files[0]) {
      //const pmax_size = 100000;
      const pallowed_types = ["application/pdf"];//, "image/jpeg", "image/jpg"

      if (!pallowed_types.includes(fileInput.target.files[0].type, 0)) {
        this.fileErrorMsg1 = false;
        this.saveSuggestionForm.controls[fileInput.target.name].setValue(null);
       // this.saveSuggestionForm.controls['docType'].setValue('');

      } else {
        this.fileErrorMsg1 = true;
        const preader = new FileReader();
        preader.onload = (pe: any) => {
          const pimage = new Image();
          pimage.src = pe.target.result;
          this.docType = fileInput.target.files[0].type.split('/')[1];
          this.docPath = (pe.target.result).split(',')[1];
          this.saveSuggestionForm.controls['docType'].setValue(this.docType);
          this.saveSuggestionForm.controls['docPath'].setValue(this.docPath);


        };

        preader.readAsDataURL(fileInput.target.files[0]);
      }
    }
  }

  resetForm() {
    this.saveSuggestionForm.reset();
    this.submitted = false;
    this.getOtpClicked = false;
    this.mobileNo = null;
    this.captchaStr = null;
    this.otp = null;
    this.getStartupCaptcha();
    this.counter = 0;
    this.verifiedOTP=false;
    this.otpTouched = false;
    this.otpVerification=false;
  }
  reset(form:NgForm){
    //window.location.reload();
   form.control.get('officeId').reset();
   form.control.get('districtIds').reset();

  }
  gotostep11() {
    this.step11 = true;
    this.getOtpClicked = false;
    

    //this.verifiedOTP=false;
    // this.mobileNo = null;
    this.counter = 0;
    this.captchaStr = null;
    this.otp = null;
    this.step12 = false;
    this.getStartupCaptcha();
    this.step0 = true;
  }
  gotostep12() {
    this.step12 = true;
    this.step13 = false;
    

    this.step11 = false;
    this.step0 = false;
  }
  get f() { return this.saveSuggestionForm.controls; }

  getStartupCaptcha() {
    this.ngxService.start();
    this.citizenAuthService.getLoginCaptcha().subscribe(result => {
      this.ngxService.stop();
      this.captchaHandler = result;
      this.captchaImageBase64Img = "data:image/png;base64," + this.captchaHandler.responseData?.captchaBase64;
      this.captchaId = this.captchaHandler.responseData?.id;
    },
      err => {
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage('captcha-error');
      }
    );
  }
  submitDraftSuggestion() {
    console.log("---------------------------------- ",this.saveSuggestionForm);
    this.submitted = true;
    if(this.saveSuggestionForm.invalid){
      this.otpVerification=false;
      return;
    }
    if(!this.fileErrorMsg1){
        return;
    }
    // this.saveSuggestionForm.controls['demographyId'].setValue(this.districtId);
    // this.saveSuggestionForm.controls['officeId'].setValue(this.officeId);
    this.ngxService.start();
    //this.saveSuggestionForm.controls['docPath'].setValue(this.docPath);
    this.saveGLSuggestion$=this.common.save_GLDraftSuggestion(this.saveSuggestionForm.value).subscribe(res=>{
    this.ngxService.stop();
    if(res.responseStatus!="false"){
      this.showSaveButton=false;
      this.alertmessage.successAlertMessage(res);
      setTimeout(() => {
        window.location.reload();
          }, 1000);
    }else{
      this.alertmessage.errorAlertMessage(res);
    }
  })
  this.getStartupCaptcha();
  }




  editGL(id:any){
   let distEnc=this._AESEncryptDecryptService.encryptInAngular(id);
   //this.ngxService.start();
   //selectedDistrictPrint

   //this.router.navigateByUrl('/get_print_pdf',{state:{districtid:distEnc}});
   //this.router.navigateByUrl('/get_print_pdf/'+id,{});
  // this.router.navigateByUrl('/printGLDraftPDF/'+id,{});
  this.glService.printDownLoadGuidelineData(id,((this.lan == 0)?"en":"hi"));
  }

  getGlBylatLng(p:any){
    this.ngxService.start();
    let financialyearobj:any = this.getCurrentFinancialYear();
    let query:string ="INTERSECTS(geom,%20POINT("+p.latlng.lng+" "+p.latlng.lat+"))"; 
    if(financialyearobj != undefined){
      //query = query + "AND valid_from>="+financialyearobj.finYearStartDate;
    }
    let url:string=this.queryLayerServices.getFinalGuidelineEncURL(query);
    forkJoin(this.queryLayerServices.customgetDecRequest('GET',url, null, null)).subscribe((resp:any) => {
      //if(resp && resp[0]?.responseStatus=="true"){
      this.ngxService.stop();
      if(resp[0] && resp[0].body?.features.length>0){
        this.addGuidelineBoundary(resp[0].body);
        this.getguidelineRates(resp[0].body.features[0].properties.col_id);
        //this.selectedMstDemographyBean = resp[0].responseData.mstDemographyBean;
        //}
      }else{

        this.alertmessage.errorAlertMessage({responseMessage:'No demography details available in this location',responseMessageEn:'No demography details available in this location',responseMessageHi:'इस स्थान पर कोई डेमोग्राफी विवरण उपलब्ध नहीं है'})
        this.ngxService.stop();
      }
    },(error:any)=>{
      this.alertmessage.errorAlertMessage({responseMessage:'There is some error processing your request, Please try again later.',responseMessageEn:'There is some error processing your request, Please try again later.',responseMessageHi:'आपका अनुरोध असफल रहा।  कृपया कुछ समय बाद पुनः प्रयास करे।'});
      this.ngxService.stop();
    });
  }

  getguidelineRates(col_id:string){
    this.pvService.getCurrentGLbyColonyId(col_id).subscribe((res:any) => {
      this.ngxService.stop();
        if (res.responseStatus == 'true') {
          this.guildeLineObj = res.responseData;
          this.selectedMstDemographyBean = res.responseData.demography;
          //this.openSidebar();
          this.selectedTabIndex =1;
          this.ngxService.stop();
        }
        else if(res.responseStatus == 'false'){
          this.alertmessage.errorAlertMessage({responseMessage:'No demography details available in this location',responseMessageEn:'No demography details available in this location',responseMessageHi:'इस स्थान पर कोई डेमोग्राफी विवरण उपलब्ध नहीं है'})
          this.guildeLineObj = null
        }
      },(error:any)=>{
        this.alertmessage.errorAlertMessage({responseMessage:'There is some error processing your request, Please try again later.',responseMessageEn:'There is some error processing your request, Please try again later.',responseMessageHi:'आपका अनुरोध असफल रहा।  कृपया कुछ समय बाद पुनः प्रयास करे।'});
        this.ngxService.stop();
      })
  }

  addGuidelineBoundary(layer:any):void{
    this.selectGuideLineLayer=L.geoJSON(layer);
    this.selectGuideLineLayer.addTo(this.map);
    this.selectGuideLineLayer.setStyle({color:'#0046FF',dashArray: '5, 5', dashOffset: '0'});
    this.selectGuideLineLayer.setZIndex(51);
    this.map.fitBounds(this.selectGuideLineLayer.getBounds());
    this.mapReset();
  }

  removeGuidelineBoundry():void{
    if(this.selectGuideLineLayer){
      this.map.removeLayer(this.selectGuideLineLayer);
      this.selectGuideLineLayer=null;
    }
  }

  clearguidelineobj(){
    this.guildeLineObj=null;
  }
  clearAll(){
    this.clearguidelineobj();
    this.removeGuidelineBoundry();
    this.selectedMstDemographyBean=null;
    this.currentDemography=null;
    //this.closeSidebar();
    //this.selectedIndex =0;
  }

  mapReset():void{
    if(this.map)
      setTimeout(()=>{ this.map.invalidateSize()}, 1000);
  }

  getdemographyGeom(e:any){
    try{
      this.currentDemography=e;
      this.ngxService.start();
      let url=this.queryLayerServices.getBoundryURLsEncfinal(e);
      if(url != ''){
        this.ngxService.startLoader('loader-propvalCommon');
        this.queryLayerServices.customgetDecRequest('GET',url, null, null).subscribe((response:any)=>{
          this.ngxService.stop();
          if(response && response.body){
              if(response.body?.features.length>0){
              this.addGuidelineBoundary(response.body);
              if(e.demographyType.id ==14 || e.demographyType.id==15){
                this.getguidelineRates(e.colonyId);
                this.selectedMstDemographyBean = e;
                //this.openSidebar();
                this.selectedTabIndex =1;
                }
              }
            }else{
              this.alertmessage.errorAlertMessage({responseMessage:'There is some error processing your request, Please try again later.',responseMessageEn:'There is some error processing your request, Please try again later.',responseMessageHi:'आपका अनुरोध असफल रहा।  कृपया कुछ समय बाद पुनः प्रयास करे।'});
            }
        },(error:any)=>{
          this.ngxService.stop();       
          this.alertmessage.errorAlertMessage({responseMessage:'There is some error processing your request, Please try again later.',responseMessageEn:'There is some error processing your request, Please try again later.',responseMessageHi:'आपका अनुरोध असफल रहा।  कृपया कुछ समय बाद पुनः प्रयास करे।'});
        });        
      }else{
        this.ngxService.stop();
        console.log('URL Not Configuried');
      }
    }catch(error:any){
      this.ngxService.stop();
      this.alertmessage.errorAlertMessage({responseMessage:'There is some error processing your request, Please try again later.',responseMessageEn:'There is some error processing your request, Please try again later.',responseMessageHi:'आपका अनुरोध असफल रहा।  कृपया कुछ समय बाद पुनः प्रयास करे।'});
    }

  }


  // getBoundryURLs(obj:any){
  //   let query:String;
  //   let url:string='';
  //   let D:any=obj.demographyType;
  //   switch(obj.demographyTypeId){
  //     case 2:
  //         query='div_cd='+obj.lgdCode;
  //         url="";//this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Ampdivision&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson';
  //       break;
  //     case 4:
  //         query = 'sr_off_cd='+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Asro_boundary&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson';
  //       break;
  //     case 3:
  //         query='dist_cd='+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Ampdistrict&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson'
  //       break;
  //     case 7:
  //         query="lgd_ulb_cd="+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson'
  //       break;
  //     case 8:
  //         query="ward_cd="+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Award_igrs&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson'
  //       break;
  //     case 12:
  //         query="teh_cd='"+obj.lgdCode+"'";
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson'
  //       break;
  //     case 10:
  //         query="vil_cd="+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Avillage_igrs&maxFeatures=50&CQL_FILTER='+query+'&outputFormat=application%2Fjson'
  //       break;
  //     case 14:
  //         query="col_id='"+obj.colonyId+"'";
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson'
  //       break;
  //     case 15:
  //         query="col_id='"+obj.colonyId+"'";
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson'
  //       break;
  //   }
  //    return url;
  // }

  openSidebar(){
    this.sidebarPannel="in";
    this.mapbody="out";
    this.mapReset();
  }

  closeSidebar(){
    this.sidebarPannel="out";
    this.mapbody="in";
    this.mapReset();
  }

  initPrintMap(){
    try{
      this.printMap.printstart(this.guildeLineObj, this.selectedMstDemographyBean);
    }catch(error:any){
      this.ngxService.stop();
    }
   
  }

  searchEvent(evt:any){
    if(evt.colonyId){
      evt.demographyTypeId = 14;
      evt.demographyType = {}
      evt.demographyType.id = 14;
      this.getdemographyGeom(evt);
    }else{
      let p:any={latlng:{lat:evt.latitude, lng:evt.longitude}}
      this.getGlBylatLng(p)
    }
  }

  getDistrict(demography:MstDemographyBean){
    this.districtIdforColonySearch =null;
    if(demography && demography.id){
      this.districtIdforColonySearch =demography.id;
    }
  }

  optionChange(){
    this.districtIdforColonySearch =null;
    this.clearSuggetion();
  }

  keyupAction(){
    if(this.districtIdforColonySearch == null){
      this.alertmessage.errorAlertMessage({responseMessage:'Please select District.',responseMessageEn:'Please select District.',responseMessageHi:'कृपया जिला चुनें!'});
      return;
    }else if(this.searchInput.nativeElement.value.length>0){
      //this.showcrosButton=true;
      this.isSearcedColony=false;
      this.searchedColony =[];
    }
    if(this.searchInput.nativeElement.value.length>4){
      this.searchedColony =[];
      this.isSearcedColony=false;
      this.isSearching =true;
      this.showSearches =true;
      this.addColonySuggetion();
    }
  }

  addColonySuggetion(){
    this.common.getColonySuggetion(this.districtIdforColonySearch, this.searchInput.nativeElement.value).subscribe((res)=>{
      this.isSearching =true;
      this.searchInput.nativeElement.focus();
      if(res.responseStatus == "true" && res.responseData.length>0){
        this.searchedColony = res.responseData;
      }else{
        this.searchedColony =[];
        this.isSearcedColony=true;
      }
    },(error:any)=>{
      this.searchedColony =[];
      this.isSearching =false;
    })
  }

  setColony(colony_deatil:any){
    colony_deatil.demographyTypeId =14;
    colony_deatil.colonyId = colony_deatil.COLONY_ID;
    colony_deatil.demographyType={}
    colony_deatil.demographyType.id=14;
    this.getDemography(colony_deatil);
    this.clearSuggetion();
  } 

  clearSuggetion(){
    this.searchedColony =[];
    this.isSearcedColony=false;
    this.showSearches =false;
  }

  socialclick(url:string){
    let curl:string = location.href.split("?")?location.href.split("?")[0]:location.href
    window.open( url + curl +"?address="+this.selectedMstDemographyBean.latitude +","+this.selectedMstDemographyBean.longitude)
  }

  openShareModel(){
    let curl:string = location.href.split("?")?location.href.split("?")[0]:location.href
    this.currentShareUrl =curl +"?address="+this.selectedMstDemographyBean.latitude +","+this.selectedMstDemographyBean.longitude;
     
  }

  doCopy(){
    navigator.clipboard.writeText(this.currentShareUrl).then(() => {
 
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }



}
export class GLDataList {
  Zone:string;
  District: string;
  Localbody_Or_Tehsil: string;
  Ward_Or_Village_Patwari_Halka: string;
  Ward_Colony_Or_Village_Colony: string;
  GL_Version_No: string;
  Financial_Year: string;
  Start_Date:string;
  End_Date:string;
  Plot_Residential_Rate: string;
  Plot_Commercial_Rate:string;
  Plot_Industrial_Rate:string;
  Building_Residential_RCC_Rate:string;
  Building_Residential_RBC_Rate:string;
  Building_Residential_Tin_Shade_Rate:string;
  Building_Residential_Kabelu_Rate:string;
  Building_Commercial_Shop_Rate:string;
  Building_Commercial_Office_Rate:string;
  Building_Commercial_Godown_Rate:string;
  Building_Multi_Residential_Rate:string;
  Building_Multi_Commercial_Rate:string;
  Agriculture_Land_Irrigated_Rate:string;
  Agriculture_Land_Un_irrigated_Rate:string;
  Agriculture_Plot_Sub_Clause_wise_Residential_Rate:string;
  Agriculture_Plot_Sub_Clause_wise_Commercial_Rate:string;
  Current_Draft_Guideline_Status:string;
}

// export class GLDraftPDFList {
//   zone:string;
//   district: string;
//   localbodyOrTehsil: string;
//   wardOrVillagePatwariHalka: string;
//   wardColonyOrVillageColony: string;
//   glVersionNo: string;
//   financialYear: string;
//   startDate:string;
//   endDate:string;
//   plotResidentialRate: string;
//   plotCommercialRate:string;
//   plotIndustrialRate:string;
//   buildingResidentialRCCRate:string;
//   buildingResidentialRBCRate:string;
//   buildingResidentialTinShadeRate:string;
//   buildingResidentialKabeluRate:string;
//   buildingCommercialShopRate:string;
//   buildingCommercialOfficeRate:string;
//   buildingCommercialGodownRate:string;
//   buildingMultiResidentialRate:string;
//   buildingMultiCommercialRate:string;
//   agricultureLandIrrigatedRate:string;
//   agricultureLandUnirrigatedRate:string;
//   agriculturePlotSubClauseWiseResidentialRate:string;
//   agriculturePlotSubClausewiseCommercialRate:string;
//   currentDraftGuidelineStatus:string;
// }