
export class juisticPersonProfileDetail {
    juristic: juisticPersonDetail;
    //citizen : CitizenUserProfileDetails;
    //roleMappingBean : RoleMappingBean;
    //subRoleMappingBean : SubRoleMappingBean;
}

export class juisticPersonDetail {
    additionalPlaceOfBusiness: string;
    authorizedCapital: number;
    centreJurisdiction: string;
    classOfCompany: string;
    companyIdNo: string;
    companyIdType: CompanyIdType;
    companyIdTypeId:Number;
    companyName: string;
    companyStatus: string;
    constitutionOfBusiness: string;
    dateOfBalanceSheet: string;
    dateOfIncorp: string;
    dateOfLastAGM: string;
    dateOfRegistration: string;
    gstinStatus: string;
    id: number;
    identityProof: IdentityProof;
    legalNameOfBusiness: string;
    listedOrNot: string;
    noOfMembers: number;
    organizationType: OrganizationType;
    otherAddress: string;
    principalPlaceOfBusiness: string;
    registeredAddress: string;
    rocCode: string;
    stateJurisdiction: string;
    suspendedAtStocks: string;
    taxpayerType: string;
    typeOfJuristicPerson: string;
    typeOfServices: string;
    citizenId:number;
    directorName: string;
    companyEmail: string;
    companyPhoneNo: string;
    companyCapital: number;
    isCompanyListed: number;
    authorizedPersonName: string;
    documentName: string;
    documentPath: string;
    undertakingDocumentName: string;
    undertakingDocumentPath: string;
    legalEntityTypeReferenceId: number;
    bankId: number;

    registrationNo: string
    isRepresentative: number

    companyCategory: string
    companySubCategory: string
    email: string
    activeCompliance: string
    directorsDetail: string
    dateOfJoining: string
    relatedPersonType: string

    departmentId: number
    authorizedPersonDesignation: string
    authorizedPersonEmail: string
}

export class CompanyIdType {
    companyIdTypeName: string;
    companyIdTypeNameEn: string;
    companyIdTypeNameHi: string;
    id: number;
    index: number;
    status: number;
}

export class IdentityProof {
    id: number;
}

export class OrganizationType {
    id: number;
    organizationTypeName: string;
    organizationTypeNameHi: string;
	organizationTypeNameEn: string;
	status: number;;
}