<div class="font-size-related mt-2">
  <!-- <div class="fid-header"></div> -->
  <!-- <div class="breadcrumb-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pt-2">
          <h2>
            {{'srOfficeDisplay.eRegQueMS' | translate}}
          </h2>
        </div>
      </div>
    </div>
  </div> -->
  <div class="container">


    <div class="logog">
      <img src="assets/images/sampada-logo.png" alt="Madhya Pradesh" height="50" />


      <div class="lang_div">

        <div *ngIf="langOptionFlag==1">
          <a (click)="changeLanguageToEnglish(0)" routerLinkActive="router-link-active" class="nav-link "
            style="cursor: pointer; " placement="bottom" ngbTooltip="Change to Hindi">
            <i class="fa fa-globe"></i> &nbsp;{{'selected-language' | translate}} </a>
        </div>
        <div *ngIf="langOptionFlag==0">
          <a (click)="changeLanguageToHindi(1)" routerLinkActive="router-link-active" class="nav-link "
            style="cursor: pointer; " placement="bottom" ngbTooltip="Change to English">
            <i class="fa fa-globe"></i> &nbsp;{{'selected-language' | translate}} </a>
        </div>

      </div>
    </div>


    <div class="card mb-3">
      <div class="card-body">
        <fieldset class="legend-section">
          <legend class="sidebar__widget-title">
            {{'eRegistry.slot-booking.chooseSROfficeLocation' | translate}}
          </legend>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>
                  {{'eRegistry.slot-booking.SROfficeLocation' | translate}}
                </label>
                <ng-select appendTo="body" [(ngModel)]="srOfficeDemoId" required (change)="onChangeSrOffice()"
                  name="srOfficeDemoId" placeholder="{{'eRegistry.slot-booking.selectSROfficelocation' | translate}}">
                  <ng-option *ngFor="let x of srOfficeList" [value]="x.id">
                    {{lan == 0 ? x.demographyNameEn : x.demographyNameHi}}</ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset class="legend-section" *ngIf="srOfficeDemoId">
          <div class="row">
            <div class="col-lg-3 col-sm-4 col-md-5">
              <div class="d-flex district"><b>
                  {{'srOfficeDisplay.District' | translate}}
                </b> : {{(lan==0) ? districtNameEn : districtNameHi}}</div>
            </div>
            <div class="col-lg-6 col-sm-5 col-md-7">
              <div class="d-flex justify-content-center">
                <b>
                  {{'srOfficeDisplay.SROffice' | translate}}
                </b> : {{(lan==0) ? srofficeNameEn : srofficeNameHi}}
              </div>
            </div>
            <div class="col-lg-3 col-sm-3 col-md-12">
              <div class="d-flex justify-content-end date">
                <b>
                  {{'srOfficeDisplay.Date' | translate}}
                </b> : {{date}}
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12 text-center">
              <b> {{'srOfficeDisplay.Toke_Assignment'| translate}}
              </b>
            </div>
          </div>
          <hr />

          <div class="row">
            <div class="col-xl-2 col-md-3">
              <fieldset class="legend-section news_Ticker">
                <legend class="sidebar__widget-title">

                  {{'srOfficeDisplay.Pending_Ass' | translate}}
                </legend>
                <div>
                  <h3> <b> {{'srOfficeDisplay.tokenNo' | translate}} </b></h3>

                  <marquee direction="up" class="h-100 marquee" scrolldelay="100" onmouseover="stop()"
                    onmouseout="start()" autoplay>
                    <ul>
                      <li *ngFor="let item of waitList">
                        {{item.tokenNo}} ({{item.slotRefNo}})
                      </li>
                    </ul>
                  </marquee>

                </div>
              </fieldset>
            </div>
            <div class="col-xl-10 col-md-9">


              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <fieldset class="legend-section h-100">
                    <legend class="sidebar__widget-title">

                      {{'srOfficeDisplay.verificationCounter' | translate}}
                    </legend>
                    <div class="table-responsive shadow-none">
                      <table class="table table-bordered alert alert-warning">
                        <thead class="thead-dark">
                          <tr>
                            <th>
                              {{'srOfficeDisplay.Maker' | translate}}
                            </th>
                            <th>
                              {{'srOfficeDisplay.tokenNo' | translate}}
                            </th>
                            <th>
                              {{'srOfficeDisplay.Status' | translate}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of makerList">
                            <td>{{lan==0 ? item.srName : item.srNameHi}}</td>
                            <td>{{item.tokenNo}} ({{item.slotRefNo}})</td>
                            <td>
                              <span class="text-success" *ngIf="item.slotStatus==16">
                                {{'srOfficeDisplay.Assigned' | translate}} </span>
                              <span class="text-primary" *ngIf="item.slotStatus==21">
                                {{'srOfficeDisplay.inProgress' | translate}}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </fieldset>
                </div>
                <div class="col-md-6 col-sm-6">
                  <fieldset class="legend-section h-100">
                    <legend class="sidebar__widget-title">

                      {{'srOfficeDisplay.registrationCounters' | translate}}
                    </legend>
                    <div class="table-responsive shadow-none">
                      <table class="table table-bordered alert alert-primary">
                        <thead class="thead-dark">
                          <tr>
                            <th>
                              {{'slotAvailabilitySummary.sr' | translate}}
                            </th>
                            <th>
                              {{'srOfficeDisplay.tokenNo' | translate}}
                            </th>
                            <th>
                              {{'srOfficeDisplay.Status' | translate}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of checkerList">
                            <td>{{lan==0 ? item.srName : item.srNameHi}}</td>
                            <td>{{item.tokenNo}} ({{item.slotRefNo}})</td>
                            <td>
                              <span class="text-success" *ngIf="item.slotStatus==16">
                                {{'srOfficeDisplay.Assigned' | translate}} </span>
                              <span class="text-primary" *ngIf="item.slotStatus==21">
                                {{'srOfficeDisplay.inProgress' | translate}}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </fieldset>
                </div>
              </div>
              <fieldset class="legend-section mt-2">
                <legend class="sidebar__widget-title">

                  {{'srOfficeDisplay.QRCodeSubRegOffice' | translate}}
                </legend>

                <div class="row align-items-center">
                  <div class="col-md-4 col-sm-4">
                    <div class="qrCodeBox">
                      <img src="{{qrCode}}" alt="QR Code" />
                    </div>
                  </div>
                  <div class="col-md-8 col-sm-8">
                    <p>
                      संपदा मोबाइल ऐप का उपयोग करके टोकन पाने के लिए, अपना स्लॉट नंबर
                      चुनें और सब-रजिस्ट्रार कार्यालय के क्यूआर कोड को स्कैन करें |
                    </p>

                    <p>
                      To get the Token using Sampada mobile app, select your slot
                      number and scan the QR code of Sub-Registrar office
                    </p>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </fieldset>

      </div>
    </div>
  </div>
</div>
<ngx-ui-loader [loaderId]="'loaderSROffice'"></ngx-ui-loader>