<div class="auth-main"> 
    <div class="container">
  
    <div class="row justify-content-center">
        <div class="col-lg-8 align-self-center">
            <div class="row d-flex justify-content-center h-50" >
                <div class="col-lg-7 user_card">
                    <!-- <div class="d-flex justify-content-center">
                        <div class="login_logo_container"> <img src="./assets/images/logo-1.png" class="login_logo" alt="Logo"> </div>
                    </div>  -->
                    <div class="d-flex ">
                        <h2 class="login-title text-primary d-block ">{{'verify-mobile-no' | translate}}</h2>
                    </div>
                    <lib-alertmessage></lib-alertmessage>

                    <div class="row">
                        <div class="col-lg-12">
                           
                            <div class=" " *ngIf="otpOption">
                                
                                <form [formGroup]="verifyMobileForm" (ngSubmit)="onSubmit()" > 
                                    
                                    <div class="input-group mb-3">
                                        <div class="input-group-append"> <span class="input-group-text bg-primary"><i class="fa fa-key"></i></span> </div>
                                        <input type="text"  formControlName="otp"  minlength="6" maxlength="6" (contextmenu)="false;" (keyup)="otpEnter()"
                                        [appCopyPastValidation]="true"
                                        class="form-control input_user"  placeholder="{{'otp' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.otp.invalid }">
                                       
                                        <div *ngIf="submitted&&f.otp.errors" class="invalid-feedback">
                                            <div *ngIf="f.otp.errors.required">{{'mandatory' | translate}}</div>
                                             </div>
                                        <div class="d-block w-100 text-right mt-1">
                                            <a class="forgetpasswrd" [hidden]="resendbtn">
                                                <span  *ngIf="counter != 0" style="font-weight: 400; font-size: 13px;">
                                                    {{'regenerate_otp' | translate}} 
                                                </span>  
                                                <span *ngIf="counter != 0">{{counter }}</span>
                                                 <span *ngIf="counter == 0" (click)="onSubmitUserNameForOtp();">{{'resend-otp' | translate}}</span></a>
                                                  <p style="font-weight: 400; font-size: 13px;">( {{'otpValid'| translate}} )</p>
                                        </div>
                                    </div>
 
                                    <div class="d-flex justify-content-center mt-3 login_container">
                                        <button mat-raised-button  class="btn btn-primary" [disabled]="verifybtn"><span class="click-icon"></span> <span class="pl-2">{{'verify-otp' | translate}}</span></button>
                                   
                                   
                                        <button type="button" (click)="backBtnClick()" class="btn btn-secondary ml-1">{{'goback' | translate}}</button>
                                  
                                   
                                   
                                    </div>
                                </form> 
                            </div> 
                            
                            <div class="mt-4">
                                <!-- <div class="d-flex justify-content-center links"><a routerLink="/login">{{'switch-login' | translate}}</a> </div> -->
                            </div>
                    
                            <!-- <div class="mt-4">
                                <div class="d-flex justify-content-center links"><a class="backtohome" routerLink="/"><i class="fa fa-home"></i> </a> </div>
                                
                            </div> -->
                        </div>
                        
                    </div>
            
                </div>
                <div class="col-md-5 bg-light">
                    <div class="d-flex h-100 align-items-center twxt-center">
                        <img src="assets/images/forgot-password.png" class="w-100" alt="Forget Password">
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    </div>
    </div>
     
    <ngx-ui-loader></ngx-ui-loader>