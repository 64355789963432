import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { MapComponent } from 'projects/common-lib/src/lib/component/map/map.component';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { IgrsSubClausePropertyMappingBean } from 'projects/common-lib/src/lib/models/IgrsSubClausePropertyMappingBean';
import { MstDemographyBean } from 'projects/common-lib/src/lib/models/MstDemographyBean';
import { MstPropertyTypeBean } from 'projects/common-lib/src/lib/models/MstPropertyTypeBean';
import { PropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/PropertyValuationRequestBean';
import { WebGisResponseBeanData } from 'projects/common-lib/src/lib/models/WebGisResponseData';
import { PropertyValuationService } from './property-valuation.service';
import { Observable, Subscription } from 'rxjs';
import { DataService } from './DataService';
import { CommunicationServiceService } from '../duty-calculation/duty-service/communication-service.service';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { HttpParams } from '@angular/common/http';
import * as L from 'leaflet';
import { DOCUMENT } from '@angular/common';
import {COMMA, ENTER, U} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { AgriPropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/agriPropertyValuationRequestBean';
import {  MPenagarpalikaResponsModel } from 'projects/common-lib/src/lib/models/enagarpalikaModel';
import { BuildingPropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/buildingPropertyValuationRequestBean';
import { MatAccordion } from "@angular/material/expansion";
import { DefaultServiceService } from '../../default/default-service.service';
import { CLRResponseModel } from 'projects/common-lib/src/lib/models/CLRResponseModel';
import { UaddResponseModel } from 'projects/common-lib/src/lib/models/UaddResponseModel';
import { PropertyOwner } from 'projects/common-lib/src/lib/models/PropertyOwner';
import { MatDrawer } from '@angular/material/sidenav';
import { PnrdModel } from 'projects/common-lib/src/lib/models/PnrdModel';
import { Select, Store } from '@ngxs/store';
import { PropertyMasterPropetyTypeAction, PropertyMasterUomAction } from 'src/app/store/action/property-valuation.action';
import { PropertyValuationState } from 'src/app/store/state/property-valuation.state';
import { UOMEnum } from 'projects/common-lib/src/lib/models/UOMEnum';
import { MapMyindiaService } from 'projects/common-lib/src/lib/helper-lib/services/MapMyindia.service';
import Swal from 'sweetalert2';
import * as turf from '@turf/turf';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MeasureToolComponent } from 'projects/common-lib/src/lib/component/measure-tool/measure-tool.component';
import { KhasraDetailsDataList } from 'projects/common-lib/src/lib/models/KhasraDetailsDataList';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';

import { QueryLayerServices } from 'projects/common-lib/src/lib/service/queryLayer.services';
import { LoggerService } from 'projects/common-lib/src/lib/service/logger.service';

declare const $: any;

@Component({
  selector: 'app-property-valuation',
  templateUrl: './property-valuation.component.html',
  styleUrls: ['./property-valuation.component.scss'],
  //providers: [DataService]
})
export class PropertyValuationComponent implements OnInit {
  @Select(PropertyValuationState.getPropertyTypeList) mstPropertyTypeBean$: Observable<MstPropertyTypeBean[]>
  @Select(PropertyValuationState.getPropertTypeLoaded) PropertTypeLoaded$:Observable<boolean>
  @Select(PropertyValuationState.getUomList) uomList$: Observable<UOMEnum[]>
  @Select(PropertyValuationState.getUomLoaded) uomListloaded$: Observable<boolean>
  @ViewChild('suggetionList')  suggetionList!: ElementRef<any>;
  @ViewChild('inputMapMyIndia') searcBox!:ElementRef;
  @ViewChild(MapComponent) mapcomponent:MapComponent;
  @ViewChild(MeasureToolComponent) measureToolComponent:MeasureToolComponent;
  @ViewChild('drawer') drawer?:MatDrawer
  @ViewChild('propertySelectionModal', { static: true }) propertySelectionModalRef: TemplateRef<any>;
  @ViewChild('propertyClrModal', { static: true }) propertyClrModalRef: TemplateRef<any>;
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() propIdAndLandParcelFromPV:any=[]
  //addImpoundProp:boolean=false;
  builderShare:number;
  latitude: number;
  longitude: number;
  zoom: number = 14;
  address: string;
  resetSubcl: boolean = true;
  id: any
  disableUaddDropdown:boolean=false
  isWebGisData:boolean=false
  itemTemplate: boolean = false;
  //popValuation: boolean = false;
  isThereCostructionDisbled: boolean = false;
  selectedFloors: any = new Map();
  showMarketValueBtn: boolean = true;
  errorMsg!: string;
  mainAreaType: number
  filterType: string
  plotSub: string;
  usage: string = "both";
  usageType: string;
  requestLoadCheck: boolean = false;
  islistShow:boolean=false;
  islCencelBtnShow:boolean=false;
  markerList:Array<L.Marker>=[];
  map:any;
  private settings!: AppSetting;

  showMyContainer: boolean = false;
  status: boolean = false;
  statusLink: boolean = false;
  isEnagarPalika:boolean=false
  isPnrd:boolean=false
  mstDemographyBeanList: MstDemographyBean[] = new Array();
  subClauseMasterBean: IgrsSubClausePropertyMappingBean[] = new Array();
  mstPropertyTypeBean: MstPropertyTypeBean[] = new Array();

  uomList: any[] = new Array();
  selectedMstDemographyBean: MstDemographyBean;
  selectedMstDemographyBeanTemp:MstDemographyBean;
  cLRResponseModel:CLRResponseModel;
  uaddResponseModel:UaddResponseModel[]=[];
  khasraDetailsDataList:KhasraDetailsDataList[]=[]
  propertyValuationRequestBean: PropertyValuationRequestBean = new PropertyValuationRequestBean();
  mpenagarpalikamodel:MPenagarpalikaResponsModel
  prndData:PnrdModel
  agriPropertyValuationRequestBean: AgriPropertyValuationRequestBean = new AgriPropertyValuationRequestBean();
  buildingPropertyValuationRequestBean:BuildingPropertyValuationRequestBean=new BuildingPropertyValuationRequestBean();
  webGisResponseData: WebGisResponseBeanData=new WebGisResponseBeanData();
  webGisResponseDataArr: WebGisResponseBeanData[]=new Array();
  lan: any=1;
  popup: any;
  public markers: any = [];
  private demographyLoaded:boolean = false;
  selectPropertyLayer:any;
  selectGuideLineLayer:any;
  private mpBoundaryGeom:any;
  isLoading = false;
  selectedText: string;
  totalAreaMsg: boolean = false;
  isPlot:boolean=false
  isBuilding:boolean=false
  isArgiculture:boolean=false
  guildeLineObj: any
  hideDropdownForMultipleDemography: boolean = false
  selectDemography$: Subscription;
  getmpenagarpalikaPropertyinfo$:Subscription
  etKhasraAndOwnerByKhasraId$:Subscription
  flag: boolean = true;
  fromDutyModule:boolean = false;
  fromCheckerModule:boolean = false;
  fromCMSModule:boolean = false;
  isImpoundAddProperty:boolean = false;
  fromCheckerModuleSubscription:Subscription
  fromCMSModuleSubscription:Subscription
  fromDutyModuleSubscription:Subscription
  fromDutyModuleShareSubscription:Subscription
  addedfromSearchIds:boolean=false;
  fullGeoAddress:string="";
  placeHolder:string
  isPartialTransact:boolean=false
  isCoowner:boolean=false
  propertyOwner: PropertyOwner[] = []
  params=new Array();
  mapPopup:any = L.popup({offset: [1, -35]});
  needToadd_K_P_P:boolean=false;
  bylatlng:boolean=false;
  khasrapopup:any[]=[{name:"landParcelId",alias:"land Parcel Id"},{name:"surveyNo",alias:"Khasra No"}, {name:"ULPin",alias:"Ulpin ID"}];
  propertypopup:any[]=[{name:"propertyNo",alias:"Property No"}];
  uaddpopup:any[]=[{name:"parcel_no",alias:"Parcel No"}];
  clrpopup:any[]=[{name:"khasra_id",alias:"khasra Id"}, {name:"ulpin",alias:"Ulpin ID"}];
  showToolsMeasureTool:boolean = false;
  khasraColor:any[]=[
    {"bg":"background-color:#FF0000;","color":"color:#ffffff;","gc":"#FF0000"},
    {"bg":"background-color:#00FFFF;","color":"color:#000000;","gc":"#00FFFF"},
    {"bg":"background-color:#800080;","color":"color:#ffffff;","gc":"#800080"},
    {"bg":"background-color:#FFA500;","color":"color:#ffffff;","gc":"#FFA500"},
    {"bg":"background-color:#008000;","color":"color:#ffffff;","gc":"#008000"},
  ];

  districtlayer:any;
  islClearBtnShow:boolean=false;
  fillterType2:string="";

  type_id:number=3;
  parent_id:number=1;
  filterType2: string;

  constructor(public matDialogRef: MatDialogRef<PropertyValuationComponent>,
    private defaultServiceService:DefaultServiceService ,
    private commService : CommunicationServiceService,
    private changeDetector:ChangeDetectorRef,
    private dataService:DataService,
    private pvService: PropertyValuationService,
    private ngxService: NgxUiLoaderService, private translateService: TranslateHEService,
    private translate: TranslateService, public dialog: MatDialog,
    private appSettingsService: AppSettingService,@Inject(DOCUMENT)
    private document:Document, private renderer: Renderer2,
    private store:Store,private logger:LoggerService,
    private mapmyindia:MapMyindiaService,private modalService: NgbModal,private _AESEncryptDecryptService: AESEncryptDecryptService,private queryLayerServices:QueryLayerServices) {
     

    this.translateService.currentApprovalStageMessage.subscribe(msg =>{
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
     
      if(this.filterType=='propid'){
        this.lan==0? this.placeHolder="Please Enter Nagar Nigam / Nagar Palika / Nagar Parishad / PTR Property ID":this.placeHolder="कृपया नगर निगम / नगर पालिका / नगर परिषद / पीटीआर संपत्ति आईडी दर्ज करें"
      }
      else if(this.filterType=='panchayatid'){
        this.lan==0? this.placeHolder="Please Enter Panchayat Property ID":this.placeHolder="कृपया पंचायत संपत्ति आईडी दर्ज करें"
      }
      else if(this.filterType=='searchByGeotag'){
        this.lan==0? this.placeHolder="Please Enter SAMPADA Geo Tag ID":this.placeHolder="कृपया संपदा जियो टैग आईडी दर्ज करें"
      }
    }
      );

    this.filterType='khasraid';
    this.fromDutyModuleSubscription =  this.commService.fromDutyModule.subscribe(res=> { this.fromDutyModule = res;
      this.logger.log("PropertyValuationComponent-fromDutyModule : ",this.fromDutyModule);
      }  );

      this.fromCheckerModuleSubscription =    this.commService.fromCheckerModule.subscribe(res=> { this.fromCheckerModule = res;
        this.logger.log("PropertyValuationComponent-fromCheckerModule : ",this.fromCheckerModule);
       }  );

       this.fromCMSModuleSubscription =   this.commService.fromCMSModule.subscribe(res=> { this.fromCMSModule = res;
        this.logger.log("PropertyValuationComponent-fromCMSModule : ",this.fromCMSModule);
       }  );

       this.fromDutyModuleShareSubscription =  this.commService.fromDutyModuleBuilderShare.subscribe(res=> {
        this.builderShare = res.builderShare;
        this.isImpoundAddProperty = res?.addImpoundProp;
        this.propertyValuationRequestBean.builderShare = this.builderShare;
        this.propertyValuationRequestBean.isImpoundAddProperty =this.isImpoundAddProperty;
        this.logger.log("propertyValuationRequestBean:builderShare- ",this.builderShare);
        this.logger.log("propertyValuationRequestBean:propertyValuationRequestBean- ",this.propertyValuationRequestBean);
       }  );

      this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
        this.settings = settings;
      });
      this.mapmyindia.saveTocken();
      this.logger.log("prop and land parcel",this.propIdAndLandParcelFromPV)
  }


  ngOnInit(): void {
    this.defaultServiceService.setDefaultType('property');
    this.dataService.getChangeMainAreaType().subscribe(res=>{
      console.log("fata service response",res)
      if(res!=null &&Object.keys(res).length!=0){
      if(res.id==2){
          this.mainAreaType=res.id
          this.isPartialTransact=res.isPartialTransact
          this.propertyValuationRequestBean=new PropertyValuationRequestBean();
          this.propertyValuationRequestBean.isConstOnAgriLand=true
          this.clickAreaTypeEventReset(res)
        }
      
      if(res.id==1){
          this.mainAreaType=res.id
          this.isPartialTransact=res.isPartialTransact
          this.propertyValuationRequestBean=new PropertyValuationRequestBean();
          this.propertyValuationRequestBean.isPlotOnAgriLand=true
          if (this.filterType == 'searchByGeotag' || this.filterType == 'panchayatid' || this.filterType == 'propid' || this.filterType == 'khasraid') {
            this.isPartialTransact = true;
          } else {
            this.isPartialTransact = false;
         }
          this.clickAreaTypeEventReset(res)
        }
      
      if(res.id==3){
        this.isArgiculture=true
        this.isBuilding=false
        this.isPlot=false
        this.propertyValuationRequestBean.isConstOnAgriLand=false
        this.propertyValuationRequestBean.isPlotOnAgriLand=false
        this.mainAreaType=3
      }
    }
      
    })
    //this.mpBoundaryGeom = this.mpBoundaryGeom.features[0].geometry;
    this.getAllSubClause();
    this.getAllPropertyType();
    this.getAllUOM();
    //this.loadyearList();
  }
  getAllSubClause() {
    this.pvService.getAllSubClauses().subscribe(dmodata => {
      this.subClauseMasterBean = dmodata.responseData;
    }
    );

  }
  getAllUOM() {
    this.uomListloaded$.subscribe(res=>{
      if(!res){
        this.store.dispatch(new PropertyMasterUomAction())
      }
    })

    this.uomList$.subscribe(res=>{
      this.logger.log(res)
      this.uomList=res
    }
   
    )
   
  }
  getAllPropertyType() {
    this.PropertTypeLoaded$.subscribe((res:any)=>{
      if(!res){
        this.store.dispatch(new PropertyMasterPropetyTypeAction())
      }
    })
    this.mstPropertyTypeBean$.subscribe((res:any)=>{
      this.logger.log(res)
      this.mstPropertyTypeBean=res
      this.mstPropertyTypeBean.filter(pdata => { // indrajeet
        //
        if (pdata.id == this.mainAreaType) {

          this.propertyValuationRequestBean.propertyType = pdata
          this.logger.log( this.propertyValuationRequestBean.propertyType)
        }
      });
    }
   
    )


  }

  initMapValue(value:any){ }

  resetMainOption(id:number) {
    this.isArgiculture=true
    this.isBuilding=false
    this.isPlot=false
    this.mainAreaType=3
    if(id==2){
    this.propertyValuationRequestBean.isConstOnAgriLand = false;
    this.agriPropertyValuationRequestBean.isConstOnAgriLand=false
    }
    else{
      this.propertyValuationRequestBean.isPlotOnAgriLand = false;
      this.agriPropertyValuationRequestBean.isPlotOnAgriLand = false;
    }
   

  }
  checkIsConstCheck(type: any) {
    let res = true;
    if (this.propertyValuationRequestBean.isConstOnAgriLand === true) {
      if (type == 1 || type == 3) {
        res = false;
      }
    }
    if (this.propertyValuationRequestBean.isPlotOnAgriLand === true&&this.propertyValuationRequestBean.usage=='undiverted') {
      if (type == 2 || type == 3) {
        res = false;
      }
    }

    return res;
  }

clickAreaTypeEventReset(mainAreaType: any) {
    if (mainAreaType.id == 3) {
    this.mainAreaType=3
    this.isArgiculture=true
    this.isPlot=false
    this.isBuilding=false
    this.isPartialTransact=false
    this.agriPropertyValuationRequestBean=new AgriPropertyValuationRequestBean();
    this.mstPropertyTypeBean.filter(pdata => {
    if(pdata.id==this.mainAreaType){
      this.agriPropertyValuationRequestBean.propertyType=pdata
     }
    });
    }
    else if(mainAreaType.id==2){
    this.mainAreaType=2
    this.isArgiculture=false
    this.isPlot=false
    this.isBuilding=true
    this.mpenagarpalikamodel=new MPenagarpalikaResponsModel();
    if(!this.propertyValuationRequestBean.isConstOnAgriLand){
      this.buildingPropertyValuationRequestBean=new BuildingPropertyValuationRequestBean();
      this.propertyValuationRequestBean=new PropertyValuationRequestBean();
    }
    this.mstPropertyTypeBean.filter(pdata => {
      if(pdata.id==this.mainAreaType){
        this.propertyValuationRequestBean.propertyType=pdata
    }
  });

    }
    else if(mainAreaType.id==1){
    this.mainAreaType=1
    this.isArgiculture=false
    this.isPlot=true
    this.isBuilding=false
    if(!this.propertyValuationRequestBean.isPlotOnAgriLand){
   
      this.propertyValuationRequestBean=new PropertyValuationRequestBean();
    }
    this.mstPropertyTypeBean.filter(pdata => {
      if(pdata.id==this.mainAreaType){
        this.propertyValuationRequestBean.propertyType=pdata
    }
  });
    }

}

ngAfterViewInit(){
  this.changeDetector.detectChanges()
}

changePlaceHolder(){
  if(this.filterType=='propid'){
    this.lan==0? this.placeHolder="Please Enter Nagar Nigam / Nagar Palika / Nagar Parishad / PTR Property ID":this.placeHolder="कृपया नगर निगम / नगर पालिका / नगर परिषद / पीटीआर संपत्ति आईडी दर्ज करें"
  }
  else if(this.filterType=='panchayatid'){
    this.lan==0? this.placeHolder="Please Enter Panchayat Property ID":this.placeHolder="कृपया पंचायत संपत्ति आईडी दर्ज करें"
  }
  else if(this.filterType=='searchByGeotag'){
    this.lan==0? this.placeHolder="Please Enter SAMPADA Geo Tag ID":this.placeHolder="कृपया संपदा जियो टैग आईडी दर्ज करें"
  }
}

searchBygeoTagAndAdress(){
  if(this.selectedMstDemographyBean != null){
    
  }else{
    this.allFormReset(this.drawer);
  }
}

allFormReset(drawer:any, type?:string) {
  if(type == this.fillterType2){
    return;
  }
  this.id = null
  this.selectedMstDemographyBean = null
  this.hideDropdownForMultipleDemography = true
  this.isPlot=false
  this.isArgiculture=false
  this.isBuilding=false
  this.propertyValuationRequestBean=new PropertyValuationRequestBean();
  this.isWebGisData=false
  this.params=[]
  this.mstDemographyBeanList =[];
  this.agriPropertyValuationRequestBean = new AgriPropertyValuationRequestBean();
  this.prndData = null;
  this.mpenagarpalikamodel=null;
  this.clearmarker();
  drawer.close()
  this.mapReset()
  this.setStartExtent();
  this.flag=true;
  this.islClearBtnShow=false;
  this.selectedMstDemographyBeanTemp =null;
  this.islCencelBtnShow=false;
  setTimeout(()=>{
    if(this.filterType == 'byname'){

    }
  },500);
  //------------End-----------------------------------------------


}

  receivedLatAndLngHandler(p: any, drawer: any) {
    this.clearmarker()
    this.bylatlng=true;
    this.isWebGisData=false
    this.isArgiculture=false
    this.isBuilding=false
    this.isPlot=false
    this.selectedMstDemographyBean=null
    this.propertyValuationRequestBean=new PropertyValuationRequestBean()
    this.agriPropertyValuationRequestBean=new AgriPropertyValuationRequestBean()
    this.propertyValuationRequestBean.isConstOnAgriLand=false
    this.propertyValuationRequestBean.isPlotOnAgriLand=false
    this.hideDropdownForMultipleDemography=true
    this.params=[]
    this.flag=false

    this.ngxService.startLoader('loader-propvalCommon');
    this.pvService.getDemographyDataByLatlng(p.latlng.lat, p.latlng.lng).subscribe(resp => {

      this.logger.log(resp)
      if (resp.responseStatus == 'true') {
        this.ngxService.stopLoader('loader-propvalCommon');
        this.uaddResponseModel=resp?.responseData?.listUaddPropertyBean
        this.khasraDetailsDataList=resp.responseData.khasraDetailsDataList
        this.selectedMstDemographyBean = resp?.responseData?.mstDemographyBean;
        this.selectedMstDemographyBeanTemp = resp?.responseData?.mstDemographyBean;
        this.cLRResponseModel=resp.responseData?.listClrPropertyBean
        this.webGisResponseData=resp.responseData?.webGisResponseData
        if(this.uaddResponseModel!=undefined||null){
          this.uaddResponseModel.forEach(index=>{
            if(index.enp_prop_id==null||undefined){
              //this.disableUaddDropdown=true
            }
          })}

        if (this.selectedMstDemographyBean.demographyType.demographyAreaType == 'R') {
          this.isWebGisData=true
         // this.propertyOwner=resp.responseData?.webGisResponseData?.propertyOwner
          this.mainAreaType =resp.responseData?.propertyTypeId;
          //this.addMarker(p.latlng, {"demography":this.selectedMstDemographyBean});
          if(this.webGisResponseData!=null && this.webGisResponseData!=undefined){
            this.webGisResponseData.demography=this.selectedMstDemographyBean;
            this.webGisResponseData.partialTransactArea=this.webGisResponseData.totalArea
            let agriPropertyValuationRequestBean =new AgriPropertyValuationRequestBean()
            agriPropertyValuationRequestBean.demography=this.selectedMstDemographyBean;
            this.webGisResponseData.latitude=p.latlng.lat;
            this.webGisResponseData.longitude=p.latlng.lng;
            agriPropertyValuationRequestBean.webGisResponseBeanDataArr.push(this.webGisResponseData)
            this.convertWebGisKhasharaToRequest(null,this.webGisResponseData);
            this.addedfromSearchIds=true;
            this.addMarker(p.latlng, agriPropertyValuationRequestBean);
          }else if(this.khasraDetailsDataList!=null&&this.khasraDetailsDataList!=undefined){
            this.openRorDetailDialog(this.propertyClrModalRef)
          }
          else{
            this.alertmessage.errorAlertMessage({responseMessage:'No Property available in this location',responseMessageEn:'No Property available in this location',responseMessageHi:'इस स्थान पर कोई संपत्ति उपलब्ध नहीं है'})
            this.addMarker(p.latlng, {"demography":this.selectedMstDemographyBean});
            this.clickAreaTypeEventReset({id:3})
            this.drawer.open();
          }
        }
        else{
          //this.logger.log(this.selectedMstDemographyBean.demographyType.demographyAreaType)
          this.addMarker(p.latlng, {"demography":this.selectedMstDemographyBean});
          if(this.uaddResponseModel!=null){
            if(this.uaddResponseModel.length==1){
              if(this.uaddResponseModel[0].enp_prop_id != undefined && this.uaddResponseModel[0].enp_prop_id != "")
                this.addedfromSearchIds=true;
                this.getUaddObjectByEnpId(this.uaddResponseModel[0].enp_prop_id)
            }
            else{
              this.openPropertyDetailDialog(this.propertySelectionModalRef);
              this.drawer.close();
              if(this.flag1){
                this.flag = true;
              }else{
                this.flag = false;
              }
              this.mapReset();
              return
            }

          }
          this.mainAreaType =2
          this.clickAreaTypeEventReset({id:2})
          this.drawer.open();

        }
        //calling Guideline
       this.getCurrentGuildeLine(resp.responseData.mstDemographyBean.id)
       //call guildeLine rate


      }
      else {
        this.isPlot=false
        this.isArgiculture=false
        this.isBuilding=false
        this.guildeLineObj=null
        //this.selectedDemography=null
        this.alertmessage.errorAlertMessage({responseMessage:'No demography details available in this location',responseMessageEn:'No demography details available in this location',responseMessageHi:'इस स्थान पर कोई डेमोग्राफि विवरण उपलब्ध नहीं है'})
        this.ngxService.stopLoader('loader-propvalCommon');
      }
      if(this.flag1){
        this.flag = true;
      }else{
        this.flag = false;
      }
      this.mapReset();
    },
    (error:any)=>{
      this.ngxService.stopLoader('loader-propvalCommon');
    }
    )


   this.flag = false;
   //setTimeout(()=>{ this.mapcomponent.map.invalidateSize()}, 2000);
   this.mapReset();
  }

  add(event?: MatChipInputEvent): void {

    const value = (event.value || '').trim();
    if (value) {
      this.params.push(value)
    }
    this.params=[...new Set(this.params)]

    event.chipInput!.clear();
  }


  // result: { containsAlphabetic: boolean, containsSpecialChar: boolean } = { containsAlphabetic: false, containsSpecialChar: false };
  // checkInput(inputText: string) {
  //   // Regular expressions to match alphabetic characters and special characters
  //   const alphabeticRegex = /[a-zA-Z]/;
  //   const specialCharRegex = /[^a-zA-Z0-9\s]/;

  //   const containsAlphabetic = alphabeticRegex.test(inputText);
  //   const containsSpecialChar = specialCharRegex.test(inputText);

  //   return this.result= { containsAlphabetic, containsSpecialChar }
  // }


  errorObj:{}
  selectKhIdAndPrIdAndUnid(filterType?: string) {
    if(filterType == "searchByGeotag"){
      if(this.selectGuideLineLayer != undefined && this.selectGuideLineLayer != null){
        this.searchByGeotag();
        return;
      }
    }
    if(this.fromCheckerModule){
      let isReturnBack:boolean=false
      this.logger.log("checker duplicate" ,this.propIdAndLandParcelFromPV)
      this.logger.log(this.propIdAndLandParcelFromPV.includes((this.id)))
      this.params.forEach(p=>{
      if( this.propIdAndLandParcelFromPV.find((s:any)=>s.id===p&&s.type===this.filterType))
        {
          this.errorObj= { responseMessage:' Property  ID already exist',
          responseMessageEn:'Property  ID already exist',
          responseMessageHi:'संपत्ति  आईडी पहले से मौजूद है'}
          this.alertmessage.errorAlertMessage(this.errorObj)
          isReturnBack=true
        }
      })
     if(this.propIdAndLandParcelFromPV.find((s:any)=>s.id===this.id&&s.type===this.filterType)){
      this.errorObj= { responseMessage:' Property  ID already exist',
      responseMessageEn:'Property valuation ID already exist',
      responseMessageHi:'संपत्ति  आईडी पहले से मौजूद है'}
      this.alertmessage.errorAlertMessage(this.errorObj)
      return 
     }
    if(isReturnBack){
      return
    }
     
   
    }
   
    this.isCoowner=false
    this.selectedMstDemographyBean=null;
    this.isArgiculture=false
    this.isBuilding=false
    this.isPlot=false
    this.hideDropdownForMultipleDemography = true;
    this.flag = true;
    this.agriPropertyValuationRequestBean = new AgriPropertyValuationRequestBean();
    this.propertyValuationRequestBean = new PropertyValuationRequestBean();
    this.prndData = null;
    this.mpenagarpalikamodel=null;
    this.clearmarker();
    if (filterType == "khasraid") {
      this.fillterType2 = this.filterType;
      if(this.params.length==10){
        this.id=null
        return
      }

      if (this.params.length==0) {
        this.alertmessage.errorAlertMessage("property-valuation.kindly-enter-khashra-id")

        return
      }
      let propertyValuationRequestBeanReq:PropertyValuationRequestBean=new PropertyValuationRequestBean()
      this.ngxService.startLoader('loader-propvalCommon');
      this.etKhasraAndOwnerByKhasraId$=this.pvService.getKhasraAndOwnerByKhasraId(this.params,propertyValuationRequestBeanReq,false,true).subscribe(res => {
        if(res.responseStatus == 'true'){
          this.ngxService.stopLoader('loader-propvalCommon');
          if(Object.keys(res?.responseData?.demography)?.length === 0){
            this.alertmessage.errorAlertMessage("property-valuation.pleaseTry");
            return;
          }
          this.addedfromSearchIds=true;
          this.propertyOwner=res.responseData.propertyOwner
         
          if(res.responseData.propertyType.id==3){
            this.clickAreaTypeEventReset({id:res.responseData.propertyType.id})
            this.agriPropertyValuationRequestBean=res.responseData;
            this.agriPropertyValuationRequestBean.isPlotOnAgriLand=false

            res.responseData.webGisResponseBeanDataArr?.forEach((w:WebGisResponseBeanData)=>{
              w.subClause.forEach((s:IgrsSubClausePropertyMappingBean)=>{
              this.agriPropertyValuationRequestBean.subClause.push(s)
            })
          })
          }
          // else{
          //   this.clickAreaTypeEventReset({id:1})
          //   this.propertyValuationRequestBean=res.responseData
           
           
          // }
            
          setTimeout(()=>this.registerDragElement(),300);
          //if Demography Type Id 10 coming
          if (null!=res.responseData.demography && null!=res.responseData.demography.demographyTypeId && res.responseData.demography.demographyTypeId == 10) {
          this.selectedMstDemographyBean=res.responseData.demography
          this.ngxService.startLoader('loader-propvalCommon');
          this.selectDemography$= this.pvService.selectDemography(res.responseData.demography.id,15).subscribe(res => {



            if(res.responseStatus=='true'){
              this.ngxService.stopLoader('loader-propvalCommon');
              this.mstDemographyBeanList = res.responseData
              // if Single Dropdown Value Coming
              if(this.mstDemographyBeanList.length==1){
              this.mstDemographyBeanList.forEach(dempgraphy=>{
                this.getCurrentGuildeLine(dempgraphy.id)
                this.selectedMstDemographyBean=dempgraphy;
                if(this.webGisResponseData != null)
                this.webGisResponseData.demography=dempgraphy
                this.hideDropdownForMultipleDemography=true
                this.agriPropertyValuationRequestBean.demography=dempgraphy
                this.convertWebGisKhasharaToRequest(this.agriPropertyValuationRequestBean,null)
               })
               this.addMarker(null, this.agriPropertyValuationRequestBean)

              }
              else{

                this.addMarker(null,  this.agriPropertyValuationRequestBean)
                this.hideDropdownForMultipleDemography = false
              }
            }
            else{
              this.ngxService.stopLoader('loader-propvalCommon');
              this.alertmessage.errorAlertMessage(res)
              this.isBuilding=false
              this.isArgiculture=false
              this.isPlot=false
              this.isWebGisData=false
            }

          },
          (error:any)=>{
            this.ngxService.stopLoader('loader-propvalCommon');
          })
        }
        else{
          this.hideDropdownForMultipleDemography = true
          this.webGisResponseData=res.responseData
          this.agriPropertyValuationRequestBean=res.responseData
          this.convertWebGisKhasharaToRequest(this.agriPropertyValuationRequestBean,null);
          this.addMarker(null, res.responseData)
          this.getCurrentGuildeLine(res.responseData.demography.id)
        }
        this.flag1=false;
        this.flag = false;
        this.mapReset();
        }
        else{
          this.ngxService.stopLoader('loader-propvalCommon');
          this.alertmessage.errorAlertMessage(res)
          this.isBuilding=false
          this.isArgiculture=false
          this.isPlot=false
          this.isWebGisData=false
          this.params=[]
          this.webGisResponseDataArr=[]
        }
      },
      (error:any)=>{
        this.ngxService.stopLoader('loader-propvalCommon');
      });
    }
    else if (filterType == "propid") {
      this.filterType='propid';
      this.fillterType2 = this.filterType;
      if (this.id == null && this.id == undefined && this.id=='') {
        this.alertmessage.errorAlertMessage("property-valuation.kindly-enter-properid")
        return
      }

      this.ngxService.startLoader('loader-propvalCommon');
      this.getmpenagarpalikaPropertyinfo$= this.pvService.getmpenagarpalikaPropertyinfo(this.id,true).subscribe(res => {
      if(res.responseStatus == 'true'){
        this.addedfromSearchIds=true;
        if(res.responseData?.demography && Object.keys(res.responseData?.demography)?.length === 0){
          this.alertmessage.errorAlertMessage("property-valuation.pleaseTry");
          return;
        }
        this.ngxService.stopLoader('loader-propvalCommon');
        this.mpenagarpalikamodel=res.responseData
        this.propertyOwner=res.responseData.propertyOwner
        this.convertUaddPropertyToRequest(this.mpenagarpalikamodel)
        this.getCurrentGuildeLine(res.responseData.demography.id)
        setTimeout(()=>this.registerDragElement(),300);
         if (null!=res.responseData.demography && null!=res.responseData.demography.demographyTypeId &&res.responseData?.demography?.demographyTypeId == 8) {
           this.hideDropdownForMultipleDemography = false;
            let latlng:any;
            if(res.responseData.latitude && res.responseData.longitude && res.responseData.latitude != "" && res.responseData.longitude != ""){
              latlng={lat:Number(res.responseData.latitude),lng:Number(res.responseData.longitude)}
            }else{
              latlng=null;
            }
            this.addMarker(latlng,res.responseData.demography);
            this.selectDemography$= this.pvService.selectDemography(res.responseData.demography.id,14).subscribe(res => {

              if(res.responseStatus=="true"){
                this.mstDemographyBeanList = res.responseData
                if( this.mstDemographyBeanList.length==1){
                  this.convertUaddPropertyToRequest(this.mpenagarpalikamodel)
                  this.getCurrentGuildeLine(res.responseData.demography.id)
                  //this.addMarker(latlng,res.responseData.demography);
                }
                else{
                  this.hideDropdownForMultipleDemography = false;
                }

              }
            },
            (error:any)=>{
              this.ngxService.stopLoader('loader-propvalCommon');
            }
            )
          }
          this.flag = false;
          this.flag1=false;
          this.mapReset();
        }
        else if(res.responseStatus == 'false'){
          this.ngxService.stopLoader('loader-propvalCommon');

          this.alertmessage.errorAlertMessage(res)
          this.selectedMstDemographyBean=null
          this.isBuilding=false
          this.isArgiculture=false
          this.isPlot=false

        }
      },
      (error:any)=>{
        this.ngxService.stopLoader('loader-propvalCommon');
      })

     //this.logger.log(this.propertyValuationRequestBean)
    }

    else if (filterType == "panchayatid") {
      this.fillterType2 = this.filterType;
      if (this.id == null && this.id == undefined) {
        this.alertmessage.errorAlertMessage("property-valuation.kindly-enter-rural-id")
        return
      }
      this.ngxService.startLoader('loader-propvalCommon');
      this.pvService.getpnrdPropertyinfo(this.id,true).subscribe(res => {
       // this.logger.log(res.responseData)
       if(res.responseStatus == 'true'){
		    this.addedfromSearchIds=true;
        this.ngxService.stop()
        this.ngxService.stopLoader('loader-propvalCommon');
        if(Object.keys(res.responseData.demography)?.length === 0){
          this.alertmessage.errorAlertMessage("property-valuation.pleaseTry");
          return;
        }
        this.prndData=res.responseData
        this.propertyOwner=res.responseData.propertyOwner
        setTimeout(()=>this.registerDragElement(),300);
        if (null!=res.responseData.demography && null!=res.responseData.demography.demographyTypeId &&res.responseData?.demography?.demographyTypeId == 10) {
          let latlng:any;
          if(res.responseData.latitude && res.responseData.longitude && res.responseData.latitude != "" && res.responseData.longitude != ""){
            latlng={lat:Number(res.responseData.latitude),lng:Number(res.responseData.longitude)}
          }else{
            latlng=null;
          }
          this.selectedMstDemographyBean=res.responseData.demography
          //this.addMarker(null, res.responseData.demography);
          this.selectDemography$= this.pvService.selectDemography(res.responseData.demography.id,15).subscribe(res => {

            if(res.responseStatus=="true"){

              this.mstDemographyBeanList = res.responseData
              if(this.mstDemographyBeanList.length==1){
                this.mstDemographyBeanList.forEach(dempgraphy=>{
                  this.hideDropdownForMultipleDemography = true;
                  this.getCurrentGuildeLine(dempgraphy.id)
                  this.selectedMstDemographyBean=dempgraphy
                  this.prndData.demography=dempgraphy
                  this.convertPnrdPanchayatToRequest(this.prndData)
                 })
                 this.addMarker(latlng, res.responseData[0]);
                 return
                }
                else{
                  this.addMarker(latlng,  this.selectedMstDemographyBean)
                  this.hideDropdownForMultipleDemography = false;
                }

            }
          },
          (error:any)=>{
            this.ngxService.stopLoader('loader-propvalCommon');
          }
          )
        }
        else{
          this.convertPnrdPanchayatToRequest(res.responseData)
        }
       }
       else{

         this.hideDropdownForMultipleDemography = true
        this.ngxService.stopLoader('loader-propvalCommon');
        this.alertmessage.errorAlertMessage(res)
       }

      },
      (error:any)=>{
        this.alertmessage.errorAlertMessage({responseMessage:'Kindly enter the valid panchayat Id',responseMessageEn:'Kindly enter the valid panchayat Id',responseMessageHi:'कृपया मान्य पंचायत आईडी दर्ज करें'})

        this.ngxService.stopLoader('loader-propvalCommon');
      })
      this.flag = false;
      this.flag1=false;
    }

    else if(filterType == "searchByGeotag"){
      if (this.id == null && this.id == undefined) {
        this.alertmessage.errorAlertMessage("property-valuation.kindly-enter-rural-id")
        return
      }
      this.fillterType2 = "searchByGeotag";
      this.searchByGeotag();
    }
  }

  searchByGeotag(){
    this.ngxService.startLoader('loader-propvalCommon');
      this.pvService.getPropertyByGeoId(this.id).subscribe(res => {
        this.ngxService.stopLoader('loader-propvalCommon');
        if(res.responseStatus == 'true'&&res.responseData){
          var response:any = {latitude:res.responseData.centerLattitude, longitude:res.responseData.centerLongitude};
          this.getPosition(response);
        }
        else if(res.responseStatus == 'false'&&res.responseData==null){
          this.alertmessage.errorAlertMessage({responseMessage:res.responseMessage,responseMessageEn:res.responseMessageEn,responseMessageHi:res.responseMessageHi})  
        }
        else{
          this.ngxService.stopLoader('loader-propvalCommon');
          this.alertmessage.errorAlertMessage({responseMessage:'Kindly enter the valid Geo Id',responseMessageEn:'Kindly enter the valid Geo Id',responseMessageHi:'कृपया मान्य भू आईडी दर्ज करें'})
        }
      },(error:any)=>{
        this.ngxService.stopLoader('loader-propvalCommon');
      })
  }

   isBlank(obj: any): boolean {
    return obj.constructor === Object && Object.keys(obj).length === 0;
  }
  convertUaddPropertyToRequest(enagarpalikaModel: MPenagarpalikaResponsModel) {
    this.mpenagarpalikamodel = enagarpalikaModel
    if(enagarpalikaModel.propertyTypeId==2){
    this.isEnagarPalika=true
    this.buildingPropertyValuationRequestBean=new BuildingPropertyValuationRequestBean()
    this.buildingPropertyValuationRequestBean.demography = enagarpalikaModel.demography
    this.buildingPropertyValuationRequestBean.buildingSubType='Independent Building'
    this.buildingPropertyValuationRequestBean.mPenagarpalikaResponseData=enagarpalikaModel
    this.buildingPropertyValuationRequestBean.isResidentialCumCommercial=false
    this.buildingPropertyValuationRequestBean.totalArea=enagarpalikaModel.totalArea
    this.buildingPropertyValuationRequestBean.partialTransactArea=enagarpalikaModel.totalArea
    this.buildingPropertyValuationRequestBean.floorResidentialCumCommercialList=[]
    this.buildingPropertyValuationRequestBean.unit = '' + enagarpalikaModel.unit
    this.buildingPropertyValuationRequestBean.propertyOwner=enagarpalikaModel.propertyOwner
    this.buildingPropertyValuationRequestBean.propertySource="ENP";
    this.mstPropertyTypeBean.filter(data => {
      if (data.id === enagarpalikaModel.propertyTypeId) {
        this.mainAreaType = enagarpalikaModel.propertyTypeId
        this.buildingPropertyValuationRequestBean.propertyType = data
      }
    })
    }
    else{
      this.isEnagarPalika=true
      this.propertyValuationRequestBean=new PropertyValuationRequestBean()
      this.propertyValuationRequestBean.demography = enagarpalikaModel.demography
      this.propertyValuationRequestBean.mPenagarpalikaResponseData=enagarpalikaModel
      this.propertyValuationRequestBean.isResidentialCumCommercial=false
      this.propertyValuationRequestBean.totalArea=enagarpalikaModel.totalArea
      this.propertyValuationRequestBean.partialTransactArea=enagarpalikaModel.totalArea
      this.propertyValuationRequestBean.floorResidentialCumCommercialList=[]
      this.propertyValuationRequestBean.unit = '' + enagarpalikaModel.unit
      this.propertyValuationRequestBean.propertyOwner=enagarpalikaModel.propertyOwner
      this.propertyValuationRequestBean.propertySource="ENP";
      this.mstPropertyTypeBean.filter(data => {
        if (data.id === enagarpalikaModel.propertyTypeId) {
          this.mainAreaType = enagarpalikaModel.propertyTypeId
          this.propertyValuationRequestBean.propertyType = data
        }
      })
    }
  
    this.selectedMstDemographyBean = enagarpalikaModel.demography;
    this.selectedMstDemographyBeanTemp = enagarpalikaModel.demography
    if(this.selectedMstDemographyBean!=null){
      this.flag1=false
      this.flag=false

    }
    this.isBuilding=enagarpalikaModel.propertyTypeId==2?true:false
    this.isArgiculture=false
    this.isPlot=enagarpalikaModel.propertyTypeId==1?true:false
    if(this.selectedMstDemographyBean.demographyTypeId>8){
      this.drawer.open()
      this.addMarker(null, enagarpalikaModel);
    }
    this.logger.log(this.buildingPropertyValuationRequestBean)
  }



  convertWebGisKhasharaToRequest(agriPropertyValuationRequestBean: AgriPropertyValuationRequestBean,webGisResponseData:WebGisResponseBeanData) {
        if(webGisResponseData==null){
          this.isWebGisData=true
          this.selectedMstDemographyBean = agriPropertyValuationRequestBean.demography;
          this.selectedMstDemographyBeanTemp = agriPropertyValuationRequestBean.demography;
          this.mstPropertyTypeBean.filter(data => {
            if (data.id === agriPropertyValuationRequestBean.propertyType.id && !agriPropertyValuationRequestBean.isPlotOnAgriLand) {
              this.mainAreaType = agriPropertyValuationRequestBean.propertyType.id
              this.agriPropertyValuationRequestBean.propertyType = data
            }
          })
          if(this.selectedMstDemographyBean.demographyTypeId>10)
          this.drawer.open();
         }
        else{
        this.isWebGisData=true
        this.params=[]
        this.filterType='khasraid'
        this.bylatlng = false;
        this.params.push(webGisResponseData.landParcelId)
        if(webGisResponseData.khasraType=='S'){
      this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr.push(webGisResponseData)
      this.agriPropertyValuationRequestBean.partialTransactArea=webGisResponseData.totalArea
      this.agriPropertyValuationRequestBean.demography = webGisResponseData.demography
      this.agriPropertyValuationRequestBean.totalArea=webGisResponseData.totalArea
      this.agriPropertyValuationRequestBean.overAllArea=webGisResponseData.totalArea
      this.agriPropertyValuationRequestBean.unit = '' + webGisResponseData.unit
      this.agriPropertyValuationRequestBean.propertyOwner=webGisResponseData.propertyOwner
      this.agriPropertyValuationRequestBean.propertySource="CLR"
        this.mstPropertyTypeBean.filter(data => {
        if (data.id === webGisResponseData.propertyTypeId) {
          this.mainAreaType = webGisResponseData.propertyTypeId
        this.agriPropertyValuationRequestBean.propertyType = data
        }
      })
      setTimeout(()=>this.registerDragElement(),300);
     this.selectedMstDemographyBean = webGisResponseData.demography
     this.selectedMstDemographyBeanTemp = webGisResponseData.demography
     this.isArgiculture=true
     this.isBuilding=false
     this.isPlot=false
    }else{
      this.agriPropertyValuationRequestBean.partialTransactArea=webGisResponseData.totalArea
      this.agriPropertyValuationRequestBean.demography = webGisResponseData.demography
      this.agriPropertyValuationRequestBean.totalArea=webGisResponseData.totalArea
      this.agriPropertyValuationRequestBean.overAllArea=webGisResponseData.totalArea
      this.agriPropertyValuationRequestBean.unit = '' + webGisResponseData.unit
      this.agriPropertyValuationRequestBean.propertyOwner=webGisResponseData.propertyOwner

      this.agriPropertyValuationRequestBean.propertySource="CLR"
        this.mstPropertyTypeBean.filter(data => {
        if (data.id === webGisResponseData.propertyTypeId) {
          this.mainAreaType = webGisResponseData.propertyTypeId
        this.agriPropertyValuationRequestBean.propertyType = data
        }
      })
      setTimeout(()=>this.registerDragElement(),300);
     this.selectedMstDemographyBean = webGisResponseData.demography
     this.selectedMstDemographyBeanTemp = webGisResponseData.demography
     this.isArgiculture=true
     this.isBuilding=false
     this.isPlot=false
  
    }
      if(this.selectedMstDemographyBean.demographyTypeId>10)
      this.drawer.open();
        }


    }


  convertPnrdPanchayatToRequest(pnrdModel: PnrdModel) {

    this.isPnrd=true
    this.isEnagarPalika=false
    this.buildingPropertyValuationRequestBean.propertySource="PNRD";
    this.propertyValuationRequestBean.propertySource="PNRD";
   // this.mstPropertyTypeBean.filter(data => {

        if (2 === pnrdModel.propertyTypeId) {
          this.mainAreaType = pnrdModel.propertyTypeId
   
          this.buildingPropertyValuationRequestBean.propertyType = this.mstPropertyTypeBean.find(id=>id.id==pnrdModel.propertyTypeId)
          this.buildingPropertyValuationRequestBean.demography = pnrdModel.demography
          this.buildingPropertyValuationRequestBean.totalArea=pnrdModel.totalArea
          this.buildingPropertyValuationRequestBean.floorResidentialCumCommercialList=[]
          this.buildingPropertyValuationRequestBean.unit = '' + pnrdModel.unit
          this.buildingPropertyValuationRequestBean.propertyOwner=pnrdModel.propertyOwner
          this.buildingPropertyValuationRequestBean.pNRDResponseData=pnrdModel
          this.propertyValuationRequestBean.partialTransactArea=pnrdModel.totalArea
          this.isBuilding=true
          this.isPlot=false
          this.isArgiculture=false

        }
        else if(1 === pnrdModel.propertyTypeId){
          this.mainAreaType = pnrdModel.propertyTypeId
         
          this.propertyValuationRequestBean.propertyType =  this.buildingPropertyValuationRequestBean.propertyType = this.mstPropertyTypeBean.find(id=>id.id==pnrdModel.propertyTypeId)
          this.propertyValuationRequestBean.demography = pnrdModel.demography
          this.propertyValuationRequestBean.totalArea=pnrdModel.totalArea
          this.propertyValuationRequestBean.partialTransactArea=pnrdModel.totalArea
          this.propertyValuationRequestBean.floorResidentialCumCommercialList=[]
          this.propertyValuationRequestBean.unit = '' + pnrdModel.unit
          this.propertyValuationRequestBean.propertyOwner=pnrdModel.propertyOwner
          this.propertyValuationRequestBean.pNRDResponseData=pnrdModel
          this.isPlot=true
          this.isBuilding=false
          this.isArgiculture=false
        }
      //})
      this.logger.log("panchayat demography",pnrdModel.demography)
      this.selectedMstDemographyBean = pnrdModel.demography
      this.selectedMstDemographyBeanTemp = pnrdModel.demography
      
      this.logger.log(this.propertyValuationRequestBean)
      this.logger.log(this.buildingPropertyValuationRequestBean)
      
      this.drawer.open();
  }

  // omit_special_charAdress(event:any){
  //  var k;
  //  k = event.charCode;
  
  //  this.logger.log((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57))  //         k = event.keyCode;  (Both can be used)
  //  return//((k > 64 && k < 91) || (k > 96 && k < 123) || k != 8 || k == 32 || (k >= 48 && k <= 57));
  // }
  omit_special_char(event:any){
  var k;
   k = event.charCode;
   
   //this.logger.log((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k !=32 || (k >= 48 && k <= 57))  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k != 32 || (k >= 48 && k <= 57));
  }

  selectDemographyByDropDown(item: any, drawer: any) {
    this.drawer.open()
    this.clearmarker(false);
    this.propertyValuationRequestBean.demography = item;
    this.hideDropdownForMultipleDemography = true
    this.mstDemographyBeanList.filter(data => {
      if (data.id == item.id) {
        this.selectedMstDemographyBean = data;
        this.selectedMstDemographyBeanTemp = data;
        this.getdemographyGeom(this.selectedMstDemographyBean);
        this.demographyLoaded=true;
        if(this.selectedMstDemographyBean?.location?.village_colony!=null&&this.mainAreaType==3){
          this.webGisResponseData.demography=this.selectedMstDemographyBean
          this.agriPropertyValuationRequestBean.demography=item
          this.convertWebGisKhasharaToRequest(this.agriPropertyValuationRequestBean,null)
        }
        else{
         if(this.prndData!=null&&this.prndData!=undefined){
          this.prndData.demography=item
          this.convertPnrdPanchayatToRequest(this.prndData)
         }
        }
        if(this.selectedMstDemographyBean?.location?.ward_colony!=null&&this.mainAreaType==2){
          this.mpenagarpalikamodel.demography=item
          this.convertUaddPropertyToRequest(this.mpenagarpalikamodel)
         
        }
        if(this.selectedMstDemographyBean?.location?.ward_colony!=null&&this.mainAreaType==1){
          this.mpenagarpalikamodel.demography=item
          this.convertUaddPropertyToRequest(this.mpenagarpalikamodel)
         
        }
        else if(this.prndData != undefined && this.prndData != null){
          this.prndData.demography=item
          this.convertPnrdPanchayatToRequest(this.prndData)
        }
        if(this.selectedMstDemographyBean?.location?.ward_colony==null && this.selectedMstDemographyBean?.location?.village_colony==null){
          this.selectedMstDemographyBean=null
          this.alertmessage.errorAlertMessage("property-valuation.th-area-not-mapped")
        }
       this.getCurrentGuildeLine(item.id)
      } 
      drawer.open();
      this.flag = false;
      //setTimeout(()=>{ this.mapcomponent.map.invalidateSize()}, 3000);
      this.mapReset();
    });
  }

  popucClose(){
    const element = this.popup?.getElement();
    $(element).popover("dispose");
  }

  ngOnDestroy() {
    this.logger.log("destroy")
    if(this.getmpenagarpalikaPropertyinfo$){
    // this.logger.log(this.getmpenagarpalikaPropertyinfo$)
      this.getmpenagarpalikaPropertyinfo$.unsubscribe();
    }
    if(this.selectDemography$){
    // this.logger.log(this.selectDemography$)
      this.selectDemography$.unsubscribe();
    }
    if(this.etKhasraAndOwnerByKhasraId$){
    // this.logger.log(this.etKhasraAndOwnerByKhasraId$)
      this.etKhasraAndOwnerByKhasraId$.unsubscribe();
    }

    if( this.fromCheckerModuleSubscription){
      this.fromCheckerModuleSubscription.unsubscribe();
    }
    if( this.fromCMSModuleSubscription){
      this.fromCMSModuleSubscription.unsubscribe();
    }
    if( this.fromDutyModuleSubscription){
      this.fromDutyModuleSubscription.unsubscribe();
    }
    if( this.fromDutyModuleShareSubscription){
      this.fromDutyModuleShareSubscription.unsubscribe();
    }

    //this.enabled = false;
    this.changeDetector.detectChanges();
  // this.enabled = true;
    this.getAllSubClause()
  }
//rahul
setStartExtent(){
  this.map.fitBounds(L.latLngBounds(this.mapcomponent.southWest, this.mapcomponent.northEast));
}
onLoadMap(map:L.Map){
  try{
    this.map=map;
    this.showToolsMeasureTool=true;
    this.pvService.getstateGeom().subscribe((res:any)=>{
      this.mpBoundaryGeom = res.features[0].geometry;
      this.ngxService?.stopLoader('loader-propvalCommon');
    },(error:any)=>{
      this.ngxService?.stopLoader('loader-propvalCommon');
    });
    if(!this.map.tools)
        this.map.tools={}
    this.map.on('click', (e:any)=>{
      this.afterClick(e);
    });
  }catch(error:any){
    this.ngxService?.stopLoader('loader-propvalCommon');
  }
  
}

afterClick(e:any){
  if(this.map?.tools?.measureTool)
    return;
  if(this.map.getZoom()<13){
    return;
  }
  if(this.selectPropertyLayer != undefined && this.selectPropertyLayer.getLayers().length>0 && this.checkMarkerInOut(e.latlng, this.getMergeBoundary(this.selectPropertyLayer.getLayers()))){
    return;
  }else if(this.selectGuideLineLayer != undefined && this.needToadd_K_P_P && this.checkMarkerInOut(e.latlng, this.getMergeBoundary(this.selectGuideLineLayer.getLayers()))){
    if((this.selectedMstDemographyBean?.demographyTypeId == 15 || this.selectedMstDemographyBean?.demographyTypeId == 14)
    &&(this.selectedMstDemographyBeanTemp?.demographyTypeId == 15 || this.selectedMstDemographyBeanTemp?.demographyTypeId == 14))
      if(this.selectedMstDemographyBean.id == this.selectedMstDemographyBeanTemp.id)
        this.needToaddKhasraAndproperty(e.latlng);
      else
        this.addNewDemography(e);
    else{
      this.addNewDemography(e);
    };
  }else if(this.demographyLoaded && !this.addedfromSearchIds){
    this.asktoseeotherproperty(e);
  }else if(!this.addedfromSearchIds){
    this.id='';
    this.fillterType2 = "bynameUrbon";
    this.clearMarker();
    this.addMarker(e.latlng);
  }
}


asktoseeotherproperty(e:any){
  Swal.fire({
    //title: `${this.lan==0?"Are you sure?":"क्या आपको यकीन है?"}`,
    // title: "Are you sure?",
    text: `${this.lan==0?"Would you like to see other properties?":"क्या आप अन्य संपत्तियां देखना चाहेंगे?"}`,
    // text: "Would you like to see other properties",
    icon: "warning",
    showCancelButton: true,
    // confirmButtonText: "Yes",
    confirmButtonText: (this.lan == 0) ? 'Yes':'हाँ ',
    allowOutsideClick: false
  }).then((result)=> {
      if (result.value) {
        if(this.filterType=="bynameUrbon")
          this.searcBox.nativeElement.value='';
        this.id='';
        this.clearMarker();
        this.addMarker(e.latlng);
      }
  });
}

addNewDemography(e:any){
  this.ngxService.startLoader('loader-propvalCommon');
  let query:string ="INTERSECTS(geom,%20POINT("+e.latlng.lng+" "+e.latlng.lat+"))"; 
  let url:string=this.queryLayerServices.getEncurlwithQuery(query, 'final_igrs_gis_gl');
  //this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson';
  this.queryLayerServices.customgetDecRequest('GET',url, null, null).subscribe((resp:any) => {
    this.logger.log(resp)
    if(resp && resp.body.features.length>0){ 
      
      this.removeGuidelineBoundry();
      this.addGuidelineBoundary(resp);
      this.getguidelineRates(resp?.body?.features[0].properties.col_id);
      this.needToaddKhasraAndproperty(e.latlng);                      

    }else{
      this.alertmessage.errorAlertMessage({responseMessage:'Not found',responseMessageEn:'Not found',responseMessageHi:'नहीं मिला|'})
      this.ngxService.stopLoader('loader-propvalCommon');
    }
  });
}

getguidelineRates(col_id:string){
  this.pvService.getCurrentGLbyColonyId(col_id).subscribe((res:any) => {
    this.ngxService.stopLoader('loader-propvalCommon');
      if (res.responseStatus == 'true') {
        this.guildeLineObj = res.responseData;
        this.selectedMstDemographyBean = res.responseData.demography;
        //this.openSidebar();
        this.hideDropdownForMultipleDemography=true;
        this.selectedMstDemographyBeanTemp = res.responseData.demography;
        //set Demograohy in Object
        this.agriPropertyValuationRequestBean.demography=this.selectedMstDemographyBean;
        if(this.mpenagarpalikamodel){
          this.mpenagarpalikamodel.demography=this.selectedMstDemographyBean;
        }
        if(this.prndData){
          this.prndData.demography=this.selectedMstDemographyBean;
        }
        //----------------------------
        //set guideline Rate
        this.guildeLineObj = res.responseData;
      }
      else if(res.responseStatus == 'false'){
        this.alertmessage.errorAlertMessage({responseMessage:'No demography details available in this location',responseMessageEn:'No demography details available in this location',responseMessageHi:'इस स्थान पर कोई डेमोग्राफी विवरण उपलब्ध नहीं है'})
        this.guildeLineObj = null
      }
    },(error:any)=>{
      this.alertmessage.errorAlertMessage({responseMessage:'There is some error processing your request, Please try again later.',responseMessageEn:'There is some error processing your request, Please try again later.',responseMessageHi:'आपका अनुरोध असफल रहा।  कृपया कुछ समय बाद पुनः प्रयास करे।'});
      this.ngxService.stop();
    })
}

needToaddKhasraAndproperty(latlng:any){
  if(this.bylatlng){
    if(this.uaddResponseModel != undefined){
      //this.uaddResponseModel.latitude = latlng.lat.toString();
      //this.uaddResponseModel.longitude = latlng.lng.toString();
    }else if(this.cLRResponseModel != undefined){
      this.cLRResponseModel.latitude = latlng.lat.toString();
      this.cLRResponseModel.longitude = latlng.lng.toString();
    }
  }else if(this.fillterType2 == "khasraid"){
    let no:number=null;
    if(this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr?.length>this.markers.length){
      for(let i=0; i<this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr.length;i++){
        if((this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[i]?.latitude == undefined && this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[i]?.longitude == undefined)
        || (this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[i]?.latitude == "" && this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[i]?.longitude == "")){
          no=i;
          this.updatedLatLngOnkhasraData(no, latlng);
          break;
        }
      }
      if(this.markers.length>0){
        this.setMarker(latlng, true, no);
        this.getKhasraBoundry([latlng], 'geo_khasra',1);
        if(this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr?.length==this.markers.length){
          this.needToadd_K_P_P=false;
          this.hidehirarchy();
        }
      }else{
        this.getAddressByLatLng(latlng).subscribe(()=>{
          this.setMarker(latlng, true, no);
          this.getKhasraBoundry([latlng], 'geo_khasra',1);
          if(this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr?.length==this.markers.length){
            this.needToadd_K_P_P=false;
            this.hidehirarchy();
          }
        });
      }
      
    }
  }else if(this.fillterType2 == "propid"){
    this.getAddressByLatLng(latlng).subscribe(()=>{
      this.updatedLatLngOnPropertyData(latlng);
      this.setMarker(latlng, true);
      this.getPropertyBoundry_2([latlng], 'plot_property_vw',1);
      this.needToadd_K_P_P=false;
      this.hidehirarchy();
    });    
  }else if(this.fillterType2 == "panchayatid"){
    this.getAddressByLatLng(latlng).subscribe(()=>{
      this.updatedLatLngOnPunchayatProperty(latlng);
      this.setMarker(latlng, true);
      this.getPropertyBoundry_2([latlng], 'plot_property_vw',1);
      this.needToadd_K_P_P=false;
      this.hidehirarchy();
    });
  }
}
updatedLatLngOnkhasraData(no:number, latlng:any){
  this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[no].latitude = latlng.lat.toString();
  this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[no].longitude = latlng.lng.toString();
  this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[no].demography=this.selectedMstDemographyBean
}

updatedLatLngOnPropertyData(latlng:any){
  this.mpenagarpalikamodel.latitude = latlng.lat.toString();
  this.mpenagarpalikamodel.longitude = latlng.lng.toString();
}

updatedLatLngOnPunchayatProperty(latlng:any){
  this.prndData.latitude = latlng.lat.toString();
  this.prndData.longitude =latlng.lng.toString();
}

updatedLatLngOnP_and_K(latlng:any){
  if(this.uaddResponseModel != undefined){

  }else if(this.cLRResponseModel != undefined){
    this.cLRResponseModel.latitude = latlng.lat.toString();
    this.cLRResponseModel.longitude = latlng.lng.toString();
  }
}

getsuggetions(searchValue:any){
   //this.logger.log(searchValue)
   if(searchValue.value.length>0){
    this.islCencelBtnShow=true;
  }else{
    this.islCencelBtnShow=false;
  }
  if(searchValue.value.length>2){
    try{
      let queryParams = new HttpParams();
      //queryParams = queryParams.append("query",`${searchValue.value}`).append("region","IND").append("filter","bounds:26.961246,73.952636;20.899871,82.961425");
      //this.pvService.getPlaceSuggetion(null, this.settings.suggestionURLofMapMyIndia, queryParams, this.map.keyMapMyIndia).subscribe((response:any)=>{
        if(this.selectGuideLineLayer && this.selectGuideLineLayer!= null){
          let ne =this.selectGuideLineLayer.getBounds().getNorthWest();
          let sw = this.selectGuideLineLayer.getBounds().getSouthEast();
          queryParams = queryParams.append("query",`${searchValue.value}`).append('access_token', this.mapmyindia.getToken()).append("filter","bounds:"+ne.lat+","+ne.lng+";"+sw.lat+","+sw.lng);
        }else{
          queryParams = queryParams.append("query",`${searchValue.value}`).append('access_token', this.mapmyindia.getToken());
        }
      this.pvService.getPlaceSuggetion(this.settings.suggestionURLofMapMyIndia, queryParams).subscribe((response:any)=>{
        if(response){
          this.islistShow=true;
          setTimeout(()=>{
            if(this.suggetionList.nativeElement.childNodes.length>0){
              this.suggetionList.nativeElement.replaceChildren();
            }
            response.suggestedLocations.forEach((list:any)=>{//this.logger.log(list)
              const sugetionList:HTMLElement = this.document.createElement('li');
              sugetionList.setAttribute('id',list.eLoc);
              sugetionList.setAttribute('class','suggetionListing')
              sugetionList.innerHTML=`<h6>${list.placeName}</h6><div>${list.placeAddress}</div>`;
              sugetionList.addEventListener('click',(e:Event)=>{
                //this.logger.log(list.eLoc);
                //this.clearmarker();
                searchValue.value=list.placeName+` (${list.placeAddress})`;
                this.suggetionList.nativeElement.replaceChildren();
                this.getPosition(list);
                this.islistShow=false;
              })
            this.renderer.appendChild(this.suggetionList.nativeElement, sugetionList);
          });
          this.addLatLngInSuggetion(searchValue);
          }, 300)
        }
      },(error:any)=>{
        if(this.islistShow && this.suggetionList.nativeElement.childNodes.length>0){
          this.suggetionList.nativeElement.replaceChildren();
        }
        this.mapmyindia.saveTocken();
        this.addLatLngInSuggetion(searchValue);
        this.searcBox.nativeElement.focus();
        if(error?.status == 401 || error?.responsecode == 400){
          //this.mapmyindia.saveTocken();
        }else{
          this.logger.log(error);
        }
      });
    }catch(error:any){
      this.logger.log(error);
      this.islistShow=false;
    }
  }else{
    this.islistShow=false;
  }
  //   this.http.get('https://jsonplaceholder.typicode.com/posts')
  //  .subscribe((response:any) => {
  //    this.logger.log(response)
  //  });
}
addLatLngInSuggetion(searchValue:any){
  if(searchValue.value.length>2){
    var inputVal ="";
    if(searchValue.value?.split(",")?.length==2){
        inputVal = searchValue.value?.split(",");
    }
    else if(searchValue.value?.split(" ")?.length==2){
      inputVal = searchValue.value?.split(" ");
    }
    if(inputVal != "" && inputVal[0] != "" && inputVal[1] != "" && Number.isFinite(Number(inputVal[0].trim())) && Number.isFinite(Number(inputVal[1].trim()))){
      let latlng:any = {
        latitude:inputVal[0],
        longitude:inputVal[1]
      }
      if(!this.islistShow)
        this.islistShow=true;
      setTimeout(()=>{
        const sugetionList:HTMLElement = this.document.createElement('li');
        sugetionList.setAttribute('id',"latlngS");
        sugetionList.setAttribute('class','suggetionListing')
        sugetionList.innerHTML=`<h6>${searchValue.value}</h6>`;
        sugetionList.addEventListener('click',(e:Event)=>{
          //this.logger.log(list.eLoc);
          
          searchValue.value=searchValue.value;
          this.suggetionList.nativeElement.replaceChildren();
          this.getPosition(latlng);
          this.islistShow=false;
        });
        if(this.islistShow && this.suggetionList.nativeElement.childNodes.length>0){
          let l:number=this.suggetionList.nativeElement.childElementCount
          if(this.suggetionList.nativeElement.childNodes[l-1].id == "latlngS"){
            this.suggetionList.nativeElement.removeChild(this.suggetionList.nativeElement.lastChild)
          }
          this.renderer.appendChild(this.suggetionList.nativeElement, sugetionList);
          //this.suggetionList.nativeElement.replaceChildren();
        }else{
          this.renderer.appendChild(this.suggetionList.nativeElement, sugetionList);
        }
      },300);
    }
  }
}

getAddressByLatLng(latlng:any){
  //this.ngxService.startLoader('pv-01');
  return new Observable((observer) =>{
  let queryParams = new HttpParams();
  let type = this.selectedMstDemographyBean?.demographyType?.demographyAreaType;
  if(type == "U"){
    queryParams = queryParams.append("lat",latlng.lat).append("lng",latlng.lng).append('access_token', this.mapmyindia.getToken());
    this.pvService.getPlaceSuggetion(this.settings.getLatLngMapMyIndia, queryParams).subscribe((response:any)=>{
      if(response.responseCode=200){
        var result = response.results[0];
        //result="Distirct: "+result.district+", Village:" + result.village +", Sub-Area : "+result.formatted_address;
        //var _result=result.formatted_address.split(".")[1].trim();
        this.propertyValuationRequestBean.address = result.formatted_address;
        this.buildingPropertyValuationRequestBean.address=result.formatted_address;
        this.agriPropertyValuationRequestBean.address=result.formatted_address;
        //this.prndData.property_Address = result.formatted_address
        //.split(".")[1].trim();;
      }else{
        this.fullGeoAddress ="";
      }
    //  this.ngxService.stopLoader('pv-01');
      return observer.next(true);
    },(error:any)=>{
      //this.ngxService.stopLoader('pv-01');
      return observer.next(true);
    });
  }else if(type =="R"){
    let tableName = 'geo_khasra';
    let dist:any=this.selectedMstDemographyBean?.location?.district;
    if(dist != undefined && dist != null && dist?.clrDistrictId){
      tableName += '_'+((dist.clrDistrictId.toString().length==1)?"0"+dist.clrDistrictId.toString():dist.clrDistrictId.toString());
    }
    let query:string = 'INTERSECTS(geom,%20POINT('+latlng.lng+' '+latlng.lat+'))'
    let url:string=this.queryLayerServices.getEncurlwithQuery(query, tableName);
    //this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3A'+tableName+'&CQL_FILTER=INTERSECTS(geom,%20POINT('+latlng.lng+' '+latlng.lat+'))&maxFeatures=1&outputFormat=application%2Fjson&PropertyName=kid'
    this.queryLayerServices.customgetDecRequest('GET',url, null, null).subscribe((response:any)=>{
      //this.ngxService.stopLoader('loader-identification');
      //this.ngxService.stopLoader('pv-01')
      if(response.body.features.length>0){
        var result = "";            
        let kid = (response?.body?.features[0].properties?.kid != null)?response?.body?.features[0].properties?.kid:"";
        result = (kid != "")?"Khasra id - "+kid+",":"";
       
        if(this.guildeLineObj){
           result += "Village Colony - "+this.guildeLineObj?.demography?.location?.village_colony?.demographyName+",";
           result += "Village - "+this.guildeLineObj?.demography?.location?.village?.demographyName+",";
           result += "Tehsil - "+this.guildeLineObj?.demography?.location?.tehsil?.demographyName+",";
           result += "District - "+this.guildeLineObj?.demography?.location?.district?.demographyName+",";
        }
        this.fullGeoAddress = result;
        this.propertyValuationRequestBean.address = result;
        this.buildingPropertyValuationRequestBean.address=result;
        this.agriPropertyValuationRequestBean.address=result;
        observer.next(result);
      } 
      else{
        observer.next("Not Found");
        //this.alertmessage.errorAlertMessage({responseMessage:'Geometry not found',responseMessageEn:'Geometry not found',responseMessageHi:'ज्यामिति नहीं मिली'});
      }
    },(error:any)=>{
      observer.next("Not Found");
      //this.ngxService.stopLoader('pv-01')
      //this.ngxService.stopLoader('loader-identification');
      this.logger.log("error");
    })
  }
  });
}

getPosition(response:any){
  if(this.selectGuideLineLayer != undefined && this.selectGuideLineLayer != null){
    var l = L.latLng(response.latitude, response.longitude);
     if(this.needToadd_K_P_P){
      if(this.checkMarkerInOut(l, this.getMergeBoundary(this.selectGuideLineLayer.getLayers()))){
        if((this.selectedMstDemographyBean?.demographyTypeId == 15 || this.selectedMstDemographyBean?.demographyTypeId == 14)
          &&(this.selectedMstDemographyBeanTemp?.demographyTypeId == 15 || this.selectedMstDemographyBeanTemp?.demographyTypeId == 14))
        if(this.selectedMstDemographyBean.id == this.selectedMstDemographyBeanTemp.id)
          this.needToaddKhasraAndproperty(l);
        else
          this.addNewDemography({"latlng":l});
        else{
          this.addNewDemography({"latlng":l});
        };
      }else{
        this.alertmessage.errorAlertMessage({responseMessage:'Your search location is outside the selected boudary',responseMessageEn:'Your search location is outside the selected boudary',responseMessageHi:'आपका खोज स्थान चयनित सीमा के बाहर है|'})
      }
    }else{
      this.alertmessage.warnAlertMessage({responseMessage:'First you have to delete the property then you can change the location of your property.',responseMessageEn:'First you have to delete the property then you can change the location of your property.',responseMessageHi:'सबसे पहले आपको संपत्ति को हटाना होगा, फिर आप अपनी संपत्ति का स्थान बदल सकते हैं।'});
    }
  }else{
    this.clearmarker();
    var latlng = L.latLng(response.latitude, response.longitude);
    this.addMarker(latlng);
  }
}

clearmarker(clearAll:boolean=true){
  this.clearMarker(clearAll);
  this.removeGuidelineBoundry();
  this.removePropertyBoundry();
}


clearDataWhenDataNotFound():void{
    this.alertmessage.errorAlertMessage({responseMessage:'You have Click or Search Outsite of Mp State Boundary',responseMessageEn:'You Had Click or Search Outsite of Mp State Boundary',responseMessageHi:'आपने एमपी राज्य की सीमा के बाहर क्लिक या सर्च किया है| '})
    this.alertmessage.errorAlertMessage("property-valuation.click-outsidemap");
    this.isWebGisData=false
    this.isArgiculture=false
    this.isBuilding=false
    this.isPlot=false
    this.selectedMstDemographyBean=null
    this.propertyValuationRequestBean.isConstOnAgriLand=false;
    this.propertyValuationRequestBean.isPlotOnAgriLand=false
    this.drawer.close();
    //setTimeout(()=>{ this.mapcomponent.map.invalidateSize()}, 2000);
    this.mapReset();
}

mapReset():void{
  setTimeout(()=>{ this.map.invalidateSize()}, 1000);
}

clearSearch(){
  this.allFormReset(this.drawer);
  this.searcBox.nativeElement.value='';
  this.islistShow=false;
}
//  Rahule Code
selectSearchType(event:any){
  let value=event.target.value;
  this.logger.log(value)
  if(value=="U"){
    this.filterType="bynameUrbon"
  }else if(value=="R"){
    this.filterType="byname";
    //------------For Googlr Search Restart------------------------
    setTimeout(()=>{
      //this.mapcomponent.googlesearch();
    },500);
    //------------End-----------------------------------------------
  }
}
//  Rahule Code
// tabonchange(){
//   setTimeout(()=>{ this.mapcomponent.map.invalidateSize()}, 300);
// }
uaddpropId:string="1"
getUaddObjectByEnpId(event:any){

  if(event){
  this.id=event
  this.logger.log(event)
  this.selectKhIdAndPrIdAndUnid('propid')
  this.logger.log(event)
  }
  else{
    this.drawer.open()
  }
}
clrPropId:string="1"
getClrObjectBylandParcelId(event:any){
  this.clrPropId="1"
  
   this.clearMarker()
  if(event){
  this.params[0]=event
  this.logger.log(event)
  this.filterType='khasraid'
  this.selectKhIdAndPrIdAndUnid('khasraid')
  this.logger.log(event)
  }
  else{
    //this.drawer.open()
  }
}

//forchip
addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  remove(item: any ,drawer?:any): void {
    const index = this.params.indexOf(item);

    if (index >= 0) {
      this.params.splice(index, 1);
    }
    this.isWebGisData=false
    this.isArgiculture=false
    this.isBuilding=false
    this.isPlot=false
    this.flag = true;
    this.selectedMstDemographyBean=null
    this.webGisResponseData=null;
    this.agriPropertyValuationRequestBean = new AgriPropertyValuationRequestBean();
    drawer.close();
    this.clearmarker();
    //setTimeout(()=>{ this.mapcomponent.map.invalidateSize()}, 2000);
    this.mapReset();
    this.islClearBtnShow =false;
  }
  clickEvent() {
    this.status = !this.status;
    //this.statusLink = !this.statusLink;

    if (this.statusLink) {
      setTimeout(() => {
        this.statusLink = false;
      }, 230);
    } else {
      this.statusLink = true;
    }
  }
//vinita

myButton() {
  this.logger.log("my button was clicked!");
}
  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  flag1:boolean=false;
  leftColtoggle(){
    if(this.flag==false){
      this.flag = true;
      this.flag1=true;
    }else{
      this.flag = false;
      this.flag1=false;
    }

  }

  openverificationMobileDialog(content:any) {
    this.modalService.open(content, {
      size: 'xl',
      centered: true,
      scrollable: true,
      backdrop : 'static',
      keyboard : false
    });
  }

  closeModal() {
    this.dialog.closeAll();
  }

  openPropertyDetailDialog(templateRef: TemplateRef<any>) {
    const dialogRef =  this.dialog.open(templateRef  , {
      maxWidth: '30vw',
      width: '100%',
     });
  }

  openRorDetailDialog(templateRef: TemplateRef<any>) {
    const dialogRef =  this.dialog.open(templateRef  , {
      maxWidth: '30vw',
      width: '100%',
     });
  }

  closeModal2(){
   this.dialog.closeAll()
  }

  getCurrentGuildeLine(demography_id:any){
    this.ngxService.startLoader('loader-propvalCommon');
    this.pvService.getCurrentGLbyDemography(demography_id).subscribe(res => {
        //this.logger.log("guilde Line", res)
        if (res.responseStatus == 'true') {
          this.ngxService.stopLoader('loader-propvalCommon');
          this.guildeLineObj = res.responseData
        }
        else if(res.responseStatus == 'false'){
          //this.alertmessage.errorAlertMessage({responseMessage:'No GuildeLine details available in this location',responseMessageEn:'No GuildeLine details available in this location',responseMessageHi:'इस स्थान पर कोई गाईडलाइन विवरण उपलब्ध नहीं है'})
          this.guildeLineObj = null
          this.ngxService.stopLoader('loader-propvalCommon');
        }
      })
  }


  showPopupPropertyNotFound(propertyOrKhasrsaDetails:any, ids:string[]=[]){
    let html:HTMLElement =document.createElement('div') as HTMLElement;
    let btndiv:HTMLElement =document.createElement('div') as HTMLElement;
    let btndiv2:HTMLElement =document.createElement('div') as HTMLElement;
    let ordiv:HTMLElement =document.createElement('div') as HTMLElement;
    let ordiv2:HTMLElement =document.createElement('div') as HTMLElement;
    let geotagdiv:HTMLElement =document.createElement('div') as HTMLElement;
    let geotag:HTMLInputElement =document.createElement('input') as HTMLInputElement;
    geotag.classList.add('form-control');
    geotag.id="geotaginputbox";
    geotagdiv.appendChild(geotag);
    geotag.placeholder=(this.lan==0)?"Please Enter GeoTag Id":"कृपया जियोटैग आईडी दर्ज करें"
    ordiv.innerText = (this.lan==0)?"OR":"या";
    ordiv2.innerText = (this.lan==0)?"OR":"या";
    btndiv.classList.add("swal-btn-div","mt-2","mb-2");
    let btn:HTMLButtonElement =document.createElement('button') as HTMLButtonElement;
    btn.classList.add('btn','btn-primary','mr-1','geotag-swal-btn');
    let btn2:HTMLButtonElement =document.createElement('button') as HTMLButtonElement;
    btn2.classList.add('btn','btn-primary','ml-1','geotag-swal-btn');
    btndiv2.appendChild(btn);
    btndiv.appendChild(btn2);
    if(this.filterType == "bynameUrbon"){
      btn.innerText=(this.lan==0)?"Identify by Click":"क्लिक से पहचानें";
    }else{
      btn.innerText=(this.lan==0)?"Identify by Address Search OR Click":"पता खोजें या क्लिक से पहचानें";
    }
    btn2.innerText = (this.lan==0)?"Identify by GeoTag Id":"जियोटैग आईडी से पहचानें";
    let inText:HTMLElement =document.createElement('div') as HTMLElement;
    if(propertyOrKhasrsaDetails?.demography?.demographyTypeId == 10 || propertyOrKhasrsaDetails?.demography?.demographyTypeId == 8){
      inText.innerText = `${this.lan==0?"The location of your property/khasra could not be found, Please select village/town colony first and then click on selected colony boundary for select your khasra /property boundary of id:  "+ids.join(','):"आपकी संपत्ति/खसरा का स्थान नहीं मिला, कृपया सबसे पहले आप ग्राम/वर्ड कॉलोनी का चयन करें और फिर अपनी आईडी "+ids.join(',')+" का खसरा/संपत्ति की सीमा का चयन करने के लिए चयनित कॉलोनी सीमा के अन्दर क्लिक करें।"}`;
    }else if(propertyOrKhasrsaDetails?.demography?.demographyTypeId == 15 || propertyOrKhasrsaDetails?.demography?.demographyTypeId == 14){
      inText.innerText = `${this.lan==0?"The location of your property/Khasra could not be found, Please click inside on the seleted colony to identify your property/khasra of id: "+ids.join(','):"आपकी संपत्ति/खसरा का स्थान नहीं मिला, कृपया आप अपनी आईडी "+ids.join(',')+" का खसरा/संपत्ति सीमा चुनने के लिए चयनित कॉलोनी सीमा के अन्दर क्लिक करें।"}`;
    }else{
      inText.innerText = `${this.lan==0?"The location of your property/khasra could not be found, please go to the option below.":" आपकी संपत्ति/खसरा का स्थान नहीं मिला, कृपया नीचे दिए गए विकल्प पर जाएं।"}`;
    }
    html.appendChild(inText);
    html.appendChild(btndiv2);
    html.appendChild(ordiv);
    html.appendChild(geotagdiv);
    html.appendChild(btndiv);
    //html.appendChild(ordiv2);
    Swal.fire({
      icon: 'info',
      html:html,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCancelButton: false
    })
    btn.addEventListener('click',()=>{
      this.filterType = "bynameUrbon";
      Swal.close();
    });
    btn2.addEventListener('click',()=>{
      this.filterType = "searchByGeotag";
      //this.geoTagid = (geotag.value)?geotag.value:"";
      Swal.close();
      if(geotag.value != "" && geotag.value != null){
        this.selectKhIdAndPrIdAndUnid("searchByGeotag");
      }
    });
  }


  addMarker(latlng:any, propertyOrKhasrsaDetails?:any){
    this.logger.log(propertyOrKhasrsaDetails);
    this.removePropertyBoundry();
    this.removeGuidelineBoundry();
    this.clearMarker(false);
    this.isShow =true;
    this.hiddenvalue = "hidden";
    this.resetDemo=false;
    this.islClearBtnShow = true;
    setTimeout(async ()=>{
      if(propertyOrKhasrsaDetails !== "" && propertyOrKhasrsaDetails != undefined){
        this.demographyLoaded = true;
        if(propertyOrKhasrsaDetails?.webGisResponseBeanDataArr?.length && propertyOrKhasrsaDetails?.webGisResponseBeanDataArr?.length>0 && this.filterType == "khasraid"){
          //await this.getDistrictlayer(propertyOrKhasrsaDetails.demography);
          await this.getdemographyGeom(propertyOrKhasrsaDetails.demography);
          let latlngs:any[] = []
          let ulpiids:string[] = [];
          let notIdsOf_k_p_p:string[]=[];
          propertyOrKhasrsaDetails.webGisResponseBeanDataArr.forEach((d:any, no:number)=>{
            if(d.latitude !== undefined && d.longitude !== undefined && d.latitude !== "" && d.longitude !== ""){
              let p_latlng:any = {lat:Number(d.latitude), lng:Number(d.longitude)};
              latlngs.push(p_latlng);
              this.setMarker(p_latlng, true, no);
            }
            if(d.ulpin != "")
              ulpiids.push(d.ulpin);
            else{
              notIdsOf_k_p_p.push(d.landParcelId);
            }
          });
          if(latlngs.length>0){
            this.getAddressByLatLng(latlngs[0]).subscribe(()=>{
              if(propertyOrKhasrsaDetails.demography.demographyType.demographyAreaType == "R" && ulpiids.length>0){
                this.getKhasraBoundry(latlngs, 'geo_khasra',latlngs.length);
              }else if(latlngs.length>0){
                this.getPropertyBoundry_2(latlngs, 'plot_property_vw',latlngs.length)
              }

              if(latlngs.length<propertyOrKhasrsaDetails?.webGisResponseBeanDataArr?.length && notIdsOf_k_p_p){
                this.needToadd_K_P_P=true;
                this.showPopupPropertyNotFound(propertyOrKhasrsaDetails, notIdsOf_k_p_p);
              }
            });
          }else{
            this.needToadd_K_P_P=true;
            this.showPopupPropertyNotFound(propertyOrKhasrsaDetails, notIdsOf_k_p_p);
          }
        }else{
          //await this.getDistrictlayer((propertyOrKhasrsaDetails?.demography)?propertyOrKhasrsaDetails.demography:propertyOrKhasrsaDetails);
          await this.getdemographyGeom((propertyOrKhasrsaDetails?.demography)?propertyOrKhasrsaDetails.demography:propertyOrKhasrsaDetails);
          if(this.bylatlng || this.filterType == "propid"){
            if((propertyOrKhasrsaDetails.latitude && propertyOrKhasrsaDetails.latitude != "") && (propertyOrKhasrsaDetails.longitude && propertyOrKhasrsaDetails.longitude != "") && propertyOrKhasrsaDetails.propertyNo !== undefined){
              this.getAddressByLatLng({lat:Number(propertyOrKhasrsaDetails.latitude), lng:Number(propertyOrKhasrsaDetails.longitude)}).subscribe(()=>{
                this.setMarker({lat:Number(propertyOrKhasrsaDetails.latitude), lng:Number(propertyOrKhasrsaDetails.longitude)}, true);
                if(propertyOrKhasrsaDetails.demography.demographyType.demographyAreaType == "R"){
                  this.getKhasraBoundry([{lat:Number(propertyOrKhasrsaDetails.latitude), lng:Number(propertyOrKhasrsaDetails.longitude)}], 'geo_khasra',1);
                }else{
                  this.getPropertyBoundry_2([{lat:Number(propertyOrKhasrsaDetails.latitude), lng:Number(propertyOrKhasrsaDetails.longitude)}], 'plot_property_vw',1);
                }
              });
            }else if(latlng){
              this.getAddressByLatLng(latlng).subscribe(()=>{
                this.setMarker(latlng, true);
                if(propertyOrKhasrsaDetails.demography.demographyType.demographyAreaType == "R"){
                  this.getKhasraBoundry([latlng], 'geo_khasra',1);
                }else{
                  this.getPropertyBoundry_2([latlng], 'plot_property_vw',1);
                }
              });
            }else{
              this.needToadd_K_P_P=true;
              this.showPopupPropertyNotFound(propertyOrKhasrsaDetails);
            }
          }else if(this.filterType == "panchayatid"){
            if(this.prndData.latitude == undefined || this.prndData.longitude == undefined || this.prndData.latitude == null || this.prndData.longitude == null){
              this.needToadd_K_P_P=true;
              this.showPopupPropertyNotFound({'demography':propertyOrKhasrsaDetails},[this.id]);
            }else{
              this.getAddressByLatLng(latlng).subscribe(()=>{
                this.setMarker(latlng, true);
                this.getKhasraBoundry([latlng], 'geo_khasra',1);
              });
            }
          }
        }
      }else if(latlng != undefined){
        var pt = turf.point([latlng.lng, latlng.lat]);
        if(!turf.booleanPointInPolygon(pt, this.mpBoundaryGeom)){
          //this.dataNotFound.emit();
          this.clearDataWhenDataNotFound()
          return;
        }
        //this.getguideLineurbanBoundry(latlng);
        //this.MapButtonEvent.emit({latlng, demography, "areaType":""});
        this.receivedLatAndLngHandler({latlng, propertyOrKhasrsaDetails, "areaType":""}, this.drawer)
        //this.setMarker(latlng, false);
        // this.map.flyTo(latlng, 14, {
        //     animate: true,
        //     duration: 2
        // });
        //this.setMarker(latlng, false);
        //this.getPropertyBoundry_2([latlng], 'plot_property_vw');
      }

    },300);
  }


  setMarker(latlng:any, dragend:boolean, no:number=0){
    let properties:any;
    if(!Number.isNaN(latlng.lat)  && !Number.isNaN(latlng.lng)){
      let marker =   L.marker(latlng,{
        data:{id:"", no:no},
        zIndexOffset: 0,
        draggable:dragend
      }).addTo(this.map);
      marker.on('click', (e)=>{
        properties=this.popupContent(e.target.options.data?.properties, latlng);
        this.mapPopup.setLatLng(e.latlng).setContent(properties).openOn(this.map);
        this.mapPopup._closeButton.removeAttribute("href");
      });

      if(dragend){
        marker.on('dragend', (event)=>{
          var position = marker.getLatLng();
          if(this.checkMarkerInOut(position, this.getMergeBoundary([this.selectPropertyLayer._layers[marker.options?.data?.id]]))){
            latlng = position;
            if(this.filterType == "khasraid" && this.addedfromSearchIds)
              this.updatedLatLngOnkhasraData(marker.options?.data?.no, latlng);
            else if (this.filterType == "propid" && this.addedfromSearchIds)
              this.updatedLatLngOnPropertyData(latlng)
            else if (this.filterType == "panchayatid" && this.addedfromSearchIds)
              this.updatedLatLngOnPunchayatProperty(latlng);
            else if(this.bylatlng && !this.addedfromSearchIds)
              this.updatedLatLngOnP_and_K(latlng);
          }else{
            marker.setLatLng(latlng);
          }
          //var position = marker.getLatLng();
          //this.receivedLatAndLngHandler({latlng,  "areaType":""}, this.drawer)
          //this.MapButtonEvent.emit({position, undefined, "areaType":""});
          //this.removePropertyBoundry();
          //this.removeGuidelineBoundry();updatedMarkerId
          //this.getguideLineurbanBoundry(position);
          // this.getPropertyBoundry_2([position], 'plot_property_vw');
        });
      }
      this.markers.push(marker);
    }

  }

  popupContent(properties:any, latlng:any):string{
    let returnhtml:string='<table class="table table-bordered"><tbody>';
    if(this.bylatlng){
      if(this.uaddResponseModel != undefined){
        this.uaddpopup.forEach((config)=> {if(properties[config.name]){returnhtml += '<tr><td>'+config.alias+'</td><td>'+properties[config.name]+'</td></tr>'}});
      }else if(this.cLRResponseModel != undefined){
        this.clrpopup.forEach((config)=> {if(properties[config.name]){returnhtml += '<tr><td>'+config.alias+'</td><td>'+properties[config.name]+'</td></tr>'}});
      }
    }else if(this.filterType == "khasraid")
      this.khasrapopup.forEach((config)=> {if(properties[config.name]){returnhtml += '<tr><td>'+config.alias+'</td><td>'+properties[config.name]+'</td></tr>'}})
    else if(this.filterType == "propid"){
      this.propertypopup.forEach((config)=> {if(properties[config.name]){returnhtml += '<tr><td>'+config.alias+'</td><td>'+properties[config.name]+'</td></tr>'}})
    }
    returnhtml += '</tbody></table>';
    returnhtml +="<a href='https://www.google.com/maps/@?api=1&map_action=pano&viewpoint="+latlng.lat+","+latlng.lng+"' class='btn btn-primary btn-go-to-google' target='_blank' style='color:white;'>Go to Street view</a>";
    return returnhtml;
  }
  getPropertyBoundry_2(latlngs:any[], layerName:string, maxfeature:number =10):void{
    //this.ngxService.startLoader('pv-01');
    let query:string ="INTERSECTS(geom,%20POINT("+latlngs[0].lng+" "+latlngs[0].lat+"))";
    if(latlngs.length>1){
      for(let i=1;i<latlngs.length;i++){
        query = query+" OR "+"INTERSECTS(geom,%20POINT("+latlngs[i].lng+" "+latlngs[i].lat+"))";
      }
    }
    let url:string=this.queryLayerServices.getEncurlwithQuery(query, layerName, maxfeature);
    //this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3A'+layerName+'&CQL_FILTER='+query+'&maxFeatures='+maxfeature +'&outputFormat=application%2Fjson'
    this.queryLayerServices.customgetDecRequest('GET',url, null, null).subscribe((response:any)=>{
     // this.ngxService.stopLoader('pv-01');
      if(response?.body.features.length==0){
        this.getKhasraBoundry(latlngs, 'geo_khasra',1);
      }
      else if(response){
        this.addPropertyBoundry(response?.body);
      }else{
        this.alertmessage.errorAlertMessage({responseMessage:'Property Geometry not found',responseMessageEn:'Property Geometry not found',responseMessageHi:'संपत्ति ज्यामिति नहीं मिली'});
      }
    },(error:any)=>{
      this.logger.log("error");
    //  this.ngxService.stopLoader('pv-01');
    })
  }

  getKhasraBoundry_2(ulpinIds:any, layerName:string, maxfeature:number =10):void{
    let dist:any=this.selectedMstDemographyBean?.location?.district;
    if(dist != undefined && dist != null && dist?.clrDistrictId){
      layerName += '_'+((dist.clrDistrictId.toString().length==1)?"0"+dist.clrDistrictId.toString():dist.clrDistrictId.toString());
    }
    //this.ngxService.startLoader('pv-01');
    //let query:string ="INTERSECTS(geom,%20POINT("+latlngs[0].lng+" "+latlngs[0].lat+"))";
    let query:string ="ulpin in ('"+ulpinIds[0]+"'";
    if(ulpinIds.length>1){
      for(let i=1;i<ulpinIds.length;i++){
        //query = query+" OR "+"INTERSECTS(geom,%20POINT("+latlngs[i].lng+" "+latlngs[i].lat+"))";
        query = query+" ,'"+ulpinIds[i]+"'";
      }
    }
    query = query+")";
    let url:string=this.queryLayerServices.getEncurlwithQuery(query, layerName, maxfeature);
    //this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3A'+layerName+'&CQL_FILTER='+query+'&maxFeatures='+maxfeature+'&outputFormat=application%2Fjson'
    this.queryLayerServices.customgetDecRequest('GET',url, null, null).subscribe((response:any)=>{
      //this.ngxService.stopLoader('pv-01');
      if(response.body?.features.length>0){
        this.addPropertyBoundry(response.body);
      }else{
        //alert("Data Not Found");
        this.alertmessage.warnAlertMessage({responseMessage:'GIS map of Khasra or local body registered property could not be obtained at the clicked site.',responseMessageEn:'GIS map of Khasra or local body registered property could not be obtained at the clicked site.',responseMessageHi:'क्लिक किए गए स्थल पर  खसरा या स्थानीय निकाय में पंजीकृत संपत्ति का  जीआईएस नक्शा प्राप्त नहीं हो सका है।'});
      }
    },(error:any)=>{
      //this.ngxService.stopLoader('pv-01');
      this.logger.log("error");
    })
  }

  getKhasraBoundry(latlngs:any, layerName:string, maxfeature:number =10):void{
    let dist:any=this.selectedMstDemographyBean?.location?.district;
    if(dist != undefined && dist != null && dist?.clrDistrictId){
      layerName += '_'+((dist.clrDistrictId.toString().length==1)?"0"+dist.clrDistrictId.toString():dist.clrDistrictId.toString());
    }
    this.ngxService.startLoader('pv-01');
    let query:string ="INTERSECTS(geom,%20POINT("+latlngs[0].lng+" "+latlngs[0].lat+"))";
    if(latlngs.length>1){
      for(let i=1;i<latlngs.length;i++){
        query = query+" OR "+"INTERSECTS(geom,%20POINT("+latlngs[i].lng+" "+latlngs[i].lat+"))";
      }
    }
    //query = query+")";
    let url:string=this.queryLayerServices.getEncurlwithQuery(query, layerName, maxfeature);
    //this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3A'+layerName+'&CQL_FILTER='+query+'&maxFeatures='+maxfeature+'&outputFormat=application%2Fjson'
    this.queryLayerServices.customgetDecRequest('GET',url, null, null).subscribe((response:any)=>{
      this.ngxService.stopLoader('pv-01');
      if(response.body?.features.length>0){
        this.addPropertyBoundry(response.body);
      }else{
        //alert("Data Not Found");
        this.alertmessage.warnAlertMessage({responseMessage:'GIS map of Khasra or local body registered property could not be obtained at the clicked site.',responseMessageEn:'GIS map of Khasra or local body registered property could not be obtained at the clicked site.',responseMessageHi:'क्लिक किए गए स्थल पर  खसरा या स्थानीय निकाय में पंजीकृत संपत्ति का  जीआईएस नक्शा प्राप्त नहीं हो सका है।'});
      }
    },(error:any)=>{
      this.ngxService.stopLoader('pv-01');
      this.logger.log("error");
    })
  }

  // getguideLineurbanBoundry(latlng:any):void{
  //   let query:string ="INTERSECTS(geom,%20POINT("+latlng.lng+" "+latlng.lat+"))";
  //   let url:string=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+':igrs_urban_final&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
  //   this.pvService.customgetRequest('GET',url, null, null).subscribe((response:any)=>{
  //     if(response.features.length==0){
  //       this.getguideLineruralBoundry(latlng);
  //     }
  //     else if(response){
  //       this.addGuidelineBoundary(response)
  //     }else{
  //       alert("Data Not Found");
  //     }
  //   },(error:any)=>{
  //     this.logger.log("error");
  //   })
  // }

  // getguideLineruralBoundry(latlng:any):void{
  //   let query:string ="INTERSECTS(geom,%20POINT("+latlng.lng+" "+latlng.lat+"))";
  //   let url:string=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+':igrs_rural_final&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
  //   this.pvService.customgetRequest('GET',url, null, null).subscribe((response:any)=>{
  //     if(response.features.length==0){
  //       this.zoomToPoint(latlng);
  //     }
  //     else if(response){
  //       this.addGuidelineBoundary(response)
  //     }else{
  //       alert("Data Not Found");
  //     }
  //   },(error:any)=>{
  //     this.logger.log("error");
  //   })
  // }

  addGuidelineBoundary(layer:any, district:boolean=false){
    this.selectGuideLineLayer=L.geoJSON(layer);
    this.selectGuideLineLayer.addTo(this.map);
    if(district)
      this.selectGuideLineLayer.setStyle({color:'yellow',dashArray: '5, 5', dashOffset: '0'});
    else
      this.selectGuideLineLayer.setStyle({color:'#0046FF',dashArray: '5, 5', dashOffset: '0'});
    this.selectGuideLineLayer.setZIndex(51);
    this.map.fitBounds(this.selectGuideLineLayer.getBounds());
    this.mapReset();
    //this.demographyLoaded=true;
  }


  addPropertyBoundry(layer:any):void{
    if(this.selectPropertyLayer == null){
      this.selectPropertyLayer=L.geoJSON(layer);
      this.selectPropertyLayer.addTo(this.map);
      this.selectPropertyLayer.setStyle({color:'#FF1700',dashArray: '5, 5', dashOffset: '0'})
      this.selectPropertyLayer.setZIndex(52);
    }else{
      let lyr = L.geoJSON(layer);
      lyr.setStyle({color:'#FF1700',dashArray: '5, 5', dashOffset: '0'})
      this.selectPropertyLayer.addLayer(lyr.getLayers()[0]);
    }
    this.updatedMarkerId();
  }

  removePropertyBoundry():void{
    if(this.selectPropertyLayer){
      this.map.removeLayer(this.selectPropertyLayer);
      this.selectPropertyLayer=null;
    }
  }

  removeGuidelineBoundry():void{
    if(this.selectGuideLineLayer){
      this.map.removeLayer(this.selectGuideLineLayer);
      this.selectGuideLineLayer=null;
    }
  }

  zoomToPoint(latlng:any){
    this.map.flyTo([latlng.lat, latlng.lng], 18, {
      animate: true,
      duration: 1
    });
  }

  async getdemographyGeom(e:any, forOtherLayer:boolean=true){
    try{
      let url=this.queryLayerServices.getBoundryURLsEncfinal(e);
      if(url != ''){
        this.ngxService.startLoader('loader-propvalCommon');
        var response:any = await this.queryLayerServices.customgetDecRequest('GET',url, null, null).toPromise();//=>{
        if(response && response.body?.features.length>0){
          this.ngxService.stopLoader('loader-propvalCommon');
          this.addGuidelineBoundary(response.body);
          return true;
        }else{
          //this.alertmessage.errorAlertMessage("property-valuation.");
          if(forOtherLayer){
            let url=this.queryLayerServices.getBoundryURLsEncfinal(e.location.district);
            var response:any = await this.queryLayerServices.customgetDecRequest('GET',url, null, null).toPromise();//=>{
              if(response && response.body?.features.length>0){
                this.ngxService.stopLoader('loader-propvalCommon');
                this.addGuidelineBoundary(response?.body, true);
                return true;
              }else{
                this.ngxService.stopLoader('loader-propvalCommon');
                return true;
              }
          }else{
            this.ngxService.stopLoader('loader-propvalCommon');
            return true;
          }          
        }
        
      }else{
        this.logger.log('URL Not Configuried');
        return true;
      }
    }catch(error:any){
      this.ngxService.stopLoader('loader-propvalCommon');
      this.logger.log('data not found');
      return true;
    }

  }
  updatedMarkerId(){
    for(let layer in this.selectPropertyLayer._layers){
      this.markers.forEach((marker:L.Marker)=>{
        if(this.checkMarkerInOut(marker.getLatLng(), this.selectPropertyLayer._layers[layer].feature.geometry)){
          marker.options.data.id = layer;
          if(this.bylatlng){
            if(this.uaddResponseModel != undefined){
              marker.options.data["properties"]=this.uaddResponseModel[0];
            }else if(this.cLRResponseModel != undefined){
              marker.options.data["properties"]=this.cLRResponseModel;
            }
          }else if(this.fillterType2 == "khasraid"){
            marker.options.data["properties"]=this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[marker.options.data.no];
            if(this.khasraColor[marker.options.data.no])
              this.selectPropertyLayer._layers[layer].setStyle({color:this.khasraColor[marker.options.data.no].gc,fillColor:this.khasraColor[marker.options.data.no].gc,dashArray: '5, 5', dashOffset: '0'});
          }else if(this.fillterType2 == "propid")
            marker.options.data["properties"]=this.mpenagarpalikamodel;
        }
      })
    }
  }

  // getBoundryURLs(obj:any){
  //   let query:string;
  //   let url:string='';
  //   let D:any=obj.demographyType;
  //   switch(obj.demographyTypeId){
  //     case 2:
  //         query='div_cd='+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Ampdivision&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson';
  //       break;
  //     case 3:
  //         query='dist_cd='+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Ampdistrict&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
  //       break;
  //     case 4:
  //         query = 'sr_off_cd='+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Asro_boundary&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson';
  //       break;
  //     case 7:
  //         query="lgd_ulb_cd="+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
  //       break;
  //     case 8:
  //         query="ward_cd="+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Award_igrs&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson';
  //       break;
  //     case 12:
  //         query="teh_cd='"+obj.lgdCode+"'";
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
  //       break;
  //     case 10:
  //         query="vil_cd="+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Avillage_igrs&maxFeatures=1&CQL_FILTER='+query+'&outputFormat=application%2Fjson';
  //       break;
  //     case 14:
  //         query="col_id='"+obj.colonyId+"'";
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson';
  //       break;
  //     case 15:
  //         query="col_id='"+obj.colonyId+"'";
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson';
  //       break;
  //   }
  //    return url;
  // }

  checkMarkerInOut(latlng:any, boundary:any):boolean{
    var pt = turf.point([latlng.lng, latlng.lat]);
    if(turf.booleanPointInPolygon(pt, boundary)){
      return true;
    }else{
      return false;
    }
  }

  getMergeBoundary(layers:any[]):any{
    var returnGeom = layers[0].feature.geometry;//turf.union(poly1, poly2);
    for(let i=1 ;i<layers.length;i++){
      returnGeom = turf.union(returnGeom, layers[i].feature.geometry);
    }
    return returnGeom;
  }

  public clearMarker(clearAll:boolean=true) {
    this.markers.forEach((marker: any) => {
      this.map.removeLayer(marker)
    });
    this.markers = [];
    this.map.closePopup();
    if(clearAll){
      this.demographyLoaded = false;
      this.needToadd_K_P_P=false;
      this.addedfromSearchIds=false;
      this.bylatlng=false;
    }
  }



  openPrimary(content:any) {
    this.modalService.open(content, {
      windowClass:"modalNew",
      size: 'auto',
      centered: true
    });
  }

  private registerDragElement() {
    const elmnt = document.getElementById('k-dragablediv');

    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

    const dragMouseDown = (e:any) => {
      e = e || window.event;
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    };

    const elementDrag = (e:any) => {
      e = e || window.event;
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
      elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
    };

    const closeDragElement = () => {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    };

    if (document.getElementById(elmnt.id + 'header')) {
      /* if present, the header is where you move the DIV from:*/
      document.getElementById(elmnt.id + 'header').onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
    }
  }

  removeAddedKhasra(data:WebGisResponseBeanData,i:number){
    //console.log(data, i);
    this.markers.forEach((marker:L.Marker, n:number)=>{
      for(let layer in this.selectPropertyLayer?._layers){
        if(marker.options.data.no == i && this.checkMarkerInOut(marker.getLatLng(), this.selectPropertyLayer._layers[layer].feature.geometry)){
          this.selectPropertyLayer.removeLayer(this.selectPropertyLayer._layers[layer]);
        }
      }
      if(marker.options.data.no == i){
        let latlng:any={lat:'',lng:''};
        this.updatedLatLngOnkhasraData(marker.options.data.no, latlng);
        marker.remove();
        this.markers.splice(n, 1);
        this.needToadd_K_P_P=true;
      }
    });
  }

  removeAddedPunchayatProperty(){
    this.clearMarker(false);
    this.removePropertyBoundry();
    this.needToadd_K_P_P=true;
    let latlng:any={lat:'',lng:''};
    this.updatedLatLngOnPunchayatProperty(latlng);
  }

  removeAddedProperty(){
    this.clearMarker(false);
    this.removePropertyBoundry();
    this.needToadd_K_P_P=true;
    let latlng:any={lat:'',lng:''};
    this.updatedLatLngOnPropertyData(latlng);
  }


// 
  resetDemo: boolean = true;
  isShow = true;
  hiddenvalue: string = "hidden";
  // toggleStatus(rmGu:boolean=true) {
  //   this.isShow = !this.isShow;
  //   if (this.isShow) {
  //     this.hiddenvalue = "hidden";
  //     this.resetDemo=false;
  //   } else {
  //     this.hiddenvalue = "";
  //     this.resetDemo=true;
  //     this.allFormReset(this.drawer);
  //   }
  // }

  toggleStatus(rmGu:boolean=true) {
    this.isShow = !this.isShow;
    if (this.isShow) {
      this.hidehirarchy();
    } else {
      this.hiddenvalue = "";
      this.resetDemo=true;
      if(this.selectedMstDemographyBean){
        if(this.needToadd_K_P_P){
          this.type_id = 4;
          this.parent_id = this.selectedMstDemographyBean.location.district.id;    
        }else{
            this.hidehirarchy();
            this.alertmessage.warnAlertMessage({responseMessage:'First you have to delete the property then you can change the location of your property.',responseMessageEn:'First you have to delete the property then you can change the location of your property.',responseMessageHi:'सबसे पहले आपको संपत्ति को हटाना होगा, फिर आप अपनी संपत्ति का स्थान बदल सकते हैं।'});
        }  
      }else{
        this.type_id = 3;
        this.parent_id =1;
      }
      //this.allFormReset1(this.drawer);
    }

 
  }

  hidehirarchy(){
    this.hiddenvalue = "hidden";
    this.resetDemo=false;
    this.isShow = true; 
  }


  getDemography(demography:MstDemographyBean){
    this.removeGuidelineBoundry();
    this.selectedMstDemographyBeanTemp=null;
    if(demography.demographyTypeId == 14 || demography.demographyTypeId == 15){
      this.selectedMstDemographyBeanTemp = demography
    }
    this.getdemographyGeom(demography, false);
  }





  // openParcelAsBuildingDialog(content:any) {
  //   this.modalService.open(content, {
  //     windowClass:"modalNew",
  //     size: 'md',
  //     centered: true
  //   });
  // }

}