<lib-alertmessage></lib-alertmessage>


<div class="card">
    <!-- <h3 class="card-title">{{'citizen-registration-details.kyc-detail'|translate}}</h3> -->
    <div class="card-body">
        <div class="row no-gutter" id="kycdiv" *ngIf="divhidekyc">
            <div class="col-md-4 d-none">
                <div class="aadhareKYCbg">

                </div>
            </div>
            <div class="col-md-12">
                <p class=" ">{{'kyc-details.guideline-a'|translate}}</p>
                <p class=" ">{{'kyc-details.guideline-b'|translate}}</p>
                <div class="p-3 pt-4" style="background: #1515160a;">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="input-group mb-3 ">
                                <label class="w-100">{{'kyc-details.aadhar-number'|translate}}</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> UID </span>
                                </div>
                                <input [type]="passhide ? 'password' : 'text'" class="form-control" [(ngModel)]="uid"
                                    [OnlyNumber]="true" maxlength="12" minlength="12"
                                    placeholder="{{'kyc-details.enter-aadhar-number'|translate}}"
                                    *ngIf="(data.ekycStatus==undefined || data.ekycStatus=='0') && this.aadharMasked == undefined">

                                <div class="input-group-prepend showPassword"
                                    *ngIf="(data.ekycStatus==undefined || data.ekycStatus=='0') && this.aadharMasked == undefined">
                                    <button type="button" class=" " (click)="passhide = !passhide"
                                        [attr.aria-label]="'Hide password'"
                                        [attr.aria-pressed]="passhide"><mat-icon>{{passhide ? 'visibility_off' :
                                            'visibility'}}</mat-icon></button>
                                </div>

                                <input type="text" class="form-control" [(ngModel)]="uid" [OnlyNumber]="true"
                                    maxlength="12" minlength="12"
                                    placeholder="{{'kyc-details.enter-aadhar-number'|translate}}"
                                    [disabled]="data.ekycStatus=='1' || this.aadharMasked != undefined && (this.citizenType != undefined && this.citizenType == 1)"
                                    *ngIf="data.ekycStatus=='1' || this.aadharMasked != undefined">

                            </div>
                        </div>
                        <div id="or" class="d-none or-d-sm">&nbsp;&nbsp;{{'kyc-details.or' | translate}}&nbsp;&nbsp;
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="input-group mb-3 vidInput">
                                <label
                                    class="w-100">{{'kyc-details.virtual-id-number'|translate}}&nbsp;&nbsp;&nbsp;&nbsp;

                                </label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> VID </span>
                                </div>
                                <input type="text" class="form-control"
                                    placeholder="{{'kyc-details.enter-virtual-id-number'|translate}}" [(ngModel)]="vid"
                                    [OnlyNumber]="true" maxlength="16" minlength="16"
                                    [disabled]="data.ekycStatus=='1' && this.citizenType != undefined && this.citizenType == 1">
                                <a class="text-right d-block w-100" target="_blank"
                                    href="https://myaadhaar.uidai.gov.in/genericGenerateOrRetriveVID"
                                    title="{{'kyc-details.click-generate-virtual-id'|translate}}"><span
                                        style="font-size: small;"><i
                                            class="fa fa-info"></i>&nbsp;&nbsp;{{'kyc-details.click-generate-virtual-id'|translate}}</span></a>
                            </div>

                        </div>

                        <!-- <div class="col-lg-2 col-md-6">
                            <label class=" dnone-sm">&nbsp;</label>
                            <button mat-raised-button color="primary">Get OTP</button>
                        </div> -->
                    </div>

                    <div class="text-center">
                        <label class="mx-2">
                            <input type="radio" [value]="11" [(ngModel)]="radio" name="radio" checked
                                (click)="setAdharKycMethod('otp')" />
                            {{'otp'|translate}}
                        </label>
                        <label class="mx-2">
                            <input type="radio" [value]="22" [(ngModel)]="radio" name="radio"
                                (click)="setAdharKycMethod('biot')" />
                            {{'kyc-details.bio-thumb'|translate}}
                        </label>
                        <label class="mx-2">
                            <input type="radio" [value]="33" [(ngModel)]="radio" name="radio"
                                (click)="setAdharKycMethod('bioi')" />
                            {{'kyc-details.bio-iris'|translate}}
                        </label>
                    </div>

                    <div class="row mb-3"
                        style="align-items: center;text-align: justify;background: #f1f3f6; margin: 0;border: 1px solid #cfd8dc;">
                        <div class="col-lg-8 pt-3 pb-3 bg-white">
                            <div class="form-group form-check mt-4">
                                <input type="checkbox" class="form-check-input" [(ngModel)]="consentcheck1"
                                    (change)='onCheckboxChange($event)' id="exampleCheck1">
                                <label class="form-check-label font-weight-normal" for="exampleCheck1">
                                    {{'kyc-details.consent-a'|translate}}
                                </label>
                                <ol>
                                    <li>{{'kyc-details.consent-a-a'|translate}} </li>
                                    <li>{{'kyc-details.consent-a-b'|translate}}</li>
                                    <li>{{'kyc-details.consent-a-c'|translate}}</li>
                                </ol>
                            </div>
                            <div class="form-group form-check mt-4">
                                <input type="checkbox" class="form-check-input" [(ngModel)]="consentcheck2"
                                    (change)='onCheckboxChange($event)' id="exampleCheck2">
                                <label class="form-check-label font-weight-normal" for="exampleCheck2">
                                    {{'kyc-details.consent-b'|translate}}
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="d-flex ">
                                <img src="assets/images/ekyc.png" class="w-100">
                            </div>
                        </div>
                    </div>

                    <div class=" " *ngIf="radio==11">
                        <span *ngIf="counter != 0">
                            <span style="font-weight: 400; font-size: 13px;">
                                {{'regenerate_otp' | translate}} 
                            </span>  &nbsp;
                            {{counter | formatTime}}</span>
                        <button *ngIf="counter == 0" mat-flat-button class="btn btn-primary"
                            (click)="getAdharOtp()">{{'kyc-details.generate-otp'|translate}}</button>

                        <div class="input-group mt-3  mb-3" *ngIf="otpGenerated">
                            <label class="w-100">{{'enter-otp'|translate}}</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text"> {{'otp'|translate}} </span>
                            </div>
                            <input type="text" class="form-control" [(ngModel)]="otpaadhar"
                                placeholder="{{'enter-otp'|translate}}" aria-label="" aria-describedby="basic-addon2"
                                minlength="6" maxlength="6" [OnlyNumber]="true">
                            <div class="input-group-append">
                                <button mat-flat-button color="primary" type="button"
                                    (click)="verifyAdharOtp()">{{'verify-otp'|translate}}</button>
                            </div>
                             <p class="w-100 mt-1" style="font-weight: 400; font-size: 13px; text-align: right;">( {{'otpValid'| translate}} )</p>
                        </div>
                    </div>
                    <div class=" " *ngIf="radio==22">
                        <div class="input-group mt-3  mb-3">
                            <label class="w-100">{{'kyc-details.select-thumb'|translate}}</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text"><img src="assets/images/thumb-scan.png" height="35">
                                    {{'kyc-details.thumb-device'|translate}} </span>
                            </div>
                            <select style="width: 50%;" #devicecode (change)="deviceCheck(devicecode.value)">
                                <option value="">Select Device Type</option>
                                <option value="mantra-fms100">MANTRA FMS100</option>
                                <option value="rd-fm220u">RD FM220U</option>
                                <option value="mantra-mfs110">MANTRA mfs110</option>
                                <!--<option value="morpho-1300e3">MORPHO 1300E3</option>-->
                            </select>
                            <div class="input-group-append">
                                <button mat-flat-button color="primary" *ngIf="scanBtn"
                                    (click)="scanDevice()">{{'kyc-details.scan'|translate}}</button>
                            </div>
                        </div>
                    </div>
                    <div class=" " *ngIf="radio==33">
                        <div class="input-group mt-3  mb-3">
                            <label class="w-100">{{'kyc-details.select-iris'|translate}}</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text"><img src="assets/images/iris-scan.png" height="35">
                                    {{'kyc-details.iris-device'|translate}} </span>
                            </div>
                            <select style="width: 50%;" #devicecodeType (change)="deviceCheck(devicecodeType.value)">
                                <option value="">Select Device Type</option>
                                <option value="iritech">IRITECH</option>
                                <option value="mantraIrisMIS100v2">MantraIrisMIS100v2</option>
                            </select>
                            <div class="input-group-append">
                                <button mat-flat-button color="primary" *ngIf="scanBtn"
                                    (click)="scanDevice()">{{'kyc-details.scan'|translate}}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class=" " id="otherkycdiv" *ngIf="divhideotherkyc">

            <div class=" ">

                <div class="row mb-3"
                    style="align-items: center; text-align: justify; background: #1515160a; margin: 0;border: 1px solid #cfd8dc;">
                    <div class="col-lg-8 pt-3 pb-3 bg-white">

                        <div id="radio-pan-select">
                            <div class="form-group">
                                <label>{{'kyc-details.pan-number'|translate}}<sup
                                    class="text-danger">*</sup></label>
                                <input [disabled]="disabledPanFullName" type="text" class="form-control"
                                    (contextmenu)="false;" placeholder="{{'kyc-details.enter-pan-number'|translate}}"
                                    [(ngModel)]="pannumber" (change)="changeFocusFromPan()" maxlength="10"
                                    oninput="this.value = this.value.toUpperCase()" AlphaNumeric>
                            </div>
                            <div class="form-group">
                                <!-- <label>DOB</label>
                                <input [disabled]="disabledPanFullName" type="text" class="form-control"
                                    (contextmenu)="false;" placeholder="enter dob"
                                    [(ngModel)]="panDob" (change)="changeFocusFromPan()" maxlength="10"> -->

                                    <div class="form-group">
                                        <label>{{'kyc-details.pan-dob'|translate}}<sup
                                            class="text-danger">*</sup></label>
                                        <input type="date" class="form-control" [disabled]="disabledPanFullName" placeholder="dd/mm/yyyy"
                                        [(ngModel)]="panDob" (change)="changeFocusFromPan()">
                                    </div>
                            </div>

                            <div class="form-group">
                                <label>{{'kyc-details.pan-full-name'|translate}}<sup
                                    class="text-danger">*</sup></label>
                                <input [disabled]="disabledPanFullName" type="text" class="form-control"
                                    (contextmenu)="false;" placeholder="Enter Full Name As Per PAN"
                                    [(ngModel)]="panFullName" (change)="changeFocusFromPan()" maxlength="100"
                                    oninput="this.value = this.value.toUpperCase()">
                            </div>
                        </div>
                        <!-- <div class="form-group">
                                <label>{{'kyc-details.mobile-number'|translate}}</label>
                                <input class="form-control" [(ngModel)]="mobileNo"  placeholder="{{'kyc-details.enter-mobile-number'|translate}}">
                            </div> -->
                        <!-- <div class="form-group">
                            <label>{{'kyc-details.full-name'|translate}}</label>
                            <input disabled="disabledPanFullName" class="form-control" [(ngModel)]="fullName"
                                placeholder="{{'kyc-details.enter-full-name'|translate}}">
                        </div> -->
                        <!-- <div class="form-group">
                                <label>{{'kyc-details.dob'|translate}}</label>
                                <input type="date" class="form-control" placeholder="dd/mm/yyyy">
                            </div>  -->
                        <!-- <div class="form-group" *ngIf="showPanSubmit"> -->
                        <div class=" ">
                            <button [disabled]="!showPanSubmit" class="btn btn-primary mr-1" type="button"
                                (click)="onSubmitPanDetails();">{{'submit'|translate}}</button>

                            <button [disabled]="!showPanSubmit" class="btn btn-secondary mr-1" type="button"
                                (click)="onResetDetails();">{{'reset'|translate}}</button>

                            <!-- <button class="btn btn-primary" type="button"
                                (click)="validatePanData();">{{'verify_details'|translate}}</button> -->
                                <button class="btn btn-primary" type="button"
                                (click)="validateProteanPanData();">{{'verify_details'|translate}}</button>
                        </div>
                        <!-- </div>  -->
                    </div>
                    <div class="col-lg-4">
                        <div class="d-flex  ">
                            <img src="assets/images/ekyc.png" class="w-100">
                        </div>
                    </div>
                </div>


            </div>
        </div>

        <div class="row mt-3 text-right">
            <div class="col-12">
                <button class="mx-2" *ngIf="byEstamp" mat-raised-button type="button " class="btn btn-secondary"
                    (click)="closeKycModel()" style="margin-right: 1%;">{{'close-kyc'|translate}}</button>
                <button mat-raised-button color="accent" class="bg-info d-none" type="button"
                    value="prefer aadhaar e-kyc"
                    style="text-align: center;">{{'kyc-details.prefer-aadhar'|translate}}</button>


                <button mat-raised-button class="btn btn-primary" type="button" value="prefer aadhaar e-kyc"
                    (click)="visibleDiv('kyc');" style="text-align: center;"
                    *ngIf="divhideotherkyc">{{'kyc-details.prefer-aadhar'|translate}}</button>

                <button mat-raised-button class="btn btn-primary" type="button" value="skip aadhaar e-kyc"
                    (click)="visibleDiv('otherkyc');" style="text-align: center;"
                    *ngIf="divhidekyc && userType !='department'">{{'kyc-details.skip-aadhar'|translate}}</button>

                <button class="mx-2" *ngIf="!byEstamp" mat-raised-button type="button"
                    (click)="skipPan()">{{'skip-pan'|translate}}</button>
            </div>
            <!-- <div class="col-6 text-right">
            <button mat-raised-button type="submit" name = "KycDetailSave" color="primary" >
                Submit
            </button>
        </div> -->

        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
<!-- <button mat-button matStepperNext>Next</button> -->