<div class="position-relative">
  <div class="fid-header"></div>
  <div class="breadcrumb-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <h2 class="mb-0">
                {{ lan == 0 ? "Frequently Asked Questions (FAQs)" : "अक्सर पूछे जाने वाले प्रश्न" }}
              </h2>
              <span class="mx-1">-</span>
              <!-- Breadcrumb Navigation -->
              <nav *ngIf="breadcrumb.length > 0" class="breadcrumb-inline">
                <ol class="breadcrumb d-flex align-items-center mb-0">
                  <li *ngFor="let item of breadcrumb; let last = last" class="breadcrumb-item">
                    <a *ngIf="!last" class="breadcrumb-item-text">
                      {{ lan == 0 ? item.moduleNameEn : item.moduleNameHi }}
                    </a>
                    <span *ngIf="last" class="breadcrumb-item-text">
                      {{ lan == 0 ? item.moduleNameEn : item.moduleNameHi }}
                    </span>
                  </li>
                </ol>
              </nav>
            </div>
            <!-- Custom Back Button aligned to the right -->
            <button *ngIf="breadcrumb.length > 0" (click)="onBack()" class="btn btn-primary mt-1 ms-auto">
              {{ lan == 0 ? "Go to main menu" : "मुख्य मेनू पर जाएँ" }}
            </button>
          </div>
        </div>
      </div>           

      <!-- Module Cards -->
      <div class="container mt-3"  >
        <mat-card class="example-card">
          <mat-card-content class="mat-card-content">
            <div class="services_chip d-flex flex-wrap align-items-center justify-content-center">
              <a *ngFor="let module of currentModules; let i = index" class="d-flex align-items-center module-card"
                (click)="onModuleClick(module)">
                <!-- Icon Container with Dynamic Background -->
                <span class="material-symbols-outlined icon_round icon_{{ (i % 8) + 1 }}">
                  <mat-icon>{{ module.iconName }}</mat-icon>
                </span>
                <div class="application-content-inner">
                  <strong class="module-name">{{ lan == 0 ? module.moduleNameEn : module.moduleNameHi }}</strong>
                  <mat-icon class="mtIcon">chevron_right</mat-icon>
                </div>
              </a>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>