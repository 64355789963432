<!-- <button class="colsebtn close-modal" (click)="closeModal()">
            <mat-icon>close</mat-icon>
        </button> -->
<button mat-button matDialogClose color="primary" class="colsebtn" (click)="closeModal()">
    <em class="fa fa-times"></em></button>
<div class="auth-main">

    <div class="container">



        <div class="row">
            <div class="col-xl-12">
                <lib-alertmessage></lib-alertmessage>

                <div>
                    <h2 class="loginTitle" *ngIf="this.userType == 3">{{'citizen-registration-details.sign-up-as' |
                        translate}}</h2>
                    <h2 class="loginTitle" *ngIf="this.userType == 1">{{'citizen-registration-details.sign-up-as' |
                        translate}}</h2>
                    <h2 class="loginTitle" *ngIf="this.userType == 2">
                        {{'citizen-registration-details.sign-up-as-gov-offical' | translate}}</h2>

                    <div *ngIf="this.userType == 3 || this.userType == 1" class="selectCityzenType mb-3">
                        <div class="row">
                            <div class="col-lg-3 col-md-4 col-sm-6">
                                <label>
                                    <input type="radio" [value]="1" [(ngModel)]="isIndian" name="radioCT"
                                        (click)="setNationality(1)" />
                                    <div class="citizentypedetls">
                                        <img src="./assets/images/indian-citizen.png">
                                        <div class="ctzntpttl">
                                            {{'citizen-registration-details.indian-citizen'|translate}}
                                        </div>

                                    </div>

                                </label>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-6">
                                <label>
                                    <input type="radio" [value]="0" [(ngModel)]="isIndian" name="radioCT"
                                        (click)="setNationality(0)" />
                                    <div class="citizentypedetls">
                                        <img src="./assets/images/nri-person.png">
                                        <div class="ctzntpttl">
                                            {{'citizen-registration-details.non-indian-citizen'|translate}}
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
                </div>


                <div *ngIf="step0">
                    <div class="registrationSteps" *ngIf="isIndian==1">
                        <div class="steps active">
                            <div class="stepBox ">
                                <div class="d-flex  align-items-center">
                                    <i class="stepIcon fa fa-mobile"></i>
                                    <p>{{'mobile-verification' | translate}}</p>
                                </div>
                                <i class="fa fa-caret-right stepforword"></i>
                            </div>

                        </div>

                        <div class="steps mx-3" *ngIf="step1clear" (click)="gotostep12()">
                            <div class="stepBox">
                                <div class="d-flex  align-items-center">
                                    <i class="stepIcon fa fa-user-circle-o"></i>
                                    <p> {{'kyc-detail' | translate}}</p>
                                </div>
                            </div>
                            <i class="fa fa-caret-right stepforword"></i>
                        </div>
                        <div class="steps mx-3" *ngIf="!step1clear">
                            <div class="stepBox">
                                <div class="d-flex  align-items-center">
                                    <i class="stepIcon fa fa-user-circle-o"></i>
                                    <p> {{'kyc-detail' | translate}}</p>
                                </div>
                            </div>
                            <i class="fa fa-caret-right stepforword"></i>
                        </div>

                        <div class="steps">
                            <div class="stepBox">
                                <div class="d-flex  align-items-center">
                                    <i class="stepIcon fa fa-id-card-o"></i>
                                    <p> {{'demographic-details'|translate}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="registrationSteps" *ngIf="isIndian==0">
                        <div class="steps active mr-3">
                            <div class="stepBox">
                                <div class="d-flex  align-items-center">
                                    <i class="stepIcon fa fa-address-card-o"></i>
                                    <p> {{'passport-details'|translate}}</p>
                                </div>
                            </div>
                            <i class="fa fa-caret-right stepforword"></i>

                        </div>

                        <div class="steps" *ngIf="step21clear" (click)="gotostep22()">
                            <div class="stepBox">
                                <div class="d-flex  align-items-center">
                                    <i class="stepIcon fa fa-id-card-o"></i>
                                    <p> {{'demographic-details'|translate}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="steps" *ngIf="!step21clear">
                            <div class="stepBox">
                                <div class="d-flex  align-items-center">
                                    <i class="stepIcon fa fa-id-card-o"></i>
                                    <p> {{'demographic-details'|translate}}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="card bg-transparent" *ngIf="isIndian==1 && step11">
                    <div class="card-body p-0 pl-3 pr-3">

                        <div class="row pt-2 bg-white"
                            *ngIf="eRegPartyModel.applicantType=='1' && eRegPartyModel.minorAllowed && !eRegPartyModel.transactingThroughPOA">
                            <div class="col-md-auto d-flex">
                                <label class="mr-3 pl-5">Is {{eRegPartyModel.partyTypeNameEn}}
                                    {{'proIdentification.minor' | translate}}?</label>

                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                        id="inlineRadio1" [value]="1" [(ngModel)]="eRegPartyModel.isMinor">
                                    <label class="form-check-label" for="inlineRadio1">{{'duty-calculation.Yes' |
                                        translate}}</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                        id="inlineRadio2" [value]="0" [(ngModel)]="eRegPartyModel.isMinor">
                                    <label class="form-check-label" for="inlineRadio2">{{'duty-calculation.No' |
                                        translate}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="row">

                            <div
                                class="col-xl-6 col-lg-7 col-md-7 col-xs-12 col-sm-12  logInForms bg-white radius4 pt-2 pd-5">

                                <form (ngSubmit)="verifyOtp()">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group mb-3">
                                                <label class="w-100">{{eRegPartyModel.isMinor!=1 ?
                                                    ((eRegPartyModel.applicantType=='1' ||
                                                    eRegPartyModel.applicantType==undefined) ? ('enter-mobile-number' |
                                                    translate) : ('enter-authorized-person-mobile-number' | translate))
                                                    : ('enter-guardian-mobile-number' | translate)}} <span
                                                        style="color: red;">*</span></label>
                                                <div class="input-group ">
                                                    <div class="input-group-append"> <span class="input-group-text"><i
                                                                class="fa fa-phone"></i></span> </div>
                                                    <input type="text" name="mobileNo" [(ngModel)]="mobileNo"
                                                        autocomplete="off" [OnlyNumber]="true" maxlength="10"
                                                        minlength="10" required class="form-control input_user"
                                                        pattern="^[6-9]\d{9}$"
                                                        placeholder="{{eRegPartyModel.isMinor!=1 ? ((eRegPartyModel.applicantType=='1' || eRegPartyModel.applicantType==undefined) ? ('enter-mobile-number' | translate) : ('enter-authorized-person-mobile-number' | translate)) : ('enter-guardian-mobile-number' | translate)}}">
                                                </div>
                                            </div>




                                            <!--  (change)="changeContactNo()" -->

                                            <!-- <div class="input-group mb-3" *ngIf="isUser">
                                                <div class="input-group-append"> <span class="input-group-text bg-primary"><i class="fa fa-globe"></i></span> </div>
                                                <select class="form-control" name="selectedUser" [(ngModel)]="selectedUser">
                                                    <option value="" selected="selected">{{'select-user' | translate}}</option>
                                                    <option *ngFor="let user of userList" [value]="user.id">
                                                        {{ lan == 0 ? user.userName + ' [' + user.firstNameEn + ' ' + user.lastNameEn + ']': user.userName + ' [' + user.firstNameHi + ' ' + user.lastNameHi + ']' }}
                                                    </option>
                                                </select>
                                            </div> -->

                                        </div>

                                    </div>
                                    <div class="row align-items-center mt-2 mb-2">
                                        <div class="col-5">
                                            <div class="input-group">
                                                <img src="{{captchaImageBase64Img}}"  height="40"/>
                                                <!-- <button (click)="getStartupCaptcha()">REFRESH CAPTCHA</button> -->
                                            </div>
                                        </div>
                                        <div class="col-2 text-center">
                                            <img src="assets/images/refresh_image.png" height="26px"
                                                (click)="getStartupCaptcha()" />
                                        </div>
                                        <div class="col-5">
                                            <div class="input-group">
                                                <input type="text" name="captchaStr" [(ngModel)]="captchaStr" required
                                                    minlength="4" maxlength="4" class="form-control input_user "
                                                    placeholder="{{'enter-captcha' | translate}}" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-end">
                                        <!-- <div class="col-6 hidden-xs"></div> -->
                                        <div class="col-md-6 col-sm-6">
                                            <label class="w-100">&nbsp;</label>

                                            <a mat-raised-button *ngIf="counter == 0 && !otpTouched" mat-button
                                                class="btn btn-primary w-100" (click)="setOtpClicked()"
                                                (keyup.enter)="setOtpClicked()">{{'get-otp' |
                                                translate}}</a>

                                            <a *ngIf="counter == 0 && otpTouched" mat-button
                                                class="btn btn-sm btn-secondary w-100" (click)="setOtpClicked()"
                                                (keyup.enter)="setOtpClicked()">{{'resend-otp' | translate}}</a>

                                        </div>
                                    </div>
                                    <div class="p-3 mt-3" style="background-color: #0000000a!important;"
                                        *ngIf="getOtpClicked">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="input-group mb-3">
                                                    <label
                                                        class="w-100 d-flex align-items-center justify-content-between"><span
                                                            class="float-left">{{'enter-otp' | translate}}</span>

                                                        <span  *ngIf="counter != 0">
                                                            <span  *ngIf="counter != 0" style="font-weight: 400; font-size: 13px;">
                                                                {{'regenerate_otp' | translate}} 
                                                            </span> &nbsp;
                                                            {{counter |
                                                            formatTime}}</span>

                                                    </label>
                                                    <input class="form-control input_user" name="otp" [(ngModel)]="otp"
                                                        minlength="6" maxlength="6" required [OnlyNumber]="true"
                                                        autocomplete="off">
                                                         <p class="w-100 mt-1" style="font-weight: 400; font-size: 13px; text-align: right;">( {{'otpValid'| translate}} )</p>
                                                </div>
                                            </div>

                                            <!-- <div class="col-md-4">
                                                    <label class="w-100">&nbsp;</label>
                                                    <button class="w-100 btn login_btn"  >Submit OTP</button>
                                                </div> -->
                                        </div>

                                    </div>



                                    <div class="row mt-3" *ngIf="getOtpClicked">
                                        <div class="col-md-6">
                                            <button mat-raised-button class="w-100 btn btn-primary"
                                                (click)="verifyOtp()">{{'submit' |
                                                translate}}</button>
                                        </div>
                                        <div class="col-md-6">
                                            <button mat-raised-button *ngIf="counter == 0" class="w-100 btn btn-default"
                                                (click)="resetForm()">{{'reset' | translate}}</button>
                                        </div>
                                    </div>
                                </form>


                            </div>

                            <div class="col-xl-6 col-lg-5 col-md-5 col-xs-12 col-sm-12 hidden-xs   align-self-center pt-2 bg-light"
                                *ngIf="step0">
                                <!-- <div class="alert alert-primary p-2 mb-3" role="alert">
                                    <div class="d-flex">
                                        <i class="fa fa-info-circle mr-1"></i>
                                        <p>An individual or Living is that which exists as a distinct entity.
                                            Individuality (or self-hood) is the state or quality of being an individual;
                                            particularly (in the case of humans) of being a person unique from other
                                            people and possessing one's own needs or goals, rights and responsibilities.
                                        </p>
                                    </div>
                                </div> -->

                                <div class="alert alert-primary p-2 mb-3" role="alert">
                                    <div class="d-flex">
                                        <i class="fa fa-info-circle mr-1"></i>
                                        <p>{{'guidelines-lp-in'|translate}}</p>
                                    </div>
                                </div>


                                <!-- <div class="loginimgBox">
                                <div class="d-flex text-center" style="align-items: center;">
                                    <img class="mr-2" src="assets/images/logo-1.png" width="70" style="position: relative;">
                                    <h2 class="m-0" style="font-size: 36px;    font-weight: 600;    color: #034377;"> SAMPADA 2</h2>
                                </div>


                                <img src="assets/images/loginimg.png" class="w-100">
                                <img src="assets/images/loginimg2.png" class="w-100 slideanimation">
                                <img src="assets/images/loginimg3.png" class="w-100 slideanimation">
                                <img src="assets/images/loginimg4.png" class="w-100 slideanimation2">
                                <img src="assets/images/loginimg5.png" class="w-100 slideanimation2">
                            </div> -->
                            </div>
                        </div>

                    </div>
                </div>

                <div class="card bg-transparent" *ngIf="isIndian==0 && step21">
                    <div class="card-body p-0 pl-3 pr-3">
                        <div class="row">
                            <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12  logInForms bg-white radius4 p-5">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="from-group">
                                            <label
                                                class="w-100">{{'citizen-registration-details.enter-passport'|translate}}
                                                <span style="color: red;">*</span></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="fa fa-book"></i></span>
                                                </div>
                                                <input type="text" name="passportNo" [(ngModel)]="passportNo"
                                                    class="form-control input_user"
                                                    placeholder="{{'citizen-registration-details.enter-passport'|translate}}"
                                                    autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="input-group mb-3 upload-btn">
                                            <input type="file" class="form-control" placeholder="" aria-label=""
                                                aria-describedby="button-addon2" style="    position: absolute;
                                                                    width: 100%;
                                                                    z-index: 1;
                                                                    height: 36px;opacity: 0;" autocomplete="off">
                                            <button mat-raised-button class="btn btn-default " type="button"
                                                id="button-addon2">
                                                <mat-icon>add</mat-icon>
                                                {{'citizen-registration-details.upload-passport-file'|translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <button mat-raised-button class="w-100 btn btn-primary"
                                            (click)="savePassport()">{{'submit'|translate}}</button>
                                    </div>
                                    <div class="col-md-6">
                                        <button mat-raised-button class="w-100 btn "
                                            (click)="resetPassport()">{{'reset'|translate}}</button>
                                    </div>
                                </div>

                            </div>

                            <div class="col-xl-7 col-lg-6 col-md-6 col-sm-12 hidden-xs   align-self-center p-5 bg-light"
                                *ngIf="step0">

                                <div class="alert alert-primary mb-3" role="alert">
                                    <div class="d-flex">
                                        <i class="fa fa-info-circle mr-1"></i>
                                        <p>{{'nri-pio-meaning' | translate}} </p>
                                    </div>
                                </div>
                                <div class="alert alert-primary mb-3" role="alert">
                                    <div class="d-flex">
                                        <i class="fa fa-info-circle mr-1"></i>
                                        <p>{{'guidelines-lp-ni'|translate}}, </p>
                                    </div>
                                </div>

                                <!-- <div class="loginimgBox">
                                    <div class="d-flex text-center" style="align-items: center;">
                                        <img class="mr-2" src="assets/images/logo-1.png" width="70" style="position: relative;">
                                        <h2 class="m-0" style="font-size: 36px;    font-weight: 600;    color: #034377;"> SAMPADA 2</h2>
                                    </div>


                                    <img src="assets/images/loginimg.png" class="w-100">
                                    <img src="assets/images/loginimg2.png" class="w-100 slideanimation">
                                    <img src="assets/images/loginimg3.png" class="w-100 slideanimation">
                                    <img src="assets/images/loginimg4.png" class="w-100 slideanimation2">
                                    <img src="assets/images/loginimg5.png" class="w-100 slideanimation2">
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div *ngIf="isIndian==1 && step12">
            <div class="registrationSteps">
                <div class="steps ">
                    <div class="stepBox ">
                        <div class="d-flex  align-items-center">
                            <i class="stepIcon fa fa-mobile"></i>
                            <p>{{'mobile-verification'|translate}}</p>
                        </div>
                        <button (click)="goBack(12)" class="stepgoback">{{'goback'|translate}}</button>
                    </div>
                    <i class="fa fa-caret-right stepforword"></i>

                </div>
                <div class="steps active mx-3">
                    <div class="stepBox">
                        <div class="d-flex  align-items-center">
                            <i class="stepIcon fa fa-user-circle-o"></i>
                            <p> {{'kyc-detail'|translate}}</p>
                        </div>
                    </div>
                    <i class="fa fa-caret-right stepforword"></i>

                </div>

                <div class="steps" *ngIf="step2clear" (click)="gotostep13()">
                    <div class="stepBox">
                        <div class="d-flex  align-items-center">
                            <i class="stepIcon fa fa-phone"></i>
                            <p> {{'demographic-details'|translate}}</p>
                        </div>
                    </div>
                </div>
                <div class="steps" *ngIf="!step2clear">
                    <div class="stepBox">
                        <div class="d-flex  align-items-center">
                            <i class="stepIcon fa fa-phone"></i>
                            <p> {{'demographic-details'|translate}}</p>
                        </div>
                    </div>
                </div>


            </div>


            <app-kyc-details (verifyAdharOtpOut)="verifyAdharOtpOut($event)" (verifyPanOut)="verifyPanOut($event)"
                [kycSource]="kycSource" [kycObject]="kycObject" [isProtestReq] = "estampPartyModel.isProtestRequest">
            </app-kyc-details>
            <!-- <input type="button" name="backtootpvalidation" value="Back" (click)="goBack(12)" /> -->
            <button mat-raised-button (click)="goBack(12)" class="mt-2">{{'goback'|translate}}</button>
        </div>

        <div *ngIf="isIndian==0 && step22">

            <div class="registrationSteps">

                <div class="steps  mr-3">
                    <div class="stepBox">
                        <div class="d-flex  align-items-center">
                            <i class="stepIcon fa fa-phone"></i>
                            <p>{{'passport-details'|translate}}</p>
                        </div>
                        <button (click)="goBack(22)" class="stepgoback">{{'goback'|translate}}</button>
                    </div>
                    <i class="fa fa-caret-right stepforword"></i>

                </div>
                <div class="steps active">
                    <div class="stepBox">
                        <div class="d-flex  align-items-center">
                            <i class="stepIcon fa fa-phone"></i>
                            <p> {{'demographic-details'|translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <app-demographic-details [kycSource]="kycSource" [kycObject]="kycObject" [citizenType]="citizenType"
                [isIndian]="isIndian" [isProtestReq] = "estampPartyModel.isProtestRequest" (demographicDetailsSaveOut)="demographicDetailsSaveOut($event)" >
            </app-demographic-details>
            <!-- <input type="button" name="backtonridetails" value="Back" (click)="goBack(22)" /> -->
            <button mat-raised-button (click)="goBack(22)" class="mt-2">{{'goback'|translate}} </button>
        </div>

        <div *ngIf="isIndian==1 && step13">
            <div class="registrationSteps">
                <div class="steps ">
                    <div class="stepBox ">
                        <div class="d-flex  align-items-center">
                            <i class="stepIcon fa fa-mobile"></i>
                            <p>{{'mobile-verification'|translate}}</p>
                        </div>
                    </div>
                    <i class="fa fa-caret-right stepforword"></i>

                </div>
                <div class="steps  mx-3">
                    <div class="stepBox">
                        <div class="d-flex  align-items-center">
                            <i class="stepIcon fa fa-user-circle-o"></i>
                            <p> {{'kyc-detail'|translate}}</p>
                        </div>
                        <button (click)="goBack(13)" class="stepgoback">{{'goback'|translate}}</button>
                    </div>
                    <i class="fa fa-caret-right stepforword"></i>

                </div>
                <div class="steps active">
                    <div class="stepBox">
                        <div class="d-flex  align-items-center">
                            <i class="stepIcon fa fa-id-card-o"></i>
                            <p> {{'demographic-details'|translate}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <app-demographic-details [kycSource]="kycSource" [kycObject]="kycObject" [mobileNo]="mobileNo"
                [citizenType]="citizenType" [isIndian]="isIndian"
                (demographicDetailsSaveOut)="demographicDetailsSaveOut($event)" [isProtestReq] = "estampPartyModel.isProtestRequest"></app-demographic-details>
            <!-- <input type="button" name="backtokycdetails" value="Back" (click)="goBack(13)" /> -->
            <button mat-raised-button (click)="goBack(13)" class="mt-2">{{'goback'|translate}}</button>
        </div>



    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>