import { Injectable } from '@angular/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { CustomHttpClient } from 'projects/common-lib/src/lib/helper-lib/httpclient/custom.httpclient';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { ResultModel } from 'projects/common-lib/src/lib/models/api.result.model/result.model';
import { IgrsEregTxnDetlsBean } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { IgrsProtestReleaseTxn } from 'projects/common-lib/src/lib/models/IgrsProtestReleaseTxn';
import { IgrsProtestRequestTxn } from 'projects/common-lib/src/lib/models/IgrsProtestRequestTxn';
import { map } from "rxjs/operators";
import { IgrsProtestLoanDetailsBean } from '../models/IgrsProtestLoanDetailsBean';

@Injectable({
  providedIn: 'root'
})
export class ProtestChargesService {
  private settings!: AppSetting;
  constructor(private appSettingsService: AppSettingService, private customeHttpClient: CustomHttpClient, private _AESEncryptDecryptService: AESEncryptDecryptService) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });
  }

  saveCourtDetails(protestReqTxn: IgrsProtestRequestTxn,urlParam:string) {

    return this.customeHttpClient.PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + urlParam+"/protest/saveCourtDetails", protestReqTxn
    ).pipe(
      map((result: any) => {
        //if (result) {
        return result;
        //}
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }

  updateIgrsProtestRequestApplicantDetails(protestReqTxn: IgrsProtestRequestTxn,urlParam:string) {

    return this.customeHttpClient.PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + urlParam+"/protest/updateIgrsProtestRequestApplicantDetails", protestReqTxn
    ).pipe(
      map((result: any) => {
        //if (result) {
        return result;
        //}
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }

  getCourtTypeDetails() {

    return this.customeHttpClient.Post<ResultModel<any>>(
      this.settings.igrs_services_url + "common/protest/getCourtType", ""
    ).pipe(
      map((result: any) => {
        //if (result) {
        return result;
        //}
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }

  getProtestRequestDetails(id: String , urlParam : string) {

    return this.customeHttpClient.GetApi<ResultModel<any>>(
      this.settings.igrs_services_url + urlParam + "/protest/getProtestRequestDetails/"+id,""
    ).pipe(
      map((result: any) => {
        //if (result) {
        return result;
        //}
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }
 /*  getProtestReleaseDetails(id: number){

  return this.customeHttpClient.GetApi<ResultModel<any>> (
  this.settings.igrs_services_url+ "citizen/protest/getProtestReleaseDetails/${id},""
  ).pipe(
      map((result: any) => {
        //if (result) {
        return result;
        //}
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  } */
    getProtestReleaseDetails(id: string) {
      return this.customeHttpClient.GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/protest/getProtestReleaseDetails/"+id,""
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
    
    }

    getProtestReleaseDetailsShow(id: string) {
      return this.customeHttpClient.GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/protest/getProtestReleaseDetails/"+id,""
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
    
    }

  getProtestIdEregPropDetailsByEregId(param: {},urlParam:string) {

    return this.customeHttpClient.PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + urlParam+"/protest/getProtestIdEregPropDetailsByEregId",param
    ).pipe(
      map((result: any) => {
        //if (result) {
        return result;
        //}
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }


  saveReleaseRequestCourtOrderDetails(igrsProtestReleaseTxn: IgrsProtestReleaseTxn) {

    return this.customeHttpClient.PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/protest/saveReleaseRequestCourtOrderDetails", igrsProtestReleaseTxn
    ).pipe(
      map((result: any) => {
        //if (result) {
        return result;
        //}
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }



  updateProtestIdInPropertyIdentification(igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean,urlParam:string) {

    return this.customeHttpClient.PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + urlParam +"/protest/updatePropIdentificationDetailsByProtestRef", igrsEregTxnDetlsBean
    ).pipe(
      map((result: any) => {
        //if (result) {
        return result;
        //}
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }

  
  downloadProtestandChargesDoc(fileName: string, monthYear:string) {
    fileName = this._AESEncryptDecryptService.encrypt(fileName);
    const param = {
      fileName : fileName
    }
      window.open(this.settings.igrs_services_url + "common/protest/downloadProtestDoc?fileName=" + fileName +"&monthyear="+monthYear,'_blank');
  
  }

  downloadReleaseDoc(fileName: string, monthYear:string) {
    fileName = this._AESEncryptDecryptService.encrypt(fileName);
    const param = {
      fileName : fileName
    }
      window.open(this.settings.igrs_services_url + "common/protest/downloadReleaseDoc?fileName=" + fileName +"&monthyear="+monthYear,'_blank');
  
  }

  viewEregDocumentForNullAndVoid(eregId : string) {

    return this.customeHttpClient.PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url +"department/protest/viewERegistryDocument/" + Number(eregId) , {}
    ).pipe(
      map((result: any) => {      
        return result;        
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }

  viewEregNullified(fileName: string, monthYear:string) {

    return this.customeHttpClient.PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url +"department/protest/getNullifiedRegDoc?fileName=" + fileName +"&monthyear="+monthYear,'_blank'
    ).pipe(
      map((result: any) => {      
        return result;        
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }

  applyNullAndVoidSealOnDocument(protestTxnBean : IgrsProtestRequestTxn) {

    return this.customeHttpClient.PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url +"department/protest/applyNullVoidSeal/" , protestTxnBean
    ).pipe(
      map((result: any) => {      
        return result;        
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }
    


  getAllProtestRequestListForSR(param: any) {

    return this.customeHttpClient
        .PostApiToken<ResultModel<any>>(
            this.settings.igrs_services_url + "department/protest/listProtestRequest", param
        ).pipe(
            map((result: any) => {

                return result;
            })
        );
}


getEregPropDetailsByEregIdAndProtestId(param: {}) {

  return this.customeHttpClient.PostApiToken<ResultModel<any>>(
    this.settings.igrs_services_url + "department/protest/getEregPropDetailsByProtestIdEregId",param
  ).pipe(
    map((result: any) => {
      //if (result) {
      return result;
      //}
    },
      (error: any) => {
        console.log("error:-", error);
        return error;
      }
    )
  );
}

  
updateProtestRequestStatus(bean : IgrsProtestRequestTxn) {

  return this.customeHttpClient.PostApiToken<ResultModel<any>>(
    this.settings.igrs_services_url + "department/protest/updateProtestStatusBySr",bean
  ).pipe(
    map((result: any) => {
      //if (result) {
      return result;
      //}
    },
      (error: any) => {
        console.log("error:-", error);
        return error;
      }
    )
  );
}

updateProtestStatus(bean : IgrsProtestRequestTxn) {

  return this.customeHttpClient.PostApiToken<ResultModel<any>>(
    this.settings.igrs_services_url + "citizen/protest/updateProtestStatus",bean
  ).pipe(
    map((result: any) => {
      //if (result) {
      return result;
      //}
    },
      (error: any) => {
        console.log("error:-", error);
        return error;
      }
    )
  );
}

updateProtestReleaseStatus(bean : IgrsProtestReleaseTxn) {

  return this.customeHttpClient.PostApiToken<ResultModel<any>>(
    this.settings.igrs_services_url + "citizen/protest/updateProtestReleaseStatus",bean
  ).pipe(
    map((result: any) => {
      //if (result) {
      return result;
      //}
    },
      (error: any) => {
        console.log("error:-", error);
        return error;
      }
    )
  );
}
updateProtestReleaseStatusFinal(bean : IgrsProtestReleaseTxn) {

  return this.customeHttpClient.PostApiToken<ResultModel<any>>(
    this.settings.igrs_services_url + "department/protest/updateProtestReleaseStatus",bean
  ).pipe(
    map((result: any) => {
      //if (result) {
      return result;
      //}
    },
      (error: any) => {
        console.log("error:-", error);
        return error;
      }
    )
  );
}


getAllProtestRequestListForCitizenUser(param: any) {

  return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
          this.settings.igrs_services_url + "citizen/protest/listProtestRequest", param
      ).pipe(
          map((result: any) => {

              return result;
          })
      );
}

getPaymentReqDetailsDept(urnNumber: string) {
  return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
          this.settings.igrs_gateway_url + "department/getonlinepaymentreqdetails",
          urnNumber
      ).pipe(
          map((result: any) => {
              if (result) {
                  return result;
              }
          },
              (error: any) => {

                  return error;
              }
          )
      );
}


saveBankLoanDetails(igrsProtestLoanDetailsBean: IgrsProtestLoanDetailsBean,urlParam:string) {

  return this.customeHttpClient.PostApiToken<ResultModel<any>>(
    this.settings.igrs_services_url + urlParam+"/protest/saveProtestLoanDetails", igrsProtestLoanDetailsBean
  ).pipe(
    map((result: any) => {
      //if (result) {
      return result;
      //}
    },
      (error: any) => {
        console.log("error:-", error);
        return error;
      }
    )
  );
}
getProtestLoanDetailsByFkProtestId(id:Number,urlParam:string){
  return this.customeHttpClient.GetApi<ResultModel<any>>(
    this.settings.igrs_services_url + urlParam+"/protest/getProtestLoanDetailsByFkProtestId/"+id, id
  ).pipe(
    map((result: any) => {
      //if (result) {
      return result;
      //}
    },
      (error: any) => {
        console.log("error:-", error);
        return error;
      }
    )
  );
}


getmststatusProtest(){
  return this.customeHttpClient.Get<ResultModel<any>>(
    this.settings.igrs_services_url+"common/protest/getMstStatusForProtestCharges/"
  ).pipe(
    map((result: any) => {
      //if (result) {
      return result;
      //}
    },
      (error: any) => {
        console.log("error:-", error);
        return error;
      }
    )
  );
}



updateProtestRequestStatusById(protestReqTxn: IgrsProtestRequestTxn,urlParam:string) {
  return this.customeHttpClient.PostApiToken<ResultModel<any>>(
          this.settings.igrs_services_url + urlParam+"/protest/updateIgrsProtestRequestStatus" ,protestReqTxn
          
      ).pipe(
          map((result: any) => {
              if (result) {
                  return result;
              }
          },
              (error: any) => {

                  return error;
              }
          )
      );
}


verifyCaptcha(param: {}) {

  return this.customeHttpClient.Post<ResultModel<any>>(
    this.settings.igrs_services_url + "common/protest/verifyCaptchaStr",param
  ).pipe(
    map((result: any) => {
      //if (result) {
      return result;
      //}
    },
      (error: any) => {
        console.log("error:-", error);
        return error;
      }
    )
  );
}

getProtestAndReleasePropertyDetails(id: string) {
  return this.customeHttpClient.GetApi<ResultModel<any>>(
    this.settings.igrs_services_url + "citizen/protest/getProtestAndReleasePropertyDetails/"+id,""
  ).pipe(
    map((result: any) => {
      //if (result) {
      return result;
      //}
    },
      (error: any) => {
        console.log("error:-", error);
        return error;
      }
    )
  );

}


}




