import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ChatbotService } from './chatbot.service';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  @ViewChild('chatBox') private chatBox!: ElementRef;

  messages: any[] = [];
  userMessage: string = '';
  isOpen: boolean = false;
  hasGreeted: boolean = false; // Flag to check if greeting message is added
  isTyping: boolean = false; // Flag for typing animation
  currentLanguage: string = 'en'; // Language state
  showHover: boolean = false; // State for hover message visibility
  showContextMenu: boolean = true; // State for context menu visibility
  suggestions: string[] = []; // Array for suggestions

  modules: any[] = [];
  selectedModule: any = null;
  selectedSubModule: any = null;

  errorMessages: { [key: string]: string } = {
    en: 'Sorry, something went wrong. Please try again later.',
    hi: 'क्षमा करें, कुछ गलत हो गया। कृपया बाद में पुनः प्रयास करें।'
  };
@ViewChild('messageInput') private messageInput!: ElementRef;

  constructor(
    private chatbotService: ChatbotService,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // Show hover message for 3 seconds when the page loads
    this.showHover = true;
    setTimeout(() => {
      this.showHover = false;
    }, 3000);

    // Subscribe to language change
    this.translateService.onLangChange.subscribe((event: any) => {
      this.currentLanguage = event.lang;
      if (this.isOpen) {
        this.loadModules(); // Reload the modules if the chatbot is open when the language changes
      }
      this.resetChat(true); // Reset the chat when the language changes
    });

    // Initial language setting
    this.currentLanguage = this.translateService.currentLang;
  }

  loadModules() {
    this.chatbotService.getChatbotData().subscribe(
      (data) => {
        // Transforming the API response to fit the expected structure
        this.modules = data.response.map((module: any) => ({
          name: this.currentLanguage === 'en' ? module.nameEn : module.nameHi,
          subModules: module.subModules.map((subModule: any) => ({
            name: this.currentLanguage === 'en' ? subModule.nameEn : subModule.nameHi,
            questions: subModule.questions.map((question: any) =>
              this.currentLanguage === 'en' ? question.questionEn : question.questionHi
            )
          }))
        }));
      },
      (error) => {
        console.error('Error fetching chatbot data:', error);
        const errorMsg = {
          sender: 'bot',
          content: this.errorMessages[this.currentLanguage],
          time: new Date().toLocaleString('en-US', { weekday: 'short', hour: '2-digit', minute: '2-digit' })
        };
        this.messages.push(errorMsg);
        this.cdRef.detectChanges();
      }
    );
  }  

  sendMessage() {
    if (this.userMessage.trim() === '') return;

    const userMsg = { 
      sender: 'user', 
      content: this.userMessage, 
      time: new Date().toLocaleString('en-US', { weekday: 'short', hour: '2-digit', minute: '2-digit' })
    };
    this.messages.push(userMsg);
    this.isTyping = true; // Show typing animation

    // Clear user message input
    this.userMessage = '';
    this.showContextMenu = false; // Hide the context menu when user sends a message
    this.suggestions = []; // Clear suggestions

    // Simulate a typing delay
    setTimeout(() => {
      this.chatbotService.sendMessage(userMsg, this.currentLanguage).subscribe(
        response => {
          this.isTyping = false; // Hide typing animation
          const botMsg = { 
            sender: 'bot', 
            content: response[0].text, 
            time: new Date().toLocaleString('en-US', { weekday: 'short', hour: '2-digit', minute: '2-digit' }) 
          };
          this.messages.push(botMsg);
          this.cdRef.detectChanges(); // Ensure the view is updated
          this.scrollToBottom(); // Ensure the chat scrolls to the bottom
          this.messageInput.nativeElement.focus(); // Keep the input box focused
        },
        error => {
          this.isTyping = false; // Hide typing animation
          const errorMsg = { 
            sender: 'bot', 
            content: this.errorMessages[this.currentLanguage], 
            time: new Date().toLocaleString('en-US', { weekday: 'short', hour: '2-digit', minute: '2-digit' }) 
          };
          this.messages.push(errorMsg);
          this.cdRef.detectChanges(); // Ensure the view is updated
          this.scrollToBottom(); // Ensure the chat scrolls to the bottom
          this.messageInput.nativeElement.focus(); // Keep the input box focused
        }
      );
    }, 500); // Adjust the delay as needed

    this.cdRef.detectChanges(); // Ensure the view is updated
    this.scrollToBottom(); // Ensure the chat scrolls to the bottom
  }

  toggleChat() {
    this.isOpen = !this.isOpen;
    // Load modules whenever the user opens the chatbot
    if (this.isOpen) {
      this.loadModules(); // Always load the modules when opening the chatbot
    }
    // Add greeting message if this is the first time opening
    if (this.isOpen && !this.hasGreeted) {
      this.addGreetingMessage();
      this.hasGreeted = true; // Set the flag to true after greeting
    }
  }

  resetChat(initial = false) {
    this.messages = [];
    this.userMessage = '';
    this.hasGreeted = false; // Reset the greeting flag
    this.selectedModule = null;
    this.selectedSubModule = null;
    this.showContextMenu = true; // Show the context menu
    this.suggestions = []; // Clear suggestions

    if (this.isOpen || initial) {
      this.addGreetingMessage(); // Add greeting message after resetting if chat is open or initial reset
    }
  }

  addGreetingMessage() {
    if (!this.hasGreeted) {
      const greetingMessage = this.currentLanguage === 'en' 
        ? { sender: 'bot', content: 'Hello! I am Saathi your virtual assistant. How can I help you today?' }
        : { sender: 'bot', content: 'नमस्ते! मैं साथी आपका वर्चुअल सहायक हूँ। आज मैं आपकी किस प्रकार सहायता कर सकता हूँ?' };
      this.messages.push(greetingMessage);
      this.hasGreeted = true; // Set the flag to true after greeting
      this.cdRef.detectChanges(); // Ensure the view is updated
      this.scrollToBottom(); // Ensure the chat scrolls to the bottom after adding greeting message
    }
  }

  private scrollToBottom(): void {
    if (this.chatBox) {
      try {
        this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
      } catch (err) {
        console.error(err);
      }
    }
  }

  selectModule(module: any) {
    this.selectedModule = module;
    if (module.subModules && module.subModules.length === 1 && module.subModules[0].name === module.name) {
      this.selectedSubModule = module.subModules[0];
    } else if (!module.subModules) {
      this.selectedSubModule = { questions: module.questions };
    }
  }

  resetMenu() {
    this.selectedModule = null;
    this.selectedSubModule = null;
  }

  selectSubModule(subModule: any) {
    this.selectedSubModule = subModule;
  }

  askQuestion(question: string) {
    this.showContextMenu = false; // Hide the context menu
    this.sendMessageToRasa(question);
  }

  sendMessageToRasa(message: string) {
    const userMsg = { 
      sender: 'user', 
      content: message, 
      time: new Date().toLocaleString('en-US', { weekday: 'short', hour: '2-digit', minute: '2-digit' })
    };
    this.messages.push(userMsg);
    this.isTyping = true; // Show typing animation

    // Simulate a typing delay
    setTimeout(() => {
      this.chatbotService.sendMessage(userMsg, this.currentLanguage).subscribe(
        response => {
          this.isTyping = false; // Hide typing animation
          const botMsg = { 
            sender: 'bot', 
            content: response[0].text, 
            time: new Date().toLocaleString('en-US', { weekday: 'short', hour: '2-digit', minute: '2-digit' }) 
          };
          this.messages.push(botMsg);
          this.cdRef.detectChanges(); // Ensure the view is updated
          this.scrollToBottom(); // Ensure the chat scrolls to the bottom
          this.messageInput.nativeElement.focus(); // Keep the input box focused
        },
        error => {
          this.isTyping = false; // Hide typing animation
          const errorMsg = { 
            sender: 'bot', 
            content: this.errorMessages[this.currentLanguage], 
            time: new Date().toLocaleString('en-US', { weekday: 'short', hour: '2-digit', minute: '2-digit' }) 
          };
          this.messages.push(errorMsg);
          this.cdRef.detectChanges(); // Ensure the view is updated
          this.scrollToBottom(); // Ensure the chat scrolls to the bottom
          this.messageInput.nativeElement.focus(); // Keep the input box focused
        }
      );
    }, 500); // Adjust the delay as needed

    this.cdRef.detectChanges(); // Ensure the view is updated
    this.scrollToBottom(); // Ensure the chat scrolls to the bottom
    this.messageInput.nativeElement.focus(); // Keep the input box focused
  }

  onUserTyping() {
    this.suggestions = this.getSuggestions(this.userMessage);
  }

  getSuggestions(input: string): string[] {
    if (!input) {
      return [];
    }
    const questions = this.getAllQuestions();
    return questions.filter(question => question.toLowerCase().includes(input.toLowerCase()));
  }

  getAllQuestions(): string[] {
    const questions: string[] = [];
    for (const module of this.modules) {
      if (module.subModules) {
        for (const subModule of module.subModules) {
          questions.push(...subModule.questions);
        }
      } else {
        questions.push(...module.questions);
      }
    }
    return questions;
  }

  selectSuggestion(suggestion: string) {
    this.userMessage = suggestion;
    this.sendMessage();
  }
  adjustTextareaHeight() {
  const textarea = this.messageInput.nativeElement;
  textarea.style.height = 40;
  const maxHeight = 4 * parseFloat(getComputedStyle(textarea).lineHeight);
  if (textarea.scrollHeight > maxHeight) 
    {
    textarea.style.height = `${maxHeight}px`;
    textarea.style.overflowY = 'scroll';
  } else {
    textarea.style.height = `${textarea.scrollHeight}px`;
    textarea.style.overflowY = 'hidden';
  }
}
}

  