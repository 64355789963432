import { MstDemographyBean } from "../MstDemographyBean";
import { MstPropertyTypeBean } from "../MstPropertyTypeBean";

export class DutyPropInputFields {
    id: number;
    propValId:number;
    propertyType:MstPropertyTypeBean;
    considerationAmt : number;
    propId:string;
    seqNo:number;
    value: string;
    marketValue: number;
    builderMarketValue: number;
    flag:string;
    mstDemographyBean:MstDemographyBean;
    totalArea:number
    transactingArea:number;
    unit:string
}
