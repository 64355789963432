<div class="position-relative">
  <div class="fid-header"></div>
  <div class="breadcrumb-content">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <h2>
          {{
            lan == 0
              ? "Frequently Asked Questions (FAQs)"
              : "अक्सर पूछे जाने वाले प्रश्न"
          }} - {{ lan == 0 ? moduleNameEn : moduleNameHi }}
        </h2>
        <!-- Add button to go to FAQ Modules -->
        <a routerLink="/faqModules" class="btn btn-primary ml-3">
          {{ lan == 0 ? "Go to FAQs" : "अक्सर पूछे जाने वाले प्रश्न पर जाएं" }}
        </a>
      </div>
    </div>
  </div>

  <div class="container mb-3" *ngIf="showData">
    <mat-card class="example-card">
      <mat-card-content class="mat-card-content">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let question of sanitizedQuestions; let i = index" [expanded]="step === i" (opened)="setStep(i)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div *ngIf="lan == 0">{{ question.questionTextEn }}</div>
                <div *ngIf="lan == 1">{{ question.questionTextHi }}</div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="p-3">
                <div *ngIf="lan == 0" [innerHTML]="question.answerTextEn"></div>
                <div *ngIf="lan == 1" [innerHTML]="question.answerTextHi"></div>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-content>
    </mat-card>
  </div>
</div>
